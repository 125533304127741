// src/context/UserContext.js
import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { API_ENDPOINT } from "../../constants/constants";
import { getHeader } from "../../utility/utility";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to load user data from the backend
  const loadUser = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}user/get-profile`,
        getHeader()
      ); // Update with your API endpoint
      setUser(data);
    } catch (error) {
      console.error("Failed to load user", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };
  const refresh = async () => {
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}user/get-profile`,
        getHeader()
      ); // Update with your API endpoint
      setUser(data);
    } catch (error) {}
  };

  // Load user data when the component mounts
  useEffect(() => {
    loadUser();
  }, []);

  return (
    <UserContext.Provider
      value={{ user, loading, refresh, reloadUser: loadUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
