import { useState } from "react";

const useModalState = () => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState("");

  const showModal = (label = "") => {
    setModalData(label);
    setModalVisibility(true);
  };

  const hideModal = () => {
    setModalVisibility(false);
  };

  return {
    isModalVisible,
    setModalVisibility,
    modalData,
    showModal,
    hideModal,
  };
};

export default useModalState;
