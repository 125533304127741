import React from "react";

const ProfileInfoCard = ({ infoCard }) => {
  if (!infoCard) return <div>No data</div>;
  return (
    <div>
      <div style={{ fontWeight: 600, fontSize: "14px" }}>{infoCard.label}</div>
      <div style={{ color: "#777", fontSize: "14px", marginTop: "5px" }}>
        {`${infoCard.value}`.startsWith("http") ? (
          <a href={infoCard.value} target="_blank" rel="noopener noreferrer">
            {infoCard.value}
          </a>
        ) : (
          infoCard.value
        )}
      </div>
    </div>
  );
};

export default ProfileInfoCard;
