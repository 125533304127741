import { Alert, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import RegHeader from "../../components/headers/regHeader";
import Footer from "../footer/footer";
import { LoadingButton } from "@mui/lab";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import axios from "axios";
import { API_ENDPOINT } from "../../constants/constants";
import {
  deleteOnsite,
  isOnsiteActivated,
  toastError,
} from "../../utils2024/utils";
import { toast } from "react-toastify";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Link } from "react-router-dom";

const ActivateOnsite = () => {
  const [acivating, setActivating] = useState(false);
  const [activated, setActivated] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  useEffect(() => {
    if (isOnsiteActivated()) {
      setActivated(true);
    }
  }, []);
  const onSubmit = async () => {
    setActivating(true);
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}user/activate-onsite-link`
      );
      toast.success(data.message);
      setLinkSent(true);
    } catch (error) {
      toastError(error);
    } finally {
      setActivating(false);
    }
  };
  const deActivate = () => {
    deleteOnsite();
    document.location.reload();
  };
  if (activated) {
    return (
      <Box height={"100vh"} display={"flex"} flexDirection={"column"}>
        <RegHeader />
        <Box
          flex={1}
          alignContent={"center"}
          ml={"auto"}
          mr={"auto"}
          alignItems={"center"}
        >
          <Alert severity="success">
            Onsite Module activated in your system!
          </Alert>
          <Box mt={2} display="flex" flexDirection="column" alignItems="center">
            <Link to="/onsite-users">Onsite Users</Link>
            <Link to="/spot-registration">Spot Registration</Link>
            <Link to="/exhibitor-badges">Exhibitor Badges</Link>
            <Link to="/scan-badge">Scan Badge</Link>
            <Link to="/bulk-student-badges">Bulk Student Badges</Link>
            <Link to="/security-scan">Security Scan</Link>
          </Box>

          <LoadingButton
            sx={{ ml: "auto", mr: "auto", mt: 4, display: "block" }}
            variant="contained"
            onClick={deActivate}
          >
            Deactivate
          </LoadingButton>
        </Box>
        <Footer />
      </Box>
    );
  }
  return (
    <Box height={"100vh"} display={"flex"} flexDirection={"column"}>
      <RegHeader />
      <Box
        flex={1}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {!linkSent ? (
          <LoadingButton
            loading={acivating}
            onClick={onSubmit}
            startIcon={<DoneAllIcon />}
            variant="contained"
          >
            Activate Onsite Module
          </LoadingButton>
        ) : (
          <Alert severity="info">
            <Typography variant="h6">
              Activate link is sent successfully! Activate the onsite module in
              this system by following the instructions in the mail.{" "}
            </Typography>
          </Alert>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default ActivateOnsite;
