import React from "react";
import "./ExhibitorCard.css";
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";
import ForumIcon from "@mui/icons-material/Forum";
import { DEFAULT_DP, VISITOR } from "../constants/constants";
import EastIcon from "@mui/icons-material/East";
import { Link } from "react-router-dom";
import { getUser } from "../utility/utility";
import InteractiveAction2 from "./InteractiveAction2";
const VisitorCard = ({ item }) => {
  const onlineStatus = item.isOnline; // Assuming there's a flag for online status

  return (
    <Card sx={{ maxWidth: 300, border: "1px solid #fdca48", borderRadius: 2 }}>
      <CardContent sx={{ textAlign: "center" }}>
        <Badge
          color="success"
          variant="dot"
          invisible={!onlineStatus}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Avatar
            src={item.profile_image}
            alt={`${item.firstName} ${item.lastName}`}
            sx={{
              width: 90,
              height: 90,
              margin: "auto",
              border: "2px solid #fdca48",
            }}
          />
        </Badge>
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.firstName} {item.lastName}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#444",
              mt: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.designation}
          </Typography>
        </Box>
        <Box mt={2}></Box>
        <InteractiveAction2 item={item} type={VISITOR} />
      </CardContent>
    </Card>
  );
};

export default VisitorCard;
