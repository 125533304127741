import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Container,
  Grid,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import { EXHIBITOR_API, OES_COMPONENTS } from "../../../../constants/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Supplier2 from "./reuse/Supplier2";
import { LoadingButton } from "@mui/lab";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
const VisaApplication = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();

  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();

  const [formData, setFormData] = useState({
    passportNumber: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    dateFrom: null,
    passportExpiryDate: null,
    companyName: "",
    designation: "",
    phoneNumber: "",
    companyEmail: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (newValue, fieldName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const generateFormDataHtmlTemplate = (formData) => {
    // Helper function to check and format the date or return "N/A" if null
    const formatDate = (date) => (date ? date : "N/A");

    // Convert the form data object into an array of key-value pairs
    const formDataEntries = Object.entries(formData);

    // Generate HTML rows for each form data field
    const formDataHtml = formDataEntries
      .map(([field, value]) => {
        // Capitalize field names and replace camelCase with spaces
        const fieldName = field
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        const displayValue = field.includes("date")
          ? formatDate(value)
          : value || "N/A";

        return `
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">${fieldName}</td>
            <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">${displayValue}</td>
          </tr>
        `;
      })
      .join("");

    // Return the full HTML table
    return `
      <table style="width:100%; border-collapse: collapse;">
        <thead>
          <tr style="background-color: #f2f2f2;">
            <th style="border: 1px solid #ddd; padding: 8px;">Fields</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Values</th>
          </tr>
        </thead>
        <tbody>
          ${formDataHtml}
        </tbody>
      </table>
    `;
  };

  // Assuming you have formData state from useState
  const formDataHtmlTemplate = generateFormDataHtmlTemplate(formData);
  // Now you can use formDataHtmlTemplate as needed

  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div>${spaceTemplate(
      data
    )} <br /> ${formDataHtmlTemplate} <br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "Visa Application",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "Visa Application",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card
          elevation={4}
          style={{
            padding: "24px",
            margin: "auto",
          }}
        >
          <Typography variant="h5" mb={1}>
            Visa Application
          </Typography>
          <Box mb={4}>
            {/* <Alert severity="warning">
              <Typography variant="subtitle1">
                Page not yet submitted, the deadline is 23 Dec, 2024.
              </Typography>
            </Alert> */}
          </Box>

          <SpaceInfo data={data} />
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  value={formData.passportNumber}
                  name="passportNumber"
                  onChange={handleInputChange}
                  fullWidth
                  label="Passport No."
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleInputChange}
                  fullWidth
                  label="First Name as written on passport"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleInputChange}
                  fullWidth
                  label="Last Name as written on passport"
                />
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={4}>
                  <DatePicker
                    className="exp_date_pciker"
                    slotProps={{
                      textField: {
                        helperText: "mm/dd/yyyy",
                      },
                    }}
                    size="small"
                    variant="outlined"
                    label="Date of Birth"
                    value={
                      formData.dateOfBirth ? dayjs(formData.dateOfBirth) : null
                    }
                    onChange={(newValue) =>
                      handleDateChange(newValue, "dateOfBirth")
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    className="exp_date_pciker"
                    label="Date From"
                    slotProps={{
                      textField: {
                        helperText: "mm/dd/yyyy",
                      },
                    }}
                    size="small"
                    variant="outlined"
                    value={formData.dateFrom ? dayjs(formData.dateFrom) : null}
                    onChange={(newValue) =>
                      handleDateChange(newValue, "dateFrom")
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    className="exp_date_pciker"
                    label="Passport Date of Expiry"
                    slotProps={{
                      textField: {
                        helperText: "mm/dd/yyyy",
                      },
                    }}
                    size="small"
                    variant="outlined"
                    value={
                      formData.passportExpiryDate
                        ? dayjs(formData.passportExpiryDate)
                        : null
                    }
                    onChange={(newValue) =>
                      handleDateChange(newValue, "passportExpiryDate")
                    }
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  name="companyName"
                  onChange={handleInputChange}
                  fullWidth
                  label="Company Name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  name="designation"
                  onChange={handleInputChange}
                  fullWidth
                  label="Designation"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  type="tel"
                  name="phoneNumber"
                  onChange={handleInputChange}
                  fullWidth
                  label="Phone No."
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  name="companyEmail"
                  onChange={handleInputChange}
                  fullWidth
                  label="Company Email Id"
                />
              </Grid>
            </Grid>
          </Box>
          <Supplier2
            user={data}
            id={19}
            supplier={supplier}
            setSupplier={setSupplier}
          />
          {/* Submit Button */}
          <Box mt={4} display="flex" justifyContent="center">
            <LoadingButton
              loading={submitting}
              disabled={!supplier}
              onClick={onSubmit}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </LoadingButton>
          </Box>
        </Card>
      </LocalizationProvider>
    </Container>
  );
};

export default VisaApplication;
