import { Avatar } from "@mui/material";
import React from "react";

import ProductItemAction from "./actionBtn/productItemAction";

const TeamMember = ({ item, onEdit, onDelete, viewMode = false }) => {
  return (
    <div className="product-item team-member">
      <Avatar
        sx={{ margin: "12px auto auto auto", height: "56px", width: "56px" }}
        alt={item.firstName}
        src={item.profile_image}
      />
      <div style={{ textAlign: "center" }} className="product-item-name">
        {item.firstName} {item.lastName}
      </div>
      <div
        style={{
          textAlign: "center",
          color: "#777",
          marginBottom: "12px",
          fontWeight: "500",
          fontSize: "14px",
        }}
      >
        {item.jobTitle}
      </div>
      {!viewMode && (
        <ProductItemAction
          onEdit={() => onEdit(item)}
          onDelete={() => onDelete(item)}
        />
      )}
    </div>
  );
};

export default TeamMember;
