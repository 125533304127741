import { Box } from '@mui/material';
import React from 'react';

const Footer = () => {
    return (
        <Box sx={{ 
            p: 2, // Padding
            textAlign: 'center', // Center the text
            backgroundColor: '#f0f0f0', // Optional background color
            color: '#333', // Text color
          }}>
            Expoplanner &copy;2023
          </Box>
    );
};

export default Footer;