import React from "react";
import { Box, Typography } from "@mui/material";
import expoFooterImg from "../../assets/expo_footer.png";

const Footer = () => {
  return (
    <Box
      component="footer"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "#054054", // Set the background color to the expected value
        padding: "16px 0",
        borderTop: "1px solid #e0e0e0",
      }}
    >
      <Box
        component="img"
        src={expoFooterImg}
        alt="Expo Footer"
        sx={{ width: "150px", mb: 1 }} // Adjust the width as needed
      />
      <Typography variant="body2" color="white">
        © IMTMA 2024. All rights reserved
      </Typography>
    </Box>
  );
};

export default Footer;
