import { Box, Grid, Pagination, Typography } from "@mui/material";
import React from "react";
import AllEvents from "../components/dashboard/admin/home/AllEvents";
import GetAppIcon from "@mui/icons-material/GetApp";
import { LoadingButton } from "@mui/lab";
import SearchBar from "../widgets/searchBar";
const EventsList = () => {
  return (
    <Box maxWidth={1280} ml={"auto"} mr={"auto"} mt={2}>
      <Grid container alignItems="center" spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={4} md={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              All Events
            </Typography>
            <Box ml={2}>
              <LoadingButton
                startIcon={<GetAppIcon />}
                variant="contained"
                color="primary"
              >
                Export
              </LoadingButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Typography variant="body1">
            Total: <span style={{ fontWeight: "bold" }}>2</span>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <SearchBar
            label="Search Events"
            cstyle={{ margin: "0 auto" }}
            mystyle={{ borderRadius: 0 }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Box display="flex" justifyContent="flex-end">
            <Pagination page={1} count={1} />
          </Box>
        </Grid>
      </Grid>
      <AllEvents />
    </Box>
  );
};

export default EventsList;
