import React from "react";
import Exhibitors from "./Exhibitors";
import Products from "./Products";
import Matchmaking from "./Matchmaking";
import Visitors from "./Visitors";
import News from "./News";
import { Route, Routes, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
const Explore = () => {
  return (
    <Box>
      {/* <NewDashHeader /> */}
      <Box m={1} p={1}>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <Box mt={2}>
              <Typography fontWeight={"550"} fontSize={17}>
                Filter
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={9}>
            <Routes>
              <Route path="" element={<Exhibitors />} />
              <Route path="products" element={<Products />} />
              <Route path="ai-matching" element={<Matchmaking />} />
              <Route path="visitors" element={<Visitors />} />
            </Routes>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Explore;
