import React, { createContext, useState } from "react";
import { createDefaultRegOb } from "../utils/utils";

export const RegFormContext = createContext();
export const RegFormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});

  const updateFormData = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const initForm = (form) => {
    setFormData(createDefaultRegOb(form));
  };
  const initUser = (user) => {
    setFormData(user);
  };

  return (
    <RegFormContext.Provider
      value={{ formData, updateFormData, initForm, initUser }}
    >
      {children}
    </RegFormContext.Provider>
  );
};
