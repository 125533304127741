import React from 'react';
import './RdLoader.css';

function RdLoader({width, height, borderSize, borderColor, borderTopColor}) {
    // Use inline styles to customize loader
    const loaderStyle = {
        border: `${borderSize}px solid ${borderColor}`,
        borderTop: `${borderSize}px solid ${borderTopColor}`,
        borderRadius: '50%',
        width: `${width}px`,
        height: `${height}px`,
        animation: 'rdSpin 1s linear infinite',
        WebkitAnimation: 'rdSpin 1s linear infinite'
    };

    return (
        <div style={loaderStyle}></div>
    );
}

export default RdLoader;
