import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  CssBaseline,
  Alert,
} from "@mui/material";
import { toast } from "react-toastify";
import { Paper, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { validateEmail } from "../../visitorregt/utils/utils";
import { useCommonPost } from "../events/hooks/useCommonPost";
import { API_ENDPOINT, LOGIN_HEADER_BG, USER } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  getDbHeader,
  getErrorResponse,
  toastError,
} from "../../utils2024/utils";

const PasswordReset = ({ show, setShow }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [sentSuccess, setSentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setSentSuccess(false);
    setError("");
    setEmail("");
  }, [show]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      toast.error("Please enter Email ID first");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please enter valid email");
      return;
    }
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}user/create-reset-link`,
        { email },
        getDbHeader()
      );
      toast.success(data.message);
      setSentSuccess(true);
    } catch (error) {
      setError(getErrorResponse(error));
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ padding: "12px 6px" }}>
      <Container component="main" maxWidth="md" sx={{ margin: "16px auto" }}>
        <img
          src={LOGIN_HEADER_BG}
          alt=""
          style={{
            display: "block",
            margin: "10px auto",
            width: "100%",
            maxWidth: "500px",
          }}
        />
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Request New Password
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
      </Container>
      {!sentSuccess ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                size="small"
                label="Registered Email Id"
                type="eamil"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                Submit
              </LoadingButton>

              {/* <p>This link expires in 5 minutes</p> */}
            </form>
          </div>
        </Container>
      ) : (
        <Paper
          elevation={3}
          sx={{
            maxWidth: "600px",
            margin: "40px auto",
            padding: "20px",
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: "20px" }}>
            Password Reset Requested
          </Typography>

          <Typography variant="body1" sx={{ marginBottom: "20px" }}>
            We've received your request to reset your password. If the email
            address you provided is associated with our system, you will receive
            a password reset link shortly. Please check your inbox and follow
            the instructions to reset your password. If you don't receive an
            email within the next 10 minutes, please check your spam/junk
            folder.
          </Typography>

          <Divider sx={{ marginBottom: "20px" }} />

          <Typography variant="body1">
            If you didn't request this password reset, no further action is
            needed. If you continue to experience issues, please contact our
            support team(<b>visitorsonline@imtex.in</b>).
          </Typography>
          <center>
            <Button
              sx={{ mt: 2 }}
              variant="outlined"
              onClick={() => {
                setShow(false);
              }}
            >
              OK
            </Button>
          </center>
        </Paper>
      )}
    </div>
  );
};

export default PasswordReset;
