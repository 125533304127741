import { Box } from "@mui/material";
import React from "react";
import Header from "../header";
import BackBtn from "../../widgets/BackBtn";

const AllocateHall = () => {
  return (
    <Box>
      <Header />
      <Box sx={{ ml: "auto", mr: "auto", maxWidth: "1280px", mt: 2 }}>
        <BackBtn />
      </Box>
    </Box>
  );
};

export default AllocateHall;
