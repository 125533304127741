import React from "react";
import "./ExhibitorCard.css";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";
import { Link } from "react-router-dom";
import ForumIcon from "@mui/icons-material/Forum";
import EastIcon from "@mui/icons-material/East";
import { DEFAULT_EX_ICON, EXHIBITOR } from "../constants/constants";
import { getUser } from "../utility/utility";
import InteractiveAction2 from "./InteractiveAction2";

const ExhibitorCard = ({ item, isOneline = false }) => {
  return (
    <Card
      sx={{
        maxWidth: 300,
        height: isOneline ? undefined : 272, // Set a fixed height for the card
        border: "1px solid #fdca48",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardContent sx={{ textAlign: "center", flexGrow: 1 }}>
        <Badge
          color="success"
          variant="dot"
          invisible={!item.isOnline} // Assuming there's a flag for online status
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Avatar
            src={item.profile_image ? item.profile_image : DEFAULT_EX_ICON}
            alt={`${item?.companyName?.value}`}
            sx={{
              width: 90,
              height: 90,
              margin: "auto",
              backgroundColor: "#a4a4a4", // Replace the border with a background color
            }}
          />
        </Badge>
        <Box sx={{ mt: 2 }}>
          <Box height={isOneline ? undefined : "48px"}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: isOneline ? 1 : 2, // Set line clamp based on isOneline
                lineHeight: "1.2em",
                maxHeight: isOneline ? "1.2em" : "2.4em", // Adjust maxHeight for one or two lines
                fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" }, // Responsive font size
              }}
            >
              {item?.companyName?.value}
            </Typography>
          </Box>

          <Link
            to={`/hall-view/${item?.hallNumber?.value}?un=${item.username}`}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#444",
                mt: isOneline ? 2 : 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item?.hallNumber?.value} | {item?.standNumber?.value}
            </Typography>
          </Link>
        </Box>
        <Box mt={2}></Box>
        <InteractiveAction2 item={item} type={EXHIBITOR} />
      </CardContent>
    </Card>
  );
};

export default ExhibitorCard;
