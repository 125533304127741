import React, { Fragment, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  ListItemButton,
  Button,
  ThemeProvider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import DateRangeIcon from "@mui/icons-material/DateRange";
import StorageIcon from "@mui/icons-material/Storage";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import GroupIcon from "@mui/icons-material/Group";
import { Link, useNavigate } from "react-router-dom";
import { ExpoTheme } from "../theme/theme";
import { isNullOrEmpty } from "../utility/utility";
import AddIcon from "@mui/icons-material/Add";

function HeaderBar({ navItems }) {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const iconMapping = {
    DateRangeIcon: <DateRangeIcon />,
    StorageIcon: <StorageIcon />,
    PhoneIphoneIcon: <PhoneIphoneIcon />,
    GroupIcon: <GroupIcon />,
    AccountCircle: <AccountCircle />,
    LogoutIcon: <LogoutIcon />,
    AddIcon: <AddIcon />,
  };
  return (
    <Fragment>
      <AppBar position="static">
        <Toolbar>
          {!isNullOrEmpty(navItems.barLogo) && (
            <Link to={`${navItems.rootPath}`}>
              <img
                src={navItems.barLogo}
                alt="Company Logo"
                style={{
                  height: "40px",
                  cursor: "pointer",
                  marginRight: "8px",
                }}
              />
            </Link>
          )}
          <Box sx={{ flex: 1, display: "flex", gap: 2 }}>
            <Typography
              sx={{
                alignSelf: "center",
                cursor: "pointer",
                fontWeight: 550,
                ml: 2,
                color: "inherit",
                textDecoration: "none",
              }}
              variant="h6"
              component={Link}
              to={`${navItems.rootPath}`}
            >
              <Hidden mdDown implementation="css">
                {navItems.barName}
              </Hidden>
              <Hidden mdUp implementation="css">
                {navItems.profileName}
              </Hidden>
            </Typography>
            <Hidden mdDown implementation="css">
              <Box display={"flex"} gap={2}>
                {navItems.leftItems.map((item, i) => (
                  <Button
                    startIcon={item.icon && iconMapping[item.icon]}
                    key={i}
                    color="lightprimary"
                    variant="contained"
                    component={Link} // Use Link as the component for Button
                    to={`${navItems.rootPath}${item.path}`}
                  >
                    {item.label}
                  </Button>
                ))}
              </Box>
            </Hidden>
          </Box>

          <Hidden mdDown implementation="css">
            <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}>
              {navItems.rightItems.map((item, i) => (
                <Tooltip key={i} title={item.label}>
                  <Link
                    to={`${navItems.rootPath}${item.path}`}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <IconButton color="inherit">
                      {iconMapping[item.icon]}
                    </IconButton>
                  </Link>
                </Tooltip>
              ))}
            </Box>
          </Hidden>

          <Hidden mdUp implementation="css">
            <Tooltip title="Menu">
              <IconButton
                color="inherit"
                edge="end"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          {navItems.rightItems.map((item, i) => (
            <ListItemButton key={i}>
              <ListItemIcon>{iconMapping[item.icon]}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </Fragment>
  );
}

export default HeaderBar;
