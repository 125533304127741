import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  Button,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import BackBtn from "../../admin/widgets/BackBtn";
import { useParams } from "react-router-dom";
import MuPb from "../../../../widgets/MuPb";
import axios from "axios";
import { INTERACTION_API } from "../../../../constants/constants";
import { getCommonHeaderVE } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import RescheduleMeeting from "./RescheduleMeeting";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";

const MeetingDetail = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const { id } = useParams();
  const { data, isLoading, isError, error, refetch } = useCommonGetInit(
    `user/get-meeting?id=${id}`
  );
  if (isLoading) {
    return <MuPb />;
  }
  if (!data) {
    return <Alert severity="error">No data loaded</Alert>;
  }

  const handleCancelClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmCancel = async () => {
    setCancelling(true);
    try {
      const { data } = await axios.post(
        `${INTERACTION_API}/cancel-meeting`,
        { id },
        getCommonHeaderVE()
      );
      refetch();
      toast.success(data.message);
    } catch (error) {
      toast.error({ message: error.message });
    } finally {
      setCancelling(false);
      setOpenDialog(false);
      setShowReschedule(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 1280, ml: "auto", mr: "auto", mt: 2 }}>
      <BackBtn />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this meeting?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="primary" autoFocus>
            Yes, Cancel Meeting
          </Button>
        </DialogActions>
      </Dialog>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Meeting Information
          </Typography>
          <Box display="flex" alignItems="center" gap={2} marginBottom={2}>
            <Avatar
              src={data.recipient.profile_image}
              alt={data.recipient.firstName}
            />
            <div>
              <Typography variant="subtitle1">
                Recipient:{" "}
                {`${data.recipient.firstName} ${data.recipient.lastName}`}
              </Typography>
              <Typography variant="body2">
                Email: {data.recipient.email}
              </Typography>
              <Typography variant="body2">
                Company: {data.recipient.companyName}
              </Typography>
            </div>
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            Sender: {`${data.sender.firstName} ${data.sender.lastName}`}
          </Typography>
          <Typography variant="body2">Email: {data.sender.email}</Typography>
          <Typography variant="body2">
            Company: {data.sender.companyName}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Meeting Details
          </Typography>
          <Typography variant="body2">
            Date: {formatDate(data.data.selectedDate)}
          </Typography>
          <Typography variant="body2">
            Type: {data.data.meeting_type}
          </Typography>
          <Typography variant="body2">
            Duration: {data.data.duration.$numberInt} minutes
          </Typography>
          <Typography variant="body2">Subject: {data.data.subject}</Typography>
          <Typography variant="body2">Message: {data.data.message}</Typography>
          {showReschedule && <RescheduleMeeting meeting={data} />}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              p: 2,
              gap: 2,
              borderTop: "1px solid #e0e0e0",
            }}
          >
            <LoadingButton
              disabled={data.status === "cancelled"}
              variant="outlined"
              color="error"
              size="small"
              loading={cancelling}
              onClick={handleCancelClick}
            >
              {data.status === "cancelled" ? `Cancelled` : `Cancel`}
            </LoadingButton>
            <Button
              disabled={data.status === "cancelled"}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                /* handle reschedule logic here */
                setShowReschedule(true);
              }}
            >
              Reschedule
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MeetingDetail;
