import { useState } from "react";
import { Box, Button } from "@mui/material";

const AlphabetSortWidget = ({ onSelectLetter }) => {
  const [selectedLetter, setSelectedLetter] = useState(null);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const handleLetterClick = (letter) => {
    const newSelectedLetter = selectedLetter === letter ? null : letter;
    setSelectedLetter(newSelectedLetter);

    if (onSelectLetter) {
      onSelectLetter(newSelectedLetter); // Call the parent function with null to indicate clearing
    }
  };

  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          whiteSpace: "nowrap",
          padding: "8px 0",
        }}
      >
        {alphabet.map((letter) => (
          <Button
            key={letter}
            variant={selectedLetter === letter ? "contained" : "outlined"}
            onClick={() => handleLetterClick(letter)}
            sx={{
              minWidth: 40,
              width: 40,
              height: 40,
              marginRight: 1,
              fontSize: "1rem",
              borderRadius: "50%",
              flex: "0 0 auto", // Prevents flex items from shrinking
            }}
          >
            {letter}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default AlphabetSortWidget;
