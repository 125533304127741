import React from "react";
import { Button, Box, Typography, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const BackAndForthNav = ({
  arrayLength,
  onBackPress,
  onForwardPress,
  currentIndex: initialIndex = 0,
}) => {
  const handleBack = () => {
    if (initialIndex > 0) {
      const newIndex = initialIndex - 1;
      onBackPress && onBackPress(newIndex);
    }
  };

  const handleForward = () => {
    if (initialIndex < arrayLength - 1) {
      const newIndex = initialIndex + 1;

      onForwardPress && onForwardPress(newIndex);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
      <IconButton
        color="primary"
        onClick={handleBack}
        disabled={initialIndex === 0}
      >
        <ArrowBack />
      </IconButton>
      <Typography variant="h6" color="textSecondary">
        {initialIndex + 1} / {arrayLength}
      </Typography>
      <IconButton
        color="primary"
        onClick={handleForward}
        disabled={initialIndex === arrayLength - 1}
      >
        <ArrowForward />
      </IconButton>
    </Box>
  );
};

export default BackAndForthNav;
