import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import "./Admin.css";
import Dashboard from "./home/home";
import GeneralFloorPlan from "../../floorplan/editor/GeneralFloorPlan";
import Exhibitors from "./home/exhibitors/Exhibitors";
import ExhibitorDetail from "../exhibitor/ExhibitorDetail";
import AllocateHall from "./home/allocatehall/allocateHall";
import OesManage from "./home/oes/OesManage";
import UserProfileById from "./home/UserProfileById";

function Admin() {
  return (
    <Fragment>
      <Routes>
        <Route path="" element={<Dashboard />} />
        <Route path="floor-plan" element={<GeneralFloorPlan />} />
        <Route path="exhibitors" element={<Exhibitors />} />
        <Route path="oes-manage" element={<OesManage />} />
        <Route path="allocate-hall" element={<AllocateHall />} />
        <Route path="exhibitor" element={<UserProfileById />} />
        <Route path="home/*" element={<Dashboard />} />
      </Routes>
    </Fragment>
  );
}

export default Admin;
