import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton } from "@mui/material";
import _ from "lodash";
import { useRegistrationSettings } from "../context/RegistrationSettingsContext";
import { SketchPicker } from "react-color";
import Draggable from "react-draggable";
import CloseButton from "../../../../../../widgets/actionBtn/CloseButton";
const LinkEditor = ({ showModal, hide }) => {
  const { settings, updateProperty, isLoading, error } =
    useRegistrationSettings();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const [currentColorType, setCurrentColorType] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  const updateColor = (color) => {
    switch (currentColorType) {
      case "background":
        updateProperty("infoLink.bgColor", color);
        break;
      case "text":
        updateProperty("infoLink.textColor", color);
        break;
      default:
        break;
    }
    setShowColorPicker(false); // Close the color picker after color update
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "Update Link":
        showModal("infoLink.link");
        break;
        break;
      case "Update Text":
        showModal("infoLink.text");
        break;
      case "Update background color":
        setCurrentColorType("background");
        setSelectedColor(settings.infoLink.bgColor);
        setShowColorPicker(true);
        break;
      case "Update text color":
        setCurrentColorType("text");
        setSelectedColor(settings.infoLink.textColor);
        setShowColorPicker(true);
        break;
      case "Show":
        updateProperty("infoLink.show", !settings.infoLink.show);
        break;
      default:
        console.warn(`Unhandled action: ${action}`);
    }
    setAnchorEl(null); // Close the menu after an item is clicked
  };
  const closePicker = () => {
    setShowColorPicker(false);
  };
  return (
    <div style={{ position: "relative", marginBottom: "16px" }}>
      <Button
        variant="outlined"
        size="small"
        fullWidth
        startIcon={<EditIcon />}
        onClick={handleMenuClick}
      >
        Link Info
      </Button>

      <Draggable>
        <div
          style={{
            display: showColorPicker ? "block" : "none",
            position: "absolute",
            zIndex: 1000,
            top: 0,
            right: 0,
          }}
        >
          <SketchPicker
            color={selectedColor}
            onChangeComplete={(color) => {
              setSelectedColor(color.hex);
              updateColor(color.hex);
            }}
          />
          <CloseButton
            onClose={closePicker}
            myStyle={{ position: "absolute", top: -16, right: -16 }}
          />
        </div>
      </Draggable>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("Update Link")}>
          Update Link
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Update Text")}>
          Update Text
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick("Update background color")}
        >
          Update background color
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Update text color")}>
          Update text color
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Show")}>
          {settings.infoLink.show ? `Hide` : `Show`}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LinkEditor;
