import React, { useEffect, useRef, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import { useRegistrationSettings } from "../context/RegistrationSettingsContext";
import {
  ADMIN_API,
  APP_LOGO,
  DEFAULT_DP,
} from "../../../../../../constants/constants";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import axios from "axios";
import { getAdminMultipartHeader } from "../../../../../../utility/utility";
const BannerEditor = ({ propName, hide }) => {
  const { settings, updatePush, isLoading, error } = useRegistrationSettings();
  const [imageLink, setImageLink] = useState("");
  const [link, setLink] = useState("");
  const fileInput = useRef(null);

  useEffect(() => {
    setLink("");
    setImageLink("");
  }, [propName]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error: {error}</p>;
  }
  const handleButtonClick = () => {
    fileInput.current.click(); // Trigger the hidden file input click event
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${ADMIN_API}/upload-file-only`,
        formData,
        getAdminMultipartHeader()
      );
      setImageLink(response.data.link);
      //updateProperty(propName, response.data.link);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const onSave = () => {
    updatePush(propName.key, { link: link, src: imageLink });
    hide();
  };
  return (
    <div style={{ margin: "48px" }}>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <div
        style={{
          margin: "8px auto",
          position: "relative",
          width: "300px",
          height: "300px",
          backgroundImage: `url(${APP_LOGO})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <IconButton
          onClick={handleButtonClick}
          sx={{ position: "absolute", top: 0, right: 0, bgcolor: "grey" }}
        >
          <PhotoCameraIcon />
        </IconButton>
        {imageLink !== "" && (
          <img style={{ width: "100%", height: "100%" }} src={imageLink} />
        )}
      </div>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        label={`Any Link to Point to?`}
        value={link}
        onChange={(e) => setLink(e.target.value)}
      />
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}></div>
        <LoadingButton
          onClick={onSave}
          sx={{ mt: 2 }}
          size="small"
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default BannerEditor;
