import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Stack,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

const halls = [
  "Hall 1A",
  "Hall 2A",
  "Hall 3A",
  "Hall 3B",
  "Hall 4",
  "Hall 4A",
  "Hall 5",
  "Hall 6",
  "Hall 7",
];
const countries = [
  "Germany",
  "India",
  "China",
  "Cyprus",
  "Malaysia",
  "Japan",
  "Singapore",
  "United States",
  "Australia",
  "Austria",
  "Switzerland",
  "Turkey",
  "Taiwan",
];

const ExhibitorFilterBar = ({ onSearch, onFilter }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedHall, setSelectedHall] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleHallChange = (event) => {
    const value = event.target.value;
    setSelectedHall(value);
    if (onFilter) {
      onFilter({ hall: value, country: selectedCountry });
    }
  };

  const handleCountryChange = (event) => {
    const value = event.target.value;
    setSelectedCountry(value);
    if (onFilter) {
      onFilter({ hall: selectedHall, country: value });
    }
  };

  const clearHallFilter = () => {
    setSelectedHall("");
    if (onFilter) {
      onFilter({ hall: "", country: selectedCountry });
    }
  };

  const clearCountryFilter = () => {
    setSelectedCountry("");
    if (onFilter) {
      onFilter({ hall: selectedHall, country: "" });
    }
  };

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h4" gutterBottom>
        Exhibitors
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        {/* Search Field */}
        <TextField
          fullWidth
          placeholder="Search Exhibitors"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />

        {/* Filter by Hall */}
        <FormControl size="small" variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Filter by Hall</InputLabel>
          <Select
            label="Filter by Hall"
            value={selectedHall}
            onChange={handleHallChange}
            endAdornment={
              selectedHall ? (
                <IconButton
                  size="small"
                  onClick={clearHallFilter}
                  aria-label="Clear Hall Filter"
                >
                  <ClearIcon />
                </IconButton>
              ) : null
            }
          >
            {halls.map((hall) => (
              <MenuItem key={hall} value={hall}>
                {hall}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filter by Country */}
        <FormControl size="small" variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Filter by Country</InputLabel>
          <Select
            label="Filter by Country"
            value={selectedCountry}
            onChange={handleCountryChange}
            endAdornment={
              selectedCountry ? (
                <IconButton
                  size="small"
                  onClick={clearCountryFilter}
                  aria-label="Clear Country Filter"
                >
                  <ClearIcon />
                </IconButton>
              ) : null
            }
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default ExhibitorFilterBar;
