import axios from "axios";
import { useQuery } from "react-query";

const doAction = async (path, header) => {
  try {
    const res = await axios.get(path, header);
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useUserUrlGet = (path, HEADER) => {
  return useQuery(
    ["com-init-get", path, HEADER],
    () => doAction(path, HEADER),
    {
      staleTime: 1 * 60 * 1000, // Data will be considered stale after 5 minutes
      retry: 2, // If the query fails, it will retry up to 3 times
    }
  );
};

export default useUserUrlGet;
