import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Container,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import { EXHIBITOR_API, OES_COMPONENTS } from "../../../../constants/constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { MAX_DATE, MIN_DATE } from "./Security";
import Supplier2 from "./reuse/Supplier2";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { reformatDate } from "./ExitPass";
import MuPb from "../../../../widgets/MuPb";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
const Translators = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();

  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [items, setItems] = useState(TRANSLATORS);

  const handleLanguageChange = (index, newQty) => {
    const updatedItems = [...items];
    updatedItems[index].language = newQty;
    setItems(updatedItems);
  };

  const handleNumChange = (index, newQty) => {
    const updatedItems = [...items];
    updatedItems[index].num = newQty;
    setItems(updatedItems);
  };

  const handleDateChange = (index, type, newValue) => {
    const updatedItems = [...items];
    updatedItems[index][type] = newValue.format("YYYY-MM-DD");

    // Calculate the difference in days
    const days =
      dayjs(updatedItems[index].dateTo).diff(
        dayjs(updatedItems[index].dateFrom),
        "days"
      ) + 1;
    updatedItems[index].numDays = days;

    setItems(updatedItems);
  };
  const generateItemsTemplate = (items) => {
    // Filter items with a subtotal greater than 0
    const filteredItems = items.filter((item) => item.numDays > 0);

    // Generate HTML for each filtered item
    const itemsHtml = filteredItems
      .map(
        (item) => `
      <tr>
        <td>${item.language}</td>
        <td>${item.num}</td>
        <td>${reformatDate(item.dateFrom) || ""}</td>
        <td>${reformatDate(item.dateTo) || ""}</td>
        <td>${item.numDays}</td>
      </tr>
    `
      )
      .join("");

    return itemsHtml;
  };
  if (isLoading) {
    return <MuPb />;
  }
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div> ${spaceTemplate(
      data
    )} <br /><table><tr> <th>Language</th> <th>Number</th> <th>Date From</th> <th>Date To</th> <th>No. Days</th> </tr>${generateItemsTemplate(
      items
    )}</table> <br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "5.8 Interpreters & Translators",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "5.8 Interpreters & Translators",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" mb={1}>
          5.8 Interpreters & Translators
        </Typography>
        <SpaceInfo data={data} />
        <Box mb={4}>
          {/* <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert> */}
        </Box>
        <Typography variant="body1" gutterBottom>
          Mail to the agency mentioned below before 30 Dec 2023. Cost of
          interpreters & translators will be provided on request.
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Language</TableCell>
                <TableCell>No. of Interpreters</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>No. of days</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {" "}
                    <TextField
                      size="small"
                      value={item.language}
                      variant="outlined"
                      onChange={(e) =>
                        handleLanguageChange(index, e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={item.num}
                      variant="outlined"
                      type="number"
                      onChange={(e) => handleNumChange(index, e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <DatePicker
                      className="exp_date_pciker"
                      slotProps={{
                        textField: {
                          helperText: "mm/dd/yyyy",
                        },
                      }}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      label="Date From"
                      size="small"
                      variant="outlined"
                      value={item.dateFrom ? dayjs(item.dateFrom) : null}
                      onChange={(newValue) =>
                        handleDateChange(index, "dateFrom", newValue)
                      }
                    />

                    <DatePicker
                      className="exp_date_pciker"
                      slotProps={{
                        textField: {
                          helperText: "mm/dd/yyyy",
                        },
                      }}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      label="Date To"
                      size="small"
                      variant="outlined"
                      value={item.dateTo ? dayjs(item.dateTo) : null}
                      onChange={(newValue) =>
                        handleDateChange(index, "dateTo", newValue)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={item.numDays}
                      variant="outlined"
                      type="number"
                      disabled
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </LocalizationProvider>

        <Supplier2
          user={data}
          id={17}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};

const TRANSLATORS = [
  {
    language: "",
    num: 0,
    dateFrom: null,
    dateTo: null,
    numDays: 0,
  },
  {
    language: "",
    num: 0,
    dateFrom: null,
    dateTo: null,
    numDays: 0,
  },
  {
    language: "",
    num: 0,
    dateFrom: null,
    dateTo: null,
    numDays: 0,
  },
  {
    language: "",
    num: 0,
    dateFrom: null,
    dateTo: null,
    numDays: 0,
  },
];

export default Translators;
