import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  IconButton,
  TableFooter,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import { EXHIBITOR_API, OES_COMPONENTS } from "../../../../constants/constants";
import dayjs from "dayjs";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MuPb from "../../../../widgets/MuPb";
import Supplier2 from "./reuse/Supplier2";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import { reformatDate } from "./ExitPass";
export const MIN_DATE = dayjs("2024-05-23");
export const MAX_DATE = dayjs("2024-05-26");

const Security = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();

  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [items, setItems] = useState([
    {
      Gaurds: "Security ASM (Assignment Manager)",
      Rate: 2400,
      Quantity: 0,
      dateFrom: null,
      dateTo: null,
      Total: 0,
    },

    {
      Gaurds: "Security Supervisor",
      Rate: 1750,
      Quantity: 0,
      dateFrom: null,
      dateTo: null,
      Total: 0,
    },

    {
      Gaurds: "Security Guard",
      Rate: 1250,
      Quantity: 0,
      dateFrom: null,
      dateTo: null,
      Total: 0,
    },
  ]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const newTotal = items.reduce((acc, item) => acc + item.Total, 0);
    setTotal(newTotal);
  }, [items]);

  const handleQtyChange = (index, newQty) => {
    updateTotal(index, newQty);
  };

  const updateTotal = (index, newQty = null) => {
    const updatedItems = [...items];
    if (newQty !== null) updatedItems[index].Quantity = newQty;

    // Check if both dates are valid and present
    const startDate = updatedItems[index].dateFrom
      ? dayjs(updatedItems[index].dateFrom)
      : null;
    const endDate = updatedItems[index].dateTo
      ? dayjs(updatedItems[index].dateTo)
      : null;
    const areDatesValid = startDate?.isValid() && endDate?.isValid();

    // Calculate the number of days only if both dates are valid
    const days = areDatesValid
      ? Math.max(endDate.diff(startDate, "days"), 0) + 1
      : 0;

    // Calculate subtotal, and default to 0 if days is 0
    updatedItems[index].Total =
      days > 0
        ? updatedItems[index].Quantity * updatedItems[index].Rate * days
        : 0;

    setItems(updatedItems);
  };

  const handleDateChange = (index, type, newValue) => {
    const updatedItems = [...items];

    if (newValue && dayjs(newValue).isValid()) {
      updatedItems[index][type] = newValue.format("YYYY-MM-DD");
      setItems(updatedItems);
      updateTotal(index);
    }
  };
  const generateItemsTemplate = (items, total) => {
    // Filter items with a subtotal greater than 0
    const filteredItems = items.filter((item) => item.Total > 0);

    // Generate HTML for each filtered item
    const itemsHtml = filteredItems
      .map(
        (item) => `
          <tr>
            <td>${item.Particulars}</td>
            <td>${item.Rate.toLocaleString()}</td>
            <td>${item.Quantity}</td>
            <td>${reformatDate(item.dateFrom) || ""}</td>
            <td>${reformatDate(item.dateTo) || ""}</td>
            <td>${item.Total.toLocaleString()}</td>
          </tr>
        `
      )
      .join("");

    // Calculate GST and grand total based on the total
    const gst = total * 0.18;
    const grandTotal = total + gst;

    // Footer HTML with total, GST, and grand total
    const footerHtml = `
      <tfoot>
        <tr>
          <td colspan="5">Total</td>
          <td>${total.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="5">GST (18%)</td>
          <td>${gst.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="5">Grand Total</td>
          <td>${grandTotal.toLocaleString()}</td>
        </tr>
      </tfoot>
    `;

    // Combine items HTML and footer to form the complete table
    return `
      <table>
        <thead>
          <tr>
            <th>Particulars</th>
            <th>Rate</th>
            <th>Quantity</th>
            <th>Date From</th>
            <th>Date To</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          ${itemsHtml}
        </tbody>
        ${footerHtml}
      </table>
    `;
  };

  if (isLoading) {
    return <MuPb />;
  }
  function generateFormDataHtml(mdata) {
    return mdata
      .map(
        (item, index) => `
      <tr>
        <td>${item.noGaurds}</td>
        <td>${item.dateFrom}</td>
        <td>${item.noDays}</td>
        <td>${item.dateTo}</td>
        <td>${item.noShifts}</td>
        <td>${item.totalNoShifts}</td>
        <td>${item.amount}</td>
      </tr>
    `
      )
      .join("");
  }
  // When calling the function, make sure to pass the 'total' along with the items.
  const itemsHtmlTable = generateItemsTemplate(items, total);

  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div>${spaceTemplate(
      data
    )} <br />${itemsHtmlTable} <br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: " 5.2 Security",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: " 5.2 Security",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" mb={1}>
          5.2 Security
        </Typography>
        <Box mb={4}>
          {/* <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert> */}
        </Box>
        <Typography variant="body1">
          <b>Shashi Detective Services Pvt. Ltd.</b> or <b>SIS Ltd.</b> before
          30 Dec 2023.
        </Typography>

        <Box mt={2}>
          <Typography variant="body1">
            Security Guard charges Rs. 1,250/-
          </Typography>
          <Typography variant="body1">
            Security Supervisor charges Rs. 1,750/-
          </Typography>
          <Typography variant="body1">
            Security ASM (Assignment Manager) charges Rs. 2,400/-
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body1">Please add GST @ 18%</Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body1">
            We agree to pay as per rate mentioned above for 12 hrs. shift
          </Typography>
        </Box>

        <Box mt={3} mb={2}>
          <Typography variant="h6">Note:</Typography>
          <Typography variant="body2" gutterBottom>
            Organiser will arrange for general security of the halls from 12 Jan
            2024 to 23 Jan 2024. The Organiser will not be liable for losses or
            damage to any property, personal or otherwise during the
            installation, exhibition, and dismantling period. Exhibitors are
            advised to safeguard their goods and articles and never leave stands
            unattended during show hours. Exhibits which are small in size must
            be safely locked in cupboards or glass cases. During non-show hours
            exhibitors must not leave any personal valuables inside the stand.
            No security personnel will be allowed inside the Hall after
            exhibition Hours.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Organiser will arrange for general security surveillance of the
            exhibition site which will not include specific attention to
            individual stands. Exhibitors interested in engaging security guards
            for their individual stands during the exhibition hours are advised
            to hire security personnel only from the approved security agency
            Shashi Detective Services Pvt. Ltd. for IMTEX Forming 2024, Tooltech
            2024, Digital Manufacturing 2024 and WeldExpo 2024. Shashi Detective
            Services Pvt. Ltd. Or SIS Ltd. shall also operate from an office at
            the BIEC complex. Contact details together with charges for hiring
            security guards are mentioned in the Security service order Form
            5.2.
          </Typography>
          <Typography variant="body2" gutterBottom>
            During the exhibition days, Exhibitors and their representatives /
            Security Guards will not be allowed inside the exhibition halls
            after 7:00 pm and movement (if necessary) of exhibits in or out of
            the hall is not permitted from 7:00 am - 7:00 pm. Exhibit movement
            (if any) after 7:00 pm, will be permitted only with prior written
            permission from the Organiser, subject to feasibility.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Exhibitors and their representatives must wear identification badges
            provided by the Organiser during the show days for entry into
            exhibition halls. Transfer or exchange of identification badges is
            strictly prohibited.
          </Typography>
        </Box>

        <SpaceInfo data={data} />

        <div style={{ overflowX: "auto" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Guard</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.Gaurds}</TableCell>
                    <TableCell>{item.Rate}</TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        value={item.Quantity}
                        variant="outlined"
                        type="number"
                        onChange={(e) => handleQtyChange(index, e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePicker
                        className="exp_date_pciker"
                        slotProps={{
                          textField: {
                            helperText: "mm/dd/yyyy",
                          },
                        }}
                        minDate={MIN_DATE}
                        maxDate={MAX_DATE}
                        label="Date From"
                        size="small"
                        variant="outlined"
                        value={item.dateFrom ? dayjs(item.dateFrom) : null}
                        onChange={(newValue) =>
                          handleDateChange(index, "dateFrom", newValue)
                        }
                      />

                      <DatePicker
                        className="exp_date_pciker"
                        slotProps={{
                          textField: {
                            helperText: "mm/dd/yyyy",
                          },
                        }}
                        minDate={MIN_DATE}
                        maxDate={MAX_DATE}
                        label="Date To"
                        size="small"
                        variant="outlined"
                        value={item.dateTo ? dayjs(item.dateTo) : null}
                        onChange={(newValue) =>
                          handleDateChange(index, "dateTo", newValue)
                        }
                      />
                    </TableCell>
                    <TableCell>{item.Total.toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    Total Amount:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(total)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    18% GST:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(0.18 * total)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    Total:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(1.18 * total)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </LocalizationProvider>
        </div>

        <Supplier2
          user={data}
          id={12}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};

export default Security;
