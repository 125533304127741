import { useQuery } from "react-query";
import axios from "axios";
import { getAdminHeader, getHeader } from "../../../../utility/utility";
import { ADMIN_API, API_ENDPOINT, USER } from "../../../../constants/constants";

const fetchExhibitors = async (page = 1) => {
  try {
    const res = await axios.get(
      `${API_ENDPOINT}${USER}/a/get-exhibitors?page=${page}`,
      getAdminHeader()
    );
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useExhibitors = (page) => {
  return useQuery(["get-exhibitors", page], () => fetchExhibitors(page), {
    staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 3, // If the query fails, it will retry up to 3 times
  });
};

export default useExhibitors;
