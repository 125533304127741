import {
  Card,
  Typography,
  Box,
  Alert,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Container,
  Grid,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import { useState } from "react";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import Supplier2 from "./reuse/Supplier2";
import { LoadingButton } from "@mui/lab";
import { EXHIBITOR_API } from "../../../../constants/constants";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { reformatDate } from "./ExitPass";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import { useParams } from "react-router-dom";
import { SubmittedStatus, oesMenus } from "./Services";

const GuidelinesMezzanine = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const { id } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [checklist, setChecklist] = useState([
    {
      label:
        "The mezzanine floor design & drawings is certified by a chartered structural engineer/ consultant for structural stability as per the guidelines given by IMTMA. Copy of the certificate is enclosed along with stand layouts.",
      checked: false,
    },
    {
      label:
        "The mezzanine floor will be used only for visitor hospitality. No exhibits will be displayed on the mezzanine floor ( including Electronic/LED/Neon signages )",
      checked: false,
    },
    {
      label:
        "No office/cabins with closed walls & ceilings are constructed on the mezzanine floor. The mezzanine floor is an open area with safety railings on open sides.",
      checked: false,
    },
    {
      label:
        "Rear and side walls of stands adjacent to/facing neighbouring stands are not transparent and are in uniform neutral colour, above a height of 3 mts.",
      checked: false,
    },
    {
      label:
        "Branding on the mezzanine railings does not exceed the height of 1.2 mts.",
      checked: false,
    },
    {
      label:
        "Construction of mezzanine floor are 3 mts away from the boundary of all open sides of the stand.",
      checked: false,
    },
  ]);
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    date: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCheckboxChange = (index) => {
    const newChecklist = [...checklist];
    newChecklist[index].checked = !newChecklist[index].checked;
    setChecklist(newChecklist);
  };
  function generateChecklistHtml(checklist) {
    return checklist
      .map(
        (item, index) => `
        <tr>
          <td>${item.label}</td>
          <td>${item.checked ? "Yes" : "No"}</td>
        </tr>
      `
      )
      .join("");
  }
  function generateFormDataHtml(formData) {
    return `
      <p><strong>Name:</strong> ${formData.name}</p>
      <p><strong>Designation:</strong> ${formData.designation}</p>
      <p><strong>Date:</strong> ${reformatDate(formData.date)}</p>
    `;
  }
  const checklistHtml = generateChecklistHtml(checklist);
  const formDataHtml = generateFormDataHtml(formData);
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div>${spaceTemplate(
      data
    )}<br/> <table><tr><th>Description</th><th>Status</th></tr>${checklistHtml}</table> <br />${formDataHtml} <br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "4.6 Guidelines For Building Mezzanine Floor",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "4.6 Guidelines For Building Mezzanine Floor",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Card elevation={4} style={{ padding: "24px", margin: "auto" }}>
        <Typography variant="h5" gutterBottom>
          4.6 Guidelines For Building Mezzanine Floor
        </Typography>

        <Box mb={3}>
          {/* <Alert severity="warning">
            Page not yet submitted, the deadline is 23 Dec, 2024.
          </Alert> */}
        </Box>

        <Typography variant="body1" gutterBottom>
          Please tick the appropriate box and send along with stall layout plan
          for approval before 15 Dec 2023.
        </Typography>
        <Typography gutterBottom>
          Area of Mezzanine floor does not exceed 25% of the allotted stand
          area.
        </Typography>
        <Typography gutterBottom>Please tick your requirements</Typography>

        <Box>
          {checklist.map((item, index) => (
            <Box mb={2} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.checked}
                    onChange={() => handleCheckboxChange(index)}
                  />
                }
                label={
                  <Typography variant="body2">{item.label}</Typography> // Use variant="body2" to make text smaller
                }
              />
            </Box>
          ))}
        </Box>

        <Typography gutterBottom>
          We understand the Guidelines for building mezzanine floor and agree to
          abide by the same. In case of any deviations found at the site the
          Organiser reserves the right to enforce changes/ alterations.
        </Typography>
        <Typography gutterBottom>
          I/We, the undersigned, hereby declare that I/We have read and
          understood the Rules and Regulations of participation in “IMTEX
          FORMING 2024, Tooltech 2024, Digital Manufacturing 2024 & WeldExpo
          2024” as well as the Exhibitor Manual and that I/We agree to abide by
          the said Rules and Regulations without reservation.
        </Typography>
        <SpaceInfo data={data} />
        <Box mt={2} mb={2}>
          <Grid container spacing={3}>
            {/* First Row */}
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                label="Name"
                margin="normal"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                label="Designation"
                margin="normal"
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Second Row */}
            {/* <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                label="Signature"
                margin="normal"
                name="signature"
                value={formData.signature}
                onChange={handleInputChange}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                label="Date"
                type="date"
                margin="normal"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </Box>
        <Supplier2
          user={data}
          id={10}
          supplier={supplier}
          setSupplier={setSupplier}
        />

        <Box mt={4} mb={2}>
          <LoadingButton
            onClick={onSubmit}
            loading={submitting}
            disabled={!supplier}
            variant="contained"
            color="primary"
          >
            Submit
          </LoadingButton>
        </Box>

        {/* ... (Any other content/components) ... */}
      </Card>
    </Container>
  );
};

export default GuidelinesMezzanine;
