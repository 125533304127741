import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Container,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
  TableFooter,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import { EXHIBITOR_API, OES_COMPONENTS } from "../../../../constants/constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { MAX_DATE, MIN_DATE } from "./Security";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { reformatDate } from "./ExitPass";
import Supplier2 from "./reuse/Supplier2";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import MuPb from "../../../../widgets/MuPb";
const TemporaryPersonnel = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();

  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [items, setItems] = useState(PERSONNELS);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const newTotal = items.reduce((acc, item) => acc + item.Total, 0);
    setTotal(newTotal);
  }, [items]);

  const handleQtyChange = (index, newQty) => {
    updateTotal(index, newQty);
  };

  const updateTotal = (index, newQty = null) => {
    const updatedItems = [...items];
    if (newQty !== null) updatedItems[index].Quantity = newQty;

    // Check if both dates are valid and present
    const startDate = updatedItems[index].dateFrom
      ? dayjs(updatedItems[index].dateFrom)
      : null;
    const endDate = updatedItems[index].dateTo
      ? dayjs(updatedItems[index].dateTo)
      : null;
    const areDatesValid = startDate?.isValid() && endDate?.isValid();

    // Calculate the number of days only if both dates are valid
    const days = areDatesValid
      ? Math.max(endDate.diff(startDate, "days"), 0) + 1
      : 0;

    // Calculate subtotal, and default to 0 if days is 0
    updatedItems[index].Total =
      days > 0
        ? updatedItems[index].Quantity * updatedItems[index].Rate * days
        : 0;

    setItems(updatedItems);
  };

  const handleDateChange = (index, type, newValue) => {
    const updatedItems = [...items];

    if (newValue && dayjs(newValue).isValid()) {
      updatedItems[index][type] = newValue.format("YYYY-MM-DD");
      setItems(updatedItems);
      updateTotal(index);
    }
  };
  const generateItemsTemplate = (items, total) => {
    // Filter items with a subtotal greater than 0
    const filteredItems = items.filter((item) => item.Total > 0);

    // Generate HTML for each filtered item
    const itemsHtml = filteredItems
      .map(
        (item) => `
          <tr>
            <td>${item.Particulars}</td>
            <td>${item.Rate.toLocaleString()}</td>
            <td>${item.Quantity}</td>
            <td>${reformatDate(item.dateFrom) || ""}</td>
            <td>${reformatDate(item.dateTo) || ""}</td>
            <td>${item.Total.toLocaleString()}</td>
          </tr>
        `
      )
      .join("");

    // Calculate GST and grand total based on the total
    const gst = total * 0.18;
    const grandTotal = total + gst;

    // Footer HTML with total, GST, and grand total
    const footerHtml = `
      <tfoot>
        <tr>
          <td colspan="5">Total</td>
          <td>${total.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="5">GST (18%)</td>
          <td>${gst.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="5">Grand Total</td>
          <td>${grandTotal.toLocaleString()}</td>
        </tr>
      </tfoot>
    `;

    // Combine items HTML and footer to form the complete table
    return `
      <table>
        <thead>
          <tr>
            <th>Particulars</th>
            <th>Rate</th>
            <th>Quantity</th>
            <th>Date From</th>
            <th>Date To</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          ${itemsHtml}
        </tbody>
        ${footerHtml}
      </table>
    `;
  };
  if (isLoading) {
    return <MuPb />;
  }

  // When calling the function, make sure to pass the 'total' along with the items.
  const itemsHtmlTable = generateItemsTemplate(items, total);

  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div> ${spaceTemplate(
      data
    )}<br />${itemsHtmlTable} <br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "5.7 Temporary Personnel",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "5.7 Temporary Personnel",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" mb={1}>
          5.7 Temporary Personnel
        </Typography>
        <Box mb={4}>
          {/* <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert> */}
        </Box>
        <Typography variant="body1" gutterBottom>
          Temporary personnel service is available for exhibitors. For your
          requirements, please fill and return this form to Eminent.{" "}
          <p>Please add GST @ 18% and other Govt. </p>Taxes & levies as
          applicable Temporary personnel must not be entrusted with the handling
          of cash or valuable. Exhibitors will be responsible for the temporary
          personnel in their stands during the show. The Organiser will not be
          responsible for any loss or damage caused by such personnel.{" "}
          <p>
            All orders must be accompanied with full payment by DD / Cheque,
            payable in Bangalore drawn in favor of JRB EVENTZ MANAGEMENT PVT.
            LTD.
          </p>
        </Typography>
        <SpaceInfo data={data} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Particulars</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.Particulars}</TableCell>
                  <TableCell>{item.Rate}</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={item.Quantity}
                      variant="outlined"
                      type="number"
                      onChange={(e) => handleQtyChange(index, e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <DatePicker
                      className="exp_date_pciker"
                      slotProps={{
                        textField: {
                          helperText: "mm/dd/yyyy",
                        },
                      }}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      label="Date From"
                      size="small"
                      variant="outlined"
                      value={item.dateFrom ? dayjs(item.dateFrom) : null}
                      onChange={(newValue) =>
                        handleDateChange(index, "dateFrom", newValue)
                      }
                    />

                    <DatePicker
                      className="exp_date_pciker"
                      slotProps={{
                        textField: {
                          helperText: "mm/dd/yyyy",
                        },
                      }}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      label="Date To"
                      size="small"
                      variant="outlined"
                      value={item.dateTo ? dayjs(item.dateTo) : null}
                      onChange={(newValue) =>
                        handleDateChange(index, "dateTo", newValue)
                      }
                    />
                  </TableCell>
                  <TableCell>{item.Total.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  Total Amount:
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat("en-IN").format(total)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  18% GST:
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat("en-IN").format(0.18 * total)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  Total:
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat("en-IN").format(1.18 * total)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </LocalizationProvider>
        <Supplier2
          user={data}
          id={16}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};

const PERSONNELS = [
  {
    Particulars: "Host",
    Rate: 1800,
    Quantity: 0,
    dateFrom: null,
    dateTo: null,
    Total: 0,
  },
  {
    Particulars: "Hostesses",
    Rate: 2800,
    Quantity: 0,
    dateFrom: null,
    dateTo: null,
    Total: 0,
  },
];

export default TemporaryPersonnel;
