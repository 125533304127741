import { Autocomplete, Container, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getHeader } from "../../../../../utility/utility";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import axios from "axios";
import { API_ENDPOINT, USER } from "../../../../../constants/constants";
import { toastError } from "../../../../../utils2024/utils";
import IndustrySectorSelector from "../../widgets/IndustrySectorSelector";

const IndustrySectorEdit = ({ user, show, setShow }) => {
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    setSelectedSectors(user?.industrySector?.value || []);
  }, [show]);
  const update = async () => {
    const toUpdateObject = {
      industrySector: {
        label: user.industrySector.label,
        value: selectedSectors,
      },
    };
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}${USER}/update-user`,
        toUpdateObject,
        getHeader()
      );
      setShow(false);
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 450);
    } catch (error) {
      toastError(error);
    } finally {
      setSaving(false);
    }
  };
  return (
    <Container>
      <Typography
        mb={2}
        fontSize={18}
        color={"#444"}
        fontWeight={600}
        variant="body1"
      >
        Update Industry Sector
      </Typography>

      <IndustrySectorSelector
        selected={selectedSectors}
        onChange={(newV) => setSelectedSectors(newV)}
      />

      <LoadingButton
        loading={saving}
        onClick={update}
        sx={{
          mt: 4,
          ml: "auto",
          mr: "auto",
          display: "block",
          mb: 2,
          width: 120,
        }}
        variant="contained"
      >
        Save
      </LoadingButton>
    </Container>
  );
};

export default IndustrySectorEdit;
