import React from "react";
import { Button } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function PDFInput({ onChange, label = "" }) {
  return (
    <div>
      <Button
        startIcon={<PictureAsPdfIcon color="error" />}
        variant="outlined"
        component="label"
        color="primary"
        sx={{ textTransform: "none" }}
      >
        {label}
        <input type="file" hidden accept=".pdf" onChange={onChange} />
      </Button>
    </div>
  );
}
