import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Container,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import BackBtn from "../../admin/widgets/BackBtn";
import MeetingsList from "./MeetingsList";

const statusOptions = [
  { label: "All", value: "" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Requested", value: "requested" },
  { label: "Accepted", value: "accepted" },
];

const AllMeetings = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [status, setStatus] = useState("");

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  useEffect(() => {
    setStatus("");
  }, [tabIndex]);

  const handleStatusChange = (event, newValue) => {
    setStatus(newValue ? newValue.value : "");
  };

  const meetingType = tabIndex === 0 ? "received" : "sent";

  return (
    <Box>
      <BackBtn />
      <Container>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Received" />
          <Tab label="Sent" />
        </Tabs>
        <Stack direction={"row"}>
          <Box flex={1}></Box>
          <Autocomplete
            size="small"
            options={statusOptions}
            getOptionLabel={(option) => option.label}
            value={
              statusOptions.find((option) => option.value === status) ||
              statusOptions[0]
            }
            onChange={handleStatusChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                variant="outlined"
                sx={{ mt: 2, minWidth: 200 }}
              />
            )}
          />
        </Stack>
        <Box sx={{ mt: 2 }}>
          <MeetingsList type={meetingType} status={status} />
        </Box>
      </Container>
    </Box>
  );
};

export default AllMeetings;
