import React, { useState, useRef, useEffect } from "react";

const PolygonCanvasNoZoom = ({ width, height, backgroundImage }) => {
  const canvasRef = useRef(null);
  const [vertices, setVertices] = useState([]);
  const [isClosed, setIsClosed] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(null);
  const imgRef = useRef(new Image());

  useEffect(() => {
    if (backgroundImage) {
      imgRef.current.onload = () => {
        setImageLoaded(true);
      };
      imgRef.current.src = backgroundImage;
    }
  }, [backgroundImage]);

  const isPointInPolygon = (x, y, vertices) => {
    let inside = false;
    for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
      let xi = vertices[i].x,
        yi = vertices[i].y;
      let xj = vertices[j].x,
        yj = vertices[j].y;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, width, height);
    if (imageLoaded) {
      ctx.drawImage(imgRef.current, 0, 0, width, height);
    }

    vertices.forEach((vertex) => {
      ctx.beginPath();
      ctx.arc(vertex.x, vertex.y, 5, 0, 2 * Math.PI);
      ctx.fill();
    });

    if (vertices.length > 0) {
      ctx.beginPath();
      ctx.moveTo(vertices[0].x, vertices[0].y);
      for (let i = 1; i < vertices.length; i++) {
        ctx.lineTo(vertices[i].x, vertices[i].y);
      }
      if (isClosed) {
        ctx.closePath();
        ctx.fillStyle = "rgba(0, 0, 255, 0.5)";
        ctx.fill();
      }
      ctx.stroke();
    }
  }, [vertices, width, height, isClosed, imageLoaded]);

  const handleMouseDown = (event) => {
    const x = event.clientX - canvasRef.current.getBoundingClientRect().left;
    const y = event.clientY - canvasRef.current.getBoundingClientRect().top;

    if (isClosed && isPointInPolygon(x, y, vertices)) {
      setIsDragging(true);
      setDragOffset({ x: x - vertices[0].x, y: y - vertices[0].y });
    } else if (!isClosed) {
      if (vertices.length > 0) {
        const firstVertex = vertices[0];
        const distanceToFirst = Math.sqrt(
          (x - firstVertex.x) ** 2 + (y - firstVertex.y) ** 2
        );
        if (distanceToFirst < 10 && vertices.length >= 2) {
          setIsClosed(true);
        } else {
          setVertices([...vertices, { x, y }]);
        }
      } else {
        setVertices([{ x, y }]);
      }
    }
  };

  const handleMouseMove = (event) => {
    if (isDragging && dragOffset) {
      const x = event.clientX - canvasRef.current.getBoundingClientRect().left;
      const y = event.clientY - canvasRef.current.getBoundingClientRect().top;

      const deltaX = x - dragOffset.x;
      const deltaY = y - dragOffset.y;

      setVertices(
        vertices.map((vertex) => ({
          x: vertex.x + deltaX - vertices[0].x,
          y: vertex.y + deltaY - vertices[0].y,
        }))
      );
    }
  };

  //info@imtex.in
  //imtex2024-imtma.expoplnner.in
  //v
  //noreply@expoplnner.in
  /*
    
  EXPPLANNER

  */

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [vertices, isDragging, dragOffset, isClosed]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{ border: "1px solid black" }}
    />
  );
};

export default PolygonCanvasNoZoom;
