import * as React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ShowHall from "./ShowHall";
import SearchBar from "../../../widgets/searchBar";
import { useNavigate } from "react-router-dom";

const styles = {
  appBar: {
    marginBottom: "20px",
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  filtersBox: {
    padding: "10px",
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
  },
  hallImage: {
    width: "100%",
    height: "auto",
  },
  select: {
    minWidth: "200px",
  },
  footer: {
    marginTop: "auto",
    padding: "20px 0",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
};

export default function ExhibitorFloorPlan() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Box sx={styles.root}>
      <Container>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={3}>
            <Box sx={styles.filtersBox}>
              <SearchBar label={"Search by Categories"} />
              <Typography variant="h6" mt={2} gutterBottom>
                Filters
              </Typography>

              {["COUNTRY", "PRODUCT CATEGORIES", "EXHIBITOR CATEGORY"].map(
                (label, index) => (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    key={index}
                  >
                    <InputLabel id={`${label.toLowerCase()}-label`}>
                      {label}
                    </InputLabel>
                    <Select
                      labelId={`${label.toLowerCase()}-label`}
                      label={label}
                    >
                      <MenuItem value={`${label}1`}>{label}1</MenuItem>
                      <MenuItem value={`${label}2`}>{label}2</MenuItem>
                    </Select>
                  </FormControl>
                )
              )}

              <Typography variant="h6" gutterBottom mt={2}>
                Quick Filters
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                {["My Meetings", "Favourites", "Visited"].map((text, index) => (
                  <Button
                    variant="contained"
                    color={text === "Visited" ? "secondary" : "primary"}
                    style={{
                      marginRight: index !== 2 ? "10px" : "0",
                      marginBottom: "10px",
                    }}
                    key={index}
                  >
                    {text}
                  </Button>
                ))}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Button
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              style={{ marginBottom: "20px" }}
              onClick={goBack}
            >
              Back to General FloorPlan
            </Button>
            <ShowHall />
          </Grid>
        </Grid>
      </Container>
      <Box sx={styles.footer}>
        <Typography variant="body2" textAlign="center">
          ExpoPlanner AI-powered networking platform for LIVE. HYBRID. VIRTUAL.
        </Typography>
      </Box>
    </Box>
  );
}
