import { Alert, Box, Container, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import MuPb from "../../../../widgets/MuPb";
import { getErrorResponse, toastError } from "../../../../utils2024/utils";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import BackBtn from "../../admin/widgets/BackBtn";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { API_ENDPOINT } from "../../../../constants/constants";
import { getHeader, getUser } from "../../../../utility/utility";
import { toast } from "react-toastify";
const MeetingReschedule = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(
    new Date("2025-01-23T10:00:00")
  );
  const [updating, setUpdating] = useState(false);
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-meeting?id=${id}`
  );

  useEffect(() => {
    if (data) {
      setSelectedDate(new Date(data.selectedDate));
    }
  }, [data]);
  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }
  const updateStatus = async () => {
    try {
      setUpdating(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}user/update-schedule?id=${id}`,
        { selectedDate },
        getHeader()
      );
      toast.success(data.message);
      setTimeout(() => {
        navigate(-1);
        setTimeout(() => {
          window.location.replace(window.location.href);
        }, 50); // Short delay to ensure navigation occurs before refresh
      }, 400);
    } catch (error) {
      toastError(error);
    } finally {
      setUpdating(false);
    }
  };
  return (
    <Container>
      <BackBtn />
      <Box maxWidth={700}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            minDateTime={new Date("2025-01-23T10:00:00")}
            maxDateTime={new Date("2025-01-29T17:00:00")}
            sx={{ width: "100%", mt: 2 }}
            label="Select Date and Time*"
            value={selectedDate}
            onChange={(newValue) => {
              setSelectedDate(newValue);
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
        <Stack mt={2} direction={"row"}>
          <Box flex={1}></Box>
          <LoadingButton
            loading={updating}
            startIcon={<SaveIcon />}
            variant="outlined"
            onClick={() => {
              updateStatus();
            }}
          >
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </Container>
  );
};

export default MeetingReschedule;
