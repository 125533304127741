import React, { forwardRef } from "react";
import { TextField, InputAdornment, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const TextFieldWithTooltip = forwardRef(
  ({ label = "", value, type = "tex", onChange, tip = "" }, ref) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    const handleTooltipClose = () => {
      setOpen(false);
    };

    return (
      <TextField
        ref={ref}
        fullWidth
        value={value}
        onChange={onChange}
        label={label}
        variant="outlined"
        type={type}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title={tip}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={handleTooltipClose}
                arrow
              >
                <IconButton
                  onClick={handleTooltipOpen}
                  onMouseLeave={handleTooltipClose}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default TextFieldWithTooltip;
