import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";

const FootFall = () => {
  const [users, setUsers] = useState([]);
  const [footfallCounts, setFootfallCounts] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://apis.expoplanner.in/visitor/get-scanned-users-temp-pbi"
        );
        const data = response.data;
        setUsers(data);

        // Calculate footfall count for each user_type
        const counts = {};
        data.forEach((user) => {
          const userType = user.user_type || "Regular Visitor"; // Handle null user types
          if (counts[userType]) {
            counts[userType] += 1;
          } else {
            counts[userType] = 1;
          }
        });
        setFootfallCounts(counts);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  // Calculate the total overall count
  const totalOverall = Object.values(footfallCounts).reduce(
    (acc, count) => acc + count,
    0
  );

  return (
    <Box p={2}>
      <TableRow>
        <TableCell component="th" scope="row">
          Foot Fall: <strong>Total Overall</strong>
        </TableCell>
        <TableCell align="right">
          <strong>{totalOverall}</strong>
        </TableCell>
      </TableRow>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 320 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User Type</TableCell>
              <TableCell align="right">Footfall Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(footfallCounts).map(([userType, count]) => (
              <TableRow
                key={userType}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {userType}
                </TableCell>
                <TableCell align="right">{count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FootFall;
