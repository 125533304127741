import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton } from "@mui/material";
import _ from "lodash";
import { useRegistrationSettings } from "../context/RegistrationSettingsContext";
import { SketchPicker } from "react-color";
import Draggable from "react-draggable";
import CloseButton from "../../../../../../widgets/actionBtn/CloseButton";
const LabelUpdater = ({ showModal, hide, propName }) => {
  const { settings, updateProperty, isLoading, error } =
    useRegistrationSettings();
  const [anchorEl, setAnchorEl] = useState(null);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "Update Label":
        showModal(`${propName}.label`);
        break;
      case "Show":
        updateProperty(`${propName}.show`, !settings[propName].show);
        break;
      case "Required":
        updateProperty(`${propName}.required`, !settings[propName].required);
        break;
      default:
        console.warn(`Unhandled action: ${action}`);
    }
    setAnchorEl(null); // Close the menu after an item is clicked
  };
  return (
    <div style={{ position: "relative" }}>
      <Button
        variant="outlined"
        size="small"
        fullWidth
        startIcon={<EditIcon />}
        onClick={handleMenuClick}
      >
        {settings[propName].label}
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("Update Label")}>
          Update Label
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Show")}>
          {settings[propName].show ? `Hide` : `Show`}
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Required")}>
          {settings[propName].required ? `Optional` : `Compulsory`}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LabelUpdater;
