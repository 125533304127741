import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Container,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
  Checkbox,
  Paper,
  TableContainer,
  Grid,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import { EXHIBITOR_API, OES_COMPONENTS } from "../../../../constants/constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import Supplier2 from "./reuse/Supplier2";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import MuPb from "../../../../widgets/MuPb";
const SeminarHalls = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();

  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [items, setItems] = useState(HALLS);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const overallTotal = items.reduce((acc, hall) => {
      return (
        acc +
        hall.shifts.reduce((shiftTotal, shift) => {
          return shiftTotal + shift.total;
        }, 0)
      );
    }, 0);

    setTotal(overallTotal);
  }, [items]);

  const handleCheckboxChange = (areaIndex, shiftIndex, dateIndex) => {
    const updatedItems = [...items];
    const shift = updatedItems[areaIndex].shifts[shiftIndex];

    // Toggle the date's checked state
    const dateKeys = Object.keys(shift.dates);
    shift.dates[dateKeys[dateIndex]] = !shift.dates[dateKeys[dateIndex]];

    // Calculate the total for the shift based on the dates selected
    const daysChecked = Object.values(shift.dates).filter(Boolean).length;
    shift.total = shift.rate * daysChecked;

    setItems(updatedItems);
  };
  const generateFormDataHtml = (hallsData, total) => {
    // Map over each hall to generate rows
    const rowsHtml = hallsData
      .map((hall) => {
        return hall.shifts
          .map((shift) => {
            const bookedDates = Object.entries(shift.dates)
              .filter(([date, isBooked]) => isBooked)
              .map(([date]) => date);

            return bookedDates
              .map(
                (date) => `
                <tr>
                  <td>${hall.area}</td>
                  <td>${shift.shift}</td>
                  <td>${date}</td>
                  <td>${shift.shiftTiming}</td>
                  <td>${shift.seatingCapacity}</td>
                  <td>${shift.rate.toLocaleString()}</td>
                </tr>
              `
              )
              .join("");
          })
          .join("");
      })
      .join("");

    // Calculate GST and grand total based on the total
    const gst = total * 0.18;
    const grandTotal = total + gst;

    // Footer HTML with total, GST, and grand total
    const footerHtml = `
      <tfoot>
        <tr>
          <td colspan="5">Total</td>
          <td>${total.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="5">GST (18%)</td>
          <td>${gst.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="5">Grand Total</td>
          <td>${grandTotal.toLocaleString()}</td>
        </tr>
      </tfoot>
    `;

    // Return the complete table HTML
    return `
      <table>
        <thead>
          <tr>
            <th>Area</th>
            <th>Shift</th>
            <th>Date</th>
            <th>Shift Timing</th>
            <th>Seating Capacity</th>
            <th>Rate</th>
          </tr>
        </thead>
        <tbody>
          ${rowsHtml}
        </tbody>
        ${footerHtml}
      </table>
    `;
  };

  // Use the function to generate the HTML, passing the total as an argument
  const hallsDataHtml = generateFormDataHtml(items, total);
  if (isLoading) {
    return <MuPb />;
  }
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div> ${spaceTemplate(
      data
    )} <br />${hallsDataHtml}<br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "5.5 Seminar Halls",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "5.5 Seminar Halls",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" mb={1}>
          5.5 Seminar Halls
        </Typography>
        <Box mb={4}>
          <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert>
        </Box>

        <TableContainer sx={{ maxWidth: 400 }} component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Area</TableCell>
                <TableCell>Seating Capacity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Jacaranda</TableCell>
                <TableCell>1368</TableCell>
                <TableCell>1300</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Gulmohar</TableCell>
                <TableCell>679</TableCell>
                <TableCell>700</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Parijatha</TableCell>
                <TableCell>168</TableCell>
                <TableCell>80</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body1">
            Date starts from 19/01/2024 to 23/01/2024
          </Typography>
        </Box>

        <SpaceInfo data={data} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Area</TableCell>
                <TableCell>Shift</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>
                  <Typography variant="caption">19 Jan</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">20 Jan</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">21 Jan</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">22 Jan</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">23 Jan</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">24 Jan</Typography>
                </TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((areaData, index) => (
                <>
                  {areaData.shifts.map((shift, shiftIndex) => (
                    <TableRow key={`${index}${shiftIndex}`}>
                      <TableCell>
                        <Box sx={{ fontWeight: 600 }}>{areaData.area}</Box>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Box sx={{ fontWeight: 600 }}>{shift.shift}</Box>
                        <Box sx={{ fontStyle: "italic" }}>
                          <div style={{ fontSize: "11px" }}>
                            {shift.shiftTiming}
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell>{shift.rate.toLocaleString()}</TableCell>
                      {Object.values(shift.dates).map(
                        (isChecked, dateIndex) => (
                          <TableCell key={dateIndex}>
                            <Checkbox
                              size="small"
                              checked={isChecked}
                              onChange={() =>
                                handleCheckboxChange(
                                  index,
                                  shiftIndex,
                                  dateIndex
                                )
                              }
                            />
                          </TableCell>
                        )
                      )}
                      <TableCell>{shift.total.toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </>
              ))}
            </TableBody>
          </Table>
        </LocalizationProvider>
        <Paper sx={{ padding: "1rem", mt: 2, elevation: 0 }}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Divider />
            </Grid>

            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">Total Amount</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">18% GST</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(0.18 * total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">Total</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(1.18 * total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>
          </Grid>
        </Paper>

        <Supplier2
          user={data}
          id={13}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};

const HALLS = [
  {
    area: "Jacaranda",
    shifts: [
      {
        shift: "Shift 1",
        area: 1368,
        seatingCapacity: 1300,
        shiftTiming: "10:00 AM to 1:00 PM",
        rate: 114068,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 2",
        area: 1368,
        seatingCapacity: 1300,
        shiftTiming: "2:00 PM to 5:00 PM",
        rate: 114068,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 3",
        area: 1368,
        seatingCapacity: 1300,
        shiftTiming: "10:00 AM to 5:00 PM",
        rate: 228135,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
    ],
  },
  {
    area: "Gulmohar",
    shifts: [
      {
        shift: "Shift 1",
        area: 679,
        seatingCapacity: 700,
        shiftTiming: "10:00 AM to 1:00 PM",
        rate: 68440,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 2",
        area: 679,
        seatingCapacity: 700,
        shiftTiming: "2:00 PM to 5:00 PM",
        rate: 68440,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 3",
        area: 679,
        seatingCapacity: 700,
        shiftTiming: "10:00 AM to 5:00 PM",
        rate: 136880,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
    ],
  },
  {
    area: "Parijatha",
    shifts: [
      {
        shift: "Shift 1",
        area: 168,
        seatingCapacity: 80,
        shiftTiming: "10:00 AM to 1:00 PM",
        rate: 22815,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 2",
        area: 168,
        seatingCapacity: 80,
        shiftTiming: "2:00 PM to 5:00 PM",
        rate: 22815,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 3",
        area: 168,
        seatingCapacity: 80,
        shiftTiming: "10:00 AM to 5:00 PM",
        rate: 45630,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
    ],
  },
];

export default SeminarHalls;
