import { useQuery } from "react-query";
import axios from "axios";
import { getExhibitorHeader, getHeader } from "../../../../../utility/utility";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  USER,
} from "../../../../../constants/constants";
const fetchItem = async (username, title) => {
  try {
    const res = await axios.get(
      `${API_ENDPOINT}${USER}/oes-submitted-status?username=${username}&title=${title}`,
      getHeader()
    );
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useOesSubmitted = (username, title) => {
  return useQuery(
    ["oes-submitted-status", username, title],
    () => fetchItem(username, title),
    {
      staleTime: 0, // Data will be considered stale after 5 minutes
      retry: 1,
    }
  );
};

export default useOesSubmitted;
