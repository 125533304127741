import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { getHeader, getUser } from "../utility/utility";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EastIcon from "@mui/icons-material/East";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import { toastError } from "../utils2024/utils";
import axios from "axios";
import { API_ENDPOINT } from "../constants/constants";
import { toast } from "react-toastify";
import { UserContext } from "../components/context/UserContext";

const InteractiveAction2 = ({ item, type, showDetail = true }) => {
  const { user, refresh } = useContext(UserContext);
  const [liking, setLiking] = useState(false);
  const userId = item?.productId ?? item.username;
  const userRole = getUser()?.role;
  const favsA = user?.favorites;
  const isLiked = favsA?.some((fav) => fav.liked_id === userId);

  const profileLink = item?.productId
    ? `/${userRole}/${type}-view/${userId}`
    : `/${userRole}/${type}-view/${item.username}`;

  // Local liked state for immediate UI feedback
  const [liked, setLiked] = useState(isLiked);

  const handleLike = async () => {
    // Update the icon immediately
    setLiked(!liked);
    setLiking(true); // Optional: show loading or disable button

    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}user/add-to-favorite`,
        {
          id: userId,
          type: type,
        },
        getHeader()
      );
      refresh();
      toast.success(data.message);

      // Optionally update user context or state here if needed
    } catch (error) {
      // Revert the liked state if the API call fails
      setLiked(!liked);
      toastError(error);
    } finally {
      setLiking(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: showDetail ? "center" : undefined,
        gap: 1,
      }}
    >
      <Link to={`/${userRole}/chat?tid=${item.username}`}>
        <Tooltip title="Message">
          <IconButton
            aria-label="message"
            size="small"
            color="primary"
            sx={{
              backgroundColor: "rgba(25, 118, 210, 0.08)",
              "&:hover": { backgroundColor: "rgba(25, 118, 210, 0.2)" },
            }}
          >
            <ForumOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to={`/${userRole}/meeting-scheduler?tid=${item.username}`}>
        <Tooltip title="Schedule Meeting">
          <IconButton
            aria-label="meeting"
            size="small"
            sx={{
              backgroundColor: "rgba(0, 176, 255, 0.08)",
              "&:hover": { backgroundColor: "rgba(0, 176, 255, 0.2)" },
            }}
          >
            <EventAvailableIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Link>
      <Tooltip title={liked ? "Remove from Favorites" : "Add to Favorites"}>
        <IconButton
          aria-label="favorites"
          size="small"
          color="error"
          onClick={handleLike}
          disabled={liking} // Optional: disable during API call
          sx={{
            backgroundColor: "rgba(211, 47, 47, 0.08)",
            "&:hover": { backgroundColor: "rgba(211, 47, 47, 0.2)" },
          }}
        >
          {liking ? (
            <CircularProgress size={22} />
          ) : liked ? (
            <FavoriteIcon fontSize="small" /> // Filled icon when liked
          ) : (
            <FavoriteBorderIcon fontSize="small" /> // Outline icon when not liked
          )}
        </IconButton>
      </Tooltip>
      {showDetail && (
        <Link to={profileLink}>
          <Tooltip title="View Profile">
            <IconButton
              aria-label="view profile"
              size="small"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
              }}
            >
              <EastIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Link>
      )}
    </Box>
  );
};

export default InteractiveAction2;
