import React, { useState } from "react";
import {
  Card,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Select,
  MenuItem,
  Divider,
  InputLabel,
  Container,
  Paper,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import TeamMember from "../../../../widgets/TeamMember";
import {
  DEFAULT_DP,
  EMAIL_FOOTER,
  EXHIBITOR_API,
} from "../../../../constants/constants";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import MuPb from "../../../../widgets/MuPb";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import Supplier2 from "./reuse/Supplier2";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import { SubmittedStatus, oesMenus } from "./Services";
import { useParams } from "react-router-dom";
import ComingSoon from "../ComingSoon";
const ServiceBadges = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [rows, setRows] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();

  if (isLoading) {
    return <MuPb />;
  }
  // return <ComingSoon />;
  const handleAdd = () => {
    if (!areAllFieldsNonEmpty()) {
      toast.error("Please enter all fields");
      return;
    }
    setRows([...rows, { name, lastName, companyName, jobTitle }]);
    setName("");
    setLastName("");
    setCompanyName("");
    setJobTitle("");
  };
  const handleDelete = (indexToDelete) => {
    const updatedRows = rows.filter((_, index) => index !== indexToDelete);
    setRows(updatedRows);
  };
  const areAllFieldsNonEmpty = () => {
    const areStringsNonEmpty =
      name.trim() !== "" &&
      lastName.trim() !== "" &&
      companyName.trim() !== "" &&
      jobTitle.trim() !== "";
    return areStringsNonEmpty;
  };
  const employeeRows = rows
    .map(
      (employee, index) =>
        `<tr key=${index}>
      <td>${employee.name}</td>
      <td>${employee.lastName}</td>
      <td>${employee.companyName}</td>
      <td>${employee.jobTitle}</td>
    </tr>`
    )
    .join("");
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div> ${spaceTemplate(
      data
    )}<br/> <table> <tr><th>FirstName</th><th>LastName</th><th>Company</th><th>Designation</th></tr>${employeeRows}</table> <br/> <img src="${EMAIL_FOOTER}" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "4.3 Services Badges",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "4.3 Services Badges",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Paper elevation={3} style={{ padding: "2rem" }}>
        <Typography variant="h5" gutterBottom>
          4.3 Services Badges
        </Typography>

        <Box mb={4}>
          {/* <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 17 Jan, 2024.
            </Typography>
          </Alert> */}
        </Box>

        <Typography variant="body1" gutterBottom>
          Fax/Mail to reach IMTMA before 5 Jan 2024. Please collect Service
          badges from respective Hall Directors booth located in Halls from 12
          Jan 2024 onwards.
        </Typography>
        <SpaceInfo data={data} />
        <Box>
          <Box display="flex" gap={2} justifyContent="space-between" mb={3}>
            <TextField
              size="small"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              size="small"
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              size="small"
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <TextField
              size="small"
              label="Job Title"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
            <Button onClick={handleAdd} variant="contained">
              Add
            </Button>
          </Box>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Job Title</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell>{row.jobTitle}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(index)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>

        {/* Supplier */}
        <Supplier2
          user={data}
          id={7}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier || rows.length <= 0}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default ServiceBadges;
