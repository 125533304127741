import React from "react";

const CountryStateCard = ({ infoCard }) => {
  if (!infoCard) return <div>No data</div>;
  return (
    <div>
      <div style={{ fontWeight: 600, fontSize: "14px" }}>{infoCard.label}</div>
      <div style={{ color: "#777", fontSize: "14px", marginTop: "5px" }}>
        {infoCard?.value?.name}
      </div>
    </div>
  );
};

export default CountryStateCard;
