import React from "react";
import { Alert, Box } from "@mui/material";
import BackBtn from "../admin/widgets/BackBtn";
import { useParams } from "react-router-dom";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import MuPb from "../../../widgets/MuPb";
import { getErrorResponse } from "../../../utils2024/utils";
import ProfileViewer from "../common/ProfileViewer";
import VisitorDetail from "./VisitorDetail";

const VisitorProfileDetail = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-user-by-id?id=${id}`
  );

  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }

  return (
    <Box>
      <ProfileViewer id={id} />
      <BackBtn />
      <VisitorDetail data={data} />
    </Box>
  );
};

export default VisitorProfileDetail;
