import React, { forwardRef, useState } from "react";
import { Button, Box, Paper, IconButton, Typography } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ClearIcon from "@mui/icons-material/Clear";
import RdMyModal from "../widgets/myModal/RdMyModal";
import UploadImage from "./UploadImage";

const AddImage = forwardRef(
  (
    { aspect_ratio = 1, type, image, setImage, sizeLimit = 500, onClearImage },
    ref
  ) => {
    const [showImgModal, setShowImgModal] = useState(false);
    const handleClearImage = () => {
      setImage(null);
      if (onClearImage) {
        onClearImage();
      }
    };

    const onCroppedImage = (croppedImg) => {
      setImage(croppedImg);
    };
    const launchImageCrop = () => {
      setShowImgModal((prev) => !prev);
    };
    return (
      <Box sx={{ mb: 2 }}>
        <RdMyModal
          showModal={showImgModal}
          setShowModal={setShowImgModal}
          modalC={
            <UploadImage
              sizeLimit={sizeLimit}
              aspect_ratio={aspect_ratio}
              onUpdated={onCroppedImage}
              show={showImgModal}
              setShow={setShowImgModal}
            />
          }
        />
        <Typography variant="h6">{type}</Typography>
        <Paper
          ref={ref}
          variant="outlined"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 140,
            mb: 1,
            // backgroundImage: `url(${image})`,
            // backgroundSize: "cover",
            // backgroundPosition: "center",
            position: "relative",
          }}
        >
          {!image && (
            <IconButton
              onClick={launchImageCrop}
              color="primary"
              component="label"
            >
              <AddPhotoAlternateIcon />
              Add Image
            </IconButton>
          )}
          {image && <img src={image} style={{ maxHeight: 140 }} />}
          {image && (
            <IconButton
              color="secondary"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 2,
              }}
              onClick={handleClearImage}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Paper>
        <Button
          size="small"
          sx={{ textTransform: "none" }}
          variant="contained"
          color="error"
          onClick={handleClearImage}
        >
          Clear {type}
        </Button>
      </Box>
    );
  }
);

export default AddImage;
