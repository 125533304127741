import { Box, TextField, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExpoTheme } from "../../../theme/theme";
import RegHeader from "../../../components/headers/regHeader";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { API_ENDPOINT, EXHIBITOR_API } from "../../../constants/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../../footer/footer";
import { MuiTelInput } from "mui-tel-input";
const ServiceBadge = () => {
  useEffect(() => {
    document.title = "SERVICE BADGE";
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [exhibitorId, setExhibitorId] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [checking, setChecking] = useState(false);

  const onClickSubmit = async () => {
    if (!exhibitorId.trim()) {
      toast.error("Please enter an Exhibitor ID.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        EXHIBITOR_API + `get-service-badge-person?id=${exhibitorId}`
      );

      if (response.status === 200) {
        navigate(`/service-badge/${response.data.exhibitorId}`);
      } else if (response.status === 204) {
        // Handle successful response with no content
        toast.info("No Exhibitor Found with that ID.");
      }
    } catch (error) {
      // Check if the error has a response object
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // If the response has a data object with a message, display it
        toast.error(error.response.data.message);
      } else {
        // Otherwise, fall back to a generic error message
        toast.error("An error occurred while registering. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };
  const handlePhoneChange = (newValue) => {
    setPhoneValue(newValue);
    //setFormData({ ...formData, ["mobileNumber"]: newValue });
  };

  const onClickMobileSubmit = async () => {
    if (phoneValue === "") {
      toast.error("Please enter mobile number");
      return;
    }

    const phoneWithoutSpaces = phoneValue.replace(/\s+/g, "");

    setChecking(true);
    try {
      const response = await axios.get(
        EXHIBITOR_API +
          `get-service-badge-person-mobile?id=${encodeURIComponent(
            phoneWithoutSpaces
          )}`
      );
      if (response.status === 200) {
        navigate(`/service-badge-preview/${phoneWithoutSpaces}`);
      } else if (response.status === 204) {
        // Handle successful response with no content
        toast.info("Your mobile number is not registered");
      }
    } catch (error) {
      // Check if the error has a response object
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // If the response has a data object with a message, display it
        toast.error(error.response.data.message);
      } else {
        // Otherwise, fall back to a generic error message
        toast.error("An error occurred while fetching. Please try again.");
      }
    } finally {
      setChecking(false);
    }
  };

  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <RegHeader />
        <Box
          mt={4}
          width={"100%"}
          maxWidth={500}
          pl={1}
          pr={1}
          ml={"auto"}
          mr={"auto"}
        >
          <Typography
            mb={4}
            textAlign={"center"}
            color={"rgb(151, 42, 76)"}
            fontWeight={"bold"}
            fontSize={"28px"}
          >
            Service Badge
          </Typography>
          <TextField
            value={exhibitorId}
            onChange={(e) => setExhibitorId(e.target.value)}
            fullWidth
            label="Enter Exhibitor ID"
          />
          <Box>
            <LoadingButton
              fullWidth
              loading={loading}
              sx={{ mt: 4 }}
              onClick={onClickSubmit}
              variant="contained"
            >
              Proceed
            </LoadingButton>
          </Box>
          <Typography sx={{ mt: 4, mb: 1 }} variant="h6">
            Already registered? Please Enter Mobile number to view your Badge.
          </Typography>
          <MuiTelInput
            size="small"
            fullWidth
            defaultCountry="IN"
            value={phoneValue}
            onChange={handlePhoneChange}
          />
          <Box>
            <LoadingButton
              sx={{
                mt: 2,
                backgroundColor: "#972a4c", // Normal state background color
                "&:hover": {
                  backgroundColor: "#7a1e3a", // Darker shade for hover state
                },
              }}
              fullWidth
              loading={checking}
              onClick={onClickMobileSubmit}
              variant="contained"
            >
              Preview Badge
            </LoadingButton>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 4,
            flexGrow: 1, // This will make the box grow to fill available space
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ServiceBadge;
