import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Container,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import { EXHIBITOR_API, OES_COMPONENTS } from "../../../../constants/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import Supplier2 from "./reuse/Supplier2";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import MuPb from "../../../../widgets/MuPb";
import { SubmittedStatus, oesMenus } from "./Services";
import { useParams } from "react-router-dom";
export function reformatDate(inputDate) {
  // Create a Date object from the input date string
  const date = new Date(inputDate);

  // Define month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract day, month, and year components from the Date object
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Create the formatted date string
  const formattedDate = `${day} ${month}, ${year}`;

  return formattedDate;
}
const ExitPass = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const { id } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [rows, setRows] = useState([
    {
      particulars: "",
      outQty: "",
      outDate: "",
      remarks: "",
    },
  ]);
  const handleAddRow = () => {
    setRows([
      ...rows,
      { particulars: "", outQty: "", outDate: "", remarks: "" },
    ]);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  if (isLoading) {
    return <MuPb />;
  }
  const employeeRows = rows
    .map(
      (employee, index) =>
        `<tr key=${index}>
    <td>${employee.particulars}</td>
    <td>${employee.outQty}</td>
    <td>${reformatDate(employee.outDate)}</td>
    <td>${employee.remarks}</td>
  </tr>`
    )
    .join("");
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div>${spaceTemplate(
      data
    )}<br/> <table> <tr><th>Particulars</th><th>Out Qty</th><th>Out Date</th><th>Remarks</th></tr>${employeeRows}</table> <br/> <img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: " 4.5 Exit Pass",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: " 4.5 Exit Pass",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" mb={1}>
          4.5 Exit Pass
        </Typography>

        <Box mb={4}>
          {/* <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert> */}
        </Box>
        <Typography variant="body1" gutterBottom>
          Submit in 4 copies to respective Hall Directors at Exhibition Site on
          24 Jan 2024.
        </Typography>

        <SpaceInfo data={data} />

        <Box mt={2} mb={3} p={2} border="1px solid grey" borderRadius="8px">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Particulars</TableCell>
                <TableCell>Out Qty.</TableCell>
                <TableCell>Out Date</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={row.particulars}
                      onChange={(e) => {
                        const newRow = { ...row, particulars: e.target.value };
                        const newRows = [...rows];
                        newRows[index] = newRow;
                        setRows(newRows);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={row.outQty}
                      onChange={(e) => {
                        const newRow = { ...row, outQty: e.target.value };
                        const newRows = [...rows];
                        newRows[index] = newRow;
                        setRows(newRows);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="date"
                      value={row.outDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        const newRow = { ...row, outDate: e.target.value };
                        const newRows = [...rows];
                        newRows[index] = newRow;
                        setRows(newRows);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={row.remarks}
                      onChange={(e) => {
                        const newRow = { ...row, remarks: e.target.value };
                        const newRows = [...rows];
                        newRows[index] = newRow;
                        setRows(newRows);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveRow(index)}
                      color="error"
                    >
                      <DeleteIcon fontSize="14px" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box mt={2}>
            <Button size="small" variant="contained" onClick={handleAddRow}>
              Add Row
            </Button>
          </Box>
        </Box>
        <Supplier2
          user={data}
          id={9}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            onClick={onSubmit}
            disabled={!supplier || rows.length <= 0}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};

export default ExitPass;
