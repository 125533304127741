import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { DEFAULT_EX_ICON } from "../../../constants/constants";

export const HoverComponent = ({ allExhibitors, hall, stand }) => {
  const exhibitor = allExhibitors?.find(
    (exh) =>
      exh.hallNumber.value === hall.name && exh.standNumber.value === stand.name
  );
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0,
  });
  const cardRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      let newX = e.clientX;
      let newY = e.clientY;
      if (cardRef.current) {
        const cardWidth = cardRef.current.offsetWidth;
        const cardHeight = cardRef.current.offsetHeight;

        // Adjust position to prevent overflow
        if (newX + cardWidth + 16 > window.innerWidth) {
          newX -= cardWidth + 16;
        } else {
          newX += 16; // 16px from cursor
        }

        if (newY + cardHeight + 16 > window.innerHeight) {
          newY -= cardHeight + 16;
        } else {
          newY += 16; // 16px from cursor
        }
      }
      setCursorPosition({ x: newX, y: newY });
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      {cursorPosition.x > 0 && cursorPosition.y > 0 && (
        <Card
          ref={cardRef}
          sx={{
            width: 220,
            position: "fixed",
            left: cursorPosition.x,
            top: cursorPosition.y,
            zIndex: 100,
            // other styles as needed
          }}
        >
          <CardContent>
            <Box>
              <Avatar
                src={
                  exhibitor?.profile_image
                    ? exhibitor.profile_image
                    : DEFAULT_EX_ICON
                }
                alt={`${exhibitor?.companyName?.value} Logo`}
                sx={{
                  width: 64,
                  height: 64,
                  ml: "auto",
                  mr: "auto",
                  backgroundColor: "#a4a4a4",
                }}
              />
              <Box>
                <Typography
                  mt={1}
                  variant="body1"
                  textAlign="center"
                  fontWeight={600}
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {exhibitor?.companyName.value}
                </Typography>

                <Typography
                  mt={1}
                  textAlign={"center"}
                  variant="body1"
                  color="text.secondary"
                >
                  {hall.name} | {stand.name}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};
