import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDropzone } from "react-dropzone";
import { Button, FormControl, TextField } from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  USER,
} from "../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../utility/utility";
import axios from "axios";

const AddCompanyVideo = ({ exhibitor, showAdd, onAdded }) => {
  const [uploading, setUploading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [selectedValue, setSelectedValue] = useState("embed");
  const [embedCode, setEmbedCode] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const onDrop = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  };

  useEffect(() => {
    if (exhibitor.companyVideo) {
      setEmbedCode(exhibitor.companyVideo);
    }
  }, [showAdd]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "video/*",
    onDrop,
  });
  const onSelect = (event) => {
    setSelectedValue(event.target.value);
  };
  const onChangeVideo = () => {
    setUploadedFile(null);
    setVideoUrl("");
  };
  const onUpdateVideo = async () => {
    if (!embedCode.trim()) {
      toast.info("Embed Code is required!");
    } else {
      try {
        const { data } = await axios.post(
          `${API_ENDPOINT}${USER}/add-video`,
          { companyVideo: embedCode },
          getHeader()
        );
        setUploading(false);
        toast.success(data.message);
        setTimeout(() => {
          document.location.reload();
        }, 5);
      } catch (error) {
        setUploading(false);
        toast.error(error.message);
      }
    }
  };
  return (
    <div style={{ maxWidth: "500px", margin: "8px auto" }}>
      <h2 style={{ color: "#185399" }}>Add Video</h2>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedValue}
          onChange={onSelect}
        >
          <FormControlLabel
            value="embed"
            control={<Radio />}
            label="Embed Code"
          />
          <FormControlLabel
            value="upload"
            disabled
            control={<Radio />}
            label="Upload Video"
          />
        </RadioGroup>
      </FormControl>
      {selectedValue === "embed" ? (
        <div style={{ marginTop: "16px" }}>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={embedCode}
            onChange={(e) => setEmbedCode(e.target.value)}
            label="Embed Code"
            placeholder="Paste your embed code here..."
          />
        </div>
      ) : (
        <>
          {videoUrl === "" ? (
            <div
              style={{
                backgroundColor: "white",
                marginTop: "16px",
                padding: "4px",
                border: "1px solid #ddd",
                color: "#888",
                borderRadius: "8px",
              }}
            >
              <div
                {...getRootProps()}
                style={{
                  cursor: "pointer",
                  borderRadius: "8px",
                  height: "115px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ddd",
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the video file here ...</p>
                ) : (
                  <p>
                    Drag and drop a video file here, or click to select a file
                  </p>
                )}
                {uploadedFile && <div>{uploadedFile.name}</div>}
              </div>
            </div>
          ) : (
            <div>
              <video width="320" height="240" controls>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <br />
              <Button
                onClick={onChangeVideo}
                startIcon={<ChangeCircleIcon />}
                size="small"
                variant="outlined"
              >
                Change
              </Button>
            </div>
          )}
        </>
      )}
      <div style={{ marginTop: "16px" }}>
        <LoadingButton
          onClick={onUpdateVideo}
          loading={uploading}
          fullWidth
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default AddCompanyVideo;
