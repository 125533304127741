import React from "react";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { Grid } from "@mui/material";
import ProductItem from "../../../widgets/ProductItem";

const ProductFavorites = () => {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-product-favorites`
  );
  return (
    <div>
      <Grid container spacing={3}>
        {data?.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <ProductItem viewMode={true} key={index} product={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProductFavorites;
