import React, { useState } from "react";
import MuPb from "../../../../../widgets/MuPb";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Pagination,
  Typography,
} from "@mui/material";
import SearchBar from "../../../../../widgets/searchBar";
import useVisitors from "../../hooks/useVisitors";
import { ADMIN_API, DEFAULT_DP } from "../../../../../constants/constants";
import { Link } from "react-router-dom";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  getAdminHeader,
  toShortHumanReadableDate,
} from "../../../../../utility/utility";
import { useAllVisitors } from "../../hooks/useAllVisters";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import SimpleYesNoDialog from "../../../../../widgets/SimpleYesNoDialog";
import CustomCircularP from "../../../../../widgets/CustomCircularP";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import axios from "axios";

const VisitorsDashboard = () => {
  const [page, setPage] = useState(1);
  const [searchQ, setSearchQ] = useState("");
  const { data, isLoading, isError, error, refetchVisitors } = useVisitors(
    page,
    searchQ
  ); // Fetch visitors for page 1
  const {
    allVisitors,
    loadingVisitors,
    visitorsFetchError,
    errorDetails,
    refetchAllVisitors,
  } = useAllVisitors();
  const [showDelete, setShowDelete] = useState(false);
  const [cV, setCv] = useState();
  const [deleting, setDeleting] = useState(false);
  if (isLoading && !searchQ) return <MuPb />;
  if (isError) return <Alert severity="error">Error: {error.message}</Alert>;
  const pageChange = (e, page) => {
    setPage(page);
  };
  const handleExport = () => {
    refetchAllVisitors();
  };
  const onYesDelete = async () => {
    setDeleting(true);
    try {
      const response = await axios.delete(
        `${ADMIN_API}/delete-visitor?id=${cV._id}`,
        getAdminHeader()
      );
      setDeleting(false);
      if (response.status === StatusCodes.OK) {
        setShowDelete(false);
        toast.success(response.data.message);
        refetchVisitors();
      }
    } catch (error) {
      setShowDelete(false);
      setDeleting(false);
      console.log(error);
      toast.error(error.message);
    }
  };
  const onClickDelete = (vis) => {
    setCv(vis);
    setShowDelete(true);
  };
  const handleSearch = (e) => {
    const vl = e.target.value;
    setSearchQ(vl);
  };
  return (
    <Box sx={{ ml: "auto", mr: "auto", maxWidth: "1280px" }}>
      <CustomCircularP show={deleting} />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message="Are you sure you want to delete this?"
        onYes={onYesDelete}
        show={showDelete}
        setShow={setShowDelete}
      />

      <Grid container alignItems="center" spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={4} md={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              All Visitors
            </Typography>
            <Box ml={2}>
              <LoadingButton
                sx={{
                  backgroundColor: "#054059",
                  "&:hover": {
                    backgroundColor: "#042D45", // A darker shade for hover
                  },
                }}
                loading={loadingVisitors}
                startIcon={<GetAppIcon />}
                variant="contained"
                color="primary"
                onClick={handleExport}
              >
                Export
              </LoadingButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Typography variant="body1">
            Total:{" "}
            <span style={{ fontWeight: "bold" }}>{data?.totalItems}</span>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <SearchBar
            label="Search Visitors"
            onSearch={handleSearch}
            cstyle={{ margin: "0 auto" }}
            mystyle={{ borderRadius: 0 }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Box display="flex" justifyContent="flex-end">
            <Pagination
              page={page}
              onChange={pageChange}
              count={Math.ceil(data?.totalItems / 30)}
            />
          </Box>
        </Grid>
      </Grid>
      <table className="expo-table">
        <thead>
          <tr>
            <th>Photo</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Country</th>
            <th>State</th>
            <th>City</th>
            <th>Joined</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((visitor, i) => (
            <tr key={i}>
              <td data-label="Image">
                <img
                  src={
                    visitor.profile_image ? visitor.profile_image : DEFAULT_DP
                  }
                  alt={`${visitor.firstName} Logo`}
                />
              </td>
              <td data-label="Name">
                <Box sx={{ display: "flex" }}>
                  <Link
                    style={{ flex: 1 }}
                    to={`/admin/home/visitor?id=${visitor.user_id}`}
                  >
                    {visitor.title} {visitor.firstName} {visitor.lastName}{" "}
                    {visitor.name}
                  </Link>
                  {/* <IconButton
                    onClick={() => onClickDelete(visitor)}
                    sx={{ ml: 1 }}
                    size="small"
                  >
                    <DeleteIcon fontSize="12px" />
                  </IconButton> */}
                </Box>
              </td>
              <td data-label="Designation">{visitor.designation}</td>
              <td data-label="Email">{visitor.email}</td>
              <td data-label="Phone">{`${visitor.mobileNo}`}</td>
              <td data-label="Country">{visitor?.country?.name}</td>
              <td data-label="State">{visitor?.stateRegion?.name}</td>
              <td data-label="City">{visitor.city?.name}</td>
              <td data-label="Joined">
                <div style={{ fontSize: "14px", fontStyle: "italic" }}>
                  {toShortHumanReadableDate(visitor.createdOn)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default VisitorsDashboard;
