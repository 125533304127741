import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import imtexLogo from "../../assets/imtexLogo2.png";

// Sample data structure

export default function VisitorStatsTable() {
  const visitorData = [
    {
      date: "19 January 2024",
      times: [1005, 800, 582, 496, 426, 300, 156, 52, 3],
      totalVisitors: 3820,
    },
    {
      date: "20 January 2024",
      times: [2041, 1558, 1215, 694, 1446, 536, 185, 65, 1],
      totalVisitors: 7741,
    },
    {
      date: "21 January 2024",
      times: [3022, 2169, 1733, 960, 2293, 680, 435, 46, 0],
      totalVisitors: 11338,
    },
    {
      date: "22 January 2024",
      times: [2649, 1925, 1615, 1117, 1031, 556, 264, 97, 0],
      totalVisitors: 9254,
    },
    {
      date: "23 January 2024",
      times: [1859, 1296, 964, 586, 1495, 1578, 240, 59, 0],
      totalVisitors: 8077,
    },
  ];
  const headerCellStyle = {
    backgroundColor: "#000000",
    color: "#ffffff",
    fontWeight: "bold",
  };

  // Style for date cells
  const dateCellStyle = {
    backgroundColor: "red",
    color: "#ffffff",
    fontWeight: "bold",
  };

  // Style for total visitor cells
  const totalVisitorCellStyle = {
    backgroundColor: "green",
    color: "#ffffff",
    fontWeight: "bold",
  };

  // Style for other cells
  const otherCellStyle = {
    backgroundColor: "#000000",
    color: "rgb(224 201 117)",
    fontWeight: "bold",
  };
  const totalVisitorsExcludingRepeats = 39535;
  const totalVisitorsIncludingRepeats = 45507;
  return (
    <Box p={2}>
      <Box display="flex">
        <Box flex={1}>
          <img
            src={imtexLogo}
            alt="Imtex Logo"
            style={{ display: "block", height: "96px" }}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box maxWidth={250}>
            <Box bgcolor={"black"} color={"white"} p={1} fontSize={"12px"}>
              Overall Visitors for the Event <br /> (Excluding Repeat Visitors)
            </Box>
            <Box
              bgcolor={"yellow"}
              fontSize={"17px"}
              fontWeight={"bold"}
              color={"black"}
              textAlign={"center"}
              p={1}
            >
              {totalVisitorsExcludingRepeats}
            </Box>
          </Box>
          <Box maxWidth={250} ml={2}>
            <Box bgcolor={"black"} color={"white"} p={1} fontSize={"12px"}>
              Overall Visitors for the Event <br /> (Including Repeat Visitors)
            </Box>
            <Box
              bgcolor={"yellow"}
              fontSize={"17px"}
              fontWeight={"bold"}
              color={"black"}
              textAlign={"center"}
              p={1}
            >
              {totalVisitorsIncludingRepeats}
            </Box>
          </Box>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="visitor statistics table">
          <TableHead>
            <TableRow>
              <TableCell style={headerCellStyle}>Date</TableCell>
              <TableCell style={headerCellStyle}>Before 11 AM</TableCell>
              <TableCell style={headerCellStyle}>11 AM to 12 PM</TableCell>
              <TableCell style={headerCellStyle}>12 PM to 1 PM</TableCell>
              <TableCell style={headerCellStyle}>1 PM to 2 PM</TableCell>
              <TableCell style={headerCellStyle}>2 PM to 3 PM</TableCell>
              <TableCell style={headerCellStyle}>3 PM to 4 PM</TableCell>
              <TableCell style={headerCellStyle}>4 PM to 5 PM</TableCell>
              <TableCell style={headerCellStyle}>5 PM to 6 PM</TableCell>
              <TableCell style={headerCellStyle}>6 PM and Later</TableCell>
              <TableCell style={headerCellStyle}>Total Visitors</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {visitorData.map((row) => (
              <TableRow key={row.date}>
                {/* Date cell with unique style */}
                <TableCell style={dateCellStyle} component="th" scope="row">
                  {row.date}
                </TableCell>
                {/* Other cells with their respective styles */}
                {row.times.map((time, index) => (
                  <TableCell key={index} style={otherCellStyle}>
                    {time}
                  </TableCell>
                ))}
                {/* Total visitor cell with unique style */}
                <TableCell style={totalVisitorCellStyle}>
                  {row.totalVisitors}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
