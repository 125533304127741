import React from "react";
import { Typography, Box, Paper } from "@mui/material";

function ComingSoon() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          textAlign: "center",
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <Typography variant="h3" gutterBottom>
          Coming Soon
        </Typography>
        <Typography variant="body1">
          We're working hard to bring you this feature. Stay tuned!
        </Typography>
      </Paper>
    </Box>
  );
}

export default ComingSoon;
