import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { ADMIN_API } from "../../../../../../constants/constants";
import { getAdminHeader } from "../../../../../../utility/utility";
import _ from "lodash";
const RegistrationSettingsContext = createContext();

export const RegistrationSettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch settings when the component is mounted
    const fetchSettings = async () => {
      try {
        const { data } = await axios.get(
          `${ADMIN_API}/visitor/registration-settings`,
          getAdminHeader()
        );
        setSettings(data);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const updateProperty = (propertyPath, value) => {
    console.log(propertyPath, value);
    if (settings) {
      const updatedSettings = { ...settings };
      _.set(updatedSettings, propertyPath, value);
      setSettings(updatedSettings);
    }
  };
  const updatePush = (propertyPath, value) => {
    if (settings) {
      const updatedSettings = { ...settings };
      let targetArray = _.get(updatedSettings, propertyPath);
      targetArray.push(value);

      _.set(updatedSettings, propertyPath, targetArray);
      setSettings(updatedSettings);
    }
  };

  return (
    <RegistrationSettingsContext.Provider
      value={{ settings, updateProperty, updatePush, isLoading, error }}
    >
      {children}
    </RegistrationSettingsContext.Provider>
  );
};

export const useRegistrationSettings = () => {
  return useContext(RegistrationSettingsContext);
};
