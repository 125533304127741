import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { API_ENDPOINT, USER } from "../../constants/constants";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import ReactDOMServer from "react-dom/server";
import { GROUP_ONE, getOnsiteHeader } from "../../utils2024/utils";
import { ImtexBadgeDimenstion } from "../onsite/SpotRegistration";
function ScanBadge() {
  const printRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    // If not printing, focus the input field
    if (!isPrinting && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isPrinting]);

  const handleScanInput = (event) => {};
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const scannedValue = event.target.value;
      fetchUserDetails(scannedValue);
      inputRef.current.value = "";
      inputRef.current?.focus();
    }
  };

  const fetchUserDetails = async (bid) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}${USER}/get-scanned-visitor?barcode_id=${bid}`,
        getOnsiteHeader(GROUP_ONE)
      );
      printRow(data);
    } catch (error) {
      toast.error(error.message);
      inputRef.current.value = "";
    } finally {
      setIsLoading(false);
      inputRef.current.value = "";
      inputRef.current?.focus();
    }
  };

  const printRow = (row) => {
    setIsPrinting(true);
    const printableContent = ReactDOMServer.renderToString(
      <ImtexBadgeDimenstion row={row} />
    );
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${printableContent}
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus(); // For some browsers to trigger the print dialog
    printWindow.print();
    printWindow.close();
    inputRef.current.value = "";
    setIsPrinting(false);
  };

  return (
    <div
      style={
        {
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          // justifyContent: "center",
          // height: "100vh",
          // background: "#f0f0f0",
        }
      }
    >
      {/* <RegHeader />
      <Box ml={2} mt={2}>
        <BackBtn />
      </Box> */}

      <Box
        mt={2}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          {/* {currentUser && <BadgeToPrint ref={printRef} user={currentUser} />} */}
          <h2>The Cursor should be in the Input Field while Scanning.</h2>
          <Box display={"table"} ml={"auto"} mr={"auto"}>
            <input
              ref={inputRef}
              id="barcodeInput"
              type="text"
              // value={barcodeId}
              onChange={handleScanInput}
              onKeyDown={handleKeyDown}
              placeholder="Scan QR Code"
              style={{
                padding: "10px",
                fontSize: "16px",
                width: "300px",
                textAlign: "center",
                borderRadius: "4px",
                border: "2px solid #000",
              }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default ScanBadge;
