import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  CardContent,
  Card,
  Container,
  Slide,
  Slider,
  List,
  ListItem,
  Button,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import SimpleCalendar from "./SimpleCalendar";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RdMyModal from "../../../widgets/myModal/RdMyModal";
import FpCropEdit from "./FpCropEditor";
import HallCropEdit from "./HallCropEdit";
import axios from "axios";
import { API_ENDPOINT, USER } from "../../../constants/constants";
import { useNavigate } from "react-router-dom";
import { getAdminHeader } from "../../../utility/utility";
import { toast } from "react-toastify";
import { StatusCodes } from "http-status-codes";
function UploadHall({
  onBack,
  color,
  strokeWidth,
  originalDimen,
  points,
  rectangle,
  selectedMode,
}) {
  const navigate = useNavigate();
  const cardRef = useRef(null);
  const imgFile = useRef(null);
  const [image, setImage] = useState(null);
  const [hallName, setHallName] = useState(null);

  const [gMenuEl, setGmenuEl] = useState(null);
  const [hMenuEl, setHmenuEl] = useState(null);
  const [showImageCrop, setShowImageCrop] = useState(false);
  const imgRef = useRef(new Image());

  console.log(selectedMode, points, rectangle);

  const handleDateClick = (info) => {
    console.log("Clicked on date: ", info.dateStr);
  };

  const hanldeHallMenuClick = (e, hall) => {
    setHmenuEl(e.target);
  };
  const handleGeneralPlanEdit = (e) => {
    setGmenuEl(e.target);
  };
  const handleGmenuClick = (e, m) => {
    setGmenuEl(null);
    setShowImageCrop(true);
  };
  const handleHmenuClick = (e, m) => {};
  const onSave = () => {
    const shape = {
      type: selectedMode,
      shape: selectedMode === "Rectangle" ? rectangle : points,
    };
    addHall(1, image, hallName, shape);
  };
  const addHall = async (eventId, image, name, shape) => {
    try {
      const response = await axios.post(
        `${API_ENDPOINT}${USER}/add-hall`,
        {
          eventId: eventId,
          color: color,
          strokeWidth: strokeWidth,
          dimen: originalDimen,
          image: image,
          name: name,
          shape: shape,
        },
        getAdminHeader()
      );

      if (response.status === 201) {
        console.log("Hall added successfully:", response.data);
        navigate(-1);
      } else {
        console.error("Failed to add hall:", response.data.message);
      }
    } catch (error) {
      if (error.response) {
        // Check for the conflict status code
        if (error.response.status === StatusCodes.CONFLICT) {
          console.error(
            "Conflict error while adding hall:",
            error.response.data.message
          );
          toast.error("Hall already exists: " + error.response.data.message);
        } else {
          console.error(
            "Error while adding hall:",
            error.response.data.message
          );
          toast.error(error.response.data.message);
        }
      } else if (error.request) {
        console.error("Error while adding hall: No response from server");
        toast.error("No response from server");
      } else {
        console.error("Error while adding hall:", error.message);
        toast.error(error.message);
      }
    }
  };

  return (
    <Box m={2}>
      <RdMyModal
        showModal={showImageCrop}
        setShowModal={setShowImageCrop}
        modalC={
          <HallCropEdit
            setPic={setImage}
            show={showImageCrop}
            setShow={setShowImageCrop}
          />
        }
      />
      <Button
        variant="text"
        sx={{
          textTransform: "none",
          "& .MuiButton-startIcon": {
            marginRight: "0", // Adjust this value to your desired spacing
          },
        }}
        startIcon={<ArrowBackIosIcon />}
        size="small"
        onClick={onBack}
      >
        Back
      </Button>
      <input
        ref={imgFile}
        type="file"
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png"
      />
      <Menu
        anchorEl={gMenuEl}
        open={Boolean(gMenuEl)}
        onClose={() => setGmenuEl(null)}
      >
        <MenuItem onClick={(e) => handleGmenuClick(e, "Edit Image")}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit Image
        </MenuItem>
        <MenuItem onClick={(e) => handleGmenuClick(e, "Replace Image")}>
          <ListItemIcon>
            <FindReplaceIcon fontSize="small" />
          </ListItemIcon>
          Replace Image
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ color: "red" }}
          onClick={(e) => handleGmenuClick(e, "Delete Image")}
        >
          <ListItemIcon>
            <DeleteOutlineIcon sx={{ color: "red" }} fontSize="small" />
          </ListItemIcon>
          Delete Image
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={hMenuEl}
        open={Boolean(hMenuEl)}
        onClose={() => setHmenuEl(null)}
      >
        <MenuItem onClick={(e) => handleHmenuClick(e, "Edit Hall")}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit Image
        </MenuItem>
        <MenuItem onClick={(e) => handleHmenuClick(e, "Add Stand")}>
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          Add Stand
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ color: "red" }}
          onClick={(e) => handleHmenuClick(e, "Delete Hall")}
        >
          <ListItemIcon>
            <DeleteOutlineIcon sx={{ color: "red" }} fontSize="small" />
          </ListItemIcon>
          Delete Hall
        </MenuItem>
      </Menu>

      <Grid marginTop={1} container spacing={2}>
        <Grid item xs={7}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // Adjust as per your needs
                }}
              >
                <Typography variant="h6">Hall Image</Typography>

                <IconButton
                  onClick={handleGeneralPlanEdit}
                  color="primary"
                  aria-label="edit"
                >
                  <EditIcon />
                </IconButton>
              </Box>

              <Card
                ref={cardRef}
                sx={{
                  mt: 2,
                  padding: 2,
                  height: "400px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center", // Centers the canvas horizontally
                  alignItems: "center",
                }}
                variant="outlined"
              >
                {image === null ? (
                  <Box
                    onClick={() => setShowImageCrop(true)}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      border: "2px dashed gray",
                      padding: 4,
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="h6">
                      Click to upload a Hall image
                    </Typography>
                    <Typography variant="body2">
                      Only images (JPG/JPEG, PNG) less than 2mb are allowed for
                      upload.
                    </Typography>
                  </Box>
                ) : (
                  <img
                    src={image}
                    alt=""
                    style={{ display: "block", width: "90%" }}
                  />
                )}
              </Card>
              {/* <Box sx={{ mt: 2, maxWidth: 300, ml: "auto", mr: "auto" }}>
                <Slider
                  value={zoom}
                  min={0.5} // Minimum zoom level (50%)
                  max={2} // Maximum zoom level (200%)
                  step={0.1}
                  onChange={(e, value) => setZoom(value)}
                />
              </Box> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card>
            <CardContent>
              <Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // Adjust as per your needs
                  }}
                >
                  <Typography variant="h6">Add New Hall</Typography>
                </Box>

                <Box sx={{ mt: 2 }}>
                  <TextField
                    sx={{ mb: 1 }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={hallName}
                    onChange={(e) => setHallName(e.target.value)}
                    label="Hall Name"
                  />
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label={
                        <Typography variant="body2">Conference Hall</Typography>
                      }
                    />
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label={
                        <Typography variant="body2">Check-in Zone</Typography>
                      }
                    />
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label={
                        <Typography variant="body2">
                          Hide on Front-End
                        </Typography>
                      }
                    />
                  </FormGroup>
                  {/* <SimpleCalendar month={9} year={2023} /> */}
                  <Stack
                    spacing={2}
                    marginTop={2}
                    direction="row"
                    sx={{ width: "100%" }}
                  >
                    <Button
                      onClick={onBack}
                      size="small"
                      variant="outlined"
                      sx={{ flex: 1 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={onSave}
                      size="small"
                      variant="contained"
                      sx={{ flex: 1 }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default UploadHall;
