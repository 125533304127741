import React from "react";
import VisitorCard from "../../../widgets/VisitorCard";
import { Grid, LinearProgress } from "@mui/material";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";

const VisitorFavorites = () => {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-visitor-favorites`
  );

  return (
    <div>
      {isLoading && <LinearProgress />}
      <Grid container spacing={3}>
        {data?.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <VisitorCard item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default VisitorFavorites;
