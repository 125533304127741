import React, { useState } from 'react';

const DashButton = ({
label="", onClick, bgColor="#ccc", hoverBgColor='#ddd', color="black", hoverColor='#333',  myStyle={}
}) => {
    const [hover, setHover] = useState(false)
    const handleClick = ()=>{
        if(onClick) onClick()
    }
    return (
        <div
        onClick={handleClick}
        onMouseEnter={e=>setHover(true)}
        onMouseLeave={e=>setHover(false)}
        style={{
            cursor: 'pointer',
           fontWeight: 500, 
           userSelect: 'none',
           fontSize: '12px',
           padding: '6px',
           color: hover? hoverColor: color,
           textAlign: 'center',
           backgroundColor: hover? hoverBgColor:bgColor,
           borderRadius: '12px',
           ...myStyle
        }}
        >
            {label}
        </div>
    );
};

export default DashButton;