import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { Box, InputAdornment, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  USER,
} from "../../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../../utility/utility";
import axios from "axios";
import xLogo from "../../../../../assets/x_logo.png";
import { toastError } from "../../../../../utils2024/utils";

function SocialLinksEdit({ user, onUpdated, show, setShow }) {
  const [links, setLinks] = useState({
    facebook: "",
    instagram: "",
    linkedin: "",
    twitter: "",
  });
  const [saving, setSaving] = useState(false);

  const handleChange = (platform) => (event) => {
    setLinks({ ...links, [platform]: event.target.value });
  };

  useEffect(() => {
    if (user.socialLinks) {
      setLinks({
        facebook: user.socialLinks.value.facebook,
        instagram: user.socialLinks.value.instagram,
        linkedin: user.socialLinks.value.linkedin,
        twitter: user.socialLinks.value.twitter,
      });
    }
  }, [show]);
  const update = async () => {
    const toUpdateObject = {
      socialLinks: {
        label: "Social Links",
        value: links,
      },
    };
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}${USER}/update-user`,
        toUpdateObject,
        getHeader()
      );
      setSaving(false);
      setShow(false);
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 10);
    } catch (error) {
      toastError(error);
    }
  };
  return (
    <Box p={2}>
      <Typography
        mb={2}
        fontSize={18}
        color={"#444"}
        fontWeight={600}
        variant="body1"
      >
        Update Social Media
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center">
        <TextField
          label="Facebook"
          variant="outlined"
          size="small"
          value={links.facebook}
          onChange={handleChange("facebook")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FacebookIcon style={{ color: "#3b5998" }} />
              </InputAdornment>
            ),
          }}
          style={{ marginRight: "16px" }} // Spacing between fields
        />

        <TextField
          label="Instagram"
          variant="outlined"
          size="small"
          value={links.instagram}
          onChange={handleChange("instagram")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InstagramIcon style={{ color: "#E1306C" }} />
              </InputAdornment>
            ),
          }}
          style={{ marginRight: "16px" }}
        />

        <TextField
          label="LinkedIn"
          variant="outlined"
          size="small"
          value={links.linkedin}
          onChange={handleChange("linkedin")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkedInIcon style={{ color: "#0077b5" }} />
              </InputAdornment>
            ),
          }}
          style={{ marginRight: "16px" }}
        />

        <TextField
          label="Twitter"
          variant="outlined"
          size="small"
          value={links.twitter}
          onChange={handleChange("twitter")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {/* <TwitterIcon style={{ color: "#1DA1F2" }} /> */}
                <img
                  src={xLogo}
                  alt="Twitter"
                  style={{ width: 24, height: 24 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <LoadingButton
        loading={saving}
        onClick={update}
        sx={{
          mt: 4,
          ml: "auto",
          mr: "auto",
          display: "block",
          mb: 2,
          width: 120,
        }}
        variant="contained"
      >
        Save
      </LoadingButton>
    </Box>
  );
}

export default SocialLinksEdit;
