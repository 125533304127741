import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import { useRegistrationSettings } from "../context/RegistrationSettingsContext";

const LabelEditor = ({ propName, hide }) => {
  const { settings, updateProperty, isLoading, error } =
    useRegistrationSettings();
  const [cvalue, setCvalue] = useState("");
  useEffect(() => {
    if (propName !== "") {
      setCvalue(_.get(settings, propName));
    }
  }, [propName]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error: {error}</p>;
  }
  const onSave = () => {
    updateProperty(propName, cvalue);
  };
  return (
    <div style={{ margin: "48px" }}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        label={`Update ${cvalue}`}
        value={cvalue}
        onChange={(e) => setCvalue(e.target.value)}
      />
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}></div>
        <LoadingButton
          onClick={onSave}
          sx={{ mt: 2 }}
          size="small"
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default LabelEditor;
