import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

function RdDesignationsSelect({
  state,
  handleStateChange,
  label = "Job Role*",
}) {
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={state.designation}
        name="designation"
        label={label}
        onChange={handleInputChange}
      >
        {positions.sort().map((position) => (
          <MenuItem key={position} value={position}>
            {position}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
const positions = [
  "Chairman",
  "Managing Director",
  "CEO",
  "President",
  "Country Head",
  "Executive Director",
  "Vice President",
  "Sr. Vice President",
  "CTO",
  "COO",
  "CFO",
  "General Manager",
  "Sr. Director",
  "Director",
  "Deputy Director",
  "General Manager",
  "Manager",
  "Production Head",
  "Technician",
  "Engineer",
  "Machine Operator",
  "Production/ Plant/ Technical Head",
  "Trainees",
  "Consultant",
  "Executive",
  "Partner",
  "Advisor",
  "Programmer",
  "Contractor",
  "Engineer",
];

export default RdDesignationsSelect;
