import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Container,
  Typography,
  Divider,
  Grid,
  Box,
  Button,
  Alert,
  FormControlLabel,
  FormLabel,
  FormControl,
  RadioGroup,
  Radio,
  TableFooter,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import Supplier2 from "./reuse/Supplier2";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { EXHIBITOR_API } from "../../../../constants/constants";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import MuPb from "../../../../widgets/MuPb";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";

const ShowDaily = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const rows = [
    { key: "Size", value: "Tabloid" },
    { key: "Pages", value: "24" },
    { key: "Printing", value: "4 Color" },
    { key: "Frequency", value: "Daily" },
    { key: "Publishing", value: "19 - 23 Jan 2024" },
    {
      key: "Material close",
      value:
        "05 Jan 2024 (please make sure that ad materials are repeated across newsletter)",
    },
    {
      key: "Material dispatch",
      value: "Soft copies only (preferably in tiff format)/High Resolution PDF",
    },
    {
      key: "Content",
      value: [
        "Exhibition info",
        "Interviews with prominent exhibitors",
        "Features",
        "Editorials",
      ].join(", "),
    },
  ];
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    const updatedPositions = positions.map((position) => ({
      ...position,
      inr: { ...position.inr, checked: false },
      usd: { ...position.usd, checked: false },
    }));
    setPositions(updatedPositions);
  };

  const [positions, setPositions] = useState([
    {
      position: "Inside Pages",
      availability: "Open",
      description: "Quarter Page (Horizontal)",
      dimension: "80 X 216mm",
      inr: { checked: false, price: 23000 },
      usd: { checked: false, price: 440 },
    },
    {
      position: "Inside Pages",
      availability: "Open",
      description: "Quarter Page (Vertical)",
      dimension: "150 X 80mm",
      inr: { checked: false, price: 23000 },
      usd: { checked: false, price: 440 },
    },
    {
      position: "Inside Pages",
      availability: "Open",
      description: "Half Page Color (Horizontal)",
      dimension: "150 X 216mm",
      inr: { checked: false, price: 32000 },
      usd: { checked: false, price: 605 },
    },
    {
      position: "Inside Pages",
      availability: "Open",
      description: "Half Page Color (Vertical)",
      dimension: "300 X 100mm",
      inr: { checked: false, price: 32000 },
      usd: { checked: false, price: 605 },
    },
    {
      position: "Front Cover",
      availability: "1 Per Day",
      description: "Bottom Strip (Horizontal)",
      dimension: "50 X 216mm",
      inr: { checked: false, price: 32000 },
      usd: { checked: false, price: 605 },
    },
    {
      position: "Front Cover",
      availability: "1 Per Day",
      description: "Side Strip (Vertical)",
      dimension: "230 X 50mm",
      inr: { checked: false, price: 32000 },
      usd: { checked: false, price: 605 },
    },
    {
      position: "Inside Pages",
      availability: "Open",
      description: "Full Page Color",
      dimension: "350 X 240mm +3mm bleed",
      inr: { checked: false, price: 40000 },
      usd: { checked: false, price: 770 },
    },
    {
      position: "Inside Back Cover",
      availability: "Open",
      description: "Full Page Color",
      dimension: "350 X 240mm +3mm bleed",
      inr: { checked: false, price: 57000 },
      usd: { checked: false, price: 1075 },
    },
    {
      position: "Back Cover",
      availability: "1 Per Day",
      description: "Full Page Color",
      dimension: "350 X 240mm +3mm bleed",
      inr: { checked: false, price: 65000 },
      usd: { checked: false, price: 1400 },
    },
    {
      position: "Centre Spread",
      availability: "1 Per Day",
      description: "Centre Spread Color",
      dimension: "350 X 480mm +3mm bleed",
      inr: { checked: false, price: 82000 },
      usd: { checked: false, price: 1540 },
    },
    {
      position: "Front False Cover",
      availability: "1 Per Day",
      description: "Front False Cover Color",
      dimension: "280 X 240mm +3mm bleed",
      inr: { checked: false, price: 110000 },
      usd: { checked: false, price: 1925 },
    },
  ]);

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckChange = (index, currency) => {
    // Make a deep copy of the positions array to prevent state mutation
    const newPositions = [...positions];
    newPositions[index][currency].checked =
      !newPositions[index][currency].checked;
    setPositions(newPositions);
  };

  const calculateTotalAndCount = (posA) => {
    let total = 0;
    let count = 0;
    posA.forEach((row) => {
      if (selectedValue === "inr" && row.inr.checked) {
        total += row.inr.price;
        count++;
      } else if (selectedValue === "usd" && row.usd.checked) {
        total += row.usd.price;
        count++;
      }
    });
    return { total, count };
  };

  const { total, count } = calculateTotalAndCount(positions);
  const formatPrice = (price, isForeign) => {
    return new Intl.NumberFormat(isForeign ? "en-US" : "en-IN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const generatePositionsHtmlTemplate = (positions, total) => {
    // Generate HTML for each position that is checked
    const positionsHtml = positions
      .map((position) => {
        let priceColumns = "";
        if (position.inr.checked) {
          priceColumns += `<td>INR ${position.inr.price.toLocaleString()} <span style="color: green;">✓</span></td>`;
        }
        if (position.usd.checked) {
          priceColumns += `<td>USD ${position.usd.price.toLocaleString()} <span style="color: green;">✓</span></td>`;
        }

        // Only include the row if at least one price is checked
        if (priceColumns) {
          return `
            <tr>
              <td>${position.position}</td>
              <td>${position.availability}</td>
              <td>${position.description}</td>
              <td>${position.dimension}</td>
              ${priceColumns}
            </tr>
          `;
        }

        return ""; // Return an empty string if no prices are checked
      })
      .join("");

    // Calculate GST and grand total based on the total
    const gst = total * 0.18;
    const grandTotal = total + gst;

    // Footer HTML with total, GST, and grand total
    const footerHtml = `
      <tfoot>
        <tr>
          <td colspan="4">Total</td>
          <td>${total.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="4">GST (18%)</td>
          <td>${gst.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="4">Grand Total</td>
          <td>${grandTotal.toLocaleString()}</td>
        </tr>
      </tfoot>
    `;

    // Determine the number of header columns based on what prices are checked
    const headerColumns = `
      <th>Position</th>
      <th>Availability</th>
      <th>Description</th>
      <th>Dimensions</th>
      ${
        positions.some((position) => position.inr.checked)
          ? "<th>Price INR</th>"
          : ""
      }
      ${
        positions.some((position) => position.usd.checked)
          ? "<th>Price USD</th>"
          : ""
      }
    `;

    // Combine positions HTML and footer to form the complete table
    return `
      <table>
        <thead>
          <tr>
            ${headerColumns}
          </tr>
        </thead>
        <tbody>
          ${positionsHtml}
        </tbody>
        ${footerHtml}
      </table>
    `;
  };
  if (isLoading) {
    return <MuPb />;
  }
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div> ${spaceTemplate(
      data
    )} <br />${generatePositionsHtmlTemplate(
      positions,
      total
    )}<br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "6.2 Advertisement Show Daily",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "6.2 Advertisement Show Daily",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Paper elevation={3} style={{ padding: "2rem" }}>
        <Typography variant="h5" mb={1}>
          6.2 Advertisement Show Daily
        </Typography>

        <Box mb={4}>
          {/* <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert> */}
        </Box>
        <Typography variant="body1">
          E-mail this form to Magic Wand Media Inc before 09 Jan 2024
        </Typography>
        <SpaceInfo data={data} />
        <Box sx={{ mt: 2, mb: 6 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Size</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Tabloid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.key}>
                  <TableCell>{row.key}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <FormControl sx={{ mt: 2 }} component="fieldset">
          <FormLabel component="legend">My Currency:</FormLabel>
          <RadioGroup row value={selectedValue} onChange={handleChange}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControlLabel
                  value="inr"
                  control={<Radio />}
                  label="India(INR)"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value="usd"
                  control={<Radio />}
                  label="Foreign(USD)"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Position</TableCell>
              <TableCell>Availability</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Dimension</TableCell>
              <TableCell>In INR per issue</TableCell>
              <TableCell>In USD per issue</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {positions.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.position}</TableCell>
                <TableCell>{row.availability}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.dimension}</TableCell>
                <TableCell>
                  <FormControlLabel
                    disabled={selectedValue !== "inr"}
                    control={
                      <Checkbox
                        checked={row.inr.checked}
                        onChange={() => handleCheckChange(index, "inr")}
                      />
                    }
                    label={formatPrice(row.inr.price, false)}
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    disabled={selectedValue !== "usd"}
                    control={
                      <Checkbox
                        checked={row.usd.checked}
                        onChange={() => handleCheckChange(index, "usd")}
                      />
                    }
                    label={formatPrice(row.usd.price, true)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2} align="right">
                {count} items selected
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right">
                Total Amount:
              </TableCell>
              <TableCell>
                {new Intl.NumberFormat("en-IN").format(total)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right">
                18% GST:
              </TableCell>
              <TableCell>
                {new Intl.NumberFormat("en-IN").format(0.18 * total)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right">
                Total:
              </TableCell>
              <TableCell>
                {new Intl.NumberFormat("en-IN").format(1.18 * total)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        <Supplier2
          user={data}
          id={20}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  );
};
/*
//deciated database server(MongoDB Atlas), decfiated file server(s3 bucket),
//
*/

const SHOW_DAILY = [
  {
    position: "Inside Pages",
    availability: "Open",
    description: "Quarter Page (Horizontal)",
    dimension: "80 X 216mm",
    inr: 23000,
    usd: 440,
  },
];
const SHOW_ITEMS = [
  {
    position: "False Cover",
    description: "Back Side",
    dimension: "350 X 240 mm + 3 mm bleed",
    inINRPerIssue: true,
    price: 0,
  },
  {
    position: "False Cover",
    description: "Front Side",
    dimension: "280 X 240 mm + 3 mm bleed",
    inINRPerIssue: true,
    price: 0,
  },
  {
    position: "Back Cover",
    description: "Full Page",
    dimension: "350 X 240 mm + 3 mm bleed",
    inINRPerIssue: true,
    price: 0,
  },
  {
    position: "Centre Spread",
    description: "Centre Spread",
    dimension: "350 X 480 mm + 3 mm bleed",
    inINRPerIssue: false,
    price: 0,
  },
  {
    position: "Main Cover",
    description: "Inside Front / Inside Back",
    dimension: "350 X 240 mm + 3 mm bleed",
    inINRPerIssue: false,
    price: 0,
  },
  {
    position: "Premium",
    description: "Page 3, Page 5, Page 7",
    dimension: "350 X 240 mm + 3 mm bleed",
    inINRPerIssue: false,
    price: 0,
  },
  {
    position: "Inside Pages",
    description: "Full Page",
    dimension: "350 X 240 mm + 3 mm bleed",
    inINRPerIssue: true,
    price: 0,
  },
  {
    position: "Inside Pages",
    description: "Half Page (V)",
    dimension: "320 X 100 mm",
    inINRPerIssue: false,
    price: 0,
  },
  {
    position: "Inside Pages",
    description: "Half Page (H)",
    dimension: "150 X 216 mm",
    inINRPerIssue: false,
    price: 0,
  },
  {
    position: "Front Cover",
    description: "Side Strip",
    dimension: "230 X 50 mm",
    inINRPerIssue: true,
    price: 0,
  },
  {
    position: "Front Cover",
    description: "Bottom Strip (H)",
    dimension: "50 X 216 mm",
    inINRPerIssue: false,
    price: 0,
  },
  {
    position: "Inside Pages",
    description: "Quarter Page (V)",
    dimension: "150 X 100 mm",
    inINRPerIssue: false,
    price: 0,
  },
  {
    position: "Inside Pages",
    description: "Quarter Page (H)",
    dimension: "50 X 216 mm",
    inINRPerIssue: true,
    price: 0,
  },
  {
    position: "Front Page",
    description: "Ear Panel",
    dimension: "72 X 64 mm",
    inINRPerIssue: false,
    price: 0,
  },
];
export default ShowDaily;
