import { Alert, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { getErrorResponse } from "../../../../utils2024/utils";
import MuPb from "../../../../widgets/MuPb";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import MeetingCard from "../../../../widgets/MeetingCard";

const MeetingsList = ({ status = "", type = "" }) => {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-meetings?status=${status}&type=${type}`
  );
  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }
  return (
    <Box maxWidth={700}>
      {data?.length === 0 && (
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          sx={{ mt: 2 }}
        >
          No meetings available
        </Typography>
      )}
      <Stack mt={2} spacing={2}>
        {data?.map((item, i) => (
          <MeetingCard key={i} onClick={() => {}} meetingItem={item} />
        ))}
      </Stack>
    </Box>
  );
};

export default MeetingsList;
