import React from "react";
import { Typography, Box, Paper } from "@mui/material";
import { SentimentDissatisfied } from "@mui/icons-material"; // Import the desired icon

function NoDataFound() {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 4,
          textAlign: "center",
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <SentimentDissatisfied sx={{ fontSize: 48, color: "warning.main" }} />
        <Typography variant="h6" gutterBottom>
          No Data Found
        </Typography>
        <Typography variant="body1">
          We're sorry, but there is no data available at the moment.
        </Typography>
      </Paper>
    </Box>
  );
}

export default NoDataFound;
