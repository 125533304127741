// useAllVisitors.js

import { useQuery } from "react-query";
import axios from "axios";
import {
  ADMIN_API,
  API_ENDPOINT,
  PRODUCT_CATEGORIES,
  USER,
} from "../../../../constants/constants";
import {
  extractDate,
  extractTime,
  getAdminHeader,
  getHeader,
} from "../../../../utility/utility";
import * as XLSX from "xlsx";

const fetchAllVisitors = async () => {
  try {
    const res = await axios.get(
      `${API_ENDPOINT}${USER}/a/get-all-visitors`,
      getAdminHeader()
    );
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

export function useAllVisitors() {
  const handleVisitorsLoaded = (data) => {
    const filteredData = data.data.map((item) => {
      // Remove _id from each object and transform country and state names
      const {
        _id,
        user_id,
        barcode_id,
        eventsList,
        title,
        firstName,
        lastName,
        phone,
        email,
        companyName,
        name,
        user_type,
        address,
        selectedCountry,
        selectedState,
        selectedCity,
        pin,
        website,
        telephone,
        designation,
        department,
        selectedEventSource,
        selectedIndustrySector,
        selectedCategories,
        profile_image,
        qrLink,
        createdOn,
      } = item;

      return {
        "Object Id": _id,
        "profile Image": profile_image,
        "User ID": user_id,
        "Barcode ID": barcode_id,
        "User Type": user_type,
        "Events List": Array.isArray(eventsList) ? eventsList?.join(", ") : [],
        Title: title,
        "First Name": firstName,
        "Last Name": lastName,
        name: name,
        "Phone Number": phone,
        Email: email,
        "Company Name": companyName,
        Address: address,
        Country: selectedCountry?.name,
        State: selectedState?.name,
        City: selectedCity,
        "Postal Code": pin,
        Website: website,
        Telephone: telephone,
        Designation: designation,
        Department: department,
        "Event Source": selectedEventSource,
        "Industry Sector": selectedIndustrySector,
        "Product Categories": `FACTEQ 2024: ${selectedCategories[
          "FACTEQ 2024"
        ]?.join(",")} and PMTX 2024: ${selectedCategories["PMTX 2024"]?.join(
          ","
        )}`,
        "QR Link": qrLink,
        Date: extractDate(createdOn),
        Time: extractTime(createdOn),
      };
    });

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Visitors");
    XLSX.writeFile(wb, "visitors.xlsx");
  };
  const {
    data: allVisitors,
    isLoading: loadingVisitors,
    isError: visitorsFetchError,
    error: errorDetails,
    refetch: refetchAllVisitors,
  } = useQuery("allVisitors", fetchAllVisitors, {
    enabled: false, // query will not automatically run
    onSuccess: handleVisitorsLoaded,
  });

  return {
    allVisitors,
    loadingVisitors,
    visitorsFetchError,
    errorDetails,
    refetchAllVisitors,
  };
}
