import React from "react";
import Button from "@mui/material/Button";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NextPrev({ page, totalItems }) {
  const navigate = useNavigate();

  return (
    <Box sx={{ mt: 2, display: "flex", justifyContent: "center", gap: "8px" }}>
      {page > 0 && (
        <Button
          size="small"
          variant="outlined"
          startIcon={<NavigateBeforeIcon />}
          onClick={() => {
            navigate(`/exhibitor-services/${page - 1}`);
          }}
        >
          Prev
        </Button>
      )}

      {totalItems > page + 1 && (
        <Button
          size="small"
          variant="outlined"
          endIcon={<NavigateNextIcon />}
          onClick={() => {
            navigate(`/exhibitor-services/${page + 1}`);
          }}
        >
          Next
        </Button>
      )}
    </Box>
  );
}

export default NextPrev;
