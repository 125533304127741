import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ConfigItem from "../../../widgets/ConfigItem";
import { LoadingButton } from "@mui/lab";
import "./AddProduct.css";
import RdLoadingButton from "../../../widgets/RdLoadingButton";
import { Close } from "@mui/icons-material";
import { API_ENDPOINT, MODULES, USER } from "../../../constants/constants";
import { getMultipartHeader } from "../../../utility/utility";
import axios from "axios";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ReactQuill from "react-quill";
import styled from "styled-components";
import PDFInput from "./PdfInput";
import RdMyModal from "../../../widgets/myModal/RdMyModal";
import ProductImageCrop from "./ProductImageCrop";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InfoIcon from "@mui/icons-material/Info";
import useTeamMembers from "./hooks/useTeamMembers";
import { toastError } from "../../../utils2024/utils";
import ProductCategoryWidget from "../common/ProductCategoryWidget";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
export const StyledReactQuill = styled(ReactQuill)`
  .ql-container {
    height: 120px;
  }
`;
export const StyledReactQuill192 = styled(ReactQuill)`
  .ql-container {
    height: 192px;
  }
`;

const AddProduct = ({
  exhibitor,
  product,
  onAdded,
  showAddProduct,
  mode = "add",
}) => {
  const { data } = useCommonGetInit(`user/get-team-members`);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputImg = useRef();
  const [productActive, setProductActive] = useState(false);
  const [productImage, setProductImage] = useState([]);
  const [name, setName] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const [selectedCategories, setSelectedCategories] = useState();
  const [description, setDescription] = useState("");
  const [brochure, setBrochure] = useState("");
  const [uploading, setUploading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [selectedValue, setSelectedValue] = useState("embed");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [video, setVideo] = useState("");
  const [brochureFile, setBrochureFile] = useState(null);
  const [showImageCrop, setShowImageCrop] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setBrochureFile(selectedFile);
      // Do something with the file, e.g., upload or read its content
    }
  };

  const onSelect = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (uploadedFile !== null) {
      uploadVideo();
    }
  }, [uploadedFile]);
  const onDrop = (acceptedFiles) => {
    // Handle the uploaded file
    setUploadedFile(acceptedFiles[0]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "video/*",
    onDrop,
  });

  useEffect(() => {
    if (product === null) {
      setName("");
      setDescription("");
      setSelectedCategories([]);
      setProductImage([]);
      setDescription("");
      setUploadedFile(null);
      setBrochureFile(null);
      setSelectedValue("embed");
      setVideo("");
    } else {
      setProductActive(product.isActive);
      setName(product.name);
      setDescription(product.description);
      setSelectedCategories(product.selectedCategories);
      setProductImage(product.images);
      setTeamMember(product.assignTo);
      setVideo(product.productVideo.content);
    }
  }, [showAddProduct]);
  const resetPic = () => {
    setProductImage([]);
  };
  const onClickInputFile = () => {
    setShowImageCrop(true);
  };
  const onSave = async () => {
    if (productImage.length === 0) {
      toast.info("Product image is required");
      return;
    }
    if (selectedCategories.length === 0) {
      toast.info("Product select atleast 1 Category");
      return;
    }
    if (!name.trim()) {
      toast.info("Name is required");
      return;
    }
    const productO = {
      isActive: productActive,
      name: name,
      images: productImage,
      selectedCategories: selectedCategories,
      description: description,
      assignTo: teamMember,
      productVideo: { selection: selectedValue, content: video },
      mode,
      id: product?._id,
    };
    if (brochureFile) {
      productO.brochure = brochureFile;
    }
    const formData = new FormData();
    for (let key in productO) {
      if (
        Array.isArray(productO[key]) ||
        (typeof productO[key] === "object" && key !== "brochure")
      ) {
        formData.append(key, JSON.stringify(productO[key]));
      } else {
        formData.append(key, productO[key]);
      }
    }
    setAdding(true);

    const url = `${API_ENDPOINT}${USER}/update-product`;
    try {
      const { data } = await axios.post(url, formData, getMultipartHeader());
      setAdding(false);
      toast.success("Added Successfully");
      onAdded();
      setTimeout(() => {
        document.location.reload();
      }, 10);
    } catch (error) {
      setAdding(false);
      toastError(error);
    }
  };
  const uploadVideo = () => {
    if (!uploadedFile) {
      toast.error("Please select a video file to upload.");
      return;
    }
    const formData = new FormData();
    formData.append("image", uploadedFile);
    axios
      .post(
        `${API_ENDPOINT}user/upload-image-only`,
        formData,
        getMultipartHeader()
      )
      .then((res) => {
        const data = res.data;
        if (data.success) {
          setVideo(data.message);
        } else {
          toast.error("Failed to upload video: " + data.message);
        }
      })
      .catch((error) => {
        toastError(error);
      });
  };

  const onChangeVideo = () => {
    setUploadedFile(null);
    setVideo("");
  };
  function handleDescChange(html) {
    setDescription(html);
  }
  const onUpdated = (imgUrl) => {
    setProductImage([...productImage, imgUrl]);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box p={1}>
      <RdMyModal
        showModal={showImageCrop}
        setShowModal={setShowImageCrop}
        modalC={
          <ProductImageCrop
            onUpdated={onUpdated}
            show={showImageCrop}
            setShow={setShowImageCrop}
          />
        }
      />
      <Typography variant="h5">Add Product</Typography>
      <div style={{ display: "flex" }}>
        <div>
          <div style={{ fontSize: "14px", color: "#777" }}>Main Picture</div>
          <div
            style={{
              width: "300px",
              height: "300px",
              backgroundColor: "#f5f5f6",
              borderRadius: "8px",
              overflow: "hidden",
              border: "1px dotted green",
              display: "flex",
              position: "relative",
              justifyContent: "center",
            }}
          >
            {productImage.length === 0 ? (
              <div style={{ alignSelf: "center" }}>
                <LoadingButton
                  loading={uploading}
                  onClick={onClickInputFile}
                  variant="text"
                >
                  Choose Image
                </LoadingButton>
                <div
                  style={{
                    fontSize: "12px",
                    color: "#777",
                    display: "table",
                    margin: "auto",
                  }}
                >
                  up to 2MB
                </div>
              </div>
            ) : (
              <>
                <IconButton
                  onClick={resetPic}
                  size="small"
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    backgroundColor: "#ddd",
                    "&:hover": {
                      backgroundColor: "#bbb", // hover color
                    },
                  }}
                >
                  <Close fontSize="14px" />
                </IconButton>
                <img
                  src={productImage[0]}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </>
            )}
          </div>
          <div style={{ fontSize: "12px", color: "#444" }}>
            <div>Supported formats: .png, .jpg, .jpeg</div>
            <div>Aspect Ratio: 1:1</div>
          </div>
        </div>
        <div style={{ marginLeft: "16px", marginRight: "12px", flex: 1 }}>
          <ConfigItem
            label="Product is Active?"
            checked={productActive}
            setChecked={setProductActive}
          />
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ margin: "16px 0" }}
            fullWidth
            size="small"
            label="Enter Product Name"
          />
          <Typography variant="body1" fontWeight={500}>
            Product Categories
          </Typography>

          {showAddProduct && (
            <ProductCategoryWidget
              currentSelectedList={selectedCategories || []}
              onChange={(newList) => {
                setSelectedCategories(newList);
              }}
              maxSelectionAllowed={10}
            />
          )}
          <Box sx={{ mt: 2, mb: 2 }}>
            <PDFInput
              label="Select Product Brochure"
              onChange={handleFileChange}
            />
            {brochureFile && (
              <Typography variant="caption">
                Selected file: {brochureFile.name}
              </Typography>
            )}
            {mode === "edit" && product.brochure && (
              <Button
                size="small"
                sx={{ mt: 2, mb: 2, textTransform: "none" }}
                variant="outlined"
                startIcon={<PictureAsPdfIcon color="error" />}
                component="a" // Use <a> tag as the root component of the Button
                href={product.brochure} // Link to the PDF
                target="_blank" // Open in a new tab
                rel="noopener noreferrer" // For security reasons when using target="_blank"
              >
                Current Brochure
              </Button>
            )}
          </Box>
          <div
            style={{
              fontWeight: 600,
              fontSize: "16px",
              color: "#444",
              marginTop: "16px",
              marginBottom: "12px",
            }}
          >
            Product Description
          </div>
          <StyledReactQuill
            modules={MODULES}
            value={description}
            onChange={handleDescChange}
          />
          <FormControl fullWidth sx={{ marginTop: "16px" }} size="small">
            <InputLabel id="demo-simple-select-label">
              Assign to Team Member
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="role"
              label="Assign to Team Member"
              value={teamMember}
              onChange={(e) => setTeamMember(e.target.value)}
            >
              {data?.data?.map((m, i) => (
                <MenuItem
                  value={`${m.firstName}  ${m.lastName}`}
                >{`${m.firstName}  ${m.lastName}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            style={{ display: "flex", fontWeight: 600, margin: "16px 0 4px 0" }}
          >
            <div style={{ alignSelf: "center" }}> Product Video</div>
            <Box sx={{ alignSelf: "center" }}>
              <IconButton
                aria-describedby={id}
                color="info"
                onClick={handleClick}
              >
                <InfoIcon />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Box p={2}>
                  <Typography variant="h6" gutterBottom>
                    How to Embed a YouTube Video
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    1. Go to the YouTube video you want to embed.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    2. Click on the 'Share' button below the video.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    3. In the Share window, click on 'Embed'.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    4. Copy the HTML code provided in the Embed section.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    Got it!
                  </Button>
                </Box>
              </Popover>
            </Box>
          </div>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedValue}
              onChange={onSelect}
            >
              <FormControlLabel
                value="embed"
                control={<Radio />}
                label="Embed Code"
              />
              <FormControlLabel
                value="upload"
                disabled
                control={<Radio />}
                label="Upload Video"
              />
            </RadioGroup>
          </FormControl>

          {selectedValue === "embed" ? (
            <div>
              <TextField
                multiline
                sx={{ mt: 1 }}
                rows={4}
                variant="outlined"
                fullWidth
                value={video}
                onChange={(e) => setVideo(e.target.value)}
                label="Embed Code"
                placeholder="Paste your embed code here..."
              />
            </div>
          ) : (
            <>
              {video === "" ? (
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "16px",
                    border: "1px solid #ddd",
                    color: "#888",
                    borderRadius: "8px",
                  }}
                >
                  <div {...getRootProps()} style={{ cursor: "pointer" }}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the video file here ...</p>
                    ) : (
                      <p>
                        Drag and drop a video file here, or click to select a
                        file
                      </p>
                    )}
                    {uploadedFile && <div>{uploadedFile.name}</div>}
                  </div>
                </div>
              ) : (
                <div>
                  <video width="320" height="240" controls>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <br />
                  <Button
                    onClick={onChangeVideo}
                    startIcon={<ChangeCircleIcon />}
                    size="small"
                    variant="outlined"
                  >
                    Change
                  </Button>
                </div>
              )}
            </>
          )}

          <RdLoadingButton loading={adding} onClick={onSave} label="Save" />
        </div>
      </div>
    </Box>
  );
};

export default AddProduct;
