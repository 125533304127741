import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";

const IndustrySectorSelector = ({
  label = "Select Industry Sector",
  placeholder = "Select",
  selected,
  onChange,
}) => {
  const [internalSelected, setInternalSelected] = useState(selected || []);
  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    } else {
      setInternalSelected(newValue);
    }
  };

  return (
    <Autocomplete
      multiple
      options={industrySectorsList}
      value={selected !== undefined ? selected : internalSelected}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default IndustrySectorSelector;
const industrySectorsList = [
  "Academia and Technical Institutions",
  "Aerospace / Civil Aviation",
  "Agriculture",
  "Aluminium Extrusion",
  "Auto Components",
  "Automation",
  "Automobile",
  "Biotechnology",
  "Capital Goods",
  "Chemicals",
  "Consumer Durables",
  "Defence and Railway Units",
  "Die & Mould",
  "Earthmoving Equipment",
  "Electrical and Electronics",
  "Food Processing and Diary Equipment",
  "General Engineering",
  "Government Department",
  "Healthcare",
  "Industrial Machinery",
  "Industry 4.0",
  "Infrastructure & Earth Moving Equipment",
  "IT Industry",
  "Job Shops",
  "Logistics",
  "Machine Tools & Other Metal working Industries",
  "Maintenance & Services",
  "Material Handling",
  "Measuring Instruments & Gauges",
  "Medical Engineering",
  "Office Equipment and automation",
  "Oil & Gas equipment",
  "Pharma Equipment",
  "Plant and Machinery",
  "Plastics Processing",
  "Polymers(Rubber Plastic PVC Resin Poly)",
  "Public Sector",
  "Renewable Energy",
  "Research and development organisations",
  "Space and Nuclear",
  "Telecom Equipment",
  "Textile Machinery & Products",
  "Tractors and Farm Equipment",
  "White and Brown Goods Manufacturers",
];
