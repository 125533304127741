import { useQuery } from "react-query";
import axios from "axios";
import { getAdminHeader, getHeader } from "../../../../utility/utility";
import {
  ADMIN_API,
  API_ENDPOINT,
  EVENT_API,
  USER,
} from "../../../../constants/constants";

const fetchItems = async (page = 1, searchQ) => {
  try {
    const res = await axios.get(
      `${API_ENDPOINT}${USER}/a/get-visitors?page=${page}&sq=${encodeURIComponent(
        searchQ
      )}`,
      getAdminHeader()
    );
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useVisitors = (page, searchQ) => {
  const queryInfo = useQuery(
    ["get-visitors", page, searchQ],
    () => fetchItems(page, searchQ),
    {
      staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
      retry: 3, // If the query fails, it will retry up to 3 times
    }
  );
  return {
    ...queryInfo,
    refetchVisitors: queryInfo.refetch, // Extract refetch function from queryInfo
  };
};

export default useVisitors;
