import { Alert, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import RegHeader from "../../components/headers/regHeader";
import Footer from "../footer/footer";
import { LoadingButton } from "@mui/lab";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { useParams } from "react-router-dom";
import {
  isOnsiteActivated,
  saveOnsite,
  toastError,
} from "../../utils2024/utils";
import axios from "axios";
import { API_ENDPOINT, USER } from "../../constants/constants";
import MuPb from "../../widgets/MuPb";
import useOnsiteInit from "../events/hooks/useOnsiteInit";
import useActivateInit from "../events/hooks/useActivateInit";

const OnsiteActivator = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useActivateInit(
    `${API_ENDPOINT}${USER}/validate-onsite-link`,
    id
  );
  useEffect(() => {
    if (data) {
      saveOnsite(data?.token);
    }
  }, [data]);
  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{`Error: ${error?.message}`}</Alert>;
  }
  return (
    <Box height={"100vh"} display={"flex"} flexDirection={"column"}>
      <RegHeader />
      <Box
        flex={1}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Alert severity="success">
          <Typography variant="h5">
            Onsite module is successfully activated in this system.
          </Typography>
        </Alert>
      </Box>
      <Footer />
    </Box>
  );
};

export default OnsiteActivator;
