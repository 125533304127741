import React, { useState, useEffect, useContext } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Checkbox,
  Box,
  Typography,
  LinearProgress,
  Alert,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { RegFormContext } from "./context/RegFormContext";
import useProductCategories from "./hooks/useProductCategories";
const MAX_SELECTIONS = 10;

const ProductCategory = ({ form }) => {
  const { data: productCategories, error, loading } = useProductCategories();
  const { formData, updateFormData } = useContext(RegFormContext);
  const [categoriesA, setCategoriesA] = useState(formData[form.id] || []);

  useEffect(() => {
    updateFormData(form.id, categoriesA);
  }, [categoriesA]);

  const deriveCheckedSubcategories = (selectedCategories) => {
    return selectedCategories.reduce((acc, cat) => {
      const [code] = cat.split(" - ");
      acc[code] = true;
      return acc;
    }, {});
  };

  const [openMainCategories, setOpenMainCategories] = useState({});
  const [openCategories, setOpenCategories] = useState({});
  const [checkedSubcategories, setCheckedSubcategories] = useState(
    deriveCheckedSubcategories(categoriesA)
  );

  const toggleMainCategory = (code) => {
    setOpenMainCategories((prev) => ({ ...prev, [code]: !prev[code] }));
  };

  const toggleCategory = (code) => {
    setOpenCategories((prev) => ({ ...prev, [code]: !prev[code] }));
  };

  const toggleSubcategory = (code) => {
    setCheckedSubcategories((prev) => {
      const isCurrentlyChecked = prev[code] || false;
      const selectedCount = Object.values(prev).filter(Boolean).length;
      if (!isCurrentlyChecked && selectedCount >= MAX_SELECTIONS) {
        alert("You have already selected the maximum of 10 subcategories.");
        return prev;
      }
      return { ...prev, [code]: !isCurrentlyChecked };
    });
  };

  const groupedData = (productCategories || []).reduce((acc, curr) => {
    if (!acc[curr.MainCategory.code]) {
      acc[curr.MainCategory.code] = { ...curr.MainCategory, categories: {} };
    }
    if (!acc[curr.MainCategory.code].categories[curr.Category.code]) {
      acc[curr.MainCategory.code].categories[curr.Category.code] = {
        ...curr.Category,
        subcategories: [],
      };
    }
    acc[curr.MainCategory.code].categories[
      curr.Category.code
    ].subcategories.push(curr.Subcategory);
    return acc;
  }, {});

  const subcategoryCodeToData = {};

  Object.values(groupedData).forEach((mainCategory) => {
    Object.values(mainCategory.categories).forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        subcategoryCodeToData[subcategory.code] = {
          code: subcategory.code,
          name: subcategory.name,
        };
      });
    });
  });

  useEffect(() => {
    const selectedSubcategories = Object.keys(checkedSubcategories)
      .filter((code) => checkedSubcategories[code])
      .map((checkedCode) => {
        const data = subcategoryCodeToData[checkedCode];
        return `${data?.code} - ${data?.name}`;
      });
    if (selectedSubcategories.length !== categoriesA.length) {
      setCategoriesA(selectedSubcategories);
    }
  }, [checkedSubcategories]);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Alert severity="warning">No data found!</Alert>;
  }
  return (
    <Box>
      <Typography mb={1}>
        {form.label} {form.required && <span style={{ color: "red" }}> *</span>}
      </Typography>
      <List
        style={{
          border: "1px solid #E0E0E0",
          borderRadius: 5,
          overflow: "hidden",
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {Object.values(groupedData).map((mainCategory) => (
          <React.Fragment key={mainCategory.code}>
            <ListItemButton
              onClick={() => toggleMainCategory(mainCategory.code)}
              style={{
                backgroundColor: "#F7F7F7",
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <ListItemText primary={<>{mainCategory.name}</>} />
              {openMainCategories[mainCategory.code] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>

            <Collapse
              in={openMainCategories[mainCategory.code]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {Object.values(mainCategory.categories).map((category) => (
                  <React.Fragment key={category.code}>
                    <ListItemButton
                      onClick={() => toggleCategory(category.code)}
                      style={{
                        paddingLeft: 30,
                        backgroundColor: "#E9E9E9",
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            {category.code} - {category.name}
                          </>
                        }
                      />
                      {openCategories[category.code] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemButton>

                    <Collapse
                      in={openCategories[category.code]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {category.subcategories.map((subcategory) => (
                          <ListItemButton
                            key={subcategory.code}
                            role={undefined}
                            dense
                            onClick={() => toggleSubcategory(subcategory.code)}
                            style={{
                              paddingLeft: 60,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "#F0F0F0")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.backgroundColor = "")
                            }
                          >
                            <Checkbox
                              size="small"
                              edge="start"
                              checked={
                                checkedSubcategories[subcategory.code] || false
                              }
                              tabIndex={-1}
                              disableRipple
                              color="primary"
                            />
                            <ListItemText
                              primary={`${subcategory.code} - ${subcategory.name}`}
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default ProductCategory;
