import React from "react";
import "./regHeader.css";
const RegHeader = () => {
  return (
    <a href="https://www.imtex.in/" target="_blank">
      <div
        className="newhcontainer"
        style={{
          backgroundImage: `url(${"https://imtex2024-imtma.expoplanner.in/static/media/header_bg.780c40d922984ce51348.png"})`,
        }}
      >
        <img
          className="newhheader-image"
          src="https://imtex2024-imtma.expoplanner.in/static/media/imtex_header1.05702e989ce83f1207ee.png"
          alt="App Header"
        />
      </div>
    </a>
  );
};

export default RegHeader;
