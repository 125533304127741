import React from "react";
import "./BadgePrinter.css";
import QRCode from "react-qr-code";
import { WidthFull } from "@mui/icons-material";

function BadgePrinter() {
  const printBadges = () => {
    window.print();
  };

  const visitors = [
    { name: "John Doe", companyName: "IMTMA Machine Tool Expo" },
  ];

  return (
    <div>
      <div className="badge-container">
        {visitors.map((visitor, index) => (
          <div className="badge" key={index}>
            <div style={{ fontWeight: "bold", fontSize: "24px" }}>
              {visitor.name}
            </div>
            <div style={{ fontWeight: "bold" }}>{visitor.companyName}</div>
            <div
              style={{
                fontWeight: "bold",
                marginTop: 2,
                fontSize: 20,
                marginBottom: "8px",
              }}
            >
              India
            </div>
            <div>
              <img
                src="https://www.techopedia.com/wp-content/uploads/2023/03/aee977ce-f946-4451-8b9e-bba278ba5f13.png"
                height={100}
              />
            </div>
          </div>
        ))}
      </div>
      <button onClick={printBadges}>Print Badges</button>
    </div>
  );
}

export default BadgePrinter;
