import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <img
      style={{ display: "block", width: "100%" }}
      src={
        "https://s3.ap-south-1.amazonaws.com/expoplanner.in/PMTX+2024+-+Expo+Planner+Footer.png"
      }
      alt="Expo Footer"
    />
  );
};

export default Footer;
