import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Avatar,
  Box,
  Stack,
} from "@mui/material";
import EditBtn from "./actionBtn/editBtn";
import DeleteBtn from "./actionBtn/deleteBtn";
import { DEFAULT_EX_ICON } from "../constants/constants";
import defImg from "../assets/default-product-image.png";
import InteractiveAction2 from "./InteractiveAction2";

const ProductItem = ({
  product,
  onEditProduct,
  onDeleteProduct,
  viewMode = false,
}) => {
  return (
    <Card sx={{ maxWidth: 300, boxShadow: 3 }}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          paddingTop: "100%", // Maintains 1:1 aspect ratio
          overflow: "hidden",
        }}
      >
        <CardMedia
          component="img"
          image={product.images ? product.images[0] : defImg}
          alt={product.name}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <CardContent>
        <Typography gutterBottom variant="body1" component="div" noWrap>
          {product.name}
        </Typography>
        {viewMode && product?.exhibitor && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={
                product.exhibitor.profile_image
                  ? product.exhibitor.profile_image
                  : DEFAULT_EX_ICON
              }
              alt={product?.exhibitor?.companyShortName?.value}
              sx={{ width: 32, height: 32 }}
            />
            <Typography variant="body2" color="text.secondary" noWrap>
              {product?.exhibitor?.companyShortName?.value}
            </Typography>
          </Stack>
        )}
      </CardContent>
      {viewMode ? (
        <Box mb={2}>
          <InteractiveAction2
            item={{
              username: product.exhibitor.username,
              productId: product._id,
            }}
            type={"product"}
          />
        </Box>
      ) : (
        <CardActions>
          <ProductItemAction
            onEdit={() => {
              if (onEditProduct) onEditProduct(product);
            }}
            onDelete={() => {
              if (onDeleteProduct) onDeleteProduct(product);
            }}
          />
        </CardActions>
      )}
    </Card>
  );
};

const ProductItemAction = ({ onEdit, onDelete }) => {
  return (
    <Box display="flex" width="100%" alignItems="center">
      <EditBtn onEdit={onEdit} />
      <Box flex={1}></Box>
      <DeleteBtn onDelete={onDelete} />
    </Box>
  );
};

export default ProductItem;
export { ProductItemAction };
