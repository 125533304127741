import React, { useState } from "react";
import { Alert, Box, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import ItemsCarousel from "react-items-carousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import VisitorCard from "../../../widgets/VisitorCard";
export const SimilarExhibitors = () => {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-matched-visitors`
  );
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  if (isLoading && !isError) {
    return <Typography variant="body1">Loading...</Typography>;
  }
  if (isError) {
    return <Alert severity="error">No data found</Alert>;
  }
  return (
    <Box>
      <div style={{ padding: `0 ${chevronWidth}px`, maxWidth: "800px" }}>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={3}
          gutter={20}
          leftChevron={
            <IconButton size="small" sx={{ bgcolor: "#d5d5d5" }}>
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
          }
          rightChevron={
            <IconButton size="small" sx={{ bgcolor: "#d5d5d5" }}>
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          }
          outsideChevron
          chevronWidth={chevronWidth}
        >
          {data?.data?.map((exh, i) => (
            <VisitorCard key={i} item={exh} />
          ))}
        </ItemsCarousel>
      </div>
      {data?.data?.length === 0 && (
        <Box sx={{ color: "#ccc", fontWeight: 600, textAlign: "center", m: 2 }}>
          No Data yet
        </Box>
      )}
    </Box>
  );
};
