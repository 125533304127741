import React, { useState } from "react";
import { Container, Typography, TextField, CssBaseline } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { API_ENDPOINT, USER } from "../../constants/constants";
import useCommonGetInit from "../../components/events/hooks/useCommonGetInit";
import MuPb from "../../widgets/MuPb";
import { getDbHeader, toastError } from "../../utils2024/utils";

const SetPasswordActivationPage = () => {
  const { code } = useParams();
  const { isLoading, isError, error, data } = useCommonGetInit(
    `${USER}/check-link?tk=${code}`
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetting, setResetting] = useState("");
  const [isPset, setPset] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password.trim() || !confirmPassword.trim()) {
      toast.error("Password fields cannot be empty.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords don't match.");
      return;
    }
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}user/set-password`,
        { password, token: code },
        getDbHeader()
      );
      setPset(true);
      toast.success(data.message);
    } catch (error) {
      toastError(error);
    }
  };

  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return (
      <Container component="main" maxWidth="xs">
        <Typography variant="h4" align="center" color="error">
          Invalid Link!
        </Typography>
      </Container>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container component="main" maxWidth="md" sx={{ margin: "16px auto" }}>
        <img
          src={
            "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Preview+Banner.webp"
          }
          alt=""
          style={{
            display: "block",
            margin: "10px auto",
            width: "100%",
            maxWidth: "500px",
          }}
        />
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Set New Password
        </Typography>
      </Container>
      {!isPset ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <form onSubmit={handleSubmit}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                fullWidth
                label="New Password"
                type="password"
                name="password"
                autoComplete="new-password"
                autoFocus
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <LoadingButton
                size="small"
                loading={resetting}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Set Password
              </LoadingButton>

              {/* <p>This link expires in 5 minutes</p> */}
            </form>
          </div>
        </Container>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h4 color="green">Password set Successfully</h4>
          <p>
            You can now login to the app <a href="/login">here</a>
          </p>
        </div>
      )}
    </div>
  );
};

export default SetPasswordActivationPage;
