import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import CloseButton from "../actionBtn/CloseButton";
import "./myModal.css";

const RdMyModal = ({ showModal, setShowModal, modalC, mWidth }) => {
  const modalContainerRef = useRef(null);

  useEffect(() => {
    // Create the modal container only once when the component mounts
    modalContainerRef.current = document.createElement("div");
    document.body.appendChild(modalContainerRef.current);

    // Cleanup function to remove the modal container when the component unmounts
    return () => {
      if (modalContainerRef.current) {
        document.body.removeChild(modalContainerRef.current);
      }
    };
  }, []);

  const close = () => {
    setShowModal(false);
  };

  const modalContent = (
    <div className={`modal ${showModal ? "modal-show" : "modal-hide"}`}>
      <div
        style={{ maxWidth: mWidth || "1000px" }}
        onClick={(e) => e.stopPropagation()}
        className="modal-content"
      >
        <div
          style={{ position: "absolute", zIndex: 2, right: "4px", top: "4px" }}
        >
          <CloseButton onClose={close} />
        </div>
        <div className="modal-c-body">{modalC}</div>
      </div>
    </div>
  );

  // Only render the modal when showModal is true
  return showModal
    ? ReactDOM.createPortal(modalContent, modalContainerRef.current)
    : null;
};

export default RdMyModal;
