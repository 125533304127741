import React, { useRef, useState } from "react";
import RegHeader from "../regHeader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import { useRegistrationSettings } from "../context/RegistrationSettingsContext";
import axios from "axios";
import { ADMIN_API } from "../../../../../../constants/constants";
import { getAdminMultipartHeader } from "../../../../../../utility/utility";
const BannerAdEditor = ({ showBM }) => {
  const { settings, updateProperty, isLoading, error } =
    useRegistrationSettings();
  const [anchorEl, setAnchorEl] = useState(null);

  const fileInput = useRef(null);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const handleButtonClick = () => {
    fileInput.current.click(); // Trigger the hidden file input click event
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${ADMIN_API}/upload-file-only`,
        formData,
        getAdminMultipartHeader()
      );
      //updateProperty(propName, response.data.link);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "first":
        //setPropName("header.leftPic");
        //handleButtonClick();
        showBM("", "", "bannerAds.ad1");
        break;
      case "second":
        //setPropName("header.midPic");
        //handleButtonClick();
        showBM("", "", "bannerAds.ad2");
        break;
      case "third":
        //setPropName("header.rightPic");
        //handleButtonClick();
        showBM("", "", "bannerAds.ad2");
        break;
      default:
        console.warn(`Unhandled action: ${action}`);
    }
    setAnchorEl(null); // Close the menu after an item is clicked
  };

  return (
    <div style={{ position: "relative", marginBottom: "16px" }}>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Button
        variant="outlined"
        size="small"
        fullWidth
        startIcon={<EditIcon />}
        onClick={handleMenuClick}
      >
        Right Banner Ads
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("first")}>
          Add Top Banners
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("second")}>
          Add Mid Banners
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("third")}>
          Add Bottom Banners
        </MenuItem>
      </Menu>
    </div>
  );
};

export default BannerAdEditor;
