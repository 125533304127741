import React from "react";
import { Box, Typography, Chip } from "@mui/material";

export const MySelectedProductCats = ({ items }) => {
  // Group items by mainCat
  const groupedItems = items.reduce((acc, item) => {
    if (!acc[item.mainCat]) {
      acc[item.mainCat] = [];
    }
    acc[item.mainCat].push(item);
    return acc;
  }, {});

  return (
    <Box>
      {Object.entries(groupedItems).map(([mainCat, itemsInGroup]) => (
        <Box
          key={mainCat}
          mb={2}
          p={2}
          border="1px solid #e0e0e0"
          borderRadius="8px"
          bgcolor="#fafafa"
        >
          <Typography variant="body1" color="text.primary" gutterBottom>
            {mainCat}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {itemsInGroup.map((item, index) => (
              <Chip
                key={index}
                label={item.subSubCat.split(" - ")[1]}
                variant="outlined"
                size="small"
                sx={{ margin: "4px 4px 0 0" }} // Adjusts margin to avoid left spacing in the next line
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
