import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Footer from "../footer/footer";
import useSpotVisitors from "./hooks/useSpotVisitors";
import BackBtn from "../dashboard/admin/widgets/BackBtn";
import { ImtexBadgeDimenstion, SPOT_REG_CATEGORIES } from "./SpotRegistration";
import CustomCircularP from "../../widgets/CustomCircularP";
import { BadgeToPrint } from "../dashboard/visitor/BadgeToPrint";
import { useReactToPrint } from "react-to-print";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import QRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";
import { useNavigate, useParams } from "react-router-dom";
import useOnsiteInit from "../events/hooks/useOnsiteInit";
import { API_ENDPOINT, USER } from "../../constants/constants";
import { GROUP_ONE, isOnsiteActivated } from "../../utils2024/utils";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
export class PrintableRowComponent extends React.Component {
  render() {
    const { row } = this.props;
    const vCard = row.barcode_id;
    // [
    //   "BEGIN:VCARD",
    //   "VERSION:3.0",
    //   `NOTE: Barcode ID: ${row._id}`,
    //   `N:${row.lastName};${row.firstName}${row.name} ;;;`,
    //   `FN:${row.title} ${row.firstName} ${row.lastName}${row.name}`,
    //   `ORG:${row.companyName}${row.instituteName}`,
    //   `TITLE:${row.designation}${row.education}`,
    //   `EMAIL;TYPE=PREF,INTERNET:${row.email}`,
    //   "END:VCARD",
    // ].join("\n");
    return (
      <div
        style={{
          textAlign: "center",
          width: "12cm",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{ marginTop: "12cm", fontWeight: "bold", fontSize: "32px" }}
          >
            {row.firstName?.toUpperCase()} {row.lastName?.toUpperCase()}
            {row.name?.toUpperCase()}
          </div>
          <div style={{ fontSize: "27px", marginTop: "16px" }}>
            {row.companyName?.toUpperCase()}
            {row.instituteName?.toUpperCase()}
          </div>
        </div>
        <div style={{ marginTop: "24px" }}>
          <QRCode value={vCard} size={180} />
        </div>
      </div>
    );
  }
}
const SpotUsers = () => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQ, setSearchQ] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [debouncedSearchQ, setDebouncedSearchQ] = useState(searchQ);
  const { data, isLoading, isError, error, refetchVisitors } = useOnsiteInit(
    `${API_ENDPOINT}${USER}/get-onsite-users?page=${page}&sq=${debouncedSearchQ}&usertype=${selectedFilter}`,
    GROUP_ONE
  );
  const handleSearch = (e) => {
    const vl = e.target.value;
    setSearchQ(vl);
  };
  const debounceSearch = useCallback(
    _.debounce((query) => {
      setDebouncedSearchQ(query);
    }, 300), // 300ms delay
    []
  );

  useEffect(() => {
    debounceSearch(searchQ);
  }, [searchQ, debounceSearch]);

  const onKeyPress = (button) => {
    console.log("Button pressed", button);
    // Handle specific button presses if needed
  };
  const handlePrint = (user) => {
    setTimeout(() => {
      printRow(user);
    }, 0);
  };
  const printRow = (row) => {
    const printableContent = ReactDOMServer.renderToString(
      <ImtexBadgeDimenstion row={row} />
    );
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${printableContent}
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus(); // For some browsers to trigger the print dialog
    printWindow.print();
    printWindow.close();
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Box flexGrow={1} maxWidth={1280} width={"100%"} ml={"auto"} mr={"auto"}>
        <Box mt={2} display={"flex"}>
          {/* <Box alignSelf={"center"}>
            <BackBtn />
          </Box> */}
          <Typography
            variant="h5"
            ml={2}
            sx={{ fontWeight: "bold", alignSelf: "center" }}
          >
            All Spot Users
          </Typography>
          <Box flex={1} ml={2} mr={2}>
            <Paper
              sx={{
                padding: "2px 4px 2px 10px",
                backgroundColor: "#f4f4f4",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={"Search Users by Name/Company Name/Email/mobile"}
                value={searchQ}
                onChange={handleSearch}
                inputProps={{
                  "aria-label":
                    "Search Users by Name/Company Name/Email/mobile",
                }}
              />
              <IconButton type="submit" sx={{ p: "8px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Keyboard
              onChange={(input) => {
                setSearchQ(input);
              }}
              onKeyPress={onKeyPress}
            />
          </Box>
          <FormControl
            sx={{ maxWidth: "200px", alignSelf: "center" }}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-label">Filter by</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="selectedCountry"
              value={selectedFilter}
              label="Filter by"
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              <MenuItem value={""}>ALL</MenuItem>
              {SPOT_REG_CATEGORIES.map((target, i) => (
                <MenuItem key={i} value={target.content}>
                  {target.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <CustomCircularP show={isLoading} />
          <TableContainer sx={{ mt: 2 }} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Designation</TableCell>
                  <TableCell align="right">Company</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Phone</TableCell>
                  <TableCell align="right">Print</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.map((row) => (
                  <TableRow
                    key={row.user_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.title} {row.firstName} {row.lastName}
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      {row.designation}
                      {row.education}
                    </TableCell>
                    <TableCell align="right">
                      {row.companyName}
                      {row.instituteName}
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.phone}</TableCell>
                    <TableCell align="right">
                      <LoadingButton
                        startIcon={<LocalPrintshopIcon />}
                        size="small"
                        variant="outlined"
                        onClick={() => handlePrint(row)}
                      >
                        Print
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

export default SpotUsers;
