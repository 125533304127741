import React from "react";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";

const StallViewer = ({ id }) => {
  const { isLoading, isError, error, data } = useCommonGetInit(
    `user/stall-view?id=${id}`
  );
  return <></>;
};

export default StallViewer;
