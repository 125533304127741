import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import HeaderLabel from "../../../../../widgets/HeaderLabel";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  MODULES,
  USER,
  VISITOR_API,
} from "../../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../../utility/utility";
import axios from "axios";
import { StyledReactQuill, StyledReactQuill192 } from "../../AddProduct";
import { toastError } from "../../../../../utils2024/utils";

const EditAbout = ({ user, show, setShow }) => {
  const [editorHtml, setEditorHtml] = useState("");
  const [loading, setLoading] = useState(false);
  function handleChange(html) {
    setEditorHtml(html);
  }
  useEffect(() => {
    if (user.about) {
      setEditorHtml(user.about.value);
    }
  }, [show]);
  const handleUpdate = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}${USER}/update-user`,
        {
          about: {
            label: "About",
            value: editorHtml,
          },
        },
        getHeader()
      );
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, [400]);
    } catch (error) {
      console.log(error);
      toastError(error);
    } finally {
      setLoading(false);
    }
  };
  function isContentEmpty() {
    const strippedContent = editorHtml.replace(/<[^>]*>/g, "").trim();
    return !strippedContent;
  }
  const save = () => {
    if (isContentEmpty()) {
      toast.info("Please type something first");
    } else {
      handleUpdate();
    }
  };

  return (
    <div style={{ padding: "16px" }}>
      <HeaderLabel label={"Edit About"} showEdit={false} />
      <StyledReactQuill192
        modules={MODULES}
        value={editorHtml}
        onChange={handleChange}
      />
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton loading={loading} onClick={save} variant="contained">
          Save
        </LoadingButton>
      </Box>
    </div>
  );
};

export default EditAbout;
