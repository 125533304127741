import { useQuery } from "react-query";
import axios from "axios";
import { VISITOR_API } from "../../../../constants/constants";
const fetchData = (ccode) => {
  return axios
    .get(`${VISITOR_API}get-states-of-country/${ccode}`)
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useStates = (ccode) => {
  return useQuery(["get-states", ccode], () => fetchData(ccode), {
    enabled: !!ccode, // Only run the query if the ccode is provided
    staleTime: 10 * 60 * 1000,
    retry: 3,
  });
};

export default useStates;
