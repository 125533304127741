import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AdminAuth,
  AuthPath,
  ExhibitorAuth,
  VisitorAuth,
} from "../../utils2024/utils";
import Login from "./Login";
import VisitorDashboard from "../dashboard/visitor/VisitorDashboard";
import Admin from "../dashboard/admin/Admin";
import ExhibitorDashboard from "../dashboard/exhibitor/ExhibitorDashboard";

const EventHome = () => {
  return (
    <Routes>
      <Route
        path="visitor/*"
        element={
          <VisitorAuth>
            <VisitorDashboard />
          </VisitorAuth>
        }
      />
      <Route
        path="exhibitor/*"
        element={
          <ExhibitorAuth>
            <ExhibitorDashboard />
          </ExhibitorAuth>
        }
      />
      <Route
        path="admin/*"
        element={
          <AdminAuth>
            <Admin />
          </AdminAuth>
        }
      />
      <Route exact path="login" element={<Login />} />
    </Routes>
  );
};

export default EventHome;
