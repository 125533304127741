import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
  Typography,
  Alert,
} from "@mui/material";
import OtpInput from "./OtpInput";
import {
  getGroupByNumber,
  getOnsite,
  getOtpVerify,
  isOnsiteActivated,
  saveOnsite,
  saveOtpVerify,
  toastError,
} from "../../utils2024/utils";
import axios from "axios";
import { API_ENDPOINT, USER } from "../../constants/constants";
import { getHeader } from "../../utility/utility";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ActivateCard = ({ groupNum }) => {
  const [open, setOpen] = useState(false); // State to control the dialog open/close
  const [verifying, setVerifying] = useState(false);
  const mygroup = getGroupByNumber(groupNum);
  const handleOpen = async () => {
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}${USER}/request-activation-code?id=${mygroup}`
      );
      toast.success(data.message);
      saveOtpVerify(data.token);
      setOpen(true);
    } catch (error) {
      toastError(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onOtpEntered = async (otp) => {
    if (open && !verifying) {
      try {
        setVerifying(true);
        const { data } = await axios.get(
          `${API_ENDPOINT}${USER}/verify-activation-code?otp=${otp}&code=${getOtpVerify()}&groupName=${mygroup}`
        );
        toast.success(data.message);
        saveOnsite(mygroup, data.token);
        setOpen(false);
        setTimeout(() => {
          document.location.reload();
        }, 90);
      } catch (error) {
        toastError(error);
      } finally {
        setVerifying(false);
      }
    }
  };

  const getGroupLink = (gn) => {
    switch (gn) {
      case 1:
        return "/group-one-onsite/spot-users";
      case 2:
        return "/security-scan";
      case 3:
        return "";
      case 4:
        return "";
      case 5:
        return "";
      case 6:
        return "";
      default:
        return null; // Return null if the number doesn't match any group
    }
  };

  return (
    <Box
      border={"1px solid grey"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      padding={2}
      bgcolor={"#fff5e6"}
      borderRadius={1}
      height={100}
    >
      {!isOnsiteActivated(mygroup) ? (
        <LoadingButton variant="contained" fullWidth onClick={handleOpen}>
          Activate Group {groupNum}
        </LoadingButton>
      ) : (
        <Box>
          <Alert severity="success">
            Group {groupNum} is Activated Successfully
          </Alert>

          {groupNum <= 2 ? (
            <Link
              to={getGroupLink(groupNum)}
              style={{ textDecoration: "none" }}
            >
              <Button
                sx={{ display: "block", ml: "auto", mr: "auto" }}
                size="small"
                variant="outlined"
              >
                Open Group
              </Button>
            </Link>
          ) : (
            <></>
          )}
        </Box>
      )}

      {/* Dialog for OTP Entry */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enter the OTP to activate Group {groupNum}</DialogTitle>
        <DialogContent>
          <OtpInput onComplete={onOtpEntered} />
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            OTP is sent to Admin. Please contact Admin for the OTP.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            loading={verifying}
            onClick={handleClose}
            color="primary"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ActivateCard;
