import React, { useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Stack,
  IconButton,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const PurchaseItems = ({
  onDelete,
  itemsList = [],
  isEdit = false,
  cartItems = [],
  onCartUpdate,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleAddToCart = (item) => {
    let updatedCartItems = [];
    const existingItem = cartItems.find(
      (cartItem) => cartItem.productId === item.productId
    );
    if (existingItem) {
      updatedCartItems = cartItems.map((cartItem) =>
        cartItem.productId === item.productId
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      );
    } else {
      updatedCartItems = [...cartItems, { ...item, quantity: 1 }];
    }
    if (onCartUpdate) onCartUpdate(updatedCartItems);
  };

  const handleDecreaseFromCart = (item) => {
    let updatedCartItems = cartItems
      .map((cartItem) =>
        cartItem.productId === item.productId
          ? { ...cartItem, quantity: cartItem.quantity - 1 }
          : cartItem
      )
      .filter((cartItem) => cartItem.quantity > 0);

    if (onCartUpdate) onCartUpdate(updatedCartItems);
  };

  const handleDeleteFromCart = (item) => {
    const updatedCartItems = cartItems.filter(
      (cartItem) => cartItem.productId !== item.productId
    );
    if (onCartUpdate) onCartUpdate(updatedCartItems);
  };

  const handleDeleteItem = (item) => {
    if (onDelete) onDelete(item);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const calculateSubtotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  const subtotal = calculateSubtotal();
  const gst = subtotal * 0.18;
  const grandTotal = subtotal + gst;

  return (
    <Box p={2}>
      {itemsList.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            backgroundColor: "#f5f5f5",
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" color="text.secondary">
            No items available. Please add items here!
          </Typography>
        </Box>
      )}
      <Grid container spacing={2}>
        {itemsList?.map((item, index) => {
          const cartItem = cartItems.find(
            (cartItem) => cartItem.productId === item.productId
          );
          const quantity = cartItem ? cartItem.quantity : 0;

          return (
            <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
              <Card sx={{ boxShadow: 3 }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={item.image}
                  alt={item.name}
                  sx={{ objectFit: "cover", cursor: "pointer" }}
                  onClick={() => handleImageClick(item.image)}
                />
                <CardContent>
                  <Typography gutterBottom variant="body1" component="div">
                    {item.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    ₹{item.price}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Stack
                    width={"100%"}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {quantity === 0 ? (
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<ShoppingCartIcon fontSize="small" />}
                        onClick={() => handleAddToCart(item)}
                        fullWidth
                      >
                        Add
                      </Button>
                    ) : (
                      <Stack direction="row" alignItems="center">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => handleDecreaseFromCart(item)}
                        >
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                        <TextField
                          value={quantity}
                          size="small"
                          inputProps={{
                            readOnly: true,
                            style: { width: 30, textAlign: "center" },
                          }}
                          variant="outlined"
                        />
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => handleAddToCart(item)}
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    )}
                    {isEdit && (
                      <IconButton
                        onClick={() => handleDeleteItem(item)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Stack>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Cart Items */}
      {cartItems.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Cart Items
          </Typography>
          <Paper elevation={3}>
            <List>
              {cartItems.map((item) => (
                <ListItem
                  key={item.productId}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteFromCart(item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={`${item.name} x ${item.quantity}`}
                    secondary={`Price: ₹${(item.price * item.quantity).toFixed(
                      2
                    )}`}
                  />
                </ListItem>
              ))}
            </List>
            <Divider />
            <Box p={2}>
              <Typography variant="body1">
                Subtotal: ₹{subtotal.toFixed(2)}
              </Typography>
              <Typography variant="body1">
                GST (18%): ₹{gst.toFixed(2)}
              </Typography>
              <Typography variant="h6">
                Grand Total: ₹{grandTotal.toFixed(2)}
              </Typography>
            </Box>
          </Paper>
        </Box>
      )}

      {/* Dialog for image preview */}
      <Dialog
        open={Boolean(selectedImage)}
        onClose={handleCloseImage}
        maxWidth="md"
      >
        <DialogContent sx={{ padding: 0 }}>
          {selectedImage && (
            <Box
              component="img"
              src={selectedImage}
              alt="Selected Item"
              sx={{
                width: "100%",
                maxHeight: "80vh",
                objectFit: "contain",
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PurchaseItems;
