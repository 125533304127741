import React from "react";
import { Box } from "@mui/material";
import Header from "./header";
import { Route, Routes, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import AddEvent from "./AddEvent";
import RegistrationSettings from "./vreigstration/RegistrationSettings";
import VisitorsDashboard from "./visitors/Dashboard";
import ProfileById from "../../visitor/ProfileById";
import AllocateHall from "./allocatehall/allocateHall";
import SideMenuBar from "../../../../widgets2024/SideMenuBar";
import HeaderBar from "../../../../widgets2024/HeaderBar";
import UserProfileById from "./UserProfileById";

/*
give me some sample data(array of objects) of expo events with logo, name, eventId, eventTags, country, city, startDate, endDate, type, atachedTo, action


give me a beautifully styled responsive table 

*/
const navItems = {
  barLogo: "https://www.imtex.in/images/imtex-2024.png",
  barName: "Organiser Dashboard",
  profileName: "Ratul Doley",
  leftItems: [
    {
      label: "Add Event",
      path: "/add-event",
    },
    {
      label: "Exhibitors",
      path: "/exhibitors",
    },
  ],
  rightItems: [
    {
      label: "Events",
      icon: "DateRangeIcon",
      path: "/events",
    },
    {
      label: "Database",
      icon: "StorageIcon",
      path: "/database",
    },
    {
      label: "Apps",
      icon: "PhoneIphoneIcon",
      path: "/apps",
    },
    {
      label: "Accounts",
      icon: "GroupIcon",
      path: "/accounts",
    },
    {
      label: "Profile",
      icon: "AccountCircle",
      path: "/profile",
    },
    {
      label: "Logout",
      icon: "LogoutIcon",
      path: "/logout",
    },
  ],
};

function Dashboard() {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Header />
      <Box sx={{ flexGrow: 1 }}>
        <Routes>
          <Route path="" element={<VisitorsDashboard />} />
          <Route path="add" element={<AddEvent />} />
          <Route path="visitor" element={<UserProfileById />} />
          <Route path="manage/:id" element={<RegistrationSettings />} />
        </Routes>
      </Box>
      <Footer />
    </Box>
  );
}

export default Dashboard;
