import "./excelu.css";
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import readXlsxFile from "read-excel-file";
import { toast } from "react-toastify";
import ExcelWidgets from "./ExcelWidgets";
import {
  API_ENDPOINT,
  EXHIBITOR,
  USER,
} from "../../../../../constants/constants";
import { LoadingButton } from "@mui/lab";
import { getAdminHeader, getHeader } from "../../../../../utility/utility";
import { excelDateToJSDate, getDbName } from "../../../../../utils2024/utils";
const ExcelUpload = ({ excelUploaded, show, setShow }) => {
  const [fn, setFn] = useState("");
  const [rs, setRs] = useState([]);
  const [uploading, setUploading] = useState(false);
  const onFileSelect = () => {
    document.getElementById("excel_input").click();
  };
  useEffect(() => {
    if (!show) {
      setFn("");
      setRs([]);
    }
  }, [show]);

  const handleChose = (event) => {
    setFn(event.target.files[0].name);
    readXlsxFile(event.target.files[0]).then((rows) => {
      if (rows[0].length !== 40) {
        setFn("");
        document.getElementById("excel_input").value = "";
        toast.error("Invalid File format! Please read instructions");
      } else if (rows.length < 2) {
        setFn("");
        document.getElementById("excel_input").value = "";
        toast.error("No data found in the excel sheet");
      } else {
        setRs(rows);
      }
    });
  };
  const uploadQuestionGroup = async () => {
    const myData = [];
    rs.slice(1).map((d, i) => {
      myData.push({
        user_id: d[0],
        username: d[1],
        password: d[2],
        companyName: { label: "Company Name", value: d[3] },
        contactSalutation: {
          label: "Contact Salutation",
          value: d[4],
        },
        contactFirstName: {
          label: "Contact FirstName",
          value: d[5],
        },
        contactLastName: {
          label: "Contact LastName",
          value: d[6],
        },
        contactDesignation: {
          label: "Contact Designation",
          value: d[7],
        },
        mobile: { label: "Direct Line/Mobile", value: d[8] },
        companyEmail: { label: "Company Email", value: d[9] },
        contactEmail: { label: "Contact Email", value: d[10] },
        address1: { label: "Address 1", value: d[11] },
        address2: { label: "Address 2", value: d[12] },
        address3: { label: "Address 3", value: d[13] },
        country: { label: "Country", value: d[14] },
        state: { label: "State", value: d[15] },
        city: { label: "City", value: d[16] },
        pin: { label: "Pin/Zip", value: `${d[17]}` },
        phone: { label: "Phone", value: d[18] },
        fax: { label: "Fax", value: d[19] },
        website: { label: "Website", value: d[20] },
        companyShortName: {
          label: "Company Short Name",
          value: d[21],
        },
        fascia: { label: "Fascia", value: d[22] },
        weAre: { label: "We Are", value: d[23] },
        industrySector: {
          label: "Industry/Sector",
          value: d[24],
        },
        exhibitorCategory: {
          label: "Exhibitor Category",
          value: d[25],
        },
        productCategories: {
          label: "Product categories",
          value: d[26]?.split(",").map((item) => item.trim()),
        },
        hallNumber: { label: "Hall Number", value: d[27] },
        standNumber: { label: "Stand Number", value: d[28] },
        spaceType: { label: "Space Type", value: d[29] },
        exhibitArrival: {
          label: "Exhibit Arrival",
          value: d[30] ? new Date(d[30]) : null,
        },
        exhibitArrivalTimeFrom: {
          label: "Exhibit Arrival Time From",
          value: d[31],
        },
        exhibitArrivalTimeTo: {
          label: "Exhibit Arrival Time To",
          value: d[32],
        },
        materialArrival: {
          label: "Material Arrival",
          value: d[33] ? new Date(d[33]) : null,
        },
        materialArrivalTimeFrom: {
          label: "Material Arrival Time From",
          value: d[34],
        },
        materialArrivalTimeTo: {
          label: "Material Arrival Time To",
          value: d[35],
        },
        dismantling: {
          label: "Dismantling",
          value: d[36] ? new Date(d[36]) : null,
        },
        dismantlingTimeFrom: {
          label: "Dismantling Time From",
          value: d[37],
        },
        dismantlingTimeTo: {
          label: "Dismantling Time To",
          value: d[38],
        },
        standSize: { label: "Stand Size", value: d[39] },
        role: EXHIBITOR,
        db: getDbName(),
      });
    });
    try {
      setUploading(true);
      const res = await axios.post(
        `${API_ENDPOINT}${USER}/a/add-exhibitors`,
        myData,
        getAdminHeader()
      );
      toast.success(res.data.message);
      setShow(false);
    } catch (error) {
      toast.error(`Some error occured ${error.message}`);
    } finally {
      setUploading(false);
    }
  };
  return (
    <div className="ql-excel">
      {rs.length > 0 ? (
        <div>
          <div className="ql-excel-analytics">
            <Typography
              sx={{
                color: "green",
                fontSize: "20px",
                fontWeight: "bold",
                mb: 1,
              }}
            >
              Please Review before Upload
            </Typography>
            <Typography>
              Total Exhibitors:{" "}
              <span style={{ marginLeft: 16 }}>{rs.length - 1}</span>
            </Typography>
            <LoadingButton
              sx={{ mt: 1, width: "300px" }}
              size="small"
              loading={uploading}
              onClick={uploadQuestionGroup}
              variant="contained"
            >
              Upload
            </LoadingButton>
          </div>
          <div className="selected_qns">
            <table>
              <tr>
                {rs[0].map((td, i) => (
                  <th key={`thd${i}`}>{td}</th>
                ))}
              </tr>
              {rs.slice(1).map((tr, i) => (
                <tr key={`trd${i}`}>
                  {tr.map((td, i) => (
                    <td key={`tdd${i}`}>{td?.toLocaleString()}</td>
                  ))}
                </tr>
              ))}
            </table>
          </div>
          <div className="ql-excel-btn-s">
            <Button
              size="small"
              onClick={uploadQuestionGroup}
              variant="contained"
            >
              Upload
            </Button>
          </div>
        </div>
      ) : (
        <div className="ql-excel-i">
          <div className="ql-excel-i-c">
            <h3>Guidelines for the Excel Template</h3>
            <ul>
              <li>
                <b>imp*</b> Please make sure to <b>NOT</b> prefix USERVIS to
                exhibitor ID. It is reserved for visitor id.
              </li>
              <li>
                Please ensure that the excel you Upload matches the template
                provided herewith. For quick reference{" "}
                <a href="/exhibitor_template.xlsx">Click here</a> to download
                the template.
              </li>
              <li>
                Please ensure that the usernames and passwords you use are
                unique. They are mandatory.
              </li>
              <li>
                Use{" "}
                <i>
                  <code>dd/mm/yyyy</code>
                </i>{" "}
                format for date and{" "}
                <i>
                  <code>hh:mm:ss</code>
                </i>{" "}
                for time.
              </li>
              <li>
                Please make sure username and password, each do not exceed 16
                characters. User only numbers and alphabets. Later Exhibitor can
                reset the password and use alphaneumeric characters.
              </li>
            </ul>
          </div>
          <ExcelWidgets
            onFileSelect={onFileSelect}
            handleChose={handleChose}
            fn={fn}
          />
        </div>
      )}
    </div>
  );
};
export default ExcelUpload;
