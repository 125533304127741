import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  ListItem,
  ListItemText,
  Alert,
  Container,
  Box,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import { useParams } from "react-router-dom";
import MuPb from "../../../widgets/MuPb";
import BackBtn from "../admin/widgets/BackBtn";
import Header from "../admin/home/header";
import useAdminCommonGet from "../../events/hooks/useAdminCommonGet";
import { USER } from "../../../constants/constants";

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 1280px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledDivider = styled(Divider)`
  margin: 15px 0;
`;

const ExhibitorDetail = () => {
  const params = useParams();
  const { id } = params;
  const { data, isLoading, isError, error } = useAdminCommonGet(
    `${USER}/get-user-by-id-ad?id=${id}&`
  );

  if (isLoading) return <MuPb />;
  if (isError) return <Alert severity="error">Error: {error.message}</Alert>;

  return (
    <Box>
      <Header />
      <Container>
        <Box mt={3} mb={3}>
          <BackBtn />
        </Box>
        <Grid justifyContent="center">
          <Grid item xs={12} md={8}>
            <Detail details={data} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const Detail = ({ details }) => {
  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {details.companyName.value}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {details.companyShortName.value}
        </Typography>
        <StyledDivider />
        <Typography variant="subtitle1" gutterBottom>
          {`${details.contactSalutation.value} ${details.contactFirstName.value} ${details.contactLastName.value}`}
        </Typography>
        <Typography gutterBottom>{details.contactDesignation.value}</Typography>
        <Typography color="textSecondary" gutterBottom>
          {details.contactEmail.value}
        </Typography>
        <StyledDivider />
        <Typography variant="body2" gutterBottom>
          Address:
        </Typography>
        <Typography variant="body2">{details.address1.value}</Typography>
        <Typography variant="body2">{details.address2.value || ""}</Typography>
        <Typography variant="body2">
          {`${details.address3.value || ""}, ${
            details.city.value.name || ""
          } - ${details.pin.value}`}
        </Typography>
        <Typography variant="body2">
          {`${details.state.value.name}, ${details.country.value.name}`}
        </Typography>
        <StyledDivider />
        <Grid container spacing={3}>
          {Object.keys(details).map((key) => {
            if (details[key].label && details[key].value) {
              return (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  <ListItem>
                    <ListItemText
                      primary={details[key].label}
                      secondary={
                        Array.isArray(details[key].value)
                          ? details[key].value.join(", ")
                          : key === "country" ||
                            key === "state" ||
                            key === "city"
                          ? details[key]?.value?.name
                          : details[key].value.toString()
                      }
                    />
                  </ListItem>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default ExhibitorDetail;
