import { Box, Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import BackBtn from "../admin/widgets/BackBtn";
import { getUser, useQuery } from "../../../utility/utility";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { EXHIBITOR, VISITOR } from "../../../constants/constants";
import MuPb from "../../../widgets/MuPb";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ExhibitorCard from "../../../widgets/ExhibitorCard";
import VisitorCard from "../../../widgets/VisitorCard";
import ExhibitorFavorites from "./ExhibitorFavorites";
import ProductFavorites from "./ProductFavorites";
import VisitorFavorites from "./VisitorFavorites";

const MyFavorites = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const myRole = getUser()?.role;

  return (
    <Box mt={1} ml={2}>
      <BackBtn />

      <Tabs value={value} onChange={handleChange}>
        <Tab icon={<BusinessIcon />} label="EXHIBITORS" />
        {myRole === "exhibitor" && (
          <Tab icon={<PeopleIcon />} label="VISITORS" />
        )}
        <Tab icon={<ShoppingCartIcon />} label="PRODUCTS" />
      </Tabs>

      <Box mt={2} ml={1}>
        {value === 0 && <ExhibitorFavorites />}
        {value === 1 && myRole === "visitor" && <ProductFavorites />}
        {value === 1 && myRole === "exhibitor" && <VisitorFavorites />}
        {value === 2 && myRole === "exhibitor" && <ProductFavorites />}
      </Box>
    </Box>
  );
};

export default MyFavorites;
