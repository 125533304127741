import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  Alert,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  Grid,
  TableFooter,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Supplier2 from "./reuse/Supplier2";
import { LoadingButton } from "@mui/lab";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import axios from "axios";
import { EXHIBITOR_API } from "../../../../constants/constants";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { reformatDate } from "./ExitPass";
import { MAX_DATE, MIN_DATE } from "./Security";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import MuPb from "../../../../widgets/MuPb";
const Horticulture = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();

  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [items, setItems] = useState(descriptionItems);

  const [totalH, setTotalH] = useState(0);

  useEffect(() => {
    const newTotal = items.reduce((acc, item) => acc + item.SubTotal, 0);
    setTotalH(newTotal);
  }, [items]);

  const handleQtyChange = (index, newQty) => {
    updateSubtotal(index, newQty);
  };

  const updateSubtotal = (index, newQty = null) => {
    const updatedItems = [...items];
    // Only update if newQty is a number
    if (newQty !== null && !isNaN(newQty)) {
      updatedItems[index].Qty = newQty;
    }

    // Check if both dates are valid and present
    const startDate = updatedItems[index].dateFrom
      ? dayjs(updatedItems[index].dateFrom)
      : null;
    const endDate = updatedItems[index].dateTo
      ? dayjs(updatedItems[index].dateTo)
      : null;
    const areDatesValid = startDate?.isValid() && endDate?.isValid();

    // Calculate the number of days only if both dates are valid
    const days = areDatesValid
      ? Math.max(endDate.diff(startDate, "days"), 0) + 1
      : 0;

    // Ensure the Qty and RatePerDay are numbers before calculating SubTotal
    const qty = !isNaN(updatedItems[index].Qty) ? updatedItems[index].Qty : 0;
    const ratePerDay = !isNaN(updatedItems[index].RatePerDay)
      ? updatedItems[index].RatePerDay
      : 0;

    // Calculate subtotal, and default to 0 if either qty or ratePerDay is not a number
    // Also ensure SubTotal itself is not NaN; if computation results in NaN, default to 0
    const calculatedSubTotal = qty * ratePerDay * days;
    updatedItems[index].SubTotal = !isNaN(calculatedSubTotal)
      ? calculatedSubTotal
      : 0;

    setItems(updatedItems);
  };
  const handleDateChange = (index, type, newValue) => {
    const updatedItems = [...items];

    // Only update the date if newValue is a valid dayjs object
    if (newValue && dayjs(newValue).isValid()) {
      updatedItems[index][type] = newValue.format("YYYY-MM-DD");
      setItems(updatedItems);
      updateSubtotal(index);
    }
  };

  const [itemsA, setItemsA] = useState(floralArrangementItems);

  const [totalF, setTotalF] = useState(0);

  useEffect(() => {
    const newTotal = itemsA.reduce((acc, item) => acc + item.SubTotal, 0);
    setTotalF(newTotal);
  }, [itemsA]);

  const handleQtyChangeF = (index, newQty) => {
    updateSubtotalF(index, newQty);
  };

  const updateSubtotalF = (index, newQty = null) => {
    const updatedItems = [...itemsA];
    // Only update if newQty is a number
    if (newQty !== null && !isNaN(newQty)) {
      updatedItems[index].Qty = newQty;
    }

    // Check if both dates are valid and present
    const startDate = updatedItems[index].dateFrom
      ? dayjs(updatedItems[index].dateFrom)
      : null;
    const endDate = updatedItems[index].dateTo
      ? dayjs(updatedItems[index].dateTo)
      : null;
    const areDatesValid = startDate?.isValid() && endDate?.isValid();

    // Calculate the number of days only if both dates are valid
    const days = areDatesValid
      ? Math.max(endDate.diff(startDate, "days"), 0) + 1
      : 0;

    // Ensure the Qty and RatePerDay are numbers before calculating SubTotal
    const qty = !isNaN(updatedItems[index].Qty) ? updatedItems[index].Qty : 0;
    const ratePerDay = !isNaN(updatedItems[index].RatePerDay)
      ? updatedItems[index].RatePerDay
      : 0;

    // Calculate subtotal, and default to 0 if either qty or ratePerDay is not a number
    // Also ensure SubTotal itself is not NaN; if computation results in NaN, default to 0
    const calculatedSubTotal = qty * ratePerDay * days;
    updatedItems[index].SubTotal = !isNaN(calculatedSubTotal)
      ? calculatedSubTotal
      : 0;

    setItemsA(updatedItems);
  };
  const handleDateChangeF = (index, type, newValue) => {
    const updatedItems = [...itemsA];

    // Only update the date if newValue is a valid dayjs object
    if (newValue && dayjs(newValue).isValid()) {
      updatedItems[index][type] = newValue.format("YYYY-MM-DD");
      setItemsA(updatedItems);
      updateSubtotalF(index);
    }
  };

  const generateItemsTemplate = (items, total) => {
    // Filter items with a Total greater than 0
    const filteredItems = items.filter((item) => item.SubTotal > 0);

    // Generate HTML for each filtered item
    const itemsHtml = filteredItems
      .map(
        (item) => `
          <tr>
            <td>${item.Description}</td>
            <td>${item.RatePerDay.toLocaleString()}</td>
            <td>${item.Qty}</td>
            <td>${reformatDate(item.dateFrom) || ""}</td>
            <td>${reformatDate(item.dateTo) || ""}</td>
            <td>${item.SubTotal.toLocaleString()}</td>
          </tr>
        `
      )
      .join("");

    // Calculate GST and grand total based on the total
    const gst = total * 0.18;
    const grandTotal = total + gst;

    // Footer HTML with total, GST, and grand total
    const footerHtml = `
      <tfoot>
        <tr>
          <td colspan="5">Total</td>
          <td>${total.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="5">GST (18%)</td>
          <td>${gst.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="5">Grand Total</td>
          <td>${grandTotal.toLocaleString()}</td>
        </tr>
      </tfoot>
    `;

    // Combine items HTML and footer to form the complete table
    return `
      <table>
        <thead>
          <tr>
            <th>Particulars</th>
            <th>Rate</th>
            <th>Quantity</th>
            <th>Date From</th>
            <th>Date To</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          ${itemsHtml}
        </tbody>
        ${footerHtml}
      </table>
    `;
  };
  if (isLoading) {
    return <MuPb />;
  }
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div> ${spaceTemplate(
      data
    )} <br /><h2>Horticulture</h2>${generateItemsTemplate(
      items,
      totalH
    )} <br /><h2>Floriculture</h2>${generateItemsTemplate(
      itemsA,
      totalF
    )} <br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "5.9 Horticulture / Floriculture",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "5.9 Horticulture / Floriculture",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" mb={1}>
          5.9 Horticulture / Floriculture
        </Typography>
        <SpaceInfo data={data} />
        <Box mb={4}>
          {/* <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert> */}
        </Box>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Rate/Day</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Sub Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.Description}</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={item.Qty}
                      variant="outlined"
                      type="number"
                      onChange={(e) => handleQtyChange(index, e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={item.RatePerDay}
                      variant="outlined"
                      type="number"
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <DatePicker
                      className="exp_date_pciker"
                      slotProps={{
                        textField: {
                          helperText: "mm/dd/yyyy",
                        },
                      }}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      label="Date From"
                      size="small"
                      variant="outlined"
                      value={item.dateFrom ? dayjs(item.dateFrom) : null}
                      onChange={(newValue) =>
                        handleDateChange(index, "dateFrom", newValue)
                      }
                    />

                    <DatePicker
                      className="exp_date_pciker"
                      slotProps={{
                        textField: {
                          helperText: "mm/dd/yyyy",
                        },
                      }}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      label="Date To"
                      size="small"
                      variant="outlined"
                      value={item.dateTo ? dayjs(item.dateTo) : null}
                      onChange={(newValue) =>
                        handleDateChange(index, "dateTo", newValue)
                      }
                    />
                  </TableCell>
                  <TableCell>{item.SubTotal}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  Total Amount:
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat("en-IN").format(totalH)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  18% GST:
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat("en-IN").format(0.18 * totalH)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  Total:
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat("en-IN").format(1.18 * totalH)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </LocalizationProvider> */}
        <Divider sx={{ mt: 4 }} />
        <Typography mt={2} variant="h5" gutterBottom>
          Floriculture
        </Typography>
        <Box sx={{ mb: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Rate/Day</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Sub Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemsA.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.Description}</TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        value={item.Qty}
                        variant="outlined"
                        type="number"
                        onChange={(e) =>
                          handleQtyChangeF(index, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        value={item.RatePerDay}
                        variant="outlined"
                        type="number"
                        disabled
                      />
                    </TableCell>
                    <TableCell>
                      <DatePicker
                        className="exp_date_pciker"
                        slotProps={{
                          textField: {
                            helperText: "mm/dd/yyyy",
                          },
                        }}
                        minDate={MIN_DATE}
                        maxDate={MAX_DATE}
                        label="Date From"
                        size="small"
                        variant="outlined"
                        value={item.dateFrom ? dayjs(item.dateFrom) : null}
                        onChange={(newValue) =>
                          handleDateChangeF(index, "dateFrom", newValue)
                        }
                      />

                      <DatePicker
                        className="exp_date_pciker"
                        slotProps={{
                          textField: {
                            helperText: "mm/dd/yyyy",
                          },
                        }}
                        minDate={MIN_DATE}
                        maxDate={MAX_DATE}
                        label="Date To"
                        size="small"
                        variant="outlined"
                        value={item.dateTo ? dayjs(item.dateTo) : null}
                        onChange={(newValue) =>
                          handleDateChangeF(index, "dateTo", newValue)
                        }
                      />
                    </TableCell>
                    <TableCell>{item.SubTotal}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    Total Amount:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(totalF)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    18% GST:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(0.18 * totalF)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    Total:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(1.18 * totalF)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </LocalizationProvider>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="body1">GST:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">
              18% on floral decoration only, plants exempted from all taxes
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body2">Payment Terms:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">
              Cash to be paid at the time of taking material
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body1">Invoice:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">
              Will be raised as per the actual after work as been done
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body1">Quantity:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">As per your requirement</Typography>
          </Grid>
        </Grid>
        <Supplier2
          user={data}
          id={18}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};

const descriptionItems = [
  {
    Description: "4FT TO 5 FT TALL PLANTS (ARECA PALMS)",
    Qty: 0,
    RatePerDay: 250,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
  {
    Description: "3FT TALL PLANTS (ARECA PALMS SMALL, DRACAENAS)",
    Qty: 0,
    RatePerDay: 200,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
  {
    Description: "2FT TALL PLANTS (AGLONEMA, ANTHURIUM JENMAMII)",
    Qty: 0,
    RatePerDay: 150,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
  {
    Description:
      "1FT TO 2.5FT TALL PLANTS(SCHFLERA, SINGONIUM, LILLIUMS, PANDANASS, SANG OF INDIA, GRASS, ARALIA)",
    Qty: 0,
    RatePerDay: 100,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
  {
    Description:
      "MAX 1FT TALL SEASONAL FLOWER PLANTS (MARIGOLD, PETUNIA, ZENIA, DIANTHUS)",
    Qty: 0,
    RatePerDay: 100,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
];

const floralArrangementItems = [
  {
    Qty: 0,
    Description: "SINGLE ROSE WITH GREENS",
    RatePerDay: 25,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
  {
    Qty: 0,
    Description: "THREE ROSE POSY",
    RatePerDay: 60,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
  {
    Qty: 0,
    Description: "15 ROSES+GREENS+PAPER+RIBBON IN A POSY",
    RatePerDay: 500,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
  {
    Qty: 0,
    Description: "20 ASSORTED FLOWERS IN AN ARRANGEMENT",
    RatePerDay: 600,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
  {
    Qty: 0,
    Description: "BETWEEN 10 TO 15 FLOWERS IN AN ARRANGEMENT",
    RatePerDay: 500,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
  {
    Qty: 0,
    Description:
      "EXOTIC FLOWERS IN A BASKET (ORCHIDS/ANTHRIUMS/LILIAMS) RS. 600 ONWARDS",
    RatePerDay: 700,
    dateFrom: null,
    dateTo: null,
    SubTotal: 0,
  },
];

export default Horticulture;
