import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from "@mui/material/Button";
import CropIcon from "@mui/icons-material/Crop";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function MyImageCropper({ aspectRatio = 1, onCropped }) {
  const cropperRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();
    if (croppedCanvas) {
      const croppedDataUrl = croppedCanvas.toDataURL();
      setCroppedImage(croppedDataUrl);
    }
  };

  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();
    if (croppedCanvas) {
      croppedCanvas.toBlob((blob) => {
        if (onCropped && typeof onCropped === "function") {
          onCropped(blob);
        }
      });
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result.toString() || "");
        setCroppedImage(null); // Reset the cropped image
      };
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <Box>
      {!imageSrc && (
        <Box>
          <Typography variant="body1" gutterBottom>
            Please choose an image file (only image files are allowed). Aspect
            Ratio: {aspectRatio}
          </Typography>
          <input type="file" accept="image/*" onChange={handleImageChange} />
        </Box>
      )}
      {imageSrc && (
        <Box display="flex" gap={2}>
          <Box width="70%">
            <Cropper
              src={imageSrc}
              style={{ height: 400, width: "100%" }}
              aspectRatio={aspectRatio}
              guides={false}
              crop={onCrop}
              ref={cropperRef}
              viewMode={1}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<CropIcon />}
              onClick={handleCrop}
              sx={{ marginTop: 2 }}
            >
              Crop Image
            </Button>
          </Box>
          <Box width="30%">
            <Typography variant="h6" gutterBottom>
              Preview
            </Typography>
            {croppedImage && (
              <img
                src={croppedImage}
                alt="Cropped Preview"
                style={{ width: "100%" }}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default MyImageCropper;
