import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import "./RdSideMenu.css";
const RdSideMenu = ({ MenuIcon, sublinks, active, text, to, onLogout }) => {
  return (
    <>
      {to !== "" ? (
        <Link to={to} style={{ textDecoration: "none" }}>
          <div className={active ? "rd-menu-button active" : "rd-menu-button"}>
            <MenuIcon className="side-menu-icon" />
            <div style={{ flex: 1, alignSelf: "center" }}>{text}</div>
          </div>
        </Link>
      ) : (
        <div
          onClick={onLogout}
          className={active ? "rd-menu-button active" : "rd-menu-button"}
        >
          <MenuIcon className="side-menu-icon" />
          <div style={{ flex: 1, alignSelf: "center" }}>{text}</div>
        </div>
      )}
    </>
  );
};
export default RdSideMenu;
