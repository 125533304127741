import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import useStates from "./hooks/useStates";

function StatesCom({ state, handleStateChange, label = "Select State*" }) {
  const { data, isLoading, isError, error } = useStates(
    state.selectedCountry ? state.selectedCountry.isoCode : ""
  );

  useEffect(() => {
    if (data) {
      const stateObj = data.find(
        (dt) => dt.isoCode === state.selectedState?.isoCode
      );
      handleStateChange("selectedState", stateObj);
    }
  }, [data]);

  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="selectedState"
        value={state.selectedState}
        label={label}
        onChange={handleInputChange}
      >
        {data?.map((target) => (
          <MenuItem key={target.isoCode} value={target}>
            {target.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default StatesCom;
