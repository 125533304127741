import React from "react";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { Alert, Box } from "@mui/material";
import ProfileViewer from "./ProfileViewer";
import BackBtn from "../admin/widgets/BackBtn";
import { VISITOR } from "../../../constants/constants";
import VisitorDetail from "../visitor/VisitorDetail";
import ExhibitorDetail from "../exhibitor/profile/ExhibitorDetail";
import MuPb from "../../../widgets/MuPb";
import { getErrorResponse } from "../../../utils2024/utils";
import { useParams } from "react-router-dom";

const ProfileDetailCommon = () => {
  const { id } = useParams();
  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useCommonGetInit(`user/get-user-by-id?id=${id}`);
  if (isLoading) {
    return <MuPb />;
  }

  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }
  return (
    <Box>
      <ProfileViewer id={id} />
      <BackBtn />
      {response.role === VISITOR ? (
        <VisitorDetail data={response} />
      ) : (
        <ExhibitorDetail response={response} />
      )}
    </Box>
  );
};

export default ProfileDetailCommon;
