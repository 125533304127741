import { LoadingButton } from "@mui/lab";
import { Box, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  getAdmin,
  getAdminHeader,
  useQuery,
} from "../../../../../utility/utility";
import useCommonGetInit from "../../../../events/hooks/useCommonGetInit";
import { API_ENDPOINT, USER } from "../../../../../constants/constants";
import MuPb from "../../../../../widgets/MuPb";
import axios from "axios";
import { toastError } from "../../../../../utils2024/utils";
import { hi } from "date-fns/locale";
import CustomCircularP from "../../../../../widgets/CustomCircularP";

const EditContainer = ({ menu, SelectComponent }) => {
  const query = useQuery();
  const id = query.get("id");
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-oes-menu?id=${id}&`
  );
  const [hidden, setHidden] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data) {
      setHidden(data.hidden);
      setDisabled(data.disabled);
    }
  }, [data]);

  const handleHiddenChange = async () => {
    setHidden(!hidden);
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}${USER}/update-oes-menu-hidden`,
        { id, hidden: !hidden },
        getAdminHeader()
      );
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDisabledChange = async () => {
    setDisabled(!disabled);
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}${USER}/update-oes-menu-disabled`,
        { id, disabled: !disabled },
        getAdminHeader()
      );
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <MuPb />;
  }
  const onEdit = () => {};
  return (
    <Box>
      <CustomCircularP show={loading} />
      <Box mb={1} display={"flex"} justifyContent={"end"} mr={2}>
        <FormControlLabel
          onChange={handleHiddenChange}
          control={<Switch checked={hidden} />}
          label="Hidden"
        />
        <FormControlLabel
          onChange={handleDisabledChange}
          control={<Switch checked={disabled} />}
          label="Disabled"
        />
        <LoadingButton
          onClick={onEdit}
          size="small"
          variant="outlined"
          startIcon={<EditIcon />}
        >
          Edit
        </LoadingButton>
      </Box>
      <Box> {<SelectComponent />}</Box>
    </Box>
  );
};

export default EditContainer;
