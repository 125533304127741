import React, { useRef, useState, useEffect } from "react";

function RectangleCanvas() {
  const canvasRef = useRef(null);
  const [action, setAction] = useState(null);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [rect, setRect] = useState(null);
  const [lastMousePos, setLastMousePos] = useState({ x: 0, y: 0 });
  const [resizeEdge, setResizeEdge] = useState(null);

  const edgeThreshold = 10;

  useEffect(() => {
    const canvas = canvasRef.current;
    if (resizeEdge) {
      switch (resizeEdge) {
        case "left":
        case "right":
          canvas.style.cursor = "ew-resize";
          break;
        case "top":
        case "bottom":
          canvas.style.cursor = "ns-resize";
          break;
        case "topLeft":
        case "bottomRight":
          canvas.style.cursor = "nwse-resize";
          break;
        case "topRight":
        case "bottomLeft":
          canvas.style.cursor = "nesw-resize";
          break;
        default:
          canvas.style.cursor = "default";
      }
    } else if (action === "dragging") {
      canvas.style.cursor = "move";
    } else {
      canvas.style.cursor = "default";
    }
  }, [resizeEdge, action]);

  const draw = () => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    if (rect) {
      ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
    }
  };

  const isMouseWithin = (mouseX, mouseY, rect) => {
    return (
      mouseX >= rect.x - edgeThreshold &&
      mouseX <= rect.x + rect.width + edgeThreshold &&
      mouseY >= rect.y - edgeThreshold &&
      mouseY <= rect.y + rect.height + edgeThreshold
    );
  };

  const handleMouseDown = (e) => {
    const mouseX = e.nativeEvent.offsetX;
    const mouseY = e.nativeEvent.offsetY;

    if (rect && isMouseWithin(mouseX, mouseY, rect)) {
      setAction("dragging");
      setLastMousePos({ x: mouseX, y: mouseY });

      // Check for resizing
      const onLeftEdge = mouseX < rect.x + edgeThreshold;
      const onRightEdge = mouseX > rect.x + rect.width - edgeThreshold;
      const onTopEdge = mouseY < rect.y + edgeThreshold;
      const onBottomEdge = mouseY > rect.y + rect.height - edgeThreshold;

      if (onLeftEdge && onTopEdge) setResizeEdge("topLeft");
      else if (onRightEdge && onTopEdge) setResizeEdge("topRight");
      else if (onLeftEdge && onBottomEdge) setResizeEdge("bottomLeft");
      else if (onRightEdge && onBottomEdge) setResizeEdge("bottomRight");
      else if (onLeftEdge) setResizeEdge("left");
      else if (onRightEdge) setResizeEdge("right");
      else if (onTopEdge) setResizeEdge("top");
      else if (onBottomEdge) setResizeEdge("bottom");
      else setResizeEdge(null);

      if (resizeEdge) setAction("resizing");
    } else {
      setAction("drawing");
      setStartPos({ x: mouseX, y: mouseY });
      setRect(null);
      setResizeEdge(null);
    }
  };

  const handleMouseUp = () => {
    setAction(null);
    draw();
  };

  const handleMouseMove = (e) => {
    const mouseX = e.nativeEvent.offsetX;
    const mouseY = e.nativeEvent.offsetY;

    if (action === "drawing") {
      let x = startPos.x;
      let y = startPos.y;
      let width = mouseX - startPos.x;
      let height = mouseY - startPos.y;

      if (width < 0) {
        width = Math.abs(width);
        x = mouseX;
      }

      if (height < 0) {
        height = Math.abs(height);
        y = mouseY;
      }

      setRect({ x, y, width, height });
      draw();
    } else if (action === "dragging") {
      const dx = mouseX - lastMousePos.x;
      const dy = mouseY - lastMousePos.y;

      setRect((prevRect) => ({
        ...prevRect,
        x: prevRect.x + dx,
        y: prevRect.y + dy,
      }));
      setLastMousePos({ x: mouseX, y: mouseY });
      draw();
    } else if (action === "resizing" && resizeEdge) {
      // Implement resizing logic based on the edge selected
      switch (resizeEdge) {
        case "left":
          setRect((prevRect) => ({
            ...prevRect,
            x: mouseX,
            width: prevRect.width - (mouseX - prevRect.x),
          }));
          break;
        case "right":
          setRect((prevRect) => ({
            ...prevRect,
            width: mouseX - prevRect.x,
          }));
          break;
        case "top":
          setRect((prevRect) => ({
            ...prevRect,
            y: mouseY,
            height: prevRect.height - (mouseY - prevRect.y),
          }));
          break;
        case "bottom":
          setRect((prevRect) => ({
            ...prevRect,
            height: mouseY - prevRect.y,
          }));
          break;
        // Implement cases for topLeft, topRight, bottomLeft, bottomRight
        case "topLeft":
          setRect((prevRect) => ({
            ...prevRect,
            x: mouseX,
            y: mouseY,
            width: prevRect.width - (mouseX - prevRect.x),
            height: prevRect.height - (mouseY - prevRect.y),
          }));
          break;
        case "topRight":
          setRect((prevRect) => ({
            ...prevRect,
            y: mouseY,
            width: mouseX - prevRect.x,
            height: prevRect.height - (mouseY - prevRect.y),
          }));
          break;
        case "bottomLeft":
          setRect((prevRect) => ({
            ...prevRect,
            x: mouseX,
            width: prevRect.width - (mouseX - prevRect.x),
            height: mouseY - prevRect.y,
          }));
          break;
        case "bottomRight":
          setRect((prevRect) => ({
            ...prevRect,
            width: mouseX - prevRect.x,
            height: mouseY - prevRect.y,
          }));
          break;
      }
      draw();
    } else if (rect) {
      // Change cursor when near an edge but not resizing
      const onLeftEdge = mouseX < rect.x + edgeThreshold;
      const onRightEdge = mouseX > rect.x + rect.width - edgeThreshold;
      const onTopEdge = mouseY < rect.y + edgeThreshold;
      const onBottomEdge = mouseY > rect.y + rect.height - edgeThreshold;

      if (onLeftEdge && onTopEdge) setResizeEdge("topLeft");
      else if (onRightEdge && onTopEdge) setResizeEdge("topRight");
      else if (onLeftEdge && onBottomEdge) setResizeEdge("bottomLeft");
      else if (onRightEdge && onBottomEdge) setResizeEdge("bottomRight");
      else if (onLeftEdge) setResizeEdge("left");
      else if (onRightEdge) setResizeEdge("right");
      else if (onTopEdge) setResizeEdge("top");
      else if (onBottomEdge) setResizeEdge("bottom");
      else setResizeEdge(null);
    }
  };

  return (
    <div>
      <canvas
        ref={canvasRef}
        width={400}
        height={400}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        style={{ border: "1px solid black" }}
      />
    </div>
  );
}

export default RectangleCanvas;
