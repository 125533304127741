import { Box, Grid } from "@mui/material";
import React from "react";
import RegHeader from "../../components/headers/regHeader";
import Footer from "../footer/footer";
import ActivateCard from "./ActivateCard";

const ActivateOnsite1 = () => {
  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <RegHeader />
      <Box
        maxWidth="1280px"
        margin="auto"
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5, 6].map((group) => (
            <Grid item xs={12} sm={6} md={4} key={group}>
              <ActivateCard groupNum={group} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default ActivateOnsite1;
