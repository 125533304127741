import React from "react";
import PolygonCanvas from "./PolygonCanvas";
import gP from "../../assets/general_floor.png";
import PolygonCanvasNoZoom from "./PolygonCanvasNoZoom";

const DrawPolygon = () => {
  return (
    <div>
      <h1>Polygon Drawer</h1>
      <PolygonCanvas width={400} height={400} backgroundImage={gP} />
      <PolygonCanvasNoZoom width={400} height={400} backgroundImage={gP} />
    </div>
  );
};

export default DrawPolygon;
