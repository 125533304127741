import AdditionalServices from "../components/dashboard/exhibitor/oes/AdditionalServices";
import Advertisement from "../components/dashboard/exhibitor/oes/Advertisement";
import AudioEquipment from "../components/dashboard/exhibitor/oes/AudioEquipment";
import EcoDesign from "../components/dashboard/exhibitor/oes/EcoDesign";
import ElectricalWork from "../components/dashboard/exhibitor/oes/ElectricalWork";
import ExhibitorBadges from "../components/dashboard/exhibitor/oes/ExhibitorBadges";
import ExitPass from "../components/dashboard/exhibitor/oes/ExitPass";
import GuidelinesMezzanine from "../components/dashboard/exhibitor/oes/GuidelinesMezzanine";
import Horticulture from "../components/dashboard/exhibitor/oes/Horticulture";
import HotelAccomodation from "../components/dashboard/exhibitor/oes/HotelAccomodation";
import InvitationInaguaration from "../components/dashboard/exhibitor/oes/InvitationInaguaration";
import MandatoryDesign from "../components/dashboard/exhibitor/oes/MandatoryDesign";
import MandatoryFormA from "../components/dashboard/exhibitor/oes/MandatoryFormA";
import MandatoryFormB from "../components/dashboard/exhibitor/oes/MandatoryFormB";
import MandatoryNote from "../components/dashboard/exhibitor/oes/MandatoryNote";
import Manual from "../components/dashboard/exhibitor/oes/Manual";
import Security from "../components/dashboard/exhibitor/oes/Security";
import SeminarHalls from "../components/dashboard/exhibitor/oes/SeminarHalls";
import ServiceBadges from "../components/dashboard/exhibitor/oes/ServiceBadges";
import SetupSchedule from "../components/dashboard/exhibitor/oes/SetupSchedule";
import ShowDaily from "../components/dashboard/exhibitor/oes/ShowDaily";
import SiteHandling from "../components/dashboard/exhibitor/oes/SiteHandling";
import StandPossession from "../components/dashboard/exhibitor/oes/StandPossession";
import Summary from "../components/dashboard/exhibitor/oes/Summary";
import TemporaryPersonnel from "../components/dashboard/exhibitor/oes/TemporaryPersonnel";
import Translators from "../components/dashboard/exhibitor/oes/Translators";
import UtilityDetails from "../components/dashboard/exhibitor/oes/UtilityDetails";
import VisaApplication from "../components/dashboard/exhibitor/oes/VisaApplication";
import exhibitorIcon from "../assets/exhibitor_icon.png";
export const ICON_TYPE_NON_IMAGE = "icon_type_non_image";
export const ICON_TYPE_IMAGE = "icon_type_image";
export const HEADER_RIGHT_NOTIFICATION = "header_right_notification";
export const HEADER_RIGHT_MESSAGE = "header_right_message";
export const HEADER_RIGHT_PROFILE = "header_right_profile";
export const MOBILE_THRESHOLD = 640;
export const VISITOR = "visitor";
export const VISITORS = "visitors";
export const EXHIBITOR = "exhibitor";
export const IMTEX_EXHIBITOR = "imtexExhibitor";
export const EXHIBITOR_TOKEN = "exhibitor_token";
export const PROFILE_IMAGE = "profile_image";
export const PROFILE_BANNER = "profile_banner";
export const MEETINGS = "meetings";
export const USER = "user";
export const ADMIN = "admin";
export const LONG_DASH = "—";
export const APP_LOGO = "/app_logo.png";
export const HEADER_RIGHT_MODE_SETTINGS = "header_right_mode_settings";
export const TRANSPARENCY = 0.8;
export const DRAWER_WIDTH = 240;
export const MAIN_DOMAIN = "expoplanner.in";
export const EVENT_NAME = "";
export const LOCAL = "local";
export const PRODUCTION = "production";
export const state = PRODUCTION;
export const API_ENDPOINT =
  state === PRODUCTION
    ? "https://apis.expoplanner.in/"
    : "http://localhost:5002/";
export const VISITOR_API = `${API_ENDPOINT}visitor/`;
export const EXHIBITOR_API = `${API_ENDPOINT}exhibitor/`;
export const INTERACTION_API = `${API_ENDPOINT}interactions/`;
export const EVENT_API = `${API_ENDPOINT}event/`;
export const ADMIN_API = `${API_ENDPOINT}admin`;
export const DEFAULT_DP = `https://s3.ap-south-1.amazonaws.com/expoplanner.in/uploads/expo_expo_placeholder.png`;
export const EXHIBITOR_PLACEHOLDER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/DALL%C2%B7E+2024-03-25+10.05.56+-+Design+a+simplistic+logo+for+an+exhibitor+company%2C+featuring+a+stylized+version+of+a+company+building.+The+logo+should+be+minimalistic%2C+utilizing+clea.webp";
export const EXHIBITOR_PLACEHOLDER2 =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/exhibitor_placeholder.png";
export const DEFAULT_EX_ICON = exhibitorIcon;
export const INTERACT_MEETING = "interact_meeting";
export const INTERACT_MESSAGE = "interact_message";
export const INTERACT_FAVORITE = "interact_favorite";
export const LOGIN_BANNER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/965c4ea4-e89f-4ad1-88e0-829979f1f1d5.jpeg";

export const APP_HEADER_LOGO =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Organiser.webp";

export const EMAIL_HEADER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Banner_Visitor+registration.png";
export const EMAIL_FOOTER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Footer.png";
export const VISITOR_REG_HEADER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/Header+1200x150.png";
export const CONFIRMATION_HEADER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Password+Banner.webp";
export const BADGE_BG =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX2025BadgeBG.png";
export const LOGIN_HEADER_BG =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Password+Banner.webp";
export const OES_MANUAL =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/PMTX+2024+-+Exhibitor+Manual+(15+April+2024).pdf";
export const MODULES = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
    ["link", "image"], // this line adds the image and video options
  ],
};
export const OES_COMPONENTS = [
  Manual,
  SetupSchedule,
  UtilityDetails,
  StandPossession,
  InvitationInaguaration,
  ExhibitorBadges,
  ElectricalWork,
  SiteHandling,
  AudioEquipment,
  ServiceBadges,
  ExitPass,
  GuidelinesMezzanine,
  Security,
  HotelAccomodation,
  SeminarHalls,
  TemporaryPersonnel,
  Translators,
  Horticulture,
  Advertisement,
  ShowDaily,
  VisaApplication,
  AdditionalServices,
  EcoDesign,
  MandatoryDesign,
  MandatoryNote,
  MandatoryFormA,
  MandatoryFormB,
  Summary,
];

export const SUPPLIER_LIST = [];
export const PRODUCT_CATEGORIES = [];
export const PUNE_CATEGORIES = [
  {
    MainCategory: "PMTX 2024",
    Categories: [
      "Metal Cutting Machines",
      "Metal Forming & Sheet Metal Working Machines",
      "Welding Machines",
      "Machine Tool Accessories",
      "Measurement & Quality Control Equipment",
      "Hand Tools",
      "Material Handling Equipment",
      "Robotics & Automation",
      "Consumables-Cutting Tools, Grinding Wheels, Welding Electrodes",
      "Coolants, Oils, Lubricants, Packing Materials etc.",
      "Factory & Workshop Furniture",
      "Factory Storage Systems",
      "Shop Floor Maintenance",
      "Lighting Systems",
      "Cleaning Equipment",
      "Energy Efficient Devices",
      "Safety Equipment",
      "Pollution Control Equipment",
      "Low Cost Automation",
      "Enterprise Software-Low Cost Tally, ERP, Daily Work Management Software etc.",
      "Design Software - CAD/CAM/CAE",
      "Financing",
      "Skill Building - Educational Institutes, Training Institutes etc.",
      "Logistics & Supply Chain Management",
      "Industrial Development Authorities",
      "Technical Journals",
      "Digital Manufacturing",
    ],
  },
  {
    MainCategory: "FACTEQ 2024",
    Categories: [
      "Air & Water Systems",
      "HVAC",
      "Material Storage & Transportation",
      "Factory Security & Industrial Safety Systems",
      "Electrical & Lighting Systems",
      "Sustainable & Environment Solutions",
      "Maintenance Systems & Equipment",
      "Information System & Hardware",
      "Factory Furniture",
      "Clean Rooms",
      "Building & Construction",
    ],
  },
];
