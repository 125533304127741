import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  Stack,
  Typography,
  LinearProgress,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { RegFormContext } from "./context/RegFormContext";
export const IconMap = {
  x: <TwitterIcon />,
  fb: <FacebookIcon style={{ color: "#1877F2" }} />,
  ln: <LinkedInIcon style={{ color: "#0077B5" }} />,
  in: <InstagramIcon style={{ color: "#E4405F" }} />,
};

const SocialMediaForm = ({ form }) => {
  const context = useContext(RegFormContext);
  if (!context) {
    throw new Error(
      "ComponentUsingFormContext must be used within a RegistrationFormProvider"
    );
  }

  const { formData, updateFormData } = context;
  const [links, setLinks] = useState(formData[form.id]);
  useEffect(() => {
    updateFormData(form.id, links);
  }, [links]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLinks((prevLinks) => ({
      ...prevLinks,
      [name]: value,
    }));
  };
  return (
    <Stack direction={"column"} gap={2}>
      {!formData.preview && <Typography>{form.label}</Typography>}
      {form.list?.map((platform) => (
        <TextField
          key={platform}
          size="small"
          label={getLabel(platform)}
          placeholder="Enter Link"
          name={platform}
          variant="outlined"
          value={formData[form.id] ? formData[form.id][platform] : ""} //formData[form.id][platform]
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {IconMap[platform]}
              </InputAdornment>
            ),
          }}
        />
      ))}
    </Stack>
  );
};

function getLabel(platform) {
  switch (platform) {
    case "x":
      return "Twitter/X";
    case "fb":
      return "Facebook";
    case "ln":
      return "LinkedIn";
    case "in":
      return "Instagram";
    default:
      return "Social Media";
  }
}

export default SocialMediaForm;
