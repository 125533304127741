import { Alert, Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import useExhibitorByHallStand from "../../dashboard/admin/hooks/useExhibitorByHallStand";
import MuPb from "../../../widgets/MuPb";
import ProfileDetails from "../../dashboard/exhibitor/profile/ProfileDetails";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { USER } from "../../../constants/constants";

const ExhibitorDetails = ({ hall, stand }) => {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-exhibitor-by-hall-stand?hallNumber=${hall}&standNumber=${stand}&`
  );
  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">Some eror occured</Alert>;
  }
  return (
    <Box>
      {data && <ProfileDetails user={data} isView={true} id={data.username} />}
    </Box>
  );
};

export default ExhibitorDetails;
