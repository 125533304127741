import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import InputPage from "./InputPage";
import { LoadingButton } from "@mui/lab";
import { saveAs } from "file-saver";
import axios from "axios";
import { toast } from "react-toastify";
import { API_ENDPOINT, USER } from "../../constants/constants";
import QRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";
import { GROUP_ONE, getOnsiteHeader, toastError } from "../../utils2024/utils";
export const SPOT_REG_CATEGORIES = [
  { label: "VISITOR", content: "VISITOR" },
  { label: "EXHIBITOR", content: "EXHIBITOR" },
  { label: "DELEGATION", content: "DELEGATION" },
  { label: "VIP", content: "VIP" },
  { label: "EC", content: "EC" },
  { label: "SERVICE", content: "SERVICE" },
  { label: "MEDIA", content: "MEDIA" },
  { label: "STUDENT", content: "STUDENT" },
  { label: "ONLINE STUDENT", content: "student" },
];

export class ImtexBadgeDimenstion extends React.Component {
  render() {
    const { row } = this.props;
    const vCard = row.barcode_id;
    return (
      <div
        style={{
          textAlign: "center",
          width: "9.8cm",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{ marginTop: "5.6cm", fontWeight: "bold", fontSize: "21px" }}
          >
            {row.firstName?.toUpperCase()} {row.lastName?.toUpperCase()}{" "}
            {row.name?.toUpperCase()}
          </div>
          <div style={{ fontSize: "19px", marginTop: "12px" }}>
            {row.companyName?.toUpperCase()}
            {row.instituteName?.toUpperCase()}
          </div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <QRCode value={vCard} size={130} />
        </div>
      </div>
    );
  }
}
const SpotRegistration = () => {
  const [registering, setRegistering] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [state, setState] = useState({
    title: "",
    firstName: "",
    lastName: "",
    designation: "",
    phone: "",
    email: "",
    companyName: "",
    address: "",
    selectedCountry: "",
    selectedState: "",
    selectedCity: "",
    pin: "",
    website: "",
    telephone: "",
  });
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleStateChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleDownload = async () => {
    const fileURL =
      "https://s3.ap-south-1.amazonaws.com/expoplanner.in/uploads/excel_template_for_spot.xlsx";
    saveAs(fileURL, "template_spot_reg.xlsx");
  };

  const onClickSubmit = async () => {
    if (state.title === "") {
      let titleField = document.getElementsByName("title")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      return;
    } else if (state.firstName === "") {
      toast("Please enter First Name");
      let titleField = document.getElementsByName("firstName")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      return;
    } else if (state.lastName === "") {
      toast("Please enter Last Name");
      let titleField = document.getElementsByName("lastName")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      return;
    } else if (state.companyName === "") {
      let titleField = document.getElementsByName("companyName")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      toast("Please enter Company Name");
      return;
    } else {
      setRegistering(true);
      const dataToUpload = { ...state };
      dataToUpload.role = SPOT_REG_CATEGORIES[selectedTab].label;
      try {
        const { data } = await axios.post(
          `${API_ENDPOINT}${USER}/spot-register`,
          dataToUpload,
          getOnsiteHeader(GROUP_ONE)
        );
        toast.success(data.message);
        const user = data.user;
        handlePrint(user);
      } catch (error) {
        toastError(error);
      } finally {
        setRegistering(false);
        setState({
          title: "",
          firstName: "",
          lastName: "",
          designation: "",
          phone: "",
          email: "",
          companyName: "",
          address: "",
          selectedCountry: "",
          selectedState: "",
          selectedCity: "",
          pin: "",
          website: "",
          telephone: "",
        });
      }
    }
  };

  const handlePrint = (user) => {
    setCurrentUser(user);
    setTimeout(() => {
      printRow(user);
    }, 0);
  };
  const printRow = (row) => {
    const printableContent = ReactDOMServer.renderToString(
      <ImtexBadgeDimenstion row={row} />
    );
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${printableContent}
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus(); // For some browsers to trigger the print dialog
    printWindow.print();
    printWindow.close();
  };

  return (
    // <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
    //   <RegHeader />
    //   <Box sx={{ marginLeft: 1, marginTop: 1 }}>
    //     <BackBtn />
    //   </Box>
    //   <Box
    //     sx={{
    //       maxWidth: 1280,
    //       ml: "auto",
    //       mr: "auto",
    //       bgcolor: "background.paper",
    //       flexGrow: 1,
    //     }}
    //   >
    //     <Box mt={2} display={"flex"}>
    //       {/* <Box alignSelf={"center"}>
    //           <BackBtn />
    //         </Box> */}
    //       {/* <Box flex={1} textAlign={"center"}>
    //           <img src={imtexLogo} style={{ height: "76px" }} />
    //         </Box> */}
    //     </Box>
    //     <Tabs
    //       sx={{
    //         ".MuiTabs-indicator": {
    //           height: "4px", // Increased height of the tab indicator (adjust as needed)
    //         },
    //       }}
    //       value={selectedTab}
    //       onChange={handleChange}
    //       centered
    //     >
    //       {SPOT_REG_CATEGORIES.filter((m) => m.label !== "ONLINE STUDENT").map(
    //         (item, index) => (
    //           <Tab label={item.label} key={index} sx={{ fontWeight: "bold" }} />
    //         )
    //       )}
    //     </Tabs>
    //     <Box maxWidth={840} ml={"auto"} mr={"auto"}>
    //       <Typography
    //         mt={3}
    //         textAlign={"center"}
    //         color={"rgb(151, 42, 76)"}
    //         fontWeight={"bold"}
    //         fontSize={"24px"}
    //       >
    //         {" "}
    //         {SPOT_REG_CATEGORIES[selectedTab].label} Registration{" "}
    //       </Typography>
    //       {/* <Box display={"flex"} gap={2} justifyContent={"end"}>
    //         <LoadingButton
    //           onClick={handleDownload}
    //           variant="contained"
    //           size="small"
    //         >
    //           Download Template
    //         </LoadingButton>
    //         <LoadingButton variant="contained" size="small">
    //           Import Excel
    //         </LoadingButton>
    //       </Box> */}
    //       <InputPage state={state} handleStateChange={handleStateChange} />

    //       <Box textAlign={"center"} mt={2} mb={2}>
    //         <LoadingButton
    //           loading={registering}
    //           onClick={onClickSubmit}
    //           variant="contained"
    //         >
    //           Save & Print
    //         </LoadingButton>
    //       </Box>
    //     </Box>
    //   </Box>
    //   <Footer />
    // </Box>
    <Box
      sx={{
        maxWidth: 1280,
        ml: "auto",
        mr: "auto",
        bgcolor: "background.paper",
        flexGrow: 1,
      }}
    >
      <Box mt={2} display={"flex"}>
        {/* <Box alignSelf={"center"}>
          <BackBtn />
        </Box> */}
        {/* <Box flex={1} textAlign={"center"}>
          <img src={imtexLogo} style={{ height: "76px" }} />
        </Box> */}
      </Box>
      <Tabs
        sx={{
          ".MuiTabs-indicator": {
            height: "4px", // Increased height of the tab indicator (adjust as needed)
          },
        }}
        value={selectedTab}
        onChange={handleChange}
        centered
      >
        {SPOT_REG_CATEGORIES.filter((m) => m.label !== "ONLINE STUDENT").map(
          (item, index) => (
            <Tab label={item.label} key={index} sx={{ fontWeight: "bold" }} />
          )
        )}
      </Tabs>
      <Box maxWidth={840} ml={"auto"} mr={"auto"}>
        <Typography
          mt={3}
          textAlign={"center"}
          color={"rgb(151, 42, 76)"}
          fontWeight={"bold"}
          fontSize={"24px"}
        >
          {" "}
          {SPOT_REG_CATEGORIES[selectedTab].label} Registration{" "}
        </Typography>
        {/* <Box display={"flex"} gap={2} justifyContent={"end"}>
        <LoadingButton
          onClick={handleDownload}
          variant="contained"
          size="small"
        >
          Download Template
        </LoadingButton>
        <LoadingButton variant="contained" size="small">
          Import Excel
        </LoadingButton>
      </Box> */}
        <InputPage state={state} handleStateChange={handleStateChange} />

        <Box textAlign={"center"} mt={2} mb={2}>
          <LoadingButton
            loading={registering}
            onClick={onClickSubmit}
            variant="contained"
          >
            Save & Print
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SpotRegistration;
