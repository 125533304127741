export const createDefaultRegOb = function (formConfig) {
  var regOb = {};
  var defaultValueMap = {
    string: "",
    array: [],
    object: {},
  };

  formConfig.items.forEach(function (section) {
    section.fields.forEach(function (field) {
      regOb[field.id] =
        defaultValueMap[field.valueType] !== undefined
          ? defaultValueMap[field.valueType]
          : null;
    });
  });

  return regOb;
};
export const getDbHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      dbauth:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYiI6ImltdGV4MjAyNSIsImlhdCI6MTcyMzcwMDkyMSwiZXhwIjoxNzU1MjU4NTIxfQ.07gBIH-rkRp9Q-6GHRORREC3w15Vj-JxbHMH7oorekQ",
    },
  };
};
export function validateForm(formData, formConfig) {
  const errors = [];
  formConfig.items.forEach((section) => {
    section.fields.forEach((field) => {
      const { id, required, valueType, label } = field;
      const value = formData[id];
      if (required) {
        // Check for empty values
        if (
          (valueType === "string" && !value) ||
          (valueType === "object" && Object.keys(value || {}).length === 0) ||
          (valueType === "array" && value.length === 0)
        ) {
          errors.push({ id, message: `${label} is required.` });
        }
      }

      // Additional validations for specific types
      if (valueType === "string" && typeof value !== "string") {
        errors.push({ id, message: `${label} must be a string.` });
      }

      if (valueType === "array" && !Array.isArray(value)) {
        errors.push({ id, message: `${label} must be an array.` });
      }

      if (valueType === "object" && typeof value !== "object") {
        errors.push({ id, message: `${label} must be an object.` });
      }
      if (field.type === "EMAIL" && value && !validateEmail(value)) {
        errors.push({ id, message: `Invalid email format in ${label}.` });
      }

      if (field.type === "PHONE" && value && !validatePhoneNumber(value)) {
        errors.push({
          id,
          message: `Invalid phone number format in ${label}.`,
        });
      }

      if (field.type === "COUNTRY" && value && !validateCountry(value)) {
        errors.push({
          id,
          message: `Invalid country selection in ${label}.`,
        });
      }
    });
  });
  return errors;
}
export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
export function validatePhoneNumber(phone) {
  const trimmedPhone = phone.trim();
  const indianPhonePattern = /^\+91\s?(\d{5}\s?\d{5})$/;
  const generalPhonePattern = /^\+?\d+(\s\d+)*$/;

  if (trimmedPhone.startsWith("+91")) {
    return indianPhonePattern.test(trimmedPhone);
  } else {
    return generalPhonePattern.test(trimmedPhone);
  }
}
export function validateCountry(country) {
  return (
    typeof country === "object" &&
    "name" in country &&
    "code" in country &&
    "flag" in country
  );
}
