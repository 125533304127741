import React from 'react';

const Onsite = () => {
    return (
        <div className='rd-dash-content'> 
            Onsite
        </div>
    );
};

export default Onsite;