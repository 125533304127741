import React, { useState } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { SUPPLIER_LIST } from "../../../../../constants/constants";
import { SubmittedStatus, oesMenus } from "../Services";

const Supplier2 = ({ user, id, supplier, setSupplier }) => {
  const [suppliers, setSuppliers] = useState(
    SUPPLIER_LIST.filter(
      (sp) => (id === 100 && sp.form_number == 2) || sp.form_number === id
    )
  );

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        mt={2}
        boxShadow={2}
        borderRadius={1}
        bgcolor="#f5f5f5"
      >
        {/* Supplier Select Field */}
        <Box width="40%" mr={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Supplier</InputLabel>
            <Select
              label="Supplier"
              value={supplier}
              onChange={(e) => setSupplier(e.target.value)}
            >
              {suppliers.map((sp, i) => (
                <MenuItem value={sp}>{sp.supplier_name}</MenuItem>
              ))}
              {/* Add other suppliers as required */}
            </Select>
          </FormControl>
        </Box>

        {/* Supplier Info */}
        <Box width="50%">
          <Typography variant="body1" gutterBottom>
            <strong>Address:</strong>
            <span style={{ fontSize: "14px" }}> {supplier?.address}</span>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Contact Person:</strong>
            <span style={{ fontSize: "14px" }}>
              {" "}
              {supplier?.contact_person}
            </span>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Phone number:</strong>
            <span style={{ fontSize: "14px" }}> {supplier?.phone_number}</span>
          </Typography>
          <Typography variant="body1">
            <strong>E-mail:</strong>{" "}
            <span style={{ fontSize: "14px" }}>
              {supplier?.email_id.join("\n")}
            </span>
          </Typography>
        </Box>
      </Box>
      <SubmittedStatus
        username={user.username}
        title={
          id === 100 ? "Exhibition Catalogue" : oesMenus[parseInt(id) - 1].title
        }
      />
    </Box>
  );
};
export default Supplier2;
