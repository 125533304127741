import React from "react";
import {
  Box,
  Avatar,
  Card,
  Typography,
  Chip,
  Divider,
  Stack,
  Alert,
  Badge,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import { Email, Phone, Public } from "@mui/icons-material";
import MuPb from "../../../../widgets/MuPb";
import { getErrorResponse } from "../../../../utils2024/utils";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import { Link, useParams } from "react-router-dom";
import pgBg from "../../../../assets/profile_cover_default.jpeg";
import BackBtn from "../../admin/widgets/BackBtn";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircleIcon from "@mui/icons-material/Circle"; // Used for online dot
import InteractActions from "./InteractActions";
import InteractiveAction2 from "../../../../widgets/InteractiveAction2";
import { MySelectedProductCats } from "./MySelectedProductCats";
import ProductsList from "./ProductsList";
import TeamMembersList from "../TeamMembersList";
import BrochuresList from "./BrochuresList";
import ProfileViewer from "../../common/ProfileViewer";

const ExhibitorDetail = ({ response }) => {
  const formatWebsiteLink = (website) => {
    if (website && !website.startsWith("http")) {
      return `http://${website}`;
    }
    return website;
  };
  const isOnline = false;
  return (
    <Card sx={{ maxWidth: 800, margin: "auto" }}>
      <Box
        sx={{
          height: "266px",
          backgroundImage: `url(${response.profile_banner || pgBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <Grid container spacing={2} alignItems="center">
        <Grid
          item
          xs={12}
          sm="auto"
          textAlign={{ xs: "center", sm: "left" }}
          sx={{ position: "relative", top: { xs: -24, sm: -72 } }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              isOnline ? (
                <CircleIcon sx={{ color: "green", fontSize: 14 }} />
              ) : null
            }
          >
            <Avatar
              alt="Profile Image"
              src={response.profile_image}
              sx={{
                width: { xs: 100, sm: 132 }, // Adjust width based on screen size
                height: { xs: 100, sm: 132 }, // Adjust height based on screen size
                mt: { xs: -4, sm: -8 }, // Adjust margin-top for small screens
                ml: { xs: "auto", sm: 1 },
                mr: { xs: "auto", sm: 0 },
                border: "3px solid white",
              }}
            />
          </Badge>
        </Grid>
        <Grid item xs>
          <Typography
            mt={{ xs: 0, sm: 2 }}
            variant="h5"
            gutterBottom
            textAlign={{ xs: "center", sm: "left" }}
          >
            {response.companyName?.value}
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            textAlign={{ xs: "center", sm: "left" }}
          >
            {`${response.contactSalutation?.value} ${response.contactFirstName?.value} ${response.contactLastName?.value}`}{" "}
            - {response.contactDesignation?.value}
          </Typography>

          <Typography
            flex={1}
            variant="body2"
            color="textSecondary"
            textAlign={{ xs: "center", sm: "left" }}
          >
            <Link
              to={`tel:${response.mobile?.value}`}
              target="_blank"
              rel="noopener"
              color="inherit"
            >
              <Phone sx={{ mr: 1 }} /> {response.mobile?.value}
            </Link>
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            textAlign={{ xs: "center", sm: "left" }}
          >
            <Link
              to={`mailto:${response.contactEmail?.value}`}
              target="_blank"
              rel="noopener"
              color="inherit"
            >
              <Email sx={{ mr: 1 }} /> {response.contactEmail?.value}
            </Link>
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            mr={2}
            mb={1}
            alignItems="center"
            justifyContent={{ xs: "center", sm: "flex-start" }}
          >
            <Typography
              flex={1}
              variant="body2"
              color="textSecondary"
              textAlign={{ xs: "center", sm: "left" }}
            >
              <Link
                to={formatWebsiteLink(response.website?.value)}
                target="_blank"
                rel="noopener"
                color="inherit"
              >
                <Public sx={{ mr: 1 }} /> {response.website?.value}
              </Link>
            </Typography>
            <Link
              to={`/hall-view/${response.hallNumber.value}?un=${response.username}`}
            >
              <Button
                size="small"
                startIcon={<LocationOnIcon fontSize="small" />}
                variant="outlined"
                sx={{ mt: { xs: 1, sm: 0 } }}
              >
                {`${response.hallNumber?.value}`} |{" "}
                {`${response.standNumber?.value}`}
              </Button>
            </Link>
          </Stack>

          <InteractiveAction2
            item={response}
            type={"exhibitor"}
            showDetail={false}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Stack direction="column" px={2}>
        {/* Address and Stand Info */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Address
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {response.address1?.value}, {response.address2?.value},{" "}
            {response.city?.value?.name}, {response.state?.value?.name},{" "}
            {response.country?.value?.name} - {response.pin?.value}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />

        {/* Industry Sectors */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Industry Sectors
          </Typography>
          <Stack direction="row" spacing={1}>
            {(response.industrySector?.value || []).map((sector, index) => (
              <Chip key={index} label={sector} />
            ))}
          </Stack>
        </Box>
        <Divider sx={{ my: 2 }} />

        {/* Product Categories */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Product Categories
          </Typography>
          <MySelectedProductCats
            items={response.productCategories.value || []}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>
            Products
          </Typography>
          <ProductsList user={response} />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography variant="h6" gutterBottom>
            Team Members
          </Typography>
          <TeamMembersList user={response} />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography variant="h6" gutterBottom>
            Brochures
          </Typography>
          <BrochuresList user={response} />
        </Box>
        <Divider sx={{ my: 2 }} />
      </Stack>
    </Card>
  );
};

export default ExhibitorDetail;
