import React from "react";
import styled from "styled-components";

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const CalendarTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const CalendarCell = styled.td`
  border: 1px solid #ccc;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #eaeaea;
  }
`;

const SimpleCalendar = ({ month, year }) => {
  const startDay = new Date(year, month, 1).getDay();
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  let dayArray = new Array(42).fill(null);
  for (let i = 1; i <= daysInMonth; i++) {
    dayArray[startDay + i - 1] = i;
  }

  return (
    <CalendarTable>
      <thead>
        <tr>
          {days.map((day) => (
            <th key={day}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array(6)
          .fill()
          .map((_, weekIndex) => (
            <tr key={weekIndex}>
              {Array(7)
                .fill()
                .map((_, dayIndex) => (
                  <CalendarCell key={dayIndex}>
                    {dayArray[weekIndex * 7 + dayIndex]}
                  </CalendarCell>
                ))}
            </tr>
          ))}
      </tbody>
    </CalendarTable>
  );
};

export default SimpleCalendar;
