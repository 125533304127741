import React from "react";
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import BackBtn from "../admin/widgets/BackBtn";
import { useParams } from "react-router-dom";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import MuPb from "../../../widgets/MuPb";
import { getErrorResponse } from "../../../utils2024/utils";
import pgBg from "../../../assets/profile_cover_default.jpeg";
import CircleIcon from "@mui/icons-material/Circle"; // Used for online dot
import InteractActions from "../exhibitor/profile/InteractActions";
import InteractiveAction2 from "../../../widgets/InteractiveAction2";
import ProfileViewer from "../common/ProfileViewer";

const VisitorDetail = ({ data }) => {
  const {
    profile_banner = pgBg,
    profile_image,
    title,
    firstName,
    lastName,
    designation,
    companyName,
    address,
    city,
    stateRegion,
    country,
    email,
    mobileNo,
    telephoneNo,
    website,
    socialLinks,
    lookingFor,
    industrySector,
    department,
    eventCategory,
    productCategories,
  } = data || {}; // Safely destructuring if data is undefined

  const isOnline = false;
  return (
    <Card sx={{ maxWidth: 800, margin: "auto" }}>
      {profile_banner && (
        <Box
          sx={{
            height: 266,
            backgroundImage: `url(${profile_banner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      )}
      <CardContent>
        {/* Profile Header */}
        <Grid container spacing={2}>
          <Grid item>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={
                isOnline ? (
                  <CircleIcon sx={{ color: "green", fontSize: 14 }} />
                ) : null
              }
            >
              <Avatar
                alt={`${firstName || ""} ${lastName || ""}`}
                src={profile_image}
                sx={{
                  width: 120,
                  height: 120,
                  mt: -10,
                  border: "3px solid white",
                }}
              />
            </Badge>
          </Grid>
          <Grid item xs>
            <Typography variant="h5">
              {title || ""} {firstName || ""} {lastName || ""}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {designation || ""}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {companyName || ""}
            </Typography>
            {address && city?.name && stateRegion?.name && country?.name && (
              <Typography variant="body2" color="textSecondary">
                {address}, {city.name}, {stateRegion.name}, {country.name}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Box mt={1}></Box>
        <InteractiveAction2 item={data} type={"visitor"} showDetail={false} />

        <Divider sx={{ my: 2 }} />

        {/* Contact Information */}
        <Typography variant="h6">Contact Information</Typography>
        <Stack spacing={1} mt={1}>
          {email && (
            <Typography>
              <strong>Email:</strong>{" "}
              <Link href={`mailto:${email}`}>{email}</Link>
            </Typography>
          )}
          {mobileNo && (
            <Typography>
              <strong>Mobile:</strong> {mobileNo}
            </Typography>
          )}
          {telephoneNo && (
            <Typography>
              <strong>Telephone:</strong> {telephoneNo}
            </Typography>
          )}
          {website && (
            <Typography>
              <strong>Website:</strong>{" "}
              <Link href={website} target="_blank" rel="noopener noreferrer">
                {website}
              </Link>
            </Typography>
          )}
        </Stack>

        {/* Social Links */}
        {socialLinks && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6">Social Links</Typography>
            <Stack direction="row" spacing={2} mt={1}>
              {socialLinks.fb && (
                <Link
                  href={socialLinks.fb}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.vectorlogo.zone/logos/facebook/facebook-icon.svg"
                    alt="Facebook"
                    width={30}
                    height={30}
                  />
                </Link>
              )}
              {socialLinks.ln && (
                <Link
                  href={socialLinks.ln}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.vectorlogo.zone/logos/linkedin/linkedin-icon.svg"
                    alt="LinkedIn"
                    width={30}
                    height={30}
                  />
                </Link>
              )}
              {socialLinks.x && (
                <Link
                  href={socialLinks.x}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://img.freepik.com/premium-photo/twitter-logo-icon-illustration-vector_895118-5895.jpg"
                    alt="X"
                    width={30}
                    height={30}
                  />
                </Link>
              )}
              {socialLinks.in && (
                <Link
                  href={socialLinks.in}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.vectorlogo.zone/logos/instagram/instagram-icon.svg"
                    alt="Instagram"
                    width={30}
                    height={30}
                  />
                </Link>
              )}
            </Stack>
          </>
        )}

        {/* Looking For */}
        {lookingFor && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6">Looking For</Typography>
            <Box
              mt={1}
              p={2}
              sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
            >
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: lookingFor }}
              />
            </Box>
          </>
        )}

        {/* Additional Information */}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6">Additional Information</Typography>
        <Stack spacing={1} mt={1}>
          {industrySector && (
            <Typography>
              <strong>Industry Sector:</strong> {industrySector}
            </Typography>
          )}
          {department && (
            <Typography>
              <strong>Department:</strong> {department}
            </Typography>
          )}
          <Box>
            {eventCategory && eventCategory.length > 0 && (
              <Typography>
                <strong>Event Categories:</strong>
              </Typography>
            )}
            {eventCategory &&
              eventCategory.map((category, index) => (
                <Chip
                  key={index}
                  label={category}
                  variant="outlined"
                  sx={{ mr: 1, mt: 1 }}
                />
              ))}
            {productCategories && productCategories.length > 0 && (
              <>
                <Typography sx={{ mt: 2 }}>
                  <strong>Product Categories:</strong>
                </Typography>
                {productCategories.map((product, index) => (
                  <Chip
                    key={index}
                    label={product}
                    variant="outlined"
                    sx={{ mr: 1, mt: 1 }}
                  />
                ))}
              </>
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default VisitorDetail;
