import React from "react";
import ComingSoon from "./ComingSoon";

const Settings = () => {
  return (
    <div className="rd-dash-content">
      <ComingSoon />
    </div>
  );
};

export default Settings;
