import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard";
import Login from "../components/dashboard/common/Login";

const SuperHome = () => {
  return (
    <Routes>
      <Route path="" element={<Dashboard />} />
    </Routes>
  );
};

export default SuperHome;
