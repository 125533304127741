import React, { useEffect, useState } from "react";
import { Container, TextField, Box, Alert, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLogin } from "./hooks/useLogin";
import { useNavigate } from "react-router-dom";
import { saveUser } from "../../../utility/utility";
import { toast } from "react-toastify";

function Login() {
  const Logo =
    "https://s3.ap-south-1.amazonaws.com/expoplanner.in/download.png";
  const { login, isLoading, isError, error, data } = useLogin();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (data) {
      saveUser(data.data);
      navigate(`/${data.data.role}`);
    }
    if (isError) {
      toast.error("Some error occurred");
    }
  }, [data, isError, navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    login({ username, password });
  };
  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
      maxWidth="sm"
    >
      <Box component="div" sx={{ width: 192, marginBottom: 4 }}>
        <img src={Logo} alt="Company Logo" width="100%" />
      </Box>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 400,
        }}
      >
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={username} // Bind value to state
          onChange={(e) => setUsername(e.target.value)} // Update state on change
        />
        <TextField
          label="Password"
          type="password"
          size="small"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password} // Bind value to state
          onChange={(e) => setPassword(e.target.value)} // Update state on change
        />
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          loading={isLoading}
        >
          Login
        </LoadingButton>
      </Box>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          backgroundColor: (theme) => theme.palette.background.paper,
          p: 1,
          textAlign: "center",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © 2023 Expoplanner. All Rights Reserved.
        </Typography>
      </Box>
    </Container>
  );
}

export default Login;
