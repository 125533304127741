import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Box,
  useMediaQuery,
  CardContent,
  Card,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import bgBanner from "../../assets/gifs/ExpoBG.gif";
import { CircularProgressbar } from "react-circular-progressbar";
import { ExpoTheme } from "../../theme/theme";
import SideMenuBar from "../../widgets2024/SideMenuBar";
import AddEvent from "../AddEvent";
import { Link, Route, Routes } from "react-router-dom";
import HeaderBar from "../../widgets2024/HeaderBar";
import { DRAWER_WIDTH } from "../../constants/constants";
import VisitorRegSetup from "./VisitorRegSetup";

const AppBarHeader = ({ open, handleDrawerOpen }) => (
  <AppBar position="fixed">
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: "none" }) }}
      >
        <MenuIcon />
      </IconButton>
      <Link
        to="/org-admin-dash"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Typography variant="h6" noWrap component="div">
          IMTEX Forming 2024
        </Typography>
      </Link>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton color="inherit">
        <NotificationsIcon />
      </IconButton>
      <IconButton color="inherit">
        <AccountCircle />
      </IconButton>
    </Toolbar>
  </AppBar>
);
const menuItems = [
  {
    text: "Event Setup",
    link: "#",
    icon: "EditIcon",
    subcategories: [
      {
        text: "General",
        link: "/explore/exhibitors",
      },
      {
        text: "Build Website",
        link: "/explore/products",
      },
      {
        text: "New UI Settings",
        link: "/explore/matchmaking",
      },
    ],
  },
  {
    text: "Registration Settings",
    link: "#",
    icon: "SettingsIcon",
    subcategories: [
      {
        text: "Exhibitor",
        link: "/explore/exhibitors",
      },
      {
        text: "Visitor",
        link: "visitor-reg-settings",
      },
      {
        text: "Badges",
        link: "/explore/matchmaking",
      },
    ],
  },
  {
    text: "Mobile Apps",
    link: "/services",
    icon: "AppSettingsAltIcon",
  },

  {
    text: "Reports",
    link: "/reports",
    icon: "AssessmentIcon",
  },
  {
    text: "Settings",
    link: "/settings",
    icon: "SettingsIcon",
  },
  {
    text: "Logout",
    link: "/logout",
    icon: "LogoutIcon",
  },
];

function ResponsiveUI() {
  const [open, setOpen] = useState(false);
  const navItems = {
    rootPath: "/org-admin-dash",
    barLogo: "https://www.imtex.in/images/imtex-2024.png",
    barName: "Event Settings",
    profileName: "Ratul Doley",
    leftItems: [],
    rightItems: [
      {
        label: "Database",
        icon: "StorageIcon",
        path: "/database",
      },
      {
        label: "Apps",
        icon: "PhoneIphoneIcon",
        path: "/apps",
      },
      {
        label: "Accounts",
        icon: "GroupIcon",
        path: "/accounts",
      },
      {
        label: "Profile",
        icon: "AccountCircle",
        path: "/profile",
      },
      {
        label: "Logout",
        icon: "LogoutIcon",
        path: "/logout",
      },
    ],
  };

  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box>
        <SideMenuBar open={open} setOpen={setOpen} menuItems={menuItems} />
        <Box
          sx={{
            position: "fixed",
            top: 0,
            pl: open ? `${DRAWER_WIDTH}px` : ExpoTheme.spacing(7),
            width: "100%",
            zIndex: 2,
          }}
        >
          <HeaderBar navItems={navItems} />
        </Box>
        <Toolbar />
        <Box
          component="main"
          sx={{
            position: "relative",
            ml: open ? `${DRAWER_WIDTH}px` : ExpoTheme.spacing(7),
            transition: ExpoTheme.transitions.create("margin", {
              easing: ExpoTheme.transitions.easing.sharp,
              duration: ExpoTheme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Box sx={{ p: 2 }}>
            <Routes>
              <Route path="" element={<AddEvent />} />
              <Route
                path="visitor-reg-settings"
                element={<VisitorRegSetup />}
              />
            </Routes>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ResponsiveUI;
