import { Box, Button } from "@mui/material";
import React from "react";
import {
  Routes,
  Route,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import RegHeader from "../../components/headers/regHeader";
import Footer from "../footer/footer";
import SpotUsers from "./SpotUsers";
import EventNoteIcon from "@mui/icons-material/EventNote";
import BadgeIcon from "@mui/icons-material/Badge";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import PrintIcon from "@mui/icons-material/Print";
import SpotRegistration from "./SpotRegistration";
import BulkPrint from "../badgeprinter/bulk/BulkPrint";
import ScanBadge from "../badgeprinter/ScanBadge";
import BulkStudent from "../badgeprinter/bulk/BulkStudent";
import OrganiserBadges from "../badgeprinter/bulk/OrganiserBadges";

const GroupOne = () => {
  const location = useLocation(); // React hook to get the current location
  const id = location.pathname
    .split("/")
    .filter((x) => x)
    .pop(); // Get the last non-empty segment

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="row"
      sx={{ userSelect: "none" }}
    >
      <Box flex={1} display="flex" flexDirection="column">
        <RegHeader />
        {/* Routes Setup */}

        <Box>
          <Box>
            <Box
              alignItems={"center"}
              justifyContent={"center"}
              mt={1}
              sx={{ display: "flex", gap: 2, alignItems: "center" }}
            >
              <Button
                color={id === "spot-users" ? "success" : "primary"}
                size="small"
                variant={id === "spot-users" ? "contained" : "outlined"}
                component={RouterLink}
                to="/group-one-onsite/spot-users"
                startIcon={<EventNoteIcon />}
              >
                Onsite Users
              </Button>
              <Button
                size="small"
                color={id === "onsite-registration" ? "success" : "primary"}
                variant={
                  id === "onsite-registration" ? "contained" : "outlined"
                }
                component={RouterLink}
                to="/group-one-onsite/onsite-registration"
                startIcon={<EventNoteIcon />}
              >
                Onsite Registration
              </Button>
              <Button
                color={id === "exhibitor-badges" ? "success" : "primary"}
                variant={id === "exhibitor-badges" ? "contained" : "outlined"}
                size="small"
                component={RouterLink}
                to="/group-one-onsite/exhibitor-badges"
                startIcon={<BadgeIcon />}
              >
                Exhibitor Badges
              </Button>
              <Button
                size="small"
                color={id === "scan-badge" ? "success" : "primary"}
                variant={id === "scan-badge" ? "contained" : "outlined"}
                component={RouterLink}
                to="scan-badge"
                startIcon={<QrCode2Icon />}
              >
                Scan QR & Print Badges
              </Button>
              <Button
                color={id === "bulk-student-badges" ? "success" : "primary"}
                variant={
                  id === "bulk-student-badges" ? "contained" : "outlined"
                }
                size="small"
                component={RouterLink}
                to="bulk-student-badges"
                startIcon={<PrintIcon />}
              >
                Bulk Students Print Badges
              </Button>
              <Button
                color={id === "organiser-badges" ? "success" : "primary"}
                variant={id === "organiser-badges" ? "contained" : "outlined"}
                size="small"
                component={RouterLink}
                to="organiser-badges"
                startIcon={<PrintIcon />}
              >
                Organiser Badges
              </Button>
            </Box>
          </Box>
          <Box>
            <Routes>
              <Route path="spot-users" element={<SpotUsers />} />
              <Route
                path="onsite-registration"
                element={<SpotRegistration />}
              />
              <Route path="exhibitor-badges" element={<BulkPrint />} />
              <Route path="scan-badge" element={<ScanBadge />} />
              <Route path="bulk-student-badges" element={<BulkStudent />} />
              <Route path="organiser-badges" element={<OrganiserBadges />} />
            </Routes>
          </Box>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default GroupOne;
