import React from "react";
import BackBtn from "../widgets/BackBtn";
import DrawerMenu from "./DrawableMenu";

const AddEvent = () => {
  return (
    <div>
      <BackBtn />
      <div>
        <DrawerMenu />
      </div>
    </div>
  );
};

export default AddEvent;
