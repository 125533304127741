import { Box, Grid, LinearProgress } from "@mui/material";
import React from "react";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import ProductItem from "../../../../widgets/ProductItem";

const ProductsList = ({ user }) => {
  const { data, isLoading } = useCommonGetInit(
    `user/get-user-products?id=${user.username}`
  );
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <Box>
      <Grid container spacing={3}>
        {data?.data?.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <ProductItem viewMode={true} key={index} product={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductsList;
