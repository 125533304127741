import React, { useRef, useEffect, useState } from "react";
import Slider from "@mui/material/Slider";

const PolygonCanvas = ({ width, height, backgroundImage }) => {
  const canvasRef = useRef(null);
  const [vertices, setVertices] = useState([]);
  const [isClosed, setIsClosed] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const imgRef = useRef(new Image());
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    if (backgroundImage) {
      imgRef.current.onload = () => {
        setImageLoaded(true);
      };
      imgRef.current.src = backgroundImage;
    }
  }, [backgroundImage]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.save();

    // Clear the canvas
    ctx.clearRect(0, 0, width, height);

    // Adjust for zoom from the center of the canvas
    ctx.translate(width / 2, height / 2);
    ctx.scale(zoom, zoom);
    ctx.translate(-width / 2, -height / 2);

    if (imageLoaded) {
      ctx.drawImage(imgRef.current, 0, 0, width, height);
    }

    // Draw the vertices as dots
    vertices.forEach((vertex) => {
      ctx.beginPath();
      ctx.arc(vertex.x, vertex.y, 5, 0, 2 * Math.PI); // 5 is the radius of the dot
      ctx.fill();
    });

    // Draw the polygon or the line segments
    if (vertices.length > 0) {
      ctx.beginPath();
      ctx.moveTo(vertices[0].x, vertices[0].y);
      for (let i = 1; i < vertices.length; i++) {
        ctx.lineTo(vertices[i].x, vertices[i].y);
      }

      if (isClosed) {
        ctx.closePath();
        ctx.fillStyle = "rgba(0, 0, 255, 0.5)"; // Set fill color to blue with transparency
        ctx.fill(); // Fill the closed polygon
      }

      ctx.stroke();
    }
    console.log(vertices);
    ctx.restore(); // restore the canvas state
  }, [vertices, width, height, isClosed, imageLoaded, zoom]);

  const handleCanvasClick = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    // Calculate the zoomed and translated coordinates
    const x = (event.clientX - rect.left - width / 2) / zoom + width / 2;
    const y = (event.clientY - rect.top - height / 2) / zoom + height / 2;

    if (vertices.length > 0) {
      const firstVertex = vertices[0];
      const distanceToFirst = Math.sqrt(
        (x - firstVertex.x) ** 2 + (y - firstVertex.y) ** 2
      );

      // If clicked near the first vertex and there are at least 2 other vertices, close the polygon
      if (distanceToFirst < 10 && vertices.length >= 2 && !isClosed) {
        setIsClosed(true);
        return;
      }
    }

    if (!isClosed) {
      setVertices((oldVertices) => [...oldVertices, { x, y }]);
    }
  };
  return (
    <div>
      <Slider
        defaultValue={1}
        step={0.1}
        min={0.5} // for example: 50% zoom out
        max={2} // for example: 200% zoom in
        valueLabelDisplay="auto"
        onChange={(e, newValue) => setZoom(newValue)}
        aria-labelledby="zoom-slider"
        style={{ width: width, margin: "10px auto" }}
      />
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        onClick={handleCanvasClick}
        style={{ border: "1px solid black", background: "white" }}
      />
    </div>
  );
};

export default PolygonCanvas;
