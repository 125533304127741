import React, { forwardRef } from "react";
import useCountries from "../visitorregt/components/previewpage/hooks/useCountries";
import { Autocomplete, Box, LinearProgress, TextField } from "@mui/material";

const CountryAutoComplete = forwardRef(
  ({ value, type = "country", size, label = "Country", onChange }, ref) => {
    const { data, isLoading, isError, error } = useCountries(); // Fetch visitors for page 1
    if (isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        <Autocomplete
          ref={ref}
          value={value}
          size={size}
          disablePortal
          options={data}
          onChange={onChange}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box {...props}>
              {option.flag} {option.name}{" "}
              {type === "country" ? "" : option.phonecode}
            </Box>
          )}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      </div>
    );
  }
);

export default CountryAutoComplete;
