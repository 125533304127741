import React, { useState } from "react";
import BrochureItem from "../../../../widgets/BrochureItem";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import AddBrochure from "../AddBrochure";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { API_ENDPOINT, USER } from "../../../../constants/constants";
import { getHeader } from "../../../../utility/utility";
import MuPb from "../../../../widgets/MuPb";
import useBrochures from "../hooks/useBrochures";
import { toast } from "react-toastify";
import { Button, Grid } from "@mui/material";

const UploadContent = ({ exhibitor, setReloadProfile, isView = false, id }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error, refetchBrochures } = useBrochures(
    page,
    id
  );
  const [showAddBrochure, setShowAddBrochure] = useState(false);
  const [showDeleteBrochure, setShowDeleteBrochure] = useState(false);
  const [currentBrochure, setCurrentBrochure] = useState();
  const [currentMode, setCurrentMode] = useState("add");

  const [loading, setLoading] = useState(false);
  if (isLoading) {
    return <MuPb />;
  }

  const onAddBrochure = () => {
    setCurrentMode("add");
    setCurrentBrochure(null);
    setShowAddBrochure(true);
  };
  const onBrochureAdded = () => {
    setShowAddBrochure(false);
    refetchBrochures();
  };
  const onEditBrochure = (item) => {
    setCurrentMode("edit");
    setCurrentBrochure(item);
    setShowAddBrochure(true);
  };
  const onDeleteBrochure = (item) => {
    setCurrentBrochure(item);
    setShowDeleteBrochure(true);
  };
  const onSelectBrochure = (item) => {
    window.open(item.pdfUrl, "_blank");
  };
  const onYesDeleteBrochure = async () => {
    setLoading(true);
    try {
      const { data } = await axios.delete(
        `${API_ENDPOINT}${USER}/delete-brochure?id=${currentBrochure._id}`,
        getHeader()
      );
      setLoading(false);
      toast.success(data.message);
      setShowDeleteBrochure(false);
      onBrochureAdded();
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "16px",
        borderRadius: "8px",
      }}
    >
      <CustomCircularP show={loading} />
      <RdMyModal
        mWidth="700px"
        showModal={showAddBrochure}
        setShowModal={setShowAddBrochure}
        modalC={
          <AddBrochure
            exhibitor={exhibitor}
            mode={currentMode}
            onAdded={onBrochureAdded}
            showAdd={showAddBrochure}
            item={currentBrochure}
          />
        }
      />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message="Are you sure you want to delete this?"
        onYes={onYesDeleteBrochure}
        show={showDeleteBrochure}
        setShow={setShowDeleteBrochure}
      />
      <div style={{ display: "flex" }}>
        <HeaderLabel onEdit={null} showEdit={false} label={"Brochures"} />
        <div style={{ flex: 1 }}></div>
        {!isView && (
          <Button
            onClick={onAddBrochure}
            startIcon={<AddIcon fontSize="small" />}
            variant="outlined"
            size="small"
            color="primary"
            sx={{ alignSelf: "center" }}
          >
            Add Brochure
          </Button>
        )}
      </div>

      <Grid container spacing={3}>
        {data?.data?.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
            <BrochureItem
              onSelect={onSelectBrochure}
              viewMode={isView}
              onEdit={onEditBrochure}
              onDelete={onDeleteBrochure}
              key={i}
              item={item}
            />
          </Grid>
        ))}
      </Grid>
      {data?.data?.length === 0 && (
        <div
          style={{
            margin: "16px",
            fontSize: "20px",
            color: "#888",
            fontWeight: 600,
          }}
        >
          No Brochures Found!
        </div>
      )}
    </div>
  );
};

export default UploadContent;
