import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Typography,
} from "@mui/material";
import AddImage from "../widgets2024/AddImage";
import TextFieldWithTooltip from "../widgets2024/TextFieldWithTooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import CountryAutoComplete from "../widgets2024/CountryAutoComplete";
import CountryCitiesAuto from "../widgets2024/CountryCitiesAuto";
import { DateTimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { navToElementMarker, toastError } from "../utils2024/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { base64ToBlob } from "../utility/utility";
import axios from "axios";
import { API_ENDPOINT } from "../constants/constants";
const eventobject = {
  logo: "",
  banner: "",
  favicon: "",
  name: "IMTEX 2024",
  subdomain: "imtex2024",
  domain: "-imtma.expoplanner.in",
  externalDomain: "",
  country: {},
  city: {},
  venue: "",
  defPhoneCode: "",
  dateFrom: "",
  dateTo: "",
  privateEvent: false,
  communityEvent: false,
};
const AddEvent = ({ eventO = eventobject }) => {
  const navigate = useNavigate();
  const [logoImage, setLogoImage] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [faviconImage, setFaviconImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const logoImgRef = useRef();
  const bannerImgRef = useRef();
  const faviconImgRef = useRef();
  const eventNameRef = useRef();
  const sessionLifetimeRef = useRef();
  const venueRef = useRef();
  const privateEventRef = useRef();
  const communityEventRef = useRef();
  const externalDomainRef = useRef();
  const countryRef = useRef();
  const phoneCodeRef = useRef();
  const cityRef = useRef();
  const dateFromRef = useRef();
  const dateToRef = useRef();
  const subdomainRef = useRef();

  const [eventName, setEventName] = useState("");
  const [sessionLifetime, setSessionLifetime] = useState();
  const [venue, setVenue] = useState("");
  const [privateEvent, setPrivateEvent] = useState(false);
  const [communityEvent, setCommunityEvent] = useState(false);
  const [externalDomain, setExternalDomain] = useState("");
  const [country, setCountry] = useState();
  const [phoneCode, setPhoneCode] = useState();
  const [city, setCity] = useState();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [subdomain, setSubdomain] = useState("");
  const [orgdomain, setOrgdomain] = useState("imtma.expoplanner.in");
  const maxLength = 16;
  const remainingChars = maxLength - subdomain.length;

  const onChangeCountry = (event, newV) => {
    setCountry(newV);
  };
  const onChangePhoneCode = (event, newV) => {
    setPhoneCode(newV);
  };
  const onChangeCity = (event, newV) => {
    setCity(newV);
  };

  const onDateFromChange = (newV) => {
    if (dayjs.isDayjs(newV)) {
      const jsDate = newV.toDate();
      setDateFrom(jsDate);
    }
  };
  const onDateToChange = (newV) => {
    if (dayjs.isDayjs(newV)) {
      const jsDate = newV.toDate();
      setDateTo(jsDate);
    }
  };
  const onSubdomainChange = (event) => {
    const regex = /^[a-zA-Z0-9]*$/;
    const inputChar = event.target.value;
    // Check both regex and length
    if (regex.test(inputChar) && inputChar.length <= maxLength) {
      setSubdomain(inputChar);
    }
  };
  const handleSessionLifetimeChange = (event) => {
    const value = event.target.value;
    setSessionLifetime(value === "" ? "" : Number(value));
  };
  const getBlobFromBase64 = (base64Image) => {
    const base64ImageContent = base64Image.replace(
      /^data:image\/(png|jpg);base64,/,
      ""
    );
    const blob = base64ToBlob(base64ImageContent, "image/png");
  };
  const onSave = async () => {
    if (!logoImage) {
      navToElementMarker(logoImgRef, `Logo is Required!`);
    } else if (!bannerImage) {
      navToElementMarker(bannerImgRef, "Banner is Required!");
    } else if (!faviconImage) {
      navToElementMarker(faviconImgRef, `Favicon is Required!`);
    } else if (!eventName) {
      navToElementMarker(eventNameRef, `Event Name is Required!`);
    } else if (!subdomain) {
      navToElementMarker(subdomainRef, `Subdomain is Required!`);
    } else if (!sessionLifetime) {
      navToElementMarker(sessionLifetimeRef, `Session Lifetime is Required!`);
    } else if (!country) {
      navToElementMarker(countryRef, `Country is Required!`);
    } else if (!country) {
      navToElementMarker(countryRef, `Country is Required!`);
    } else if (!venue) {
      navToElementMarker(venueRef, `Venue is Required!`);
    } else if (!dateFrom) {
      navToElementMarker(dateFromRef, `Date From is Required!`);
    } else if (!dateTo) {
      navToElementMarker(dateToRef, `Date To is Required!`);
    } else {
      const fD = new FormData();
      fD.append("logo", getBlobFromBase64(logoImage));
      fD.append("banner", getBlobFromBase64(bannerImage));
      fD.append("favicon", getBlobFromBase64(faviconImage));
      fD.append("name", eventName);
      fD.append("subdomain", subdomain);
      fD.append("orgdomain", orgdomain);
      fD.append("externalDomain", externalDomain);
      fD.append("sessionLifetime", sessionLifetime);

      fD.append("country", JSON.stringify(country));
      fD.append("city", JSON.stringify(city));
      fD.append("venue", venue);
      fD.append("phoneCode", JSON.stringify(phoneCode));
      fD.append("dateFrom", dateFrom);
      fD.append("dateTo", dateTo);
      fD.append("privateEvent", privateEvent);
      fD.append("communityEvent", communityEvent);

      try {
        const response = await axios.post(`${API_ENDPOINT}/add-event`);
        const data = response.data;
      } catch (error) {
        toastError(error);
      }

      // toast.success("Added Successfully");
      // navigate("/org-admin-dash");
    }
  };

  return (
    <Box>
      <Typography fontSize={24} fontWeight={600}>
        Add Event
      </Typography>
      <Grid container spacing={3} mt={1}>
        {/* First column for upload buttons */}
        <Grid item xs={12} md={4}>
          <AddImage
            ref={logoImgRef}
            sizeLimit={750}
            aspect_ratio={2}
            type="Logo*"
            image={logoImage}
            setImage={setLogoImage}
          />
          <AddImage
            ref={bannerImgRef}
            sizeLimit={900}
            aspect_ratio={3}
            type="Banner*"
            image={bannerImage}
            setImage={setBannerImage}
          />
          <AddImage
            ref={faviconImgRef}
            sizeLimit={50}
            aspect_ratio={1}
            type="Favicon*"
            image={faviconImage}
            setImage={setFaviconImage}
          />
        </Grid>

        {/* Second column for event name, subdomain, session lifetime, and private event toggle */}
        <Grid item xs={12} md={4}>
          <TextField
            inputRef={eventNameRef}
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            fullWidth
            label="Event Name*"
            variant="outlined"
          />
          <Box display={"flex"} mt={2} gap={2}>
            <TextField
              inputRef={subdomainRef}
              value={subdomain}
              onChange={onSubdomainChange}
              helperText={`${remainingChars} chars left`}
              label="Event Subdomain*"
              variant="outlined"
            />
            <TextField
              value={orgdomain}
              fullWidth
              disabled
              label="Suffixed Org Subdomain"
              variant="outlined"
            />
          </Box>
          <Box mt={2}>
            <TextFieldWithTooltip
              ref={externalDomainRef}
              type="text"
              label="External Domain"
              value={externalDomain}
              onChange={(e) => {
                setExternalDomain(e.target.value);
              }}
              tip="Please enter the domain name only (e.g. expoplanner.in)"
            />
          </Box>
          <TextField
            inputRef={sessionLifetimeRef}
            fullWidth
            value={sessionLifetime}
            onChange={handleSessionLifetimeChange}
            label="Session Lifetime (sec.)*"
            variant="outlined"
            style={{ marginTop: 16 }}
            type="number"
          />
          <FormControlLabel
            ref={privateEventRef}
            control={
              <Switch
                checked={privateEvent}
                onChange={() => setPrivateEvent((prev) => !prev)}
                name="privateEvent"
              />
            }
            label="Private Event (by invitation only)"
            style={{ marginTop: 16 }}
          />
        </Grid>

        {/* Third column for country, city, address, venue, default phone code, and date pickers */}
        <Grid item xs={12} md={4}>
          <CountryAutoComplete
            ref={countryRef}
            label="Country*"
            type="country"
            value={country}
            onChange={onChangeCountry}
          />
          <Box mt={2}>
            <CountryCitiesAuto
              ref={cityRef}
              value={city}
              onChange={onChangeCity}
              ccode={country?.isoCode}
            />
          </Box>
          <TextField
            inputRef={venueRef}
            value={venue}
            onChange={(e) => setVenue(e.target.value)}
            sx={{ mt: 2 }}
            fullWidth
            label="Venue*"
            variant="outlined"
          />
          <Box mt={2}>
            <CountryAutoComplete
              ref={phoneCodeRef}
              type="phonecode"
              value={phoneCode}
              onChange={onChangePhoneCode}
              label="Default Phone Code"
            />
          </Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box mt={2} display={"flex"} gap={2}>
              <DateTimePicker
                inputRef={dateFromRef}
                sx={{ flex: 1 }}
                value={dateFrom}
                onChange={onDateFromChange}
                slotProps={{
                  textField: {
                    helperText: "dd/mm/yyyy hh:mm",
                  },
                }}
                format="DD/MM/YYYY hh:mm a"
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                label="Date From*"
                size="small"
                variant="outlined"
              />

              <DateTimePicker
                inputRef={dateToRef}
                sx={{ flex: 1 }}
                slotProps={{
                  textField: {
                    helperText: "dd/mm/yyyy hh:mm",
                  },
                }}
                value={dateTo}
                onChange={onDateToChange}
                format="DD/MM/YYYY hh:mm a"
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                label="Date To*"
                size="small"
                variant="outlined"
              />
            </Box>
          </LocalizationProvider>
          <FormControlLabel
            inputRef={communityEventRef}
            control={
              <Switch
                name="communityEvent"
                checked={communityEvent}
                onChange={() => setCommunityEvent((prev) => !prev)}
              />
            }
            label="Community Event"
            style={{ marginTop: 16 }}
          />
        </Grid>

        {/* Submit button at the end */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={onSave}
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
            >
              Save Event
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEvent;
