// Import necessary modules and components
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Button,
  IconButton,
  Grid,
  Box,
  CardActionArea,
  Stack,
  LinearProgress,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import BackBtn from "../admin/widgets/BackBtn";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import HeaderLabel from "../../../widgets/HeaderLabel";
import SimpleYesNoDialog from "../../../widgets/SimpleYesNoDialog";
import CustomCircularP from "../../../widgets/CustomCircularP";
import { toastError } from "../../../utils2024/utils";
import axios from "axios";
import { getHeader } from "../../../utility/utility";
import { API_ENDPOINT } from "../visitor/registration/myconst";
import { toast } from "react-toastify";
const useStyles = makeStyles({
  card: {
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-8px)",
      boxShadow: "0 16px 32px rgba(0, 0, 0, 0.15)",
    },
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1, // Allow card to grow and fill the grid item
    overflow: "hidden", // Ensures content doesn't overflow the rounded corners
    background: "linear-gradient(180deg, #ffffff 70%, #f1f1f1 100%)", // Smooth gradient transition from content to action area
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    width: 80,
    height: 80,
  },
  content: {
    textAlign: "center",
    flexGrow: 1, // Allow content to fill the card
    padding: "16px",
    backgroundColor: "transparent", // Maintain transparency to see the gradient
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  actions: {
    justifyContent: "center",
    backgroundColor: "#e0e0e0", // Slightly darker background for the action area
    boxShadow: "inset 0px 1px 5px rgba(0, 0, 0, 0.1)", // Slight shadow to distinguish the action area
  },
  addButton: {
    marginBottom: 20,
    borderRadius: 8,
  },
});
const TeamMembers = ({ mode = "edit", gc = 4, internal = false }) => {
  const [reload, setReload] = useState(false);
  const {
    data: membersData,
    isLoading: istmLoading,
    isError: istmError,
  } = useCommonGetInit(`user/get-team-members?reload=${reload}`);
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentTm, setCurrentTm] = useState();

  const classes = useStyles();
  const mdSize = gc === 4 ? 3 : 4;

  return (
    <Box p={2} mb={2}>
      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to delete this member?`}
        onYes={async () => {
          try {
            setShowDelete(false);
            setDeleting(true);
            const { data } = await axios.delete(
              `${API_ENDPOINT}user/delete-team-member?id=${currentTm?._id}`,
              getHeader()
            );
            toast.success(data.message);
            setReload((prev) => !prev);
          } catch (error) {
            toastError(error);
          } finally {
            setDeleting(false);
          }
        }}
        show={showDelete}
        setShow={setShowDelete}
      />
      <CustomCircularP show={deleting} />
      <Box>
        {istmLoading && <LinearProgress />}
        {!internal && <BackBtn />}
        <Box mb={1}>
          <Stack direction={"row"}>
            <HeaderLabel
              onEdit={null}
              showEdit={false}
              label={"Team Members"}
            />
            <Box flex={1}></Box>

            <Link to={`/exhibitor/add-team-member`}>
              <Button
                startIcon={<AddIcon fontSize="small" />}
                variant="outlined"
                size="small"
                color="primary"
              >
                Add Member
              </Button>
            </Link>
          </Stack>
        </Box>

        {/* Grid container for responsive layout */}
        <Grid container spacing={3}>
          {membersData?.data?.map((member, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={mdSize}
              key={index}
              className={classes.gridItem}
            >
              <Card className={classes.card} elevation={3}>
                <CardActionArea
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding={2}
                    style={{ flexGrow: 1 }}
                  >
                    <Avatar
                      src={member.profile_image}
                      alt={`${member.firstName} ${member.lastName}`}
                      className={classes.avatar}
                    >
                      {!member.profile_image &&
                        `${member.firstName[0]}${member.lastName[0]}`}
                    </Avatar>
                    {/* Member Info */}
                    <CardContent className={classes.content}>
                      <Typography variant="body1" className={classes.name}>
                        {member.firstName} {member.lastName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {member.designation}
                      </Typography>
                    </CardContent>
                  </Box>
                </CardActionArea>

                {/* Edit and Delete Buttons */}
                {mode === "edit" && (
                  <CardActions className={classes.actions}>
                    <Link to={`/exhibitor/edit-team-member/${member._id}`}>
                      <IconButton size="small" aria-label="edit">
                        <Edit fontSize="small" />
                      </IconButton>
                    </Link>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => {
                        setShowDelete(true);
                        setCurrentTm(member);
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </CardActions>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

// Export the component
export default TeamMembers;
