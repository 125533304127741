import React, { useState } from "react";
import "../../components/login/login.css";
import {
  Alert,
  Box,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { ADMIN, API_ENDPOINT, LOGIN_BANNER } from "../../constants/constants";
import { LoadingButton } from "@mui/lab";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RdMyModal from "../../widgets/myModal/RdMyModal";
import PasswordReset from "../../components/login/PasswordReset";
import axios from "axios";
import { getDbHeader, toastError } from "../../utils2024/utils";
import { useNavigate } from "react-router-dom";
import { saveAdmin, saveUser } from "../../utility/utility";
const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [isLoggingIn, setIsLoggingIn] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberChecked, setRememberChecked] = useState(false);
  const onLogin = async () => {
    if (username === "") {
      toast.error("Enter username");
    } else if (password === "") {
      toast.error("Enter password");
    } else {
      try {
        setIsLoggingIn(true);
        const { data } = await axios.post(
          `${API_ENDPOINT}user/login`,
          { username, password },
          getDbHeader()
        );
        if (data.data.role === ADMIN) {
          saveAdmin(data.data);
        } else {
          saveUser(data.data);
        }
        navigate(`/${data.data.role}`);
      } catch (error) {
        setError(error?.response?.data);
      } finally {
        setIsLoggingIn(false);
      }
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const rememberPwd = () => {
    setRememberChecked((prev) => !prev);
  };
  const handleForgotPwd = () => {
    setShowPasswordReset(true);
  };
  return (
    <div className="expp-login-ca">
      <RdMyModal
        mWidth={"500px"}
        showModal={showPasswordReset}
        setShowModal={setShowPasswordReset}
        modalC={
          <PasswordReset
            show={showPasswordReset}
            setShow={setShowPasswordReset}
          />
        }
      />
      <div className="login-box">
        <Card
          sx={{
            borderRadius: 0,
            backgroundImage: `url(${LOGIN_BANNER})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="login-flex-left"
        ></Card>
        <div className="login-flex-right">
          <div className="login-action-box">
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  Welcome to
                </Box>
                <img
                  src={
                    "https://s3.ap-south-1.amazonaws.com/expoplanner.in/download.png"
                  }
                  alt=""
                />
              </Box>
            </div>
            {error && <Alert severity="error">{error.message}</Alert>}
            <div style={{ marginTop: "28px" }}>
              <TextField
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                variant="outlined"
                size="small"
                label="Username"
                fullWidth
              />
              <FormControl
                variant="outlined"
                size="small"
                required
                fullWidth
                sx={{ marginTop: 3 }}
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <div style={{ marginTop: "28px" }}>
                <div style={{ display: "flex" }}>
                  <FormControlLabel
                    sx={{
                      flex: 1,
                      color: "#777",
                      "& .MuiTypography-root": {
                        fontSize: "15px",
                      },
                      "& .MuiCheckbox-root": {
                        "& .MuiSvgIcon-root": {
                          fontSize: "18px", // Adjust the checkbox icon size
                        },
                      },
                    }}
                    label="Remember Password"
                    control={
                      <Checkbox
                        checked={rememberChecked}
                        onChange={rememberPwd}
                      />
                    }
                  />
                  <div
                    style={{
                      textDecoration: "underline",
                      fontSize: "15px",
                      alignSelf: "center",
                      display: "flex",
                      justifyContent: "end",
                      cursor: "pointer",
                      color: "#888",
                    }}
                    onClick={handleForgotPwd}
                  >
                    Forgot your password?
                  </div>
                </div>

                <div style={{ marginTop: "16px" }}>
                  <LoadingButton
                    onClick={onLogin}
                    loading={isLoggingIn}
                    fullWidth
                    sx={{
                      backgroundColor: "#054054",
                      "&:hover": {
                        backgroundColor: "#0B6B6B", // A lighter shade of teal for hover
                      },
                    }}
                    variant="contained"
                  >
                    Sign in
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
