import { Cropper } from "react-cropper";
import { useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Grid, Button, Typography, Input, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getMultipartHeader, roundToTwoDecimals } from "../utility/utility";
import { toastError } from "../utils2024/utils";
export const MAP_IMAGE = "map_image";
export const HALL_IMAGE = "hall_image";
function UploadImageForm({ url, sizeLimit, aspect_ratio = 3 }) {
  const cropperRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(null);

  const onInputChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const cancelCrop = () => {
    document.location.reload();
  };
  const uploadImage = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const canvas = cropper.getCroppedCanvas();
    const blob = await getCanvasBlob(canvas, "image/png");
    const sizeInKB = blob.size / 1024;
    if (sizeInKB > sizeLimit) {
      toast.error(
        `Image size is more than ${roundToTwoDecimals(
          sizeLimit / 1024
        )}MB. Please choose a smaller image. Your size is ${roundToTwoDecimals(
          sizeInKB / 1024
        )}MB`
      );
      return;
    }
    let formData = new FormData();
    formData.append("image", blob, "myimage.png");
    setUploading(true);
    try {
      const { data } = await axios.post(url, formData, getMultipartHeader());
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 500);
    } catch (error) {
      toastError(error);
    } finally {
      setUploading(false);
    }
  };
  const getCanvasBlob = (canvas, type) =>
    new Promise((resolve) => canvas.toBlob(resolve, type));
  return (
    <Box sx={{ height: "70vh", position: "relative" }}>
      <Box
        sx={{
          display: "table",
          color: "#777",
          fontSize: "14px",
          padding: "8px",
          border: "1px dotted #ddd",
          mb: 1,
        }}
      >
        <Input
          type="file"
          inputProps={{ accept: "image/jpeg, image/png, image/jpg" }}
          onChange={onInputChange}
        />
        <Typography variant="body2">
          Apect Ration: {aspect_ratio}:1 | Max size:{" "}
          {roundToTwoDecimals(sizeLimit / 1024)}MB
        </Typography>
        <Typography variant="body2">
          Supported Formats: PNG, JPEG, JPG
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Cropper
            key={aspect_ratio}
            src={image}
            style={{ height: 300, width: "100%" }}
            viewMode={1}
            aspectRatio={aspect_ratio}
            preview=".img-preview"
            ref={cropperRef}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography align="center">Preview</Typography>
          <div
            className="img-preview"
            style={{
              width: "100%",
              height: "172px",
              overflow: "hidden",
              display: "inline-block",
            }}
          ></div>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: "absolute",
          bottom: 2,
          display: "flex",
          right: 2,
          gap: 4,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={cancelCrop}
        >
          Cancel
        </Button>
        <LoadingButton
          size="small"
          loading={uploading}
          variant="contained"
          color="primary"
          onClick={uploadImage}
        >
          Upload
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default UploadImageForm;
