import React from "react";
import { Stack, Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const HeaderLabel = ({ label, onEdit, labelStyle = {}, showEdit = true }) => {
  return (
    <Stack direction="row" mt={1} mb={1} alignItems="center">
      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          color: "#185399",
          flex: 1,
          ...labelStyle,
        }}
        noWrap
      >
        {label}
      </Typography>
      {showEdit && (
        <IconButton color="primary" onClick={onEdit} aria-label="edit">
          <EditIcon fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
};

export default HeaderLabel;
