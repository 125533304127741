import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
const SearchBar = ({
  label,
  onSearch,
  size = "10px",
  boxAlign = "block",
  mystyle = {},
  cstyle = {},
}) => {
  return (
    <div style={{ alignSelf: "center", display: boxAlign, ...cstyle }}>
      <Paper
        sx={{
          padding: "2px 4px 2px 10px",
          backgroundColor: "#f4f4f4",
          display: "flex",
          alignItems: "center",
          ...mystyle,
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={label}
          onChange={onSearch}
          inputProps={{ "aria-label": label }}
        />
        <IconButton type="submit" sx={{ p: size }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    </div>
  );
};
export default SearchBar;
