import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  TextField,
  Typography,
  Select,
  MenuItem,
  Container,
  Paper,
  Grid,
  InputLabel,
  FormControl,
  Divider,
  Alert,
  LinearProgress,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import { styled } from "@mui/system";
import {
  API_ENDPOINT,
  DEFAULT_DP,
  ICON_TYPE_NON_IMAGE,
} from "../../../../constants/constants";
import EditIcon from "@mui/icons-material/Edit";
import RdIconContainer from "../../../../widgets/RdIconContainer";
import MuPb from "../../../../widgets/MuPb";
import { TITLES, getHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import ProfileCropEdit from "../profile/ProfileCropEdit";
import { toastError } from "../../../../utils2024/utils";
import { UserContext } from "../../../context/UserContext";
import ProductCategoryWidget from "../../common/ProductCategoryWidget";
import CountrySelect from "../../../../widgets/CountrySelector";
import StateSelector from "../../../../widgets/StateSelector";
import CitySelector from "../../../../widgets/CitySelector";
import { MySelectedProductCats } from "../profile/MySelectedProductCats";
import SelectSupplierDetail from "../widgets/SelectSupplierDetail";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
const StyledTabs = styled(Tabs)({
  backgroundColor: "#054054", // Base color set to #054054
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  flexGrow: 1,
  ".MuiTabs-indicator": {
    backgroundColor: "#ff9800", // Tab indicator color (orange for contrast)
    height: "6px", // Increased height of the tab indicator (adjust as needed)
  },
});

const StyledTab = styled(Tab)({
  flex: 1,
  color: "white",
  "&.Mui-selected": {
    color: "white",
  },
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
});
const ExhibitionCatalogue = () => {
  const formName = "Exhibition Catalogue";
  const [reload, setReload] = useState(false);
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-oes-submit-by-name?id=${formName}&reload=${reload}`,
    0
  );
  const [tabValue, setTabValue] = useState(0);
  const { user, loading } = useContext(UserContext);
  const [categories, setCategories] = useState();
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    companyShortName: "",
    profile_image: "",
    fascia: "",
    weAre: "",
    phone: "",
    fax: "",
    website: "",
    companyEmail: "",
    state: "",
    city: "",
    country: "",
    pin: "",
    address1: "",
    address2: "",
    address3: "",

    contactSalutation: "",
    contactFirstName: "",
    contactLastName: "",
    contactDesignation: "",
    mobile: "",
    contactEmail: "",
  });
  const [saving, setSaving] = useState(false);
  const [supplier, setSupplier] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(false);
  useEffect(() => {
    if (user) {
      let companyO = {
        companyName: user.companyName.value,
        companyShortName: user.companyShortName.value,
        fascia: user.fascia.value,
        weAre: user.weAre.value,
        phone: user.phone.value,
        fax: user.fax.value,
        website: user.website.value,
        companyEmail: user.companyEmail.value,
        country: user.country?.value,
        state: user.state?.value,
        city: user.city?.value,
        pin: user.pin.value,
        address1: user.address1.value,
        address2: user.address2.value,
        address3: user.address3.value,

        contactSalutation: user.contactSalutation.value,
        contactFirstName: user.contactFirstName.value,
        contactLastName: user.contactLastName.value,
        contactDesignation: user.contactDesignation.value,
        mobile: user.mobile.value,
        contactEmail: user.contactEmail.value,
      };
      setCategories(user.productCategories.value);
      setCompanyInfo(companyO);
    }
  }, [user]);

  useEffect(() => {
    if (data) {
      setSupplier(data.supplier);
    }
  }, [data]);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  if (loading) {
    return <MuPb />;
  }
  const updateProperty = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };
  const updatePropertyNamed = (property, value) => {
    setCompanyInfo({ ...companyInfo, [property]: value });
  };

  const updateCompanyInfo = async () => {
    if (!categories || (categories && categories.length === 0)) {
      toast.error("Please select categories");
      return;
    }
    const toUpdateObject = {
      companyName: {
        label: user.companyName.label,
        value: companyInfo.companyName,
      },
      companyShortName: {
        label: user.companyShortName.label,
        value: companyInfo.companyShortName,
      },
      fascia: {
        label: user.fascia.label,
        value: companyInfo.fascia,
      },
      weAre: {
        label: user.weAre.label,
        value: companyInfo.weAre,
      },
      phone: {
        label: user.phone.label,
        value: companyInfo.phone,
      },
      fax: {
        label: user.fax.label,
        value: companyInfo.fax,
      },
      website: {
        label: user.website.label,
        value: companyInfo.website,
      },
      companyEmail: {
        label: user.companyEmail.label,
        value: companyInfo.companyEmail,
      },
      country: {
        label: user.country.label,
        value: companyInfo.country,
      },
      state: {
        label: user.state.label,
        value: companyInfo.state,
      },
      city: {
        label: user.city.label,
        value: companyInfo.city,
      },
      pin: {
        label: user.pin.label,
        value: companyInfo.pin,
      },
      address1: {
        label: user.address1.label,
        value: companyInfo.address1,
      },
      address2: {
        label: user.address2.label,
        value: companyInfo.address2,
      },
      address3: {
        label: user.address3.label,
        value: companyInfo.address3,
      },

      contactSalutation: {
        label: user.contactSalutation.label,
        value: companyInfo.contactSalutation,
      },
      contactFirstName: {
        label: user.contactFirstName.label,
        value: companyInfo.contactFirstName,
      },
      contactLastName: {
        label: user.contactLastName.label,
        value: companyInfo.contactLastName,
      },
      contactDesignation: {
        label: user.contactDesignation.label,
        value: companyInfo.contactDesignation,
      },
      mobile: {
        label: user.mobile.label,
        value: companyInfo.mobile,
      },
      contactEmail: {
        label: user.contactEmail.label,
        value: companyInfo.contactEmail,
      },
      productCategories: {
        label: user.productCategories.label,
        value: categories,
      },
    };

    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}user/update-user`,
        toUpdateObject,
        getHeader()
      );
      setSaving(false);
      toast.success(data.message);
    } catch (error) {
      toastError(error);
    } finally {
      setSaving(false);
    }
  };
  const onEditProfileClick = () => {
    setShowImageUpload(true);
  };
  const onUpdated = () => {
    //refetchExhibitorProfile();
  };

  const onSubmit = async () => {
    if (!categories || (categories && categories.length === 0)) {
      toast.error("Please select categories");
      return;
    }

    const toSubmitData = {
      name: formName,
      user: {
        ...companyInfo,
        productCategories: categories,
        profile_image: user.profile_image,
      },
      supplier,
    };

    try {
      setSubmitting(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}user/submit-oes`,
        toSubmitData,
        getHeader()
      );

      toast.success(data.message);
      setReload((prev) => !prev);
    } catch (error) {
      toastError(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <RdMyModal
        showModal={showImageUpload}
        setShowModal={setShowImageUpload}
        modalC={
          <ProfileCropEdit
            show={showImageUpload}
            setShow={setShowImageUpload}
            upload_type="profile_image"
            onUpdated={onUpdated}
          />
        }
      />
      {data?.supplier && (
        <Alert severity="success">
          You submitted this form on{" "}
          {new Date(data.updatedOn).toLocaleDateString("en-IN")}
        </Alert>
      )}
      {isLoading && <LinearProgress />}
      {!isLoading && !data && (
        <Alert severity="warning">You have not submitted this form yet.</Alert>
      )}
      <Paper sx={{ padding: "2rem", mb: 2 }}>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
        >
          <StyledTab label="Company Details" />
          <StyledTab label="Contact Details" />
        </StyledTabs>
        {tabValue === 0 && (
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  name="companyName"
                  label={user.companyName.label}
                  value={companyInfo.companyName}
                  onChange={updateProperty}
                  size="small"
                  sx={{ mt: 1 }}
                />
                <TextField
                  fullWidth
                  label={user.fascia.label}
                  value={companyInfo.fascia}
                  name="fascia"
                  onChange={updateProperty}
                  size="small"
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  label={user.companyShortName.label}
                  value={companyInfo.companyShortName}
                  onChange={updateProperty}
                  size="small"
                  name="companyShortName"
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  label={user.address1.label}
                  value={companyInfo.address1}
                  name="address1"
                  onChange={updateProperty}
                  size="small"
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  name="address2"
                  label={user.address2.label}
                  value={companyInfo.address2}
                  onChange={updateProperty}
                  size="small"
                  sx={{ mt: 2 }}
                />
                <TextField
                  name="address3"
                  fullWidth
                  label={user.address3.label}
                  value={companyInfo.address3}
                  onChange={updateProperty}
                  size="small"
                  sx={{ mt: 2, mb: 2 }}
                />

                <CountrySelect
                  selectedCountry={companyInfo.country || null}
                  onChange={(newV) => {
                    setCompanyInfo({ ...companyInfo, country: newV });
                  }}
                />

                <Box mt={2}>
                  <StateSelector
                    countryCode={companyInfo.country?.code}
                    selectedState={companyInfo.state || null}
                    onChange={(newV) => {
                      setCompanyInfo({ ...companyInfo, state: newV });
                    }}
                  />
                </Box>
                <Box mt={2}>
                  <CitySelector
                    selectedCity={companyInfo.city || null}
                    onChange={(newV) => {
                      setCompanyInfo({ ...companyInfo, city: newV });
                    }}
                    countryCode={companyInfo.country?.code || null}
                    stateCode={companyInfo.state?.code || null}
                  />
                </Box>

                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Product Categories
                  </Typography>
                  <Typography variant="caption">
                    * Maximum 15 selections allowed
                  </Typography>
                  {categories && (
                    <ProductCategoryWidget
                      currentSelectedList={categories}
                      onChange={(newList) => {
                        setCategories(newList);
                      }}
                      maxSelectionAllowed={15}
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 1.7, mb: 2, textAlign: "center" }}>
                  <div
                    style={{
                      position: "relative",
                      display: "table",
                      margin: "auto",
                    }}
                  >
                    <img
                      src={user.profile_image ? user.profile_image : DEFAULT_DP}
                      alt="profile"
                      style={{
                        width: "90px",
                        height: "90px",
                        borderRadius: "50%",
                        // border: "1px solid #b68337",
                        cursor: "pointer",
                      }}
                    />

                    <RdIconContainer
                      icon={{
                        icon: <EditIcon sx={{ color: "#777" }} />,
                        type: ICON_TYPE_NON_IMAGE,
                        desc: "edit icon",
                        show: true,
                      }}
                      bg="transparent"
                      myStyle={{ position: "absolute", top: 0, right: "-36px" }}
                      onClick={onEditProfileClick}
                    />
                  </div>
                  <Box>
                    <Typography variant="subtitle1">Company Logo</Typography>
                    <Typography variant="body2">
                      Supported formats: PNG, JPG, JPEG
                    </Typography>
                    <Typography variant="body2">Aspect Ratio: 1:1</Typography>
                  </Box>
                </Box>
                <TextField
                  fullWidth
                  name="pin"
                  label={user.pin.label}
                  value={companyInfo.pin}
                  onChange={updateProperty}
                  size="small"
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  name="phone"
                  label={user.phone.label}
                  value={companyInfo.phone}
                  onChange={updateProperty}
                  size="small"
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  required
                  name="companyEmail"
                  label={user.companyEmail.label}
                  value={companyInfo.companyEmail}
                  onChange={updateProperty}
                  size="small"
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  name="fax"
                  label={user.fax.label}
                  value={companyInfo.fax}
                  onChange={updateProperty}
                  size="small"
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  name="website"
                  label={user.website.label}
                  value={companyInfo.website}
                  onChange={updateProperty}
                  size="small"
                  sx={{ mt: 2 }}
                />
                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Selected Categories
                  </Typography>

                  <MySelectedProductCats items={categories || []} />
                </Box>
              </Grid>
            </Grid>
            <Box mt={3} display="flex" justifyContent="center">
              <LoadingButton
                loading={saving}
                onClick={updateCompanyInfo}
                variant="contained"
                color="primary"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        )}
        {tabValue === 1 && (
          <Box mt={3} maxWidth={500}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                {user.contactSalutation.label}*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={companyInfo.contactSalutation}
                name="contactSalutation"
                label={user.contactSalutation.label}
                onChange={updateProperty}
              >
                {TITLES.map((position, i) => (
                  <MenuItem key={i} value={position}>
                    {position}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              size="small"
              label={user.contactFirstName.label}
              name="contactFirstName"
              value={companyInfo.contactFirstName}
              onChange={updateProperty}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              size="small"
              label={user.contactLastName.label}
              name="contactLastName"
              value={companyInfo.contactLastName}
              onChange={updateProperty}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              size="small"
              label={user.contactDesignation.label}
              name="contactDesignation"
              value={companyInfo.contactDesignation}
              onChange={updateProperty}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              size="small"
              label={user.mobile.label}
              name="mobile"
              value={companyInfo.mobile}
              onChange={updateProperty}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              size="small"
              label={user.contactEmail.label}
              name="contactEmail"
              value={companyInfo.contactEmail}
              onChange={updateProperty}
            />
            <Box mt={3} display="flex" justifyContent="center">
              <LoadingButton
                loading={saving}
                onClick={updateCompanyInfo}
                variant="contained"
                color="primary"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        )}
        <br />
        <Divider /> <br />
        <SelectSupplierDetail
          selectedSupplier={supplier}
          supplierList={[
            {
              _id: "670374306c8d0e58f8832b3c",
              supplierName: "Indian Machine Tool Manufacturers' Association",
              address:
                "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India",
              contactPerson: "Mr. Christopher A | Mr. Prasant Panda",
              emails: ["info@imtex.in"],
              phoneNumber: "+91-80-66246600",
              createdOn: "2024-10-07T05:40:00.539Z",
              updatedOn: "2024-10-07T05:40:20.613Z",
            },
          ]}
          onChange={(newSup) => {
            setSupplier(newSup);
          }}
        />
        <Box mt={2}>
          <LoadingButton
            disabled={!supplier}
            loading={submitting}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            style={{ marginRight: "10px" }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default ExhibitionCatalogue;
