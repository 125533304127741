import React, { createContext, useEffect, useState } from "react";
import BackBtn from "../../widgets/BackBtn";
import FormEditor from "./FormEditor";
import { RegistrationSettingsProvider } from "./context/RegistrationSettingsContext";
import FormPreview from "./preview/FormPreview";

const RegistrationSettings = () => {
  return (
    <RegistrationSettingsProvider>
      <div style={{ marginTop: "10px" }}>
        <BackBtn />
        <div style={{ display: "flex" }}>
          <div style={{ flex: 0.3, maxWidth: "700px" }}>
            <FormEditor />
          </div>
          <div style={{ flex: 0.7 }}></div>
        </div>
      </div>
    </RegistrationSettingsProvider>
  );
};

export default RegistrationSettings;
