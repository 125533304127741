import React from "react";
import MadeFloor from "./MadeFloor";

const ShowHall = () => {
  const stalls = [
    {
      id: 1,
      name: "Stall 1",
      floorId: 1,
      position: { x: 50, y: 50 },
      size: { width: 100, height: 50 },
      company: {
        name: "Tech Corp",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/M%C3%BCnster%2C_LVM%2C_B%C3%BCrogeb%C3%A4ude_--_2013_--_5149-51.jpg/800px-M%C3%BCnster%2C_LVM%2C_B%C3%BCrogeb%C3%A4ude_--_2013_--_5149-51.jpg",
        website: "https://techcorp.com",
      },
      description:
        "Tech Corp specializes in software solutions for businesses. Visit our stall to learn more!",
    },
    {
      id: 2,
      name: "Stall 2",
      floorId: 1,
      position: { x: 200, y: 50 },
      size: { width: 100, height: 50 },
      company: {
        name: "Green Energies",
        logo: "https://blog.ipleaders.in/wp-content/uploads/2019/07/hands-1063442_960_720.jpg",
        website: "https://greenenergies.com",
      },
      description:
        "Green Energies provides sustainable energy solutions for the future. Check out our latest products!",
    },
    {
      id: 3,
      name: "Stall 3",
      floorId: 1,
      position: { x: 50, y: 150 },
      size: { width: 100, height: 50 },
      company: {
        name: "Mobile Masters",
        logo: "https://bsmedia.business-standard.com/_media/bs/img/article/2019-06/05/full/1559674671-2023.jpg",
        website: "https://mobilemasters.com",
      },
      description:
        "Mobile Masters offers cutting-edge mobile devices and accessories. Drop by for exclusive deals!",
    },
    {
      id: 4,
      name: "Stall 4",
      floorId: 1,
      position: { x: 200, y: 150 },
      size: { width: 100, height: 50 },
      company: {
        name: "Book Haven",
        logo: "https://bsmedia.business-standard.com/_media/bs/img/article/2019-06/05/full/1559674671-2023.jpg",
        website: "https://bookhaven.com",
      },
      description:
        "Book Haven is your go-to place for best-selling books and literary treasures. Visit us now!",
    },
    {
      id: 5,
      name: "Stall 5",
      floorId: 1,
      position: { x: 350, y: 150 },
      size: { width: 100, height: 50 },
      company: {
        name: "FitLife Gym",
        logo: "https://bsmedia.business-standard.com/_media/bs/img/article/2019-06/05/full/1559674671-2023.jpg",
        website: "https://fitlife.com",
      },
      description:
        "FitLife Gym offers state-of-the-art equipment and personalized training programs. Transform your fitness journey with us!",
    },
  ];

  const floorImageUrl =
    "https://www.homeplansindia.com/uploads/1/8/8/6/18862562/hfp-4004_orig.jpg";

  return <MadeFloor stalls={stalls} imageUrl={floorImageUrl} />;
};

export default ShowHall;
