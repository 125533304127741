import React from "react";

import ProductItemAction from "./actionBtn/productItemAction";

const BrochureItem = ({
  item,
  onEdit,
  onDelete,
  onSelect,
  viewMode = false,
}) => {
  return (
    <div className="product-item" onClick={() => onSelect(item)}>
      <img src={item.preview} alt="" className="brochure-item-img" />
      <div className="product-item-name">{item.title}</div>
      {!viewMode && (
        <ProductItemAction
          onEdit={() => onEdit(item)}
          onDelete={() => onDelete(item)}
        />
      )}
    </div>
  );
};

export default BrochureItem;
