import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Avatar,
  Button,
  Chip,
  Divider,
  Alert,
} from "@mui/material";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import UpdateIcon from "@mui/icons-material/Update";
import { Link, useParams } from "react-router-dom";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import { getErrorResponse, toastError } from "../../../../utils2024/utils";
import MuPb from "../../../../widgets/MuPb";
import { UserContext } from "../../../context/UserContext";
import BackBtn from "../../admin/widgets/BackBtn";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
import { getHeader, getUser } from "../../../../utility/utility";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import axios from "axios";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { API_ENDPOINT } from "../../../../constants/constants";

const MeetingItem = () => {
  const { id } = useParams();
  const { user, refresh } = useContext(UserContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [updating, setUpdating] = useState(false);
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-meeting?id=${id}`
  );

  if (isLoading || !user) {
    return <MuPb />;
  }

  if (!data) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }

  const {
    subject,
    selectedDate,
    duration,
    meeting_type,
    status,
    selectedMembers,
    selectedOtherMembers,
    message,
    recipient_id,
  } = data;

  const updateStatus = async (status) => {
    try {
      setUpdating(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}user/update-meeting?id=${id}`,
        { status: status === "accept" ? "accepted" : "cancelled" },
        getHeader()
      );
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 400);
    } catch (error) {
      toastError(error);
    } finally {
      setUpdating(false);
    }
  };
  const onYesUpdate = () => {
    setShowConfirmation(false);
    updateStatus(currentStatus);
  };

  return (
    <Box>
      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to ${
          currentStatus === "accept" ? "accept" : "cancel"
        } this?`}
        onYes={onYesUpdate}
        show={showConfirmation}
        setShow={setShowConfirmation}
      />
      <BackBtn />
      <Box
        sx={{
          maxWidth: 700,
          mx: "auto",
          mt: 3,
          p: 3,
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
          {subject}
        </Typography>
        <Chip
          label={status}
          color={
            status === "requested"
              ? "info"
              : status === "accepted"
              ? "success"
              : "error"
          }
          sx={{ mb: 2 }}
        />

        <Stack spacing={1} sx={{ color: "#777" }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <CalendarTodayIcon fontSize="small" />
            <Typography variant="body2">
              {new Date(selectedDate).toLocaleDateString()} |{" "}
              {new Date(selectedDate).toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <AlarmOnIcon fontSize="small" />
            <Typography variant="body2">{duration} mins</Typography>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <AlignVerticalBottomIcon fontSize="small" />
            <Typography variant="body2">{meeting_type}</Typography>
          </Stack>

          <Divider sx={{ my: 2 }} />

          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Message
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {message || "No additional message"}
          </Typography>

          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Participants
          </Typography>
          <Stack spacing={2} sx={{ my: 2 }}>
            {[...selectedMembers, ...selectedOtherMembers].map(
              (member, index) => (
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                >
                  <Avatar alt={member.name} src={member.profile_image} />
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {member.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {member.email}
                    </Typography>
                  </Box>
                </Stack>
              )
            )}
          </Stack>
        </Stack>

        <Divider sx={{ my: 2 }} />

        {data.status === "requested" ? (
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            {user.username === recipient_id ? (
              <>
                <LoadingButton
                  variant="contained"
                  color="success"
                  loading={currentStatus === "accept" && updating}
                  onClick={() => {
                    setCurrentStatus("accept");
                    setShowConfirmation(true);
                  }}
                  startIcon={<CheckCircleIcon />}
                >
                  Accept
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  color="error"
                  loading={currentStatus === "cancel" && updating}
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    setCurrentStatus("cancel");
                    setShowConfirmation(true);
                  }}
                >
                  Cancel
                </LoadingButton>
                <Link to={`/${getUser().role}/reschedule/${id}`}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<UpdateIcon />}
                  >
                    Reschedule
                  </Button>
                </Link>
              </>
            ) : (
              <Button
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                onClick={() => {
                  setCurrentStatus("cancel");
                  setShowConfirmation(true);
                }}
              >
                Cancel
              </Button>
            )}
          </Stack>
        ) : (
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2 }}>
            {status === "accepted" ? (
              <>
                <DoneIcon color="success" />
                <Typography variant="body2" color="success.main">
                  This meeting has been accepted.
                </Typography>
              </>
            ) : (
              <>
                <ErrorIcon color="error" />
                <Typography variant="body2" color="error.main">
                  This meeting has been canceled.
                </Typography>
              </>
            )}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default MeetingItem;
