import React, { useContext, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { MODULES } from "../../../../../constants/constants";
import ReactQuill from "react-quill";
import { Box, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { toastError } from "../../../../../utils2024/utils";
import axios from "axios";
import { PROFILE_EDIT_API } from "../../ProfileDetails";
import { getHeader } from "../../../../../utility/utility";
import { UserContext } from "../../../../context/UserContext";
const EditLookingFor = () => {
  const { user, loading, reloadUser } = useContext(UserContext);
  const [editorHtml, setEditorHtml] = useState("");
  const [saving, setSaving] = useState(false);

  function handleChange(html) {
    setEditorHtml(html);
  }

  useEffect(() => {
    if (user) {
      setEditorHtml(user.lookingFor ?? "");
    }
  }, [user]);

  const save = async () => {
    if (editorHtml === "") {
      toast.error("Please enter what you are looking for");
    } else {
      setSaving(true);
      try {
        const { data } = await axios.post(
          PROFILE_EDIT_API,
          { lookingFor: editorHtml },
          getHeader()
        );
        toast.success(data.message);
        setTimeout(() => {
          document.location.reload();
        }, 100);
      } catch (error) {
        toastError(error);
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <Box p={2}>
      <Typography fontSize={17}>I am Looking for?</Typography>
      <Box mt={2} mb={2}>
        <ReactQuill
          modules={MODULES}
          value={editorHtml}
          onChange={handleChange}
        />
      </Box>
      <Stack direction={"row"}>
        <Box flex={1}></Box>
        <LoadingButton
          loading={saving}
          onClick={save}
          sx={{ width: 200 }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default EditLookingFor;
