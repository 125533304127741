import { Box, Grid, List, ListItemButton, ListItemText } from "@mui/material";
import React, { useState } from "react";
import Header from "../header";
import BackBtn from "../../widgets/BackBtn";
import { OES_COMPONENTS, USER } from "../../../../../constants/constants";
import NextPrev from "../../../exhibitor/oes/reuse/NextPrev";
import { Link, useParams } from "react-router-dom";
import { ExpoTheme } from "../../../../../theme/theme";
import Manual from "../../../exhibitor/oes/Manual";
import SetupSchedule from "../../../exhibitor/oes/SetupSchedule";
import UtilityDetails from "../../../exhibitor/oes/UtilityDetails";
import StandPossession from "../../../exhibitor/oes/StandPossession";
import InvitationInaguaration from "../../../exhibitor/oes/InvitationInaguaration";
import ExhibitorBadges from "../../../exhibitor/oes/ExhibitorBadges";
import ServiceBadges from "../../../exhibitor/oes/ServiceBadges";
import ElectricalWork from "../../../exhibitor/oes/ElectricalWork";
import ExitPass from "../../../exhibitor/oes/ExitPass";
import GuidelinesMezzanine from "../../../exhibitor/oes/GuidelinesMezzanine";
import SiteHandling from "../../../exhibitor/oes/SiteHandling";
import Security from "../../../exhibitor/oes/Security";
import HotelAccomodation from "../../../exhibitor/oes/HotelAccomodation";
import SeminarHalls from "../../../exhibitor/oes/SeminarHalls";
import AudioEquipment from "../../../exhibitor/oes/AudioEquipment";
import TemporaryPersonnel from "../../../exhibitor/oes/TemporaryPersonnel";
import Translators from "../../../exhibitor/oes/Translators";
import Horticulture from "../../../exhibitor/oes/Horticulture";
import Advertisement from "../../../exhibitor/oes/Advertisement";
import ShowDaily from "../../../exhibitor/oes/ShowDaily";
import VisaApplication from "../../../exhibitor/oes/VisaApplication";
import AdditionalServices from "../../../exhibitor/oes/AdditionalServices";
import EditContainer from "./EditContainer";
import { useQuery } from "../../../../../utility/utility";
import useCommonGetInit from "../../../../events/hooks/useCommonGetInit";
import MuPb from "../../../../../widgets/MuPb";

const OES_MAP = {
  exhibitor_manual: Manual,
  setup_and_dismantling_schedule: SetupSchedule,
  utility_details: UtilityDetails,
  stand_possession: StandPossession,
  invitation_for_inauguration: InvitationInaguaration,
  exhibitor_badges: ExhibitorBadges,
  service_badges: ServiceBadges,
  electrical_work_completion_report: ElectricalWork,
  exit_pass: ExitPass,
  guidelines_for_building_mezzanine_floor: GuidelinesMezzanine,
  site_handling: SiteHandling,
  security: Security,
  hotel_accommodation: HotelAccomodation,
  seminar_halls: SeminarHalls,
  audio_visual_equipment: AudioEquipment,
  audio_visual_equipment: TemporaryPersonnel,
  interpreters_and_translators: Translators,
  horticulture_floriculture: Horticulture,
  advertisement_in_exhibition_catalogue: Advertisement,
  advertisement_show_daily: ShowDaily,
  visa_application: VisaApplication,
  additional_services: AdditionalServices,
};
const OesManage = () => {
  const [selectedItem, setSelectedItem] = useState();
  const query = useQuery();
  const id = query.get("id");
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-oes-menus?`
  );

  const SelectComponent = id ? OES_MAP[id] : null;
  if (isLoading) {
    return <MuPb />;
  }
  return (
    <Box>
      <Header />
      <Box>
        <Box ml={2} mt={2}>
          <BackBtn />
        </Box>

        <Grid
          maxWidth={1320}
          sx={{ ml: "auto", mr: "auto" }}
          p={1}
          container
          spacing={1}
        >
          <Grid item xs={3.5}>
            {" "}
            <Box
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: 1,
                padding: "12px",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "4px",
                  marginTop: "4px",
                }}
              >
                ONLINE EXHIBITOR SERVICES
              </div>
              <List>
                {data?.map((menu, index) => {
                  const isSelected = menu.id === id;
                  return (
                    <Link
                      to={`/admin/oes-manage?id=${menu.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItemButton
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          overflow: "hidden",
                          backgroundColor: isSelected
                            ? "#054054" // Base color
                            : "transparent",
                          "&:hover": {
                            backgroundColor: isSelected
                              ? ExpoTheme.palette.mode === "dark"
                                ? "#036767" // Slightly lighter color for dark mode hover
                                : "#032a2a" // Slightly darker color for light mode hover
                              : ExpoTheme.palette.mode === "dark"
                              ? "rgba(255, 255, 255, 0.1)"
                              : "rgba(0, 0, 0, 0.04)",
                            color: ExpoTheme.palette.text.primary,
                          },
                        }}
                      >
                        <Box
                          sx={{
                            minWidth: "28px",
                            minHeight: "28px",
                            width: "28px",
                            height: "28px",
                            borderRadius: "50% !important",
                            backgroundColor: isSelected
                              ? "#0c8c8c" // Lighter version of the base color
                              : "#0c8c8c", // Base color
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "16px",
                            fontSize: "0.875rem",
                            fontWeight: "bold",
                          }}
                        >
                          {index + 1}
                        </Box>
                        <ListItemText
                          primary={menu.title}
                          secondary={`Deadline ${menu.deadline}`}
                          primaryTypographyProps={{
                            noWrap: true,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            flex: 1,
                            color: isSelected ? "white" : "text.secondary",
                          }}
                          secondaryTypographyProps={{
                            noWrap: true,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color: isSelected ? "white" : "text.secondary",
                          }}
                        />
                      </ListItemButton>
                    </Link>
                  );
                })}
              </List>
            </Box>
          </Grid>

          <Grid item xs={8.5}>
            {SelectComponent && (
              <EditContainer
                menu={selectedItem}
                SelectComponent={SelectComponent}
              />
            )}
            {/* <NextPrev page={parseInt(id)} totalItems={oesMenus.length} />  */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OesManage;
