import { useQuery } from "react-query";
import axios from "axios";
import { VISITOR_API } from "../../../../constants/constants";
const fetchData = (ccode, scode) => {
  return axios
    .get(`${VISITOR_API}get-cities/${ccode}/${scode}`)
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useCities = (ccode, scode) => {
  return useQuery(["get-cities", ccode, scode], () => fetchData(ccode, scode), {
    enabled: !!ccode && !!scode,
    staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 3, // If the query fails, it will retry up to 3 times
  });
};

export default useCities;
