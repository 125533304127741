import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Avatar,
  IconButton,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Alert,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import EditIcon from "@mui/icons-material/Edit";
import MyImageCropper from "../common/MyImageCropper";
import CloseIcon from "@mui/icons-material/Close";
import ImageCropperDialog from "../common/ImageCropperDialog";
import BackBtn from "../admin/widgets/BackBtn";
import { toastError } from "../../../utils2024/utils";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { getHeader, getMultipartHeader } from "../../../utility/utility";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINT } from "../../../constants/constants";
const AddTeamMembers = () => {
  const navigate = useNavigate(false);
  const { id } = useParams();
  const [showCropper, setShowCropper] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null); // For display
  const [croppedImageBlob, setCroppedImageBlob] = useState(null); // For upload
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validId, setValidId] = useState(null);
  const [picUrl, setPicUrl] = useState("");
  // Form state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    role: "",
    designation: "",
  });

  // Handle form field changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle phone number change
  const handlePhoneNumberChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
  };
  const handleCroppedImage = (blob) => {
    setCroppedImageBlob(blob);
    const reader = new FileReader();
    reader.onloadend = () => {
      setCroppedImage(reader.result);
    };
    reader.readAsDataURL(blob);
    setShowCropper(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${API_ENDPOINT}user/get-team-member-by-id?id=${id}`,
          getHeader()
        );

        // Update form values with fetched data
        setFormData((prevValues) => ({
          ...prevValues,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          email: data.email,
          role: data.role,
          designation: data.designation || "",
        }));
        setPicUrl(data.profile_image);
      } catch (error) {
        toastError(error);
        setValidId(false);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  if (validId !== null && validId === false) {
    return <Alert severity="warning">Invalid request ID!</Alert>;
  }

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create FormData object
    const submitData = new FormData();
    submitData.append("firstName", formData.firstName);
    submitData.append("lastName", formData.lastName);
    submitData.append("phone", formData.phone);
    submitData.append("email", formData.email);
    submitData.append("role", formData.role);
    submitData.append("designation", formData.designation);

    if (!!croppedImageBlob) {
      submitData.append("image", croppedImageBlob, "profile_image.png");
    }
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        id
          ? `${API_ENDPOINT}user/edit-team-member?id=${id}`
          : `${API_ENDPOINT}user/add-team-member`,
        submitData,
        getMultipartHeader()
      );
      toast.success(data.message);
      if (!id) {
        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          role: "",
          designation: "",
        });
        setCroppedImage(null);
        setCroppedImageBlob(null);
      }
    } catch (error) {
      toastError(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <BackBtn />
      <Box sx={{ maxWidth: 600, margin: "0 auto", padding: 2 }}>
        {/* Profile Picture */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            marginBottom: 3,
          }}
        >
          <Avatar
            src={croppedImage ? croppedImage : picUrl}
            alt="Profile Picture"
            sx={{ width: 120, height: 120, backgroundColor: "#E0E0E0" }}
          />
          <IconButton
            sx={{
              position: "absolute",
              bottom: 0,
              right: "45%",
              background: "#fff",
              boxShadow: 1,
            }}
            size="small"
            onClick={() => setShowCropper(true)}
          >
            <EditIcon />
          </IconButton>
        </Box>

        {/* Form Fields */}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="First Name"
                variant="outlined"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Last Name */}
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Last Name"
                variant="outlined"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Phone Number */}
            <Grid item xs={12}>
              <MuiTelInput
                fullWidth
                size="small"
                label="Phone Number"
                defaultCountry="IN"
                forceCallingCode
                value={formData.phone}
                onChange={handlePhoneNumberChange}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Email"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Role */}
            <Grid item xs={12}>
              <FormControl size="small" fullWidth required>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  label="Role"
                  name="role"
                  value={formData.role}
                  onChange={(event) =>
                    handleInputChange({
                      target: {
                        name: "role",
                        value: event.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="User">User</MenuItem>
                  {/* Add more roles as needed */}
                </Select>
              </FormControl>
            </Grid>

            {/* Designation */}
            <Grid item xs={12}>
              <FormControl size="small" fullWidth required>
                <InputLabel id="designation-select-label">
                  Designation
                </InputLabel>
                <Select
                  labelId="designation-select-label"
                  label="Designation*"
                  name="designation"
                  value={formData.designation}
                  onChange={(event) =>
                    handleInputChange({
                      target: {
                        name: "designation",
                        value: event.target.value,
                      },
                    })
                  }
                >
                  {[
                    "Chairman",
                    "Managing Director",
                    "CEO",
                    "President",
                    "Country Head",
                    "Executive Director",
                    "Vice President",
                    "Sr. Vice President",
                    "CTO",
                    "COO",
                    "CFO",
                    "General Manager",
                    "Sr. Director",
                    "Director",
                    "Deputy Director",
                    "General Manager",
                    "Manager",
                    "Production Head",
                    "Technician",
                    "Engineer",
                    "Machine Operator",
                    "Production/ Plant/ Technical Head",
                    "Trainees",
                    "Consultant",
                    "Executive",
                    "Partner",
                    "Advisor",
                    "Programmer",
                    "Contractor",
                    "Engineer",
                  ].map((designation) => (
                    <MenuItem key={designation} value={designation}>
                      {designation}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Submit Button */}
          <LoadingButton
            loading={submitting}
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            size="small"
          >
            Submit
          </LoadingButton>
        </form>
      </Box>
      <ImageCropperDialog
        title="Crop Profile image"
        open={showCropper}
        onClose={() => setShowCropper(false)}
        aspectRatio={1}
        onCropped={handleCroppedImage}
      />
    </Container>
  );
};

export default AddTeamMembers;
