import React, { forwardRef } from "react";
import useCountries from "../visitorregt/components/previewpage/hooks/useCountries";
import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import useCitiesCountry from "../visitorregt/components/previewpage/hooks/useCitiesCountry";

const CountryCitiesAuto = forwardRef(
  ({ label = "Cities", size, value, onChange, ccode }, ref) => {
    const { data, isLoading, isError, error } = useCitiesCountry(ccode); // Fetch visitors for page 1
    if (isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        <Autocomplete
          ref={ref}
          value={value}
          size={size}
          disablePortal
          options={data || []}
          getOptionLabel={(option) => option.name}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      </div>
    );
  }
);

export default CountryCitiesAuto;
