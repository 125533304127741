import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import BackBtn from "../components/dashboard/admin/widgets/BackBtn";
import { getMultipartHeader } from "../utility/utility";
import axios from "axios";
import { toastError } from "../utils2024/utils";
import { toast } from "react-toastify";
import { API_ENDPOINT } from "../constants/constants";

const SupportRequest = () => {
  const [message, setMessage] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("message", message);
    if (image) {
      formData.append("image", image);
    }
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}user/request-support`,
        formData,
        getMultipartHeader()
      );
      setSuccessDialog(true);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    setSuccessDialog(false);
    document.location.reload();
  };

  return (
    <Box m={1}>
      <BackBtn />
      <Box
        sx={{
          maxWidth: 600,
          margin: "auto",
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <Card>
          <CardHeader
            title="Support Request"
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              textAlign: "center",
            }}
          />
          <CardContent>
            <Typography variant="body1" gutterBottom>
              If you need any help, please describe your issue below and upload
              an image if needed.
            </Typography>
            <Stack spacing={3}>
              <TextField
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                sx={{
                  "& label.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "primary.main",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "primary.main",
                    },
                  },
                }}
              />
              <Box>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<UploadFileIcon />}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Button>
                {image && (
                  <Typography variant="body2" mt={1}>
                    Selected file: {image.name}
                  </Typography>
                )}
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                size="large"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit Request"}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Box>

      {/* Success Dialog */}
      <Dialog
        open={successDialog}
        onClose={handleDialogClose}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">
          {"Request Submitted!"}
        </DialogTitle>
        <DialogContent>
          <Typography id="success-dialog-description">
            Your support request has been successfully submitted.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SupportRequest;
