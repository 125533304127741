import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const FootfallLinks = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          gap: 2,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Select a Footfall Option
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="https://imtex2024-imtma.expoplanner.in/footfall-by-time"
          sx={{ width: "100%", maxWidth: 300 }}
        >
          Footfall Unique Visits
        </Button>
        <Button
          variant="contained"
          href="https://imtex2024-imtma.expoplanner.in/footfall-with-repeat"
          sx={{ width: "100%", maxWidth: 300 }}
        >
          Footfall Repeat Visits
        </Button>
        <Button
          variant="contained"
          color="secondary"
          href="https://imtex2024-imtma.expoplanner.in/footfall-by-time-hall-one"
          sx={{ width: "100%", maxWidth: 300 }}
        >
          Footfall Hall 1 Unique visits
        </Button>
      </Box>
    </Container>
  );
};

export default FootfallLinks;
