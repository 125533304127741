import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MyImageCropper from "./MyImageCropper";

const ImageCropperDialog = ({
  open,
  onClose,
  aspectRatio = 1,
  onCropped,
  title = "Crop Image",
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <MyImageCropper aspectRatio={aspectRatio} onCropped={onCropped} />
      </DialogContent>
    </Dialog>
  );
};

export default ImageCropperDialog;
