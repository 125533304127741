import { useQuery } from "react-query";
import axios from "axios";
import {
  getCommonHeaderVE,
  getExhibitorHeader,
  getHeader,
} from "../../../../utility/utility";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  USER,
} from "../../../../constants/constants";
const fetchItems = async (page = 1, id) => {
  let url = `${API_ENDPOINT}${USER}/get-team-members?page=${page}&id=${id}`;
  try {
    const res = await axios.get(url, getHeader());
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useTeamMembers = (page, id) => {
  const queryInfo = useQuery(
    ["get-team-members", page, id],
    () => fetchItems(page, id),
    {
      staleTime: 0,
      retry: 3,
    }
  );

  return {
    ...queryInfo,
    refetchMembers: queryInfo.refetch, // Extract refetch function from queryInfo
  };
};

export default useTeamMembers;
