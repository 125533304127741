import { useState } from "react";

const useBannerModal = () => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState();

  const showModal = (link = "", src = "", key) => {
    setModalData({ link, src, key });
    setModalVisibility(true);
  };

  const hideModal = () => {
    setModalVisibility(false);
  };

  return {
    isBMVisible: isModalVisible,
    setBMVisible: setModalVisibility,
    bannerData: modalData,
    showBM: showModal,
    hideBM: hideModal,
  };
};

export default useBannerModal;
