import React from "react";
import HeaderBar from "../widgets2024/HeaderBar";

const Dashboard = () => {
  const navItems = {
    rootPath: "/super",
    barLogo:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRme8ngSadIpD8MTZ_EoRLsvdYE6CRJRtNVz1JgZkvEWqW1j1dmKHf-fEAMa5LtQSt2sXo&usqp=CAU",
    barName: "ExpoPlanner",
    profileName: "Super Admin",
    leftItems: [
      {
        label: "Add Organiser",
        path: "/add-organiser",
        icon: "AddIcon",
      },
    ],
    rightItems: [
      {
        label: "Profile",
        icon: "AccountCircle",
        path: "/profile",
      },
      {
        label: "Logout",
        icon: "LogoutIcon",
        path: "/logout",
      },
    ],
  };
  return (
    <div>
      <HeaderBar navItems={navItems} />
    </div>
  );
};

export default Dashboard;
