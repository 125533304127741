import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DynamicRowTable = ({ headers = [], rows = [], onRowsChange }) => {
  const generateEmptyRow = () => {
    const emptyRow = {};
    headers.forEach((header) => {
      emptyRow[header.key] = "";
    });
    return emptyRow;
  };

  // Initialize internal state based on the passed rows or default to one empty row
  const [internalRows, setInternalRows] = useState(
    rows.length > 0 ? rows : [generateEmptyRow()]
  );

  useEffect(() => {
    setInternalRows(rows.length > 0 ? rows : [generateEmptyRow()]);
  }, [rows]);

  // Update the parent component whenever rows change
  const updateRows = (newRows) => {
    setInternalRows(newRows);
    onRowsChange && onRowsChange(newRows); // Call the callback if provided
  };

  const handleAddRow = () => {
    const newRows = [...internalRows, generateEmptyRow()];
    updateRows(newRows);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = internalRows.filter((_, i) => i !== index);
    updateRows(updatedRows);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = internalRows.map((row, i) =>
      i === index ? { ...row, [name]: value } : row
    );
    updateRows(updatedRows);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.key}>{header.header}</TableCell>
            ))}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {internalRows.map((row, index) => (
            <TableRow key={index}>
              {headers.map((header) => (
                <TableCell key={header.key}>
                  <TextField
                    type={header.type}
                    name={header.key}
                    size="small"
                    value={row[header.key]}
                    onChange={(event) => handleInputChange(index, event)}
                    variant="outlined"
                    fullWidth
                  />
                </TableCell>
              ))}
              <TableCell>
                <IconButton
                  size="small"
                  onClick={() => handleDeleteRow(index)}
                  disabled={internalRows.length === 1}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box display={"flex"} justifyContent={"end"}>
        <Button
          onClick={handleAddRow}
          variant="outlined"
          color="primary"
          size="small"
          style={{ marginTop: "10px" }}
        >
          Add Row
        </Button>
      </Box>
    </TableContainer>
  );
};

export default DynamicRowTable;
