import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Container,
  Avatar,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import { EXHIBITOR_API, OES_COMPONENTS } from "../../../../constants/constants";
import { Link } from "react-router-dom";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import Supplier2 from "./reuse/Supplier2";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import MuPb from "../../../../widgets/MuPb";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";

const HotelAccomodation = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();

  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();

  const formDataHtml = `
<div style="text-align: center;">
  <img
    src="https://via.placeholder.com/150"
    alt="MICE HOSPITALITY Logo"
    style="width: 100px; height: 100px; margin: 20px auto;"
  />
</div>

<p style="font-size: 16px; margin: 0;">
  Hotel Accommodation, Service Apartments & Travel Arrangements (Booking and Confirmation)
</p>
`;
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div> ${spaceTemplate(
      data
    )} <br />${formDataHtml} <br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "5.3 Hotel Accommodation",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "5.3 Hotel Accommodation",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  if (isLoading) {
    return <MuPb />;
  }
  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" mb={1}>
          5.3 Hotel Accommodation
        </Typography>
        <Box mb={4}>
          {/* <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert> */}
        </Box>
        <Typography variant="body1">
          Online Booking before the 30 Dec 2023
        </Typography>

        {/* Placeholder demo image for logo */}
        <img
          src="https://www.micehospitality.com/images/newLogo.svg"
          alt="MICE HOSPITALITY Logo"
          style={{ height: "90px", margin: "20px auto" }}
        />

        <SpaceInfo data={data} />

        <Typography variant="body1">
          Hotel Accommodation, Service Apartments & Travel Arrangements (Booking
          and Confirmation)
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
          • The recommended method of booking is through the official online
          booking site at
        </Typography>

        <Typography variant="body2" gutterBottom>
          <Link
            href="https://www.imtex.in/index.php?page=travelplan"
            target="_blank"
            rel="noopener"
          >
            IMTEX 2023 / Tooltech 2023 URL LINK
          </Link>
        </Typography> */}

        <Supplier2
          user={data}
          id={13}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};

export default HotelAccomodation;
