import React, { useEffect, useRef, useState } from "react";
import Slider from "@mui/material/Slider"; // Import the MUI Slider component
import Box from "@mui/material/Box"; // Box is a utility component for general layout

const FloorPlan = () => {
  const imageUrl =
    "https://www.homeplansindia.com/uploads/1/8/8/6/18862562/hfp-4004_orig.jpg";
  const imgRef = useRef(null);
  const [imageResolution, setImageResolution] = useState("");
  const [clickedPosition, setClickedPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });

  const [isDrawing, setIsDrawing] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [resizeEdge, setResizeEdge] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const rectRef = useRef(null);

  const startDrawing = (e) => {
    console.log("Drawing", e.clientX, e.clientY);
    setIsDrawing(true);
    setPosition({
      x: e.clientX / zoom, // Divided by zoom
      y: e.clientY / zoom, // Divided by zoom
    });
  };

  const startDragging = (e) => {
    e.stopPropagation();
    console.log("Dragging", e.clientX, e.clientY);
    setIsDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const resizeStart = (edge, e) => {
    e.stopPropagation();
    setIsResizing(true);
    setResizeEdge(edge);
  };

  const move = (e) => {
    if (isDrawing) {
      setSize({
        width: (e.clientX - position.x * zoom) / zoom, // Divided by zoom
        height: (e.clientY - position.y * zoom) / zoom, // Divided by zoom
      });
    } else if (isDragging) {
      setPosition({
        x: position.x + (e.clientX - dragStart.x) / zoom,
        y: position.y + (e.clientY - dragStart.y) / zoom,
      });
      setDragStart({ x: e.clientX, y: e.clientY });
    } else if (isResizing) {
      const rect = rectRef.current.getBoundingClientRect();
      switch (resizeEdge) {
        case "left":
          const widthChange = (e.clientX - rect.left) / zoom;
          setSize((prevSize) => ({
            width: prevSize.width - widthChange,
            height: prevSize.height,
          }));
          setPosition((prevPosition) => ({
            x: prevPosition.x + widthChange,
            y: prevPosition.y,
          }));
          break;
        case "right":
          setSize({
            width: (e.clientX - rect.left) / zoom,
            height: size.height,
          });
          break;
        case "top":
          const heightChange = (e.clientY - rect.top) / zoom;
          setSize((prevSize) => ({
            width: prevSize.width,
            height: prevSize.height - heightChange,
          }));
          setPosition((prevPos) => ({
            x: position.x,
            y: prevPos.y + heightChange,
          }));
          break;
        case "bottom":
          setSize({
            width: size.width,
            height: (e.clientY - rect.top) / zoom,
          });
          break;
        default:
          break;
      }
    }
  };

  const endDrawOrResize = () => {
    setIsDrawing(false);
    setIsResizing(false);
    setIsDragging(false);
  };

  const onImageLoaded = () => {
    if (imgRef.current) {
      const image = imgRef.current;
      setOriginalSize({
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
      const resolution = `${image.naturalWidth} x ${image.naturalHeight}`;
      setImageResolution(resolution);
    }
  };

  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  return (
    <div>
      <div
        style={{
          margin: "16px 16px",
          position: "relative",
          width: "700px",
          height: "500px",
          border: "1px solid #ccc",
          overflow: "scroll",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 4,
            width: `${originalSize.width * zoom}px`,
            height: `${originalSize.height * zoom}px`,
            backgroundColor: "rgba(173, 216, 230, 0.2)", // lightblue with 50% transparency
          }}
          onMouseDown={startDrawing}
          onMouseMove={move}
          onMouseUp={endDrawOrResize}
        >
          <div
            ref={rectRef}
            style={{
              position: "absolute",
              top: position.y * zoom, // Multiplied by zoom
              left: position.x * zoom, // Multiplied by zoom
              width: size.width * zoom, // Multiplied by zoom
              height: size.height * zoom, // Multiplied by zoom
              backgroundColor: "rgba(0, 255, 0, 0.5)",
            }}
          >
            {["left", "right", "top", "bottom"].map((edge) => (
              <div
                key={edge}
                style={{
                  position: "absolute",
                  background: "rgba(0, 0, 0, 0.5)",
                  ...(edge === "left" && {
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: "3px",
                    cursor: "ew-resize",
                  }),
                  ...(edge === "right" && {
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: "3px",
                    cursor: "ew-resize",
                  }),
                  ...(edge === "top" && {
                    top: 0,
                    left: 0,
                    right: 0,
                    height: "3px",
                    cursor: "ns-resize",
                  }),
                  ...(edge === "bottom" && {
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "3px",
                    cursor: "ns-resize",
                  }),
                }}
                onMouseDown={(e) => resizeStart(edge, e)}
              />
            ))}
            <div
              style={{
                width: "calc(100% - 6px)", // 3px left and 3px right
                height: "calc(100% - 6px)", // 3px top and 3px bottom
                margin: "3px",
              }}
              onMouseDown={startDragging}
            />
          </div>
        </div>
        <img
          ref={imgRef}
          src={imageUrl}
          alt="Floor Plan"
          // onClick={handleImageClick}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            cursor: "crosshair",
            width: `${originalSize.width * zoom}px`,
            height: `${originalSize.height * zoom}px`,
          }}
          onLoad={onImageLoaded}
        />
      </div>
      <Box sx={{ width: 300, ml: 2, padding: "20px 0" }}>
        <Slider
          value={zoom}
          min={0.5}
          max={2}
          step={0.1}
          onChange={handleZoomChange}
          aria-labelledby="zoom-slider"
        />
        <div>Clicked position: {`(${position.x}, ${position.y})`}</div>
        <div>Image resolution: {imageResolution}</div>
      </Box>
    </div>
  );
};

export default FloorPlan;
