import { toast } from "react-toastify";
import {
  detectSubdomainAndDash,
  getAdmin,
  getUser,
  isAdminLoggedIn,
  isLoggedIn,
} from "../utility/utility";
import {
  ADMIN,
  API_ENDPOINT,
  EXHIBITOR,
  VISITOR,
} from "../constants/constants";
import { Navigate } from "react-router-dom";

const ONSITE = "onsite";
export const OTP_VERIFY = "otp_verify";
export const GROUP_ONE = "group_one";
export const GROUP_TWO = "group_two";
export const GROUP_THREE = "group_three";
export const GROUP_FOUR = "group_four";
export const GROUP_FIVE = "group_five";
export const GROUP_SIX = "group_six";
export const saveOtpVerify = (token) => {
  localStorage.setItem(OTP_VERIFY, token);
};
export const getOtpVerify = () => {
  return localStorage.getItem(OTP_VERIFY);
};
export const deleteOtpVerify = () => {
  localStorage.removeItem(OTP_VERIFY);
};
export const getGroupByNumber = (number) => {
  switch (number) {
    case 1:
      return GROUP_ONE;
    case 2:
      return GROUP_TWO;
    case 3:
      return GROUP_THREE;
    case 4:
      return GROUP_FOUR;
    case 5:
      return GROUP_FIVE;
    case 6:
      return GROUP_SIX;
    default:
      return null; // Return null if the number doesn't match any group
  }
};
export const saveOnsite = (type, token) => {
  localStorage.setItem(type, token);
};
export const getOnsite = (type) => {
  return localStorage.getItem(type);
};
export const deleteOnsite = (type) => {
  localStorage.removeItem(type);
};
export const isOnsiteActivated = (type) => {
  return false; //localStorage.getItem(type) === null;
};
export const getOnsiteHeader = (type) => {
  return {
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${getOnsite(type)}`,
      type: type,
    },
  };
};
export const getOnsiteMultipartHeader = (type) => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${getOnsite(type)}`,
      type: type,
    },
  };
};

export function navToElementMarker(elem, msg) {
  if (elem.current) {
    elem.current.scrollIntoView({ behavior: "smooth", block: "center" });
    elem.current.parentElement.style.border = "1px solid red";
    setTimeout(() => {
      elem.current.parentElement.style.border = ""; // Revert to the original style
    }, 3000);
    toast.error(msg);
  }
}
export function toastError(error) {
  toast.error(getErrorResponse(error));
}
export function getErrorResponse(error) {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else {
    return "An error occurred. Please try again.";
  }
}

export function getFirstSubdomain(hostname) {
  const parts = hostname.split(".");
  if (parts.length > 2) {
    return parts[0];
  } else {
    return null;
  }
}
export function getDbName() {
  const hostname = window.location.hostname;
  return getFirstSubdomain(hostname);
}
export function getHomeUrl(cPath = "") {
  const hostname = window.location.hostname;
  const domainCheck = detectSubdomainAndDash(hostname);
  if (domainCheck.hasSubdomain) {
    const subD = getFirstSubdomain(hostname);
    return `${API_ENDPOINT}user/${cPath}?pb=${subD}`;
  } else {
    return `${API_ENDPOINT}user/${cPath}?pb=superDb`;
  }
}
export function getCommonUrl(cPath = "") {
  const hostname = window.location.hostname;
  const domainCheck = detectSubdomainAndDash(hostname);
  if (domainCheck.hasSubdomain) {
    const subD = getFirstSubdomain(hostname);
    return `${API_ENDPOINT}${cPath}pb=${subD}`;
  } else {
    return `${API_ENDPOINT}${cPath}pb=superDb`;
  }
}
export const VisitorAuth = ({ children }) => {
  return isLoggedIn() && getUser().role === VISITOR ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};
export const OnsiteAuth = ({ children, type }) => {
  return isOnsiteActivated(type) ? (
    children
  ) : (
    <Navigate to="/activate-onsite1" />
  );
};
export const ExhibitorAuth = ({ children }) => {
  return isLoggedIn() && getUser().role === EXHIBITOR ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};
export const AdminAuth = ({ children }) => {
  return isAdminLoggedIn() && getAdmin().role === ADMIN ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};
export function excelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  return new Date(utc_value * 1000);
}
/*


*/
export const getDbHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      dbauth:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYiI6ImltdGV4MjAyNSIsImlhdCI6MTcyMzcwMDkyMSwiZXhwIjoxNzU1MjU4NTIxfQ.07gBIH-rkRp9Q-6GHRORREC3w15Vj-JxbHMH7oorekQ",
    },
  };
};
