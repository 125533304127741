import React, { useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Box,
  Checkbox,
  Paper,
  Divider,
  Grid,
  Alert,
  Card,
  Button,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  TableFooter,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import { EXHIBITOR_API } from "../../../../constants/constants";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import Supplier2 from "./reuse/Supplier2";
import { LoadingButton } from "@mui/lab";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import MuPb from "../../../../widgets/MuPb";

const Advertisement = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();

  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [positions, setPositions] = useState(premiumPositions);
  const [positionsA, setPositionsA] = useState(customaryPositions);

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCheckboxChangeA = (index, column) => {
    const newPositions = [...positionsA];
    newPositions[index][column].checked = !newPositions[index][column].checked;
    setPositions(newPositions);
  };
  const handleCheckboxChange = (index, category) => {
    const newPositions = [...positions];
    newPositions[index][category].checked =
      !newPositions[index][category].checked;
    setPositions(newPositions);
  };

  const formatPrice = (price, isForeign) => {
    return new Intl.NumberFormat(isForeign ? "en-US" : "en-IN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };
  const calculateTotalAndCount = (posA) => {
    let total = 0;
    let count = 0;
    posA.forEach((row) => {
      if (selectedValue === "imtmaMember" && row.IMTMAMemberExhibitor.checked) {
        total += row.IMTMAMemberExhibitor.price;
        count++;
      } else if (selectedValue === "foreignExhibitor" && row.foreign.checked) {
        total += row.foreign.price;
        count++;
      } else if (
        selectedValue === "indianExhibitor" &&
        row.nonMemberExhibitor.checked
      ) {
        total += row.nonMemberExhibitor.price;
        count++;
      } else if (
        selectedValue === "others" &&
        row.IMTMAMemberNonExhibitor.checked
      ) {
        total += row.IMTMAMemberNonExhibitor.price;
        count++;
      }
    });
    return { total, count };
  };

  const { total: posTotal, count: posCount } =
    calculateTotalAndCount(positions);
  const { total: cusTotal, count: cusCount } =
    calculateTotalAndCount(positionsA);

  const generatePositionsTemplate = (positions, total) => {
    // Generate HTML for each position that is checked
    const positionsHtml = positions
      .map((position) => {
        let positionDetails = [];

        if (position.IMTMAMemberExhibitor.checked) {
          positionDetails.push(
            `${position.IMTMAMemberExhibitor.price.toLocaleString()} <span style="color: green;">✓</span> `
          );
        }

        if (position.nonMemberExhibitor.checked) {
          positionDetails.push(
            `${position.nonMemberExhibitor.price.toLocaleString()} <span style="color: green;">✓</span> `
          );
        }

        if (position.foreign.checked) {
          positionDetails.push(
            `${position.foreign.price.toLocaleString()} <span style="color: green;">✓</span> `
          );
        }

        if (position.IMTMAMemberNonExhibitor.checked) {
          positionDetails.push(
            `${position.IMTMAMemberNonExhibitor.price.toLocaleString()} <span style="color: green;">✓</span>`
          );
        }

        // Only return a row if there are checked items
        if (positionDetails.length > 0) {
          return `
              <tr>
                <td>${position.position}</td>
                <td>${positionDetails.join(" | ")}</td>
              </tr>
            `;
        }

        return ""; // Return an empty string if no items are checked
      })
      .join("");

    // Calculate GST and grand total based on the total
    const gst = total * 0.18;
    const grandTotal = total + gst;

    // Footer HTML with total, GST, and grand total
    const footerHtml = `
        <tfoot>
          <tr>
            <td colspan="1">Total</td>
            <td>${total.toLocaleString()}</td>
          </tr>
          <tr>
            <td colspan="1">GST (18%)</td>
            <td>${gst.toLocaleString()}</td>
          </tr>
          <tr>
            <td colspan="1">Grand Total</td>
            <td>${grandTotal.toLocaleString()}</td>
          </tr>
        </tfoot>
      `;

    // Combine positions HTML and footer to form the complete table
    return `
        <table>
          <thead>
            <tr>
              <th>Position</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            ${positionsHtml}
          </tbody>
          ${footerHtml}
        </table>
      `;
  };

  const premiumTemplate = generatePositionsTemplate(positions, posTotal);
  const cusTemplate = generatePositionsTemplate(positionsA, cusTotal);
  if (isLoading) {
    return <MuPb />;
  }
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div> ${spaceTemplate(
      data
    )}<br /><h2>Premium Positions</h2>${premiumTemplate} <br /><h2>Customary Positions</h2>${cusTemplate} <br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "6.1 Advertisement in Exhibition Catalogue",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "6.1 Advertisement in Exhibition Catalogue",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Box sx={{ mt: 2 }}>
          <Typography variant="h5" mb={1}>
            6.1 Advertisement in Exhibition Catalogue
          </Typography>
          <Box mb={4}>
            {/* <Alert severity="warning">
              <Typography variant="subtitle1">
                Page not yet submitted, the deadline is 23 Dec, 2024.
              </Typography>
            </Alert> */}
          </Box>
          <Typography variant="h5" gutterBottom>
            Mechanical Data
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Print Area for:</TableCell>
                <TableCell>Dimensions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Premium Position (except Book Mark)</TableCell>
                <TableCell>105 mm(w) x 190 mm(h)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Full Page</TableCell>
                <TableCell>105 mm(w) x 190 mm(h)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Double Spread</TableCell>
                <TableCell>240 mm(w) x 190 mm(h)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Book Mark</TableCell>
                <TableCell>75 mm(w) x 180 mm(h)</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
            Ad Material Required
          </Typography>
          <Typography variant="body1" gutterBottom>
            Positives with Progressive Colour Proofs/Softcopy in either CDR or
            EPS format.
          </Typography>

          <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
            Deadlines
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Details</TableCell>
                <TableCell>Deadline / Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>For Premium Positions</TableCell>
                <TableCell>First - Come - First Served</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  For Ads with Early Bird Incentives. <br />
                  Any Ad reaching IMTMA Secretariat after this deadline SHALL
                  NOT BE ENTITLED to this incentive.
                </TableCell>
                <TableCell>Earlybird 30 Nov 2023</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>For All Ads</TableCell>
                <TableCell>15 Dec 2023</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <SpaceInfo data={data} />
          <FormControl sx={{ mt: 2 }} component="fieldset">
            <FormLabel component="legend">We are:</FormLabel>
            <RadioGroup row value={selectedValue} onChange={handleChange}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="imtmaMember"
                    control={<Radio />}
                    label="IMTMA Member Exhibitor"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="foreignExhibitor"
                    control={<Radio />}
                    label="Foreign Exhibitor"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="indianExhibitor"
                    control={<Radio />}
                    label="Indian Exhibitor (Non IMTMA Member)"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="others"
                    control={<Radio />}
                    label="Others"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              PREMIUM POSITIONS
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Position</TableCell>
                  <TableCell>IMTMA Member Exhibitor</TableCell>
                  <TableCell>Non Member Exhibitor</TableCell>
                  <TableCell>Foreign (in US$)</TableCell>
                  <TableCell>IMTMA Member (Non Exhibitor)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {positions.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.position}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.IMTMAMemberExhibitor.checked}
                        onChange={() =>
                          handleCheckboxChange(index, "IMTMAMemberExhibitor")
                        }
                        disabled={
                          selectedValue !== "imtmaMember" ||
                          row.position.includes("Early Bird")
                        }
                      />
                      {formatPrice(row.IMTMAMemberExhibitor.price)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.nonMemberExhibitor.checked}
                        onChange={() =>
                          handleCheckboxChange(index, "nonMemberExhibitor")
                        }
                        disabled={
                          selectedValue !== "indianExhibitor" ||
                          row.position.includes("Early Bird")
                        }
                      />
                      {formatPrice(row.nonMemberExhibitor.price)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.foreign.checked}
                        onChange={() => handleCheckboxChange(index, "foreign")}
                        disabled={
                          selectedValue !== "foreignExhibitor" ||
                          row.position.includes("Early Bird")
                        }
                      />
                      {formatPrice(row.foreign.price, true)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.IMTMAMemberNonExhibitor.checked}
                        onChange={() =>
                          handleCheckboxChange(index, "IMTMAMemberNonExhibitor")
                        }
                        disabled={
                          selectedValue !== "others" ||
                          row.position.includes("Early Bird")
                        }
                      />
                      {formatPrice(row.IMTMAMemberNonExhibitor.price)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="right">{posCount} items selected</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    Total Amount:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(posTotal)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    18% GST:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(0.18 * posTotal)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    Total:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(1.18 * posTotal)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              CUSTOMARY POSITIONS
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Position</TableCell>
                  <TableCell>IMTMA Member Exhibitor</TableCell>
                  <TableCell>Non Member Exhibitor</TableCell>
                  <TableCell>Foreign (in US$)</TableCell>
                  <TableCell>IMTMA Member (Non Exhibitor)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {positionsA.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.position}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.IMTMAMemberExhibitor.checked}
                        onChange={() =>
                          handleCheckboxChangeA(index, "IMTMAMemberExhibitor")
                        }
                        disabled={
                          selectedValue !== "imtmaMember" ||
                          row.position.includes("Early Bird")
                        }
                      />
                      {formatPrice(row.IMTMAMemberExhibitor.price)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.nonMemberExhibitor.checked}
                        onChange={() =>
                          handleCheckboxChangeA(index, "nonMemberExhibitor")
                        }
                        disabled={
                          selectedValue !== "indianExhibitor" ||
                          row.position.includes("Early Bird")
                        }
                      />
                      {formatPrice(row.nonMemberExhibitor.price)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.foreign.checked}
                        onChange={() => handleCheckboxChangeA(index, "foreign")}
                        disabled={
                          selectedValue !== "foreignExhibitor" ||
                          row.position.includes("Early Bird")
                        }
                      />
                      {formatPrice(row.foreign.price, true)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.IMTMAMemberNonExhibitor.checked}
                        onChange={() =>
                          handleCheckboxChangeA(
                            index,
                            "IMTMAMemberNonExhibitor"
                          )
                        }
                        disabled={
                          selectedValue !== "others" ||
                          row.position.includes("Early Bird")
                        }
                      />
                      {formatPrice(row.IMTMAMemberNonExhibitor.price)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="right">{cusCount} items selected</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    Total Amount:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(cusTotal)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    18% GST:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(0.18 * cusTotal)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    Total:
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-IN").format(1.18 * cusTotal)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </Box>
        <Supplier2
          user={data}
          id={19}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};
const premiumPositions = [
  {
    position: "Back Cover",
    IMTMAMemberExhibitor: { checked: false, price: 110000 },
    nonMemberExhibitor: { checked: false, price: 120000 },
    foreign: { checked: false, price: 2850 },
    IMTMAMemberNonExhibitor: { checked: false, price: 120000 },
  },
  {
    position: "Inside Front Cover",
    IMTMAMemberExhibitor: { checked: false, price: 62000 },
    nonMemberExhibitor: { checked: false, price: 72000 },
    foreign: { checked: false, price: 2200 },
    IMTMAMemberNonExhibitor: { checked: false, price: 72000 },
  },
  {
    position: "Inside Back Cover",
    IMTMAMemberExhibitor: { checked: false, price: 55000 },
    nonMemberExhibitor: { checked: false, price: 67000 },
    foreign: { checked: false, price: 2100 },
    IMTMAMemberNonExhibitor: { checked: false, price: 67000 },
  },
  {
    position: "Page Facing Inside Back Cover",
    IMTMAMemberExhibitor: { checked: false, price: 46000 },
    nonMemberExhibitor: { checked: false, price: 50000 },
    foreign: { checked: false, price: 1250 },
    IMTMAMemberNonExhibitor: { checked: false, price: 50000 },
  },
  {
    position: "First Page in Ad Section",
    IMTMAMemberExhibitor: { checked: false, price: 32000 },
    nonMemberExhibitor: { checked: false, price: 37000 },
    foreign: { checked: false, price: 900 },
    IMTMAMemberNonExhibitor: { checked: false, price: 37000 },
  },
  {
    position: "Book Marks",
    IMTMAMemberExhibitor: { checked: false, price: 96000 },
    nonMemberExhibitor: { checked: false, price: 110000 },
    foreign: { checked: false, price: 2650 },
    IMTMAMemberNonExhibitor: { checked: false, price: 110000 },
  },
];
const customaryPositions = [
  {
    position: "Full Page",
    IMTMAMemberExhibitor: { checked: false, price: 22000 },
    nonMemberExhibitor: { checked: false, price: 24000 },
    foreign: { checked: false, price: 550 },
    IMTMAMemberNonExhibitor: { checked: false, price: 24000 },
  },
  {
    position: "Full Page (With Early Bird Incentive)",
    IMTMAMemberExhibitor: { checked: false, price: 20000 },
    nonMemberExhibitor: { checked: false, price: 22000 },
    foreign: { checked: false, price: 500 },
    IMTMAMemberNonExhibitor: { checked: false, price: 22000 },
  },
  {
    position: "Double Spread",
    IMTMAMemberExhibitor: { checked: false, price: 39000 },
    nonMemberExhibitor: { checked: false, price: 42000 },
    foreign: { checked: false, price: 1050 },
    IMTMAMemberNonExhibitor: { checked: false, price: 42000 },
  },
  {
    position: "Double Spread (With Early Bird Incentive)",
    IMTMAMemberExhibitor: { checked: false, price: 35000 },
    nonMemberExhibitor: { checked: false, price: 37000 },
    foreign: { checked: false, price: 950 },
    IMTMAMemberNonExhibitor: { checked: false, price: 37000 },
  },
];

export default Advertisement;
