import React from 'react';
import './ExhibitorCard.css'
import { IconButton } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StarIcon from '@mui/icons-material/Star';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { DEFAULT_DP } from '../constants/constants';
import { formatToSimpleDate } from '../utility/utility';
const NewsCard = ({
    item
}) => {
    const markFav = ()=>{
        toast("Marked as Favorite!")
    }
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: '10px',
            overflow: 'hidden'
        }}>
            <img src={item.image} style={{
                 width: '100%',
                 height: '192px',
            }}/>
            <div style={{marginLeft: '12px', marginRight: '12px'}}>
                
                <div>
                    <div style={{display: 'flex', marginTop: '12px',fontSize: '12px',}}>
                        <div
                        style={{
                            userSelect: 'none',
        cursor: 'pointer',
        borderRadius: '6px',
        padding: '2px 6px', 
        color: 'white',
        width: '100px',
        backgroundColor: 'blue',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
                        }}
                           >{item.title}
                        </div>
                        <div
                        style={{color: '#777', width: '76px', marginLeft: '12px', alignSelf: 'center',}}
                        >{formatToSimpleDate(item.createdOn)}</div>
                        <div
                        style={{alignSelf: 'center', flex: 1,  textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',}}
                        >by {item.creator}</div>
                    </div>
                    <Link to='' style={{textDecoration: 'none'}}
                    ><div style={{
                        fontWeight: 500, 
                        margin: '12px 10px',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        overflow: 'hidden'
                        }}>
                        This is Link to a Website With s o many things availableo many things availableo many things available in this world</div></Link>
                </div>
            </div>
        </div>
    );
};

export default NewsCard