import React from "react";
import ComingSoon from "./ComingSoon";

const Reports = () => {
  return (
    <div className="rd-dash-content">
      <ComingSoon />
    </div>
  );
};

export default Reports;
