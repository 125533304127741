import React from "react";
import vBg from "../../../assets/sBg.png"; // Path to your image
import { Box, Card, Paper } from "@mui/material";
import QRCode from "react-qr-code";
export const EbadgeService = React.forwardRef((props, ref) => {
  const { user, isPreview = false } = props;
  const style = {
    badgeContainer: {
      marginTop: "16px",
      position: "relative",
      width: "9.5cm",
      height: "14cm",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
      alignItems: "center",
      color: "black",
      padding: "1cm", // Adjust padding as needed
      boxSizing: "border-box",
    },
    detailsContainer: {
      textAlign: "center",
      marginTop: "4cm",
    },
    qrCode: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      marginTop: "0.5cm", // Adjust the margin as needed
      width: "3.5cm", // Adjust size as needed
      height: "3.5cm", // Adjust size as needed
    },
  };

  return (
    <div ref={ref} style={style.badgeContainer}>
      <div style={style.detailsContainer}>
        {isPreview && (
          <img
            src={vBg}
            alt="Badge Background"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />
        )}
        <Box
          sx={{
            position: "absolute",
            top: "4.3cm",
            left: "1cm",
            right: "1cm",
            bottom: "1cm",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          {/* <Box sx={{ fontWeight: "bold", fontSize: "17px", mt: 1 }}>
            {`${user.selectedCountry.name}`}
          </Box> */}
          <Box mt={4} gap={4} display={"flex"}>
            <Box>
              <img
                style={{
                  height: "130px",
                  width: "130px",
                  objectFit: "contain",
                }}
                src={user.photoUrl}
              />
            </Box>
            <Box>
              <QRCode value={user.barcode_id} size={130} />
            </Box>
          </Box>
          <Box sx={{ fontWeight: "bold", fontSize: "17px", mt: 2 }}>
            {`${user?.companyName?.toUpperCase()}`}
          </Box>
          <Box sx={{ fontWeight: "bold", fontSize: "24px", mt: 1 }}>
            {`${user?.name?.toUpperCase()}`}
          </Box>
        </Box>
      </div>
      <div />
    </div>
  );
});
