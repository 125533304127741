import React from "react";
import {
  Paper,
  Button,
  Typography,
  TextField,
  Box,
  Container,
  Alert,
} from "@mui/material";
import { CloudDownload, CloudUpload } from "@mui/icons-material";
import NextPrev from "./reuse/NextPrev";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { OES_MANUAL } from "../../../../constants/constants";
const Manual = () => {
  return (
    <Container>
      <Paper elevation={3} style={{ padding: "2rem" }}>
        <Box mb={2}>
          <Typography variant="h5">Exhibitor Manual</Typography>
        </Box>
        <Typography variant="h6" gutterBottom>
          Required files and documents
        </Typography>
        <Typography gutterBottom>
          Download files, upload required documents and attach forwarded files
          if needed
        </Typography>

        <Box my={3}>
          <Typography variant="subtitle1">FILES TO DOWNLOAD</Typography>
          <Button
            sx={{ mt: 2, mb: 2, textTransform: "none" }}
            variant="outlined"
            startIcon={<PictureAsPdfIcon color="error" />}
            component="a" // Use <a> tag as the root component of the Button
            href={OES_MANUAL} // Link to the PDF
            target="_blank" // Open in a new tab
            rel="noopener noreferrer" // For security reasons when using target="_blank"
          >
            PUNE MACHINE TOOL EXPO 2024.pdf
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Manual;
