import React from "react";
import { Box, Alert } from "@mui/material";
import MuPb from "../../../../widgets/MuPb";
import { getErrorResponse } from "../../../../utils2024/utils";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import { useParams } from "react-router-dom";
import BackBtn from "../../admin/widgets/BackBtn";

import ProfileViewer from "../../common/ProfileViewer";
import ExhibitorDetail from "./ExhibitorDetail";

const ExhibitorProfileView = () => {
  const { id } = useParams();
  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useCommonGetInit(`user/get-user-by-id?id=${id}`);

  if (isLoading) {
    return <MuPb />;
  }

  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }

  return (
    <Box>
      <ProfileViewer id={id} />
      <BackBtn />
      <ExhibitorDetail response={response} />
    </Box>
  );
};
export default ExhibitorProfileView;
