import React, { useState } from "react";
import { ICON_TYPE_NON_IMAGE } from "../constants/constants";

const RdIconContainer = ({
  icon,
  anyRef = null,
  onClick,
  bg = "rgb(238 237 237)",
  hoverBg = "#ddd",
  myStyle = {},
}) => {
  const [hover, setHover] = useState(false);
  const rdIconStyle = {
    display: icon.show ? "flex" : "none",
    cursor: "pointer",
    backgroundColor: hover ? hoverBg : bg,
    margin: "8px",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
    WebkitUserSelect: "none",
    ...myStyle,
  };
  return (
    <div
      ref={anyRef}
      className="rd-menu-icon"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={rdIconStyle}
      onClick={onClick}
    >
      {icon.type === ICON_TYPE_NON_IMAGE ? (
        icon.icon
      ) : (
        <img className="header-profile-img" src={icon.icon} alt={icon.desc} />
      )}
    </div>
  );
};

export default RdIconContainer;
