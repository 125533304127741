import { Cropper } from "react-cropper";
import { useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import { Grid, Button, Typography, Input, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { base64ToBlob } from "../utility/utility";
function UploadImage({
  onUpdated,
  sizeLimit = 500,
  aspect_ratio = 1,
  show,
  setShow,
}) {
  const cropperRef = useRef(null);
  const [image, setImage] = useState(null);
  const image_selector = useRef();

  const onInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const cancelCrop = () => {
    setImage(null);
    if (image_selector.current) {
      image_selector.current.value = "";
    }
    setShow(false);
  };

  const croppedImage = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const base64Image = cropper.getCroppedCanvas().toDataURL();

    // remove prefix 'data:image/png;base64,' from the string
    const base64ImageContent = base64Image.replace(
      /^data:image\/(png|jpg);base64,/,
      ""
    );
    const blob = base64ToBlob(base64ImageContent, "image/png");
    const sizeInKB = blob.size / 1024;
    if (sizeInKB > sizeLimit) {
      toast.error(
        `Image size is more than ${sizeLimit}KB. Please choose a smaller image or reduce its quality.`
      );
      return;
    }
    cancelCrop();
    onUpdated(base64Image);
  };
  return (
    <Box style={{ height: "70vh" }}>
      {/* Informing user about aspect ratio and supported formats */}
      <Box
        sx={{
          display: "table",
          color: "#777",
          fontSize: "14px",
          padding: "8px",
          border: "1px dotted #ddd",
          marginBottom: "16px",
        }}
      >
        <Input
          type="file"
          key={show}
          inputProps={{ accept: "image/jpeg, image/png, image/jpg" }}
          onChange={onInputChange}
        />
        <Typography variant="body2">
          Aspect Ratio: {aspect_ratio}:1 (Max size: {sizeLimit}KB)
        </Typography>
        <Typography variant="body2">
          Supported Formats: PNG, JPEG, JPG
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Cropper
            key={show}
            src={image}
            style={{ height: 300, width: "100%" }}
            initialAspectRatio={aspect_ratio}
            aspectRatio={aspect_ratio}
            preview=".img-preview"
            ref={cropperRef}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography align="center">Preview</Typography>
          <div
            key={show}
            className="img-preview"
            style={{
              width: "100%",
              height: "172px",
              overflow: "hidden",
              display: "inline-block",
            }}
          ></div>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "12px",
        }}
      >
        <LoadingButton
          size="small"
          variant="contained"
          color="primary"
          onClick={croppedImage}
          style={{ margin: "0 8px" }}
        >
          Upload
        </LoadingButton>
        <LoadingButton
          size="small"
          variant="outlined"
          color="secondary"
          onClick={cancelCrop}
          style={{ margin: "0 8px" }}
        >
          Cancel
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default UploadImage;
