import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import useCities from "./hooks/useCities";

function CitiesCom({ state, handleStateChange, label = "Select City*" }) {
  const { data, isLoading, isError, error } = useCities(
    state.selectedCountry ? state.selectedCountry.isoCode : "",
    state.selectedState ? state.selectedState.isoCode : ""
  );
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="selectedCity"
        value={state.selectedCity}
        label={label}
        onChange={handleInputChange}
      >
        {data &&
          data.map((target, i) => (
            <MenuItem key={i} value={target.name}>
              {target.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default CitiesCom;
