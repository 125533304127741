import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItemButton,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
  Home,
  Settings,
  Hotel,
  NetworkCheck,
  BusinessCenter,
  ManualRecord,
  Marketing,
  BarChart,
  MobileFriendly,
  Site,
  DataUsage,
  People,
  Business,
  Book,
  LocalOffer,
  PhoneAndroid,
  LocationOn,
  Storage,
} from '@mui/icons-material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const menuItems = [
    {
      name: 'Event Setup',
      icon: <Settings />,
      submenus: ['General', 'Build Website', 'Sign In Page', 'New UI Settings', 'Payments', 'Module Management'],
    },
    {
      name: 'Registration Settings',
      icon: <PersonAddIcon />,
      submenus: ['Exhibitor', 'Visitor', 'Registration Emails', 'Badges'],
    },
    {
      name: 'Hospitality',
      icon: <Hotel />,
      submenus: ['Accommodation', 'Transportation', 'Activities', 'Groups', 'Bookings', 'Dinner Seating', 'Travel Requests', 'Sent Emails'],
    },
    {
      name: 'Networking & Matchmaking',
      icon: <People />,
      submenus: ['Meetings', 'Messaging', 'Delegate List', 'Search', 'Categories', 'Data Matching', 'Matchmaking', 'Filters', 'Messages', 'Recommendations', 'Contact Sharing'],
    },
    {
      name: 'Management',
      icon: <Business />,
      submenus: ['Participants', 'Exhibitors', 'Sponsors', 'Sessions', 'Floor Plan'],
    },
    {
      name: 'Exhibitor Manual',
      icon: <Book />,
      submenus: ['Manual Settings', 'Global Settings', 'Vendors', 'Email Templates', 'Exhibitors'],
    },
    {
      name: 'Marketing',
      icon: <LocalOffer />,
      submenus: ['Email Sender', 'SMS Sender', 'Social', 'News', 'Slugs', 'Surveys', 'Admin Notifications', 'Forms'],
    },
    {
      name: 'Analytics',
      icon: <BarChart />,
      submenus: ['General', 'Accounts Activity', 'Online Meetings', 'Real-Time Meetings', 'Online Sessions', 'On-Demand Sessions'],
    },
    {
      name: 'Mobile App Builder',
      icon: <PhoneAndroid />,
      submenus: [],
    },
    {
      name: 'OnSite',
      icon: <LocationOn />,
      submenus: ['On-Site Form', 'Kiosk Self Registration', 'Check-in', 'Check-in Analytics'],
    },
    {
      name: 'Data',
      icon: <Storage />,
      submenus: ['Import/Export', 'Log Info', 'GDPR Data', 'WebHooks'],
    }
  ];
  
  

export default function DrawerMenu() {
  const [openId, setOpenId] = useState(null);

  const handleToggleSubmenu = (id) => {
    setOpenId(openId === id ? null : id);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: '240px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '240px',
          boxSizing: 'border-box',
        },
      }}
    >
      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.name}>
            <ListItemButton onClick={() => handleToggleSubmenu(index)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
              {openId === index ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openId === index} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.submenus.map((submenu) => (
                  <ListItemButton key={submenu} sx={{ paddingLeft: 4 }}>
                    <ListItemText primary={submenu} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}
