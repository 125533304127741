import React from "react";

import HeaderEditor from "./editors/headerEditor";
import LinkEditor from "./editors/linkEditor";
import RdMyModal from "../../../../../widgets/myModal/RdMyModal";
import useModalState from "../../hooks/useModalState";
import LabelEditor from "./editors/LabelEditor";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FirstName from "./editors/firstName";
import LabelUpdater from "./editors/LabelUpdater";
import { maxBy } from "lodash";
import useBannerModal from "../../hooks/useBannerModal";
import BannerAdEditor from "./editors/bannerAdEditor";
import BannerEditor from "./editors/bannerEditor";

const FormEditor = () => {
  const {
    isModalVisible,
    setModalVisibility,
    modalData,
    showModal,
    hideModal,
  } = useModalState();
  const { isBMVisible, setBMVisible, bannerData, showBM, hideBM } =
    useBannerModal();
  return (
    <div style={{ padding: "16px", maxHeight: "76vh", overflowY: "auto" }}>
      <RdMyModal
        showModal={isModalVisible}
        setShowModal={setModalVisibility}
        modalC={<LabelEditor propName={modalData} hide={hideModal} />}
        mWidth={"640px"}
      />
      <RdMyModal
        showModal={isBMVisible}
        setShowModal={setBMVisible}
        modalC={<BannerEditor propName={bannerData} hide={hideBM} />}
        mWidth={"640px"}
      />
      <HeaderEditor />
      <LinkEditor showModal={showModal} hide={hideModal} />
      <BannerAdEditor showBM={showBM} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`firstName`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`lastName`}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "4px" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`designation`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`phone`}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "4px" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`email`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`companyName`}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "4px" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`address`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`country`}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "4px" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`pin`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`state`}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "4px" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`city`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`website`}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "4px", mb: "16px" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`telephone`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`department`}
          />
        </Grid>
      </Grid>
      <LabelUpdater
        showModal={showModal}
        hide={hideModal}
        propName={`eventAds`}
      />
      <Grid sx={{ mt: "4px", mb: "16px" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`industrySector`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`categories`}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mb: 2 }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`isTermsAccepted`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelUpdater
            showModal={showModal}
            hide={hideModal}
            propName={`notes`}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FormEditor;
