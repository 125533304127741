import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Typography,
  Divider,
  Paper,
  Autocomplete,
  Checkbox,
  ThemeProvider,
  Alert,
  Avatar,
} from "@mui/material";
import {
  getHeader,
  getUser,
  getUserName,
  useQuery,
} from "../../../../utility/utility";
import BackBtn from "../../admin/widgets/BackBtn";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ExpoTheme } from "../../../../theme/theme";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MuPb from "../../../../widgets/MuPb";
import {
  API_ENDPOINT,
  EXHIBITOR,
  USER,
  VISITOR,
} from "../../../../constants/constants";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toast } from "react-toastify";
import axios from "axios";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import { getErrorResponse, toastError } from "../../../../utils2024/utils";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
function ScheduleMeeting() {
  const navigate = useNavigate();
  const query = useQuery();
  const tid = query.get("tid");
  const { isLoading, isError, error, data } = useCommonGetInit(
    `user/get-meeting-people?id=${tid}`
  );

  const [requesting, setRequesting] = useState(false);
  const [meeting_type, setMeetingType] = useState("");
  const [duration, setDuration] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedOtherMembers, setSelectedOtherMembers] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [otherMembersData, setOtherMembersData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date("2025-01-23T10:00:00")
  );
  useEffect(() => {
    if (data) {
      if (data?.meeter?.role === VISITOR) {
        const mainContact = {
          id: data?.meeter?.username,
          name: `${data?.meeter?.firstName} ${data?.meeter?.lastName}`,
          profile_image: data?.meeter?.profile_image,
          email: data?.meeter?.email,
        };
        setSelectedMembers([mainContact]);
      } else if (data?.meeter?.role === EXHIBITOR) {
        const mainContact = {
          id: data?.meeter?.username,
          name: `${data?.meeter?.contactFirstName.value} ${data?.meeter?.contactLastName.value}`,
          profile_image: data?.meeter?.profile_image,
          email: data?.meeter?.companyEmail.value,
        };

        setSelectedMembers([mainContact]);

        const msA = [mainContact];
        data?.members?.forEach((m) => {
          if (m.email !== mainContact.email) {
            msA.push({
              id: m._id,
              name: `${m.firstName} ${m.lastName}`,
              email: m.email,
              profile_image: m.profile_image,
            });
          }
        });
        setMembersData(msA);
      }
      if (data?.meeting?.role === EXHIBITOR) {
        const msA =
          data?.othermembers?.map((m) => ({
            id: m._id,
            name: `${m.firstName} ${m.lastName}`,
            email: m.email,
            profile_image: m.profile_image,
          })) || [];

        setOtherMembersData(msA);
      }
      if (data?.meeting?.role === VISITOR) {
        setOtherMembersData([
          {
            id: data.meeting.username,
            name: `${data.meeting.firstName} ${data.meeting.lastName}`,
            email: data.meeting.email,
            profile_image: data.meeting.profile_image,
          },
        ]);
        setSelectedOtherMembers([
          {
            id: data.meeting.username,
            name: `${data.meeting.firstName} ${data.meeting.lastName}`,
            email: data.meeting.email,
            profile_image: data.meeting.profile_image,
          },
        ]);
      }
    }
  }, [data]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedMembers.length) {
      toast.error("Please add at least one member.");
      return; // Stop the execution if this condition fails
    }
    if (!meeting_type) {
      toast.error("Please select a meeting type.");
      return; // Stop the execution if this condition fails
    }
    if (!duration) {
      toast.error("Please select the duration of the meeting.");
      return; // Stop the execution if this condition fails
    }
    if (!subject.trim()) {
      toast.error("Please enter a subject.");
      return; // Stop the execution if this condition fails
    }
    if (!message.trim()) {
      toast.error("Please enter a message.");
      return; // Stop the execution if this condition fails
    }

    const dataToSubmit = {
      selectedDate,
      selectedMembers,
      selectedOtherMembers,
      meeting_type,
      duration,
      subject,
      message,
    };
    try {
      setRequesting(true);

      const { data } = await axios.post(
        `${API_ENDPOINT}user/schedule-meeting?tid=${tid}`,
        dataToSubmit,
        getHeader()
      );
      toast.success(data.message);
      setTimeout(() => {
        navigate(`/${getUser()?.role}/meetings`);
      }, 200);
    } catch (error) {
      toastError(error);
    } finally {
      setRequesting(false);
    }
  };

  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }

  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box p={2}>
        <Box sx={{ maxWidth: "1100px", ml: "auto", mr: "auto" }}>
          <BackBtn />
          <Typography fontWeight={"bold"} mt={2} variant="h5">
            Meeting Scheduler
          </Typography>
          <Box mt={2} container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize={"17px"} mb={1}>
                You are scheduling a meeting with
              </Typography>
              <Box display={"flex"}>
                <Avatar src={data?.meeting?.profile_image} />
                <Box pl={2}>
                  <Typography fontWeight={600} fontSize={"17px"}>
                    {data?.meeting && getUserName(data.meeting)}
                  </Typography>
                  <Typography fontWeight={500} fontSize={"14px"}>
                    {data?.meeting?.role === "visitor"
                      ? `${data?.meeting?.companyName}`
                      : `${data?.meeting?.contactFirstName?.value} ${data?.meeting?.contactLastName?.value}`}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Box>
              <Box mt={2} mb={2}>
                <Divider />
                <Typography mt={2} fontWeight={"bold"} variant="h5">
                  Meeting Data
                </Typography>
                <Typography fontSize={"17px"}>
                  Please fill the following information for the meeting
                </Typography>
              </Box>
            </Box>
            <Paper style={{ padding: 16 }}>
              <Grid container spacing={2}>
                {/* Left Block */}
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    {/* Location */}
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        disabled={data?.meeter?.role !== EXHIBITOR}
                        value={selectedMembers}
                        options={membersData}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected}
                              style={{ marginRight: 8 }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>{option.name}</span>
                              <span
                                style={{ fontSize: "0.8em", color: "gray" }}
                              >
                                {option.email}
                              </span>
                            </div>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Add who will join from your side*"
                            placeholder="Add member"
                          />
                        )}
                        onChange={(event, newValue) => {
                          setSelectedMembers(newValue);
                        }}
                      />

                      <Autocomplete
                        multiple
                        disabled={data?.meeting?.role === VISITOR}
                        sx={{ mt: 2 }}
                        value={selectedOtherMembers}
                        options={otherMembersData}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected}
                              style={{ marginRight: 8 }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>{option.name}</span>
                              <span
                                style={{ fontSize: "0.8em", color: "gray" }}
                              >
                                {option.email}
                              </span>
                            </div>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Who you want to meet from other side*`}
                            placeholder="Add member"
                          />
                        )}
                        onChange={(event, newValue) => {
                          setSelectedOtherMembers(newValue);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Meeting Type*</InputLabel>
                        <Select
                          label="Meeting Type*"
                          value={meeting_type}
                          onChange={(e) => setMeetingType(e.target.value)}
                        >
                          <MenuItem disabled value="Online Meeting">
                            Online Meeting
                          </MenuItem>
                          <MenuItem value="OnSite Meeting">
                            OnSite Meeting
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Duration */}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="duration-label">
                          Duration of the meeting*
                        </InputLabel>
                        <Select
                          labelId="duration-label"
                          id="duration"
                          label="Duration of the meeting*"
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                        >
                          <MenuItem value={15}>15 mins</MenuItem>
                          <MenuItem value={30}>30 mins</MenuItem>
                          <MenuItem value={45}>45 mins</MenuItem>
                          <MenuItem value={60}>60 mins</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Right Block */}
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    {/* Subject */}
                    <Grid item xs={12}>
                      <TextField
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        fullWidth
                        label="Subject"
                        required
                      />
                    </Grid>
                    {/* Message */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Message"
                        required
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        inputProps={{ maxLength: 200 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      minDateTime={new Date("2025-01-23T10:00:00")}
                      maxDateTime={new Date("2025-01-29T17:00:00")}
                      sx={{ width: "100%", mt: 2 }}
                      label="Select Date and Time*"
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"end"}
                  pt={2}
                  pb={2}
                >
                  <LoadingButton
                    loading={requesting}
                    onClick={handleSubmit}
                    startIcon={<CalendarMonthIcon />}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send Request
                  </LoadingButton>
                </Box>
              </Grid>
            </Paper>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
export default ScheduleMeeting;
