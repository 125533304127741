import React, { useEffect, useRef, useState } from "react";
import { Typography, Box, CardContent, Card, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Image as KonvaImage, Layer, Line, Rect, Stage } from "react-konva";
import _ from "lodash";
import { hexToRGBA } from "../../../utility/utility";
import { TRANSPARENCY } from "../../../constants/constants";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import BackBtn from "../../dashboard/admin/widgets/BackBtn";
import { getScales } from "./HallView";

export function getImageDimen(imgWidth, imgHeight) {
  const width = Math.min(imgWidth, 1280);
  const height = width * (imgHeight / imgWidth);
  return { width, height };
}

function GeneralView() {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-floor?id=1&pb=imtex2025`
  );
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [hoveredShape, setHoveredShape] = useState(null);
  const layerRef = useRef(null);

  const [image, setImage] = useState(null);
  const [stageScale, setStageScale] = useState(1);
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [newHalls, setNewHalls] = useState();

  const stageRef = useRef(null);

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.08;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    const minScale = 0.5;
    const maxScale = 3;
    const finalScale = Math.max(minScale, Math.min(maxScale, newScale));

    setStageScale(finalScale);
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newPos = {
      x: pointer.x - mousePointTo.x * finalScale,
      y: pointer.y - mousePointTo.y * finalScale,
    };

    setStagePos(newPos);
  };

  useEffect(() => {
    if (data && !image) {
      const img = new window.Image();
      img.src = data.image;
      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [data]);
  useEffect(() => {
    if (data && image && containerRef) {
      const containerWidth = containerRef.current.offsetWidth;
      setDimensions({
        width: containerWidth,
        height: 560,
      });
      const { scaleX, scaleY } = getScales(
        containerWidth,
        560,
        image.width,
        image.height
      );

      const imageX =
        (containerWidth -
          Math.min(containerWidth, (560 / image.height) * image.width)) /
        2;
      const halls = data.halls.map((hall) => {
        if (hall.shape.type === "Rectangle") {
          return {
            ...hall,
            shape: {
              ...hall.shape,
              shape: {
                ...hall.shape.shape,
                x: hall.shape.shape.x * scaleX + imageX,
                y: hall.shape.shape.y * scaleY,
                width: hall.shape.shape.width * scaleX,
                height: hall.shape.shape.height * scaleY,
              },
            },
          };
        } else if (hall.shape.type === "Polygon") {
          return {
            ...hall,
            shape: {
              ...hall.shape,
              shape: hall.shape.shape.map((point, index) =>
                index % 2 === 0 ? point * scaleX + imageX : point * scaleY
              ),
            },
          };
        }
        return hall;
      });
      setNewHalls(halls);
    }
  }, [data, image, containerRef]);

  return (
    <Box maxWidth={1280} m="8px auto">
      <Card>
        <CardContent>
          <Stack direction={"row"} gap={2} mb={1}>
            <BackBtn />
            <Typography variant="h6">General Plan</Typography>
            <Typography variant="body1" alignSelf={"center"}>
              Click on the marked hall to view details
            </Typography>
          </Stack>

          {data?.image ? (
            <Box
              border={"1px solid #ddd"}
              ref={containerRef}
              sx={{
                overflow: "hidden",
                position: "relative",
              }}
            >
              {image ? (
                <Stage
                  width={dimensions.width}
                  height={dimensions.height}
                  scaleX={stageScale}
                  scaleY={stageScale}
                  x={stagePos.x}
                  y={stagePos.y}
                  ref={stageRef}
                  draggable
                  onWheel={handleWheel}
                >
                  <Layer ref={layerRef}>
                    <KonvaImage
                      image={image.img}
                      width={Math.min(
                        dimensions.width,
                        (dimensions.height / image.height) * image.width
                      )}
                      height={Math.min(
                        dimensions.height,
                        (dimensions.width / image.width) * image.height
                      )}
                      x={
                        (dimensions.width -
                          Math.min(
                            dimensions.width,
                            (dimensions.height / image.height) * image.width
                          )) /
                        2
                      }
                      y={0}
                    />

                    {newHalls
                      ?.filter((hall) => hall.shape.type === "Polygon")
                      .map((hall, index) => (
                        <Line
                          key={index}
                          points={hall.shape.shape}
                          fill={
                            hoveredShape === `polygon-${index}`
                              ? hexToRGBA(hall.hoverColor || "#FFCC00", 0.6)
                              : hexToRGBA(hall.color, TRANSPARENCY)
                          }
                          stroke={
                            hoveredShape === `polygon-${index}`
                              ? hexToRGBA(hall.hoverStrokeColor || "#FFCC00", 1)
                              : hexToRGBA(hall.color, TRANSPARENCY)
                          }
                          strokeWidth={hall.strokeWidth}
                          closed
                          onClick={() => {
                            navigate(`/hall-view/${hall.name}`);
                          }}
                          onMouseEnter={() => {
                            console.log("Hovered on polygon:", index);
                            setHoveredShape(`polygon-${index}`);
                          }}
                          onMouseLeave={() => setHoveredShape(null)}
                        />
                      ))}

                    {newHalls
                      ?.filter((hall) => hall.shape.type === "Rectangle")
                      .map((hall, index) => (
                        <Rect
                          key={index}
                          x={hall.shape.shape.x}
                          y={hall.shape.shape.y}
                          width={hall.shape.shape.width}
                          height={hall.shape.shape.height}
                          rotation={hall.shape.shape.rotation || 0}
                          fill={
                            hoveredShape === `rectangle-${index}`
                              ? hexToRGBA(hall.hoverColor || "#FFCC00", 0.6)
                              : hexToRGBA(hall.color, TRANSPARENCY)
                          }
                          stroke={
                            hoveredShape === `rectangle-${index}`
                              ? hexToRGBA(hall.hoverStrokeColor || "#FFCC00", 1)
                              : hexToRGBA(hall.color, TRANSPARENCY)
                          }
                          strokeWidth={hall.strokeWidth}
                          onClick={() => {
                            console.log(hall);
                          }}
                          onMouseEnter={() => {
                            console.log("Hovered on rectangle:", index);
                            setHoveredShape(`rectangle-${index}`);
                          }}
                          onMouseLeave={() => setHoveredShape(null)}
                        />
                      ))}
                  </Layer>
                </Stage>
              ) : (
                <Box sx={{ p: 3, width: "100%", height: "100%" }}>
                  Loading ...
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                p: 3,
                fontWeight: 600,
                color: "#ccc",
                width: "100%",
                height: "100%",
              }}
            >
              Floor Plan not available
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default GeneralView;
