import React from "react";
import vBg from "../../../assets/visitor_bg.png"; // Path to your image
import { Box, Button, Card } from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useReactToPrint } from "react-to-print";
import { BadgeToPrint } from "./BadgeToPrint";

const PrintBadge = ({ user }) => {
  const printRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        justifyContent: "center",
        pt: 2,
      }}
    >
      <Button
        onClick={handlePrint}
        sx={{ position: "absolute", top: 0, right: 0, textTransform: "none" }}
        startIcon={<LocalPrintshopIcon />}
      >
        Print
      </Button>
      <BadgeToPrint isPreview={true} ref={printRef} user={user} />
    </Box>
  );
};

export default PrintBadge;
