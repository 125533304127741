import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  CardActions,
  IconButton,
  Box,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import React from "react";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { getUser } from "../../../utility/utility";

const TeamMembersList = ({ user }) => {
  const { data, isLoading, isError } = useCommonGetInit(
    `user/get-team-members?id=${user.username}`
  );
  return (
    <Box display="flex" gap={2} flexWrap="wrap">
      {data?.data?.map((member, index) => (
        <Card
          key={index}
          sx={{
            width: 244,
            borderRadius: 2,
            boxShadow: 3,
            textAlign: "center",
            padding: 2,
          }}
        >
          <Box display="flex" justifyContent="center" mb={2}>
            <Avatar
              src={member.profile_image}
              sx={{ width: 80, height: 80, bgcolor: "primary.main" }}
            >
              {!member.profile_image &&
                `${member.firstName.charAt(0)}${member.lastName.charAt(0)}`}
            </Avatar>
          </Box>
          <CardContent sx={{ padding: 1 }}>
            <Typography variant="h6" component="div">
              {`${member.firstName} ${member.lastName}`}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {member.designation}
            </Typography>
            {member.email && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={0.5}
              >
                <EmailIcon color="action" sx={{ mr: 0.5, fontSize: 18 }} />
                <Typography variant="body2" color="textPrimary">
                  {member.email}
                </Typography>
              </Box>
            )}
            {member.phone && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <PhoneIcon color="action" sx={{ mr: 0.5, fontSize: 18 }} />
                <Typography variant="body2" color="textPrimary">
                  {member.phone}
                </Typography>
              </Box>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: "center", paddingTop: 0 }}>
            {member.email && (
              <IconButton
                aria-label="send email"
                href={`/${getUser()?.role}/chat?tid=${member.owner}`}
                size="small"
              >
                <ForumOutlinedIcon fontSize="small" />
              </IconButton>
            )}
            {member.phone && (
              <IconButton
                aria-label="call"
                href={`/${getUser()?.role}/meeting-scheduler?tid=${
                  member.owner
                }`}
                size="small"
              >
                <CalendarMonthIcon fontSize="small" />
              </IconButton>
            )}
          </CardActions>
        </Card>
      ))}
    </Box>
  );
};

export default TeamMembersList;
