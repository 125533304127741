import React from "react";
import { Stage, Layer, Group, Rect, Circle } from "react-konva";

const TestKonva = () => {
  return (
    <Stage width={window.innerWidth} height={window.innerHeight}>
      <Layer>
        {/* Group of shapes that will transform together */}
        <Group x={100} y={100} draggable>
          <Rect x={0} y={0} width={100} height={100} fill="red" />
          <Circle x={150} y={50} radius={50} fill="blue" />
        </Group>
      </Layer>
    </Stage>
  );
};

export default TestKonva;
