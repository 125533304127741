import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "./utility/utility";
import MuPb from "./widgets/MuPb";

const Logout = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    logoutUser();
  }, []);
  function logoutUser() {
    setTimeout(() => {
      deleteUser();
      navigate("/login");
    }, 200);
  }
  if (loading) return <MuPb />;
  return <div></div>;
};

export default Logout;
