import React, { useState } from "react";

const InfoCard = ({ text, num, icon, color }) => {
  const [hover, setHover] = useState(false);

  const containerStyle = {
    position: "relative",
    display: "flex",
    flex: "1 0 calc(25% - 20px)",
    backgroundColor: "white",
    border: hover ? `1px solid ${color}` : "1px solid white",
    boxShadow: `0 10px 20px ${color}1A, 0 6px 6px ${color}38`,
    borderRadius: "12px",
    padding: "24px 8px",
    cursor: "pointer",
    userSelect: "none",
    WebkitUserSelect: "none",
    transition: "transform 0.2s ease-in-out", // Add the transition property
    transform: hover ? "translateY(-4px)" : "none", // Add translateY transformation on hover
  };

  const iconStyle = {
    height: "32px",
    width: "32px",
    position: "absolute",
    right: 8,
    top: 8,
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={containerStyle}
    >
      <img src={icon} style={iconStyle} alt="Icon" />
      <div>
        <div style={{ fontWeight: 700, fontSize: "24px", color: color }}>
          {num}
        </div>
        <div style={{ fontSize: "14px", color: "#777" }}>{text}</div>
      </div>
    </div>
  );
};

export default InfoCard;
