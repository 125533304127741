import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { TITLES } from "../../utility/utility";
import RdDesignationsSelect from "../../visitorregt/components/previewpage/RdDesignationsSelect";
import PhoneInput from "react-phone-number-input";
import CountriesCom from "../../visitorregt/components/previewpage/CountriesCom";
import StatesCom from "../../visitorregt/components/previewpage/StatesCom";
import CitiesCom from "../../visitorregt/components/previewpage/CitiesCom";

const InputPage = ({ state, handleStateChange }) => {
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };
  return (
    <Box>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Basic Information</div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <div style={{ display: "flex" }}>
              <FormControl
                sx={{ width: "115px", marginRight: "4px" }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label">Title*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={state.title}
                  name="title"
                  label="Title*"
                  onChange={handleInputChange}
                >
                  {TITLES.map((position, i) => (
                    <MenuItem key={i} value={position}>
                      {position}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                size="small"
                name="firstName"
                value={state.firstName}
                onChange={handleInputChange}
                label="First Name"
                variant="outlined"
                fullWidth
              />
            </div>
          </div>
          <div className="visitor-items-sec">
            <TextField
              required
              size="small"
              name="lastName"
              value={state.lastName}
              onChange={handleInputChange}
              label="Last Name"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="designation">
            <RdDesignationsSelect
              state={state}
              handleStateChange={handleStateChange}
              label="Designation"
            />
          </div>
          <div
            className="visitor-items-sec"
            id="mobileNumber"
            style={{ position: "relative" }}
          >
            {state.phone && state.phone.length > 0 && (
              <label
                style={{
                  position: "absolute",
                  top: "-12px",
                  left: "56px",
                  color: "#1876d1",
                  fontSize: "14px",
                  backgroundColor: "white",
                }}
                htmlFor="phone-input"
              >
                Mobile number
              </label>
            )}
            <PhoneInput
              defaultCountry="IN"
              placeholder="Mobile number"
              required
              name="phone"
              value={state.phone}
              onChange={(phone) => handleStateChange("phone", phone)}
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <TextField
                  size="small"
                  name="email"
                  value={state.email}
                  onChange={handleInputChange}
                  label="Email"
                  variant="outlined"
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Company Information</div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <TextField
              size="small"
              name="companyName"
              value={state.companyName}
              onChange={handleInputChange}
              label="Company Name*"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="visitor-items-sec">
            <TextField
              size="small"
              name="address"
              value={state.address}
              onChange={handleInputChange}
              label="Address"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="selectedCountry">
            <CountriesCom
              state={state}
              handleStateChange={handleStateChange}
              label="Select Country"
            />
          </div>
          <div className="visitor-items-sec" id="selectedState">
            <StatesCom
              state={state}
              handleStateChange={handleStateChange}
              label="Select State"
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="selectedCity">
            <CitiesCom
              state={state}
              handleStateChange={handleStateChange}
              label="Select City"
            />
          </div>
          <div className="visitor-items-sec" id="pin">
            <TextField
              size="small"
              name="pin"
              value={state.pin}
              onChange={handleInputChange}
              label="Pin/Zip"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <TextField
              size="small"
              name="website"
              onChange={handleInputChange}
              label="Website"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="visitor-items-sec">
            <TextField
              size="small"
              name="telephone"
              value={state.telephone}
              onChange={handleInputChange}
              label="Telephone No"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default InputPage;
