import React from "react";
import { EXHIBITOR } from "../constants/constants";
import ExhibitorCard from "./ExhibitorCard";
import VisitorCard from "./VisitorCard";

const CommonItemCard = ({ item }) => {
  return (
    <>
      {item.role === EXHIBITOR ? (
        <ExhibitorCard item={item} isOneline={true} />
      ) : (
        <VisitorCard item={item} />
      )}
    </>
  );
};

export default CommonItemCard;
