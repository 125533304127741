import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import {
  Group,
  Image as KonvaImage,
  Layer,
  Line,
  Rect,
  Stage,
  Text,
} from "react-konva";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TRANSPARENCY } from "../../../constants/constants";
import { getHoverColor, hexToRGBA, isLoggedIn } from "../../../utility/utility";
import BackBtn from "../../dashboard/admin/widgets/BackBtn";
import CloseIcon from "@mui/icons-material/Close";
import locationIcon from "../../../assets/location_icon.png";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { getImageDimen } from "./GeneralView";
import ClearIcon from "@mui/icons-material/Clear";
import UserDetailCard from "./UserDetailCard";
import { HoverComponent } from "./HoverComponent";
export const getRelativePointerPosition = (node) => {
  const transform = node.getAbsoluteTransform().copy();
  transform.invert();
  const pos = node.getStage().getPointerPosition();
  return transform.point(pos);
};
export function getScales(
  containerWidth,
  containerHeight,
  imgWidth,
  imgHeight
) {
  const { width: originalWidth, height: originalHeight } = getImageDimen(
    imgWidth,
    imgHeight
  );
  const newWidth = Math.min(
    containerWidth,
    (containerHeight / imgHeight) * imgWidth
  );
  const newHeight = Math.min(
    containerHeight,
    (containerWidth / imgWidth) * imgHeight
  );
  const scaleX = newWidth / originalWidth;
  const scaleY = newHeight / originalHeight;
  return { scaleX, scaleY };
}
const HallView = () => {
  const { id } = useParams();
  const [query] = useSearchParams();
  const un = query.get("un");
  const navigate = useNavigate();
  const [selectedChip, setSelectedChip] = useState(null);
  const { data: Halls } = useCommonGetInit(
    `user/get-hall-view?id=${id}&pb=imtex2025`
  );
  const [image, setImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [stageScale, setStageScale] = useState(1);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [newHalls, setNewHalls] = useState();
  const hoverTimeout = useRef(null);

  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const stageRef = useRef(null);

  const [hoverData, setHoverData] = useState({
    show: false,
    hall: null,
    stand: null,
    position: { x: 0, y: 0, width: 0, height: 0 },
  });
  const [lastDist, setLastDist] = useState(0);
  const [lastCenter, setLastCenter] = useState(null);

  const [selectCategory, setSelectCategory] = useState("");
  const [selectCountry, setSelectCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locationImg, setLocationImg] = useState(null);
  const [filteredExhibitors, setFilteredExhibitors] = useState();
  const [currentExhibitor, setCurrentExhibitor] = useState();

  useEffect(() => {
    const img = new window.Image();
    img.src = locationIcon; // Replace with your image path
    img.onload = () => setLocationImg(img);
  }, []);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    setStagePos({ x: 0, y: 0 });
    setStageScale(1); // Reset the zoom to default when the image URL changes
  }, [id]);

  useEffect(() => {
    if (!selectCountry && !selectCategory) {
      setFilteredExhibitors([]);
      return;
    }
    const filtered = Halls?.exhibitors?.filter((exhibitor) => {
      let matches = true;
      if (selectCountry) {
        matches = matches && exhibitor?.country?.value?.name === selectCountry;
      }

      // Apply category filter if a category is selected
      if (selectCategory) {
        matches =
          matches && exhibitor.exhibitorCategory.value.includes(selectCategory);
      }

      return matches;
    });

    setFilteredExhibitors(filtered);
  }, [selectCategory, selectCountry]);

  useEffect(() => {
    if (un && Halls) {
      const exhibitor = Halls.exhibitors.find((item) => item.username === un);

      setFilteredExhibitors([exhibitor]);
    }
  }, [un, Halls]);

  const isHallHighlighted = (hall) => {
    return filteredExhibitors?.some(
      (exhibitor) =>
        exhibitor.hallNumber.value === Halls.hall.name &&
        exhibitor.standNumber.value === hall.name
    );
  };

  const getDistance = (touch1, touch2) => {
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const getCenter = (touch1, touch2) => {
    return {
      x: (touch1.clientX + touch2.clientX) / 2,
      y: (touch1.clientY + touch2.clientY) / 2,
    };
  };

  const handleTouchMove = (e) => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    const touches = e.evt.touches;

    if (touches.length === 2) {
      const newDist = getDistance(touches[0], touches[1]);
      if (lastDist) {
        const scaleRatio = newDist / lastDist;
        const newScale = stageScale * scaleRatio;

        const center = getCenter(touches[0], touches[1]);
        if (lastCenter) {
          const deltaX = center.x - lastCenter.x;
          const deltaY = center.y - lastCenter.y;

          const newPos = {
            x: stage.x() - deltaX * scaleRatio,
            y: stage.y() - deltaY * scaleRatio,
          };

          stage.scale({ x: newScale, y: newScale });
          stage.position(newPos);
          stage.batchDraw();
        }
        setLastCenter(center);
        setStageScale(newScale);
      }
      setLastDist(newDist);
    }
  };

  const handleTouchEnd = () => {
    setLastDist(0);
    setLastCenter(null);
  };

  const handleMouseEnter = (event, hall) => {
    clearTimeout(hoverTimeout.current);
    setHoverData((prevData) => {
      if (!prevData.show || prevData.hall !== hall.hall) {
        return {
          show: true,
          hall: Halls.hall,
          stand: hall,
          position: {
            x: hall.shape.shape.x,
            y: hall.shape.shape.y,
            width: hall.shape.shape.width,
            height: hall.shape.shape.height,
          },
        };
      }
      return prevData;
    });
  };

  const handleMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      setHoverData((prev) => ({ ...prev, show: false }));
    }, 100);
  };
  useEffect(() => {
    return () => clearTimeout(hoverTimeout.current);
  }, []);

  useEffect(() => {
    if (Halls) {
      if (Halls?.exhibitors) {
        const uniqueCountryNames = [
          ...new Set(
            Halls.exhibitors
              .map((exhibitor) => exhibitor.country?.value?.name)
              .filter((name) => name) // Filter out undefined or falsy values
          ),
        ];
        setCountries(uniqueCountryNames);
        const uniqueExhibitorCategories = [
          ...new Set(
            Halls.exhibitors.map(
              (exhibitor) => exhibitor.exhibitorCategory.value
            )
          ),
        ];
        setCategories(uniqueExhibitorCategories);
      }

      setSelectedChip(Halls.hall.name);
      const img = new window.Image();
      img.src = Halls.hall.image;

      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [Halls]);

  useEffect(() => {
    if (Halls && image && containerRef) {
      const containerWidth = containerRef.current.offsetWidth;
      setDimensions({
        width: containerWidth,
        height: 560,
      });
      const { scaleX, scaleY } = getScales(
        containerWidth,
        560,
        image.width,
        image.height
      );

      const imageX =
        (containerWidth -
          Math.min(containerWidth, (560 / image.height) * image.width)) /
        2;

      const halls = Halls.stands.map((hall) => {
        if (hall.shape.type === "Rectangle") {
          return {
            ...hall,
            shape: {
              ...hall.shape,
              shape: {
                ...hall.shape.shape,
                x: hall.shape.shape.x * scaleX + imageX,
                y: hall.shape.shape.y * scaleY,
                width: hall.shape.shape.width * scaleX,
                height: hall.shape.shape.height * scaleY,
              },
            },
          };
        } else if (hall.shape.type === "Polygon") {
          return {
            ...hall,
            shape: {
              ...hall.shape,
              shape: hall.shape.shape.map((point, index) =>
                index % 2 === 0 ? point * scaleX + imageX : point * scaleY
              ),
            },
          };
        }
        return hall;
      });
      setNewHalls(halls);
    }
  }, [Halls, image, containerRef]);

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.09;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    const minScale = 0.5;
    const maxScale = 3;
    const finalScale = Math.max(minScale, Math.min(maxScale, newScale));

    setStageScale(finalScale);
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newPos = {
      x: pointer.x - mousePointTo.x * finalScale,
      y: pointer.y - mousePointTo.y * finalScale,
    };

    setStagePos(newPos);
  };
  const onViewExhibitor = (hall, stand) => {
    const exhibitor = Halls.exhibitors.find(
      (item) =>
        item.hallNumber.value === hall.name &&
        item.standNumber.value === stand.name
    );
    setCurrentExhibitor(exhibitor);
    setIsOpen(true);
  };
  function truncateText(text, maxWidth, fontSize) {
    const exhibitor = Halls?.exhibitors?.find(
      (d) =>
        d.hallNumber.value === Halls.hall.name && d.standNumber.value === text
    );
    const name = exhibitor ? exhibitor.companyName.value : "";
    const approxCharWidth = fontSize * 0.6; // Approximate width of each character
    const maxChars = Math.floor(maxWidth / approxCharWidth);

    if (name.length > maxChars) {
      return name.substring(0, maxChars - 3) + ".."; // Subtract 3 for the ellipsis
    }
    return name;
  }

  return (
    <Box maxWidth={1280} m="8px auto">
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          width: "300px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "300px",
            boxSizing: "border-box",
          },
        }}
      >
        <IconButton
          size="small"
          onClick={() => setIsOpen(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 20,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box>
          {currentExhibitor && <UserDetailCard user={currentExhibitor} />}
        </Box>
      </Drawer>
      <Box>
        <Stack
          p={1}
          gap={2}
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "stretch", sm: "center" }}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <BackBtn />
          <Box flex={1}></Box>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            gap={2}
            flexGrow={1}
            alignItems="center"
          >
            {/* Select Hall */}
            <FormControl
              size="small"
              variant="outlined"
              sx={{ minWidth: { xs: "100%", sm: 200 } }}
            >
              <InputLabel>Select Hall</InputLabel>
              <Select
                value={selectedChip || ""}
                onChange={(event) => {
                  const hallName = event.target.value;
                  if (id !== hallName) {
                    setImage(null);
                    navigate(`/hall-view/${hallName}`, { replace: true });
                  }
                }}
                label="Select Hall"
              >
                <MenuItem value="" disabled>
                  Select Hall
                </MenuItem>
                {Halls?.halls?.map((hall, i) => (
                  <MenuItem
                    key={i}
                    value={hall.name}
                    sx={{
                      fontWeight:
                        selectedChip === hall.name ? "bold" : "normal",
                    }}
                  >
                    {hall.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Select Category */}
            <FormControl
              size="small"
              variant="outlined"
              sx={{ minWidth: { xs: "100%", sm: 200 } }}
            >
              <InputLabel>Select Category</InputLabel>
              <Select
                value={selectCategory || ""}
                onChange={(event) => setSelectCategory(event.target.value)}
                label="Select Category"
                endAdornment={
                  selectCategory && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSelectCategory("")}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                <MenuItem value="" disabled>
                  Select Category
                </MenuItem>
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Select Country */}
            <FormControl
              size="small"
              variant="outlined"
              sx={{ minWidth: { xs: "100%", sm: 200 } }}
            >
              <InputLabel>Select Country</InputLabel>
              <Select
                value={selectCountry || ""}
                onChange={(event) => setSelectCountry(event.target.value)}
                label="Select Country"
                endAdornment={
                  selectCountry && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSelectCountry("")}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                <MenuItem value="" disabled>
                  Select Country
                </MenuItem>

                {countries.map((country, index) => (
                  <MenuItem key={index} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Card ref={containerRef} sx={{ mt: 1 }} variant="outlined">
          {hoverData.show && (
            <HoverComponent
              allExhibitors={Halls?.exhibitors}
              hall={hoverData.hall}
              stand={hoverData.stand}
            />
          )}
          {image ? (
            <Stage
              width={dimensions.width}
              height={dimensions.height}
              scaleX={stageScale}
              scaleY={stageScale}
              ref={stageRef}
              x={stagePos.x}
              y={stagePos.y}
              onWheel={handleWheel}
              draggable={true}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <Layer>
                <KonvaImage
                  image={image.img}
                  width={Math.min(
                    dimensions.width,
                    (dimensions.height / image.height) * image.width
                  )}
                  height={Math.min(
                    dimensions.height,
                    (dimensions.width / image.width) * image.height
                  )}
                  x={
                    (dimensions.width -
                      Math.min(
                        dimensions.width,
                        (dimensions.height / image.height) * image.width
                      )) /
                    2
                  }
                  y={0}
                />

                {newHalls
                  ?.filter((hall) => hall.shape.type === "Polygon")
                  .map((hall) => (
                    <Group>
                      <Line
                        points={hall.shape.shape}
                        fill={hexToRGBA(hall.color, TRANSPARENCY)}
                        stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                        strokeWidth={hall.strokeWidth}
                        closed={true}
                        onMouseEnter={(e) => {
                          const shape = e.target;
                          shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                          shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                          handleMouseEnter(e, hall);
                        }}
                        onMouseLeave={(e) => {
                          const shape = e.target;
                          shape.fill(hexToRGBA(hall.color, TRANSPARENCY)); // Reset to the original color on mouse leave
                          shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                          handleMouseLeave();
                        }}
                        onClick={(e) => {
                          onViewExhibitor(Halls.hall, hall);
                        }}
                        onTap={(e) => {
                          onViewExhibitor(Halls.hall, hall);
                        }}
                      />
                    </Group>
                  ))}
                {newHalls
                  ?.filter((hall) => hall.shape.type === "Rectangle")
                  .map((hall) => (
                    <Group>
                      <Rect
                        x={hall.shape.shape.x}
                        y={hall.shape.shape.y}
                        width={hall.shape.shape.width}
                        height={hall.shape.shape.height}
                        fill={hexToRGBA(hall.color, TRANSPARENCY)}
                        stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                        strokeWidth={hall.strokeWidth}
                        onClick={(e) => {
                          onViewExhibitor(Halls.hall, hall);
                        }}
                        onTap={(e) => {
                          onViewExhibitor(Halls.hall, hall);
                        }}
                        onMouseEnter={(e) => {
                          const shape = e.target;
                          shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                          shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                          handleMouseEnter(e, hall);
                        }}
                        onMouseLeave={(e) => {
                          const shape = e.target;
                          shape.fill(hexToRGBA(hall.color, TRANSPARENCY)); // Reset to the original color on mouse leave
                          shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                          handleMouseLeave();
                        }}
                      />

                      {locationImg && isHallHighlighted(hall) && (
                        <KonvaImage
                          image={locationImg}
                          x={hall.shape.shape.x + 4}
                          y={hall.shape.shape.y - 10}
                          width={24}
                          height={24}
                        />
                      )}
                      <Text
                        text={truncateText(
                          hall.name,
                          hall.shape.shape.width,
                          8
                        )}
                        x={hall.shape.shape.x + 4}
                        y={hall.shape.shape.y + hall.shape.shape.height / 2 - 4} // Center text vertically
                        fill={"black"}
                        fontSize={8} // Adjust the font size
                        fontWeight="bold"
                      />
                    </Group>
                  ))}
              </Layer>
            </Stage>
          ) : (
            <Box sx={{ p: 3, width: "100%", height: "100%" }}>Loading ...</Box>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default HallView;
