import React from "react";
import PhoneInput from "react-phone-number-input";

const MyPhoneInput = ({ label = "Mobile number", phone, setPhone }) => {
  return (
    <div
      className="visitor-items-sec"
      id="mobileNumber"
      style={{ position: "relative" }}
    >
      {phone && phone.length > 0 && (
        <label
          style={{
            position: "absolute",
            top: "-12px",
            left: "56px",
            color: "#1876d1",
            fontSize: "14px",
            backgroundColor: "white",
          }}
          htmlFor="phone-input"
        >
          {label}
        </label>
      )}
      <PhoneInput
        limitMaxLength={12}
        defaultCountry="IN"
        placeholder={label}
        required
        name="phone"
        value={phone}
        onChange={(ph) => setPhone(ph)}
      />
    </div>
  );
};

export default MyPhoneInput;
