import { useQuery } from "react-query";
import axios from "axios";
import { USER } from "../../../constants/constants";
import { getCommonUrl } from "../../../utils2024/utils";

const fetchItem = async (id) => {
  const url = getCommonUrl(`${USER}/get-floor?id=${id}&`);
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 404:
          throw new Error("Not Found");
        case 500:
          throw new Error("Internal Server Error");
        default:
          throw new Error(error.response.data);
      }
    } else {
      // If there's no error.response, it might be a network issue or something else
      throw error;
    }
  }
};

export const useFloorPlan = (id) => {
  const {
    data,
    error,
    isError,
    isLoading,
    refetch: refetchFloorPlan,
  } = useQuery(["get-floor", id], () => fetchItem(id), {
    enabled: false,
    staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 0, // If the query fails, it won't retry
  });

  return { data, error, isError, isLoading, refetchFloorPlan };
};

export default useFloorPlan;
