import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import {
  Group,
  Image as KonvaImage,
  Layer,
  Line,
  Rect,
  Stage,
  Text,
} from "react-konva";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TRANSPARENCY } from "../../../constants/constants";
import { hexToRGBA, isLoggedIn } from "../../../utility/utility";
import BackBtn from "../../dashboard/admin/widgets/BackBtn";
import CloseIcon from "@mui/icons-material/Close";
import locationIcon from "../../../assets/location_icon.png";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import ClearIcon from "@mui/icons-material/Clear";
import throttle from "lodash/throttle";
import { getScales } from "./HallView";
import MobileUserCard from "./MobileUserCard";
const MobileHallView = () => {
  const { id } = useParams();
  const [query] = useSearchParams();
  const un = query.get("un");
  const navigate = useNavigate();
  const [selectedChip, setSelectedChip] = useState(null);
  const { data: Halls } = useCommonGetInit(
    `user/get-hall-view?id=${id}&pb=imtex2025`
  );
  const [image, setImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [stageScale, setStageScale] = useState(1);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [newHalls, setNewHalls] = useState();

  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const stageRef = useRef(null);

  const [lastDist, setLastDist] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [lastTouchPos, setLastTouchPos] = useState(null);

  const [selectCategory, setSelectCategory] = useState("");
  const [selectCountry, setSelectCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locationImg, setLocationImg] = useState(null);
  const [filteredExhibitors, setFilteredExhibitors] = useState();
  const [currentExhibitor, setCurrentExhibitor] = useState();
  const MIN_SCALE = 0.5;
  const MAX_SCALE = 3;

  useEffect(() => {
    const img = new window.Image();
    img.src = locationIcon; // Replace with your image path
    img.onload = () => setLocationImg(img);
  }, []);

  useEffect(() => {
    setStagePos({ x: 0, y: 0 });
    setStageScale(1); // Reset the zoom to default when the image URL changes
  }, [id]);

  useEffect(() => {
    if (!selectCountry && !selectCategory) {
      setFilteredExhibitors([]);
      return;
    }
    const filtered = Halls?.exhibitors?.filter((exhibitor) => {
      let matches = true;
      if (selectCountry) {
        matches = matches && exhibitor?.country?.value?.name === selectCountry;
      }

      // Apply category filter if a category is selected
      if (selectCategory) {
        matches =
          matches && exhibitor.exhibitorCategory.value.includes(selectCategory);
      }

      return matches;
    });

    setFilteredExhibitors(filtered);
  }, [selectCategory, selectCountry]);

  useEffect(() => {
    if (un && Halls) {
      const exhibitor = Halls.exhibitors.find((item) => item.username === un);

      setFilteredExhibitors([exhibitor]);
    }
  }, [un, Halls]);

  const isHallHighlighted = (hall) => {
    return filteredExhibitors?.some(
      (exhibitor) =>
        exhibitor.hallNumber.value === Halls.hall.name &&
        exhibitor.standNumber.value === hall.name
    );
  };

  // Helper functions
  const getDistance = (p1, p2) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  };

  const getCenter = (p1, p2) => {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  };

  // Handle touch start
  const handleTouchStart = (e) => {
    e.evt.preventDefault();
    const touch1 = e.evt.touches[0];
    const touch2 = e.evt.touches[1];

    if (e.evt.touches.length === 1) {
      // Single touch point - start panning
      setIsDragging(true);
      setLastTouchPos({
        x: touch1.clientX,
        y: touch1.clientY,
      });
    } else if (e.evt.touches.length === 2) {
      // Two touch points - start zooming
      const p1 = { x: touch1.clientX, y: touch1.clientY };
      const p2 = { x: touch2.clientX, y: touch2.clientY };

      const center = getCenter(p1, p2);
      setLastDist(getDistance(p1, p2));
    }
  };

  // Handle touch move
  const handleTouchMove = throttle((e) => {
    e.evt.preventDefault();
    const stage = stageRef.current;

    if (e.evt.touches.length === 1 && isDragging) {
      // Single touch point - handle panning
      const touch = e.evt.touches[0];
      const touchPos = {
        x: touch.clientX,
        y: touch.clientY,
      };

      const deltaX = touchPos.x - lastTouchPos.x;
      const deltaY = touchPos.y - lastTouchPos.y;

      const newPos = {
        x: stagePos.x + deltaX,
        y: stagePos.y + deltaY,
      };

      setStagePos(newPos);
      setLastTouchPos(touchPos);
    } else if (e.evt.touches.length === 2) {
      // Two touch points - handle zooming
      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      const p1 = { x: touch1.clientX, y: touch1.clientY };
      const p2 = { x: touch2.clientX, y: touch2.clientY };

      const newCenter = getCenter(p1, p2);
      const dist = getDistance(p1, p2);

      let newScale = (stageScale * dist) / lastDist;
      newScale = Math.max(MIN_SCALE, Math.min(MAX_SCALE, newScale));

      // Adjust position to keep the content under the fingers
      const pointTo = {
        x: (newCenter.x - stagePos.x) / stageScale,
        y: (newCenter.y - stagePos.y) / stageScale,
      };

      const newPos = {
        x: newCenter.x - pointTo.x * newScale,
        y: newCenter.y - pointTo.y * newScale,
      };

      setStageScale(newScale);
      setStagePos(newPos);
      setLastDist(dist);
    }
  }, 16); // Throttle to roughly 60fps

  // Handle touch end
  const handleTouchEnd = (e) => {
    e.evt.preventDefault();
    if (e.evt.touches.length === 0) {
      // All touches ended
      setIsDragging(false);
      setLastTouchPos(null);
      setLastDist(0);
    } else if (e.evt.touches.length === 1) {
      setLastDist(0);

      const touch = e.evt.touches[0];
      setIsDragging(true);
      setLastTouchPos({
        x: touch.clientX,
        y: touch.clientY,
      });
    }
  };
  useEffect(() => {
    if (Halls) {
      if (Halls?.exhibitors) {
        const uniqueCountryNames = [
          ...new Set(
            Halls.exhibitors
              .map((exhibitor) => exhibitor.country?.value?.name)
              .filter((name) => name) // Filter out undefined or falsy values
          ),
        ];
        setCountries(uniqueCountryNames);
        const uniqueExhibitorCategories = [
          ...new Set(
            Halls.exhibitors.map(
              (exhibitor) => exhibitor.exhibitorCategory.value
            )
          ),
        ];
        setCategories(uniqueExhibitorCategories);
      }

      setSelectedChip(Halls.hall.name);
      const img = new window.Image();
      img.src = Halls.hall.image;

      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [Halls]);

  useEffect(() => {
    if (Halls && image && containerRef) {
      const containerWidth = 480; // containerRef.current.offsetWidth;
      const containerHeight = 480;
      setDimensions({
        width: containerWidth,
        height: containerHeight,
      });
      const { scaleX, scaleY } = getScales(
        containerWidth,
        containerHeight,
        image.width,
        image.height
      );

      const imageX = 0;

      const halls = Halls.stands.map((hall) => {
        if (hall.shape.type === "Rectangle") {
          return {
            ...hall,
            shape: {
              ...hall.shape,
              shape: {
                ...hall.shape.shape,
                x: hall.shape.shape.x * scaleX + imageX,
                y: hall.shape.shape.y * scaleY,
                width: hall.shape.shape.width * scaleX,
                height: hall.shape.shape.height * scaleY,
              },
            },
          };
        } else if (hall.shape.type === "Polygon") {
          return {
            ...hall,
            shape: {
              ...hall.shape,
              shape: hall.shape.shape.map((point, index) =>
                index % 2 === 0 ? point * scaleX + imageX : point * scaleY
              ),
            },
          };
        }
        return hall;
      });
      setNewHalls(halls);
    }
  }, [Halls, image, containerRef]);

  const onViewExhibitor = (hall, stand) => {
    const exhibitor = Halls.exhibitors.find(
      (item) =>
        item.hallNumber.value === hall.name &&
        item.standNumber.value === stand.name
    );
    setCurrentExhibitor(exhibitor);
    setIsOpen(true);
  };
  function truncateText(text, maxWidth, fontSize) {
    const exhibitor = Halls?.exhibitors?.find(
      (d) =>
        d.hallNumber.value === Halls.hall.name && d.standNumber.value === text
    );
    const name = exhibitor ? exhibitor.companyName.value : "";
    const approxCharWidth = fontSize * 0.6; // Approximate width of each character
    const maxChars = Math.floor(maxWidth / approxCharWidth);

    if (name.length > maxChars) {
      return name.substring(0, maxChars - 3) + ".."; // Subtract 3 for the ellipsis
    }
    return name;
  }

  return (
    <Box maxWidth={1280} m="8px auto">
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          width: "300px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "300px",
            boxSizing: "border-box",
          },
        }}
      >
        <IconButton
          size="small"
          onClick={() => setIsOpen(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 20,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box>
          {currentExhibitor && <MobileUserCard user={currentExhibitor} />}
        </Box>
      </Drawer>
      <Box>
        <Stack
          p={1}
          gap={2}
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "stretch", sm: "center" }}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            gap={2}
            flexGrow={1}
            alignItems="center"
          >
            {/* Select Hall */}
            <FormControl
              size="small"
              variant="outlined"
              sx={{ minWidth: { xs: "100%", sm: 200 } }}
            >
              <InputLabel>Select Hall</InputLabel>
              <Select
                value={selectedChip || ""}
                onChange={(event) => {
                  const hallName = event.target.value;
                  if (id !== hallName) {
                    setImage(null);
                    navigate(`/mobile-hall-view/${hallName}`, {
                      replace: true,
                    });
                  }
                }}
                label="Select Hall"
              >
                <MenuItem value="" disabled>
                  Select Hall
                </MenuItem>
                {Halls?.halls?.map((hall, i) => (
                  <MenuItem
                    key={i}
                    value={hall.name}
                    sx={{
                      fontWeight:
                        selectedChip === hall.name ? "bold" : "normal",
                    }}
                  >
                    {hall.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Select Category */}
            <FormControl
              size="small"
              variant="outlined"
              sx={{ minWidth: { xs: "100%", sm: 200 } }}
            >
              <InputLabel>Select Category</InputLabel>
              <Select
                value={selectCategory || ""}
                onChange={(event) => setSelectCategory(event.target.value)}
                label="Select Category"
                endAdornment={
                  selectCategory && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSelectCategory("")}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                <MenuItem value="" disabled>
                  Select Category
                </MenuItem>
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Select Country */}
            <FormControl
              size="small"
              variant="outlined"
              sx={{ minWidth: { xs: "100%", sm: 200 } }}
            >
              <InputLabel>Select Country</InputLabel>
              <Select
                value={selectCountry || ""}
                onChange={(event) => setSelectCountry(event.target.value)}
                label="Select Country"
                endAdornment={
                  selectCountry && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSelectCountry("")}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                <MenuItem value="" disabled>
                  Select Country
                </MenuItem>

                {countries.map((country, index) => (
                  <MenuItem key={index} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Card
          ref={containerRef}
          sx={{ mt: 1, width: 360, height: 860 }}
          variant="outlined"
        >
          {image ? (
            <Stage
              width={360}
              height={830}
              scaleX={stageScale}
              scaleY={stageScale}
              ref={stageRef}
              x={stagePos.x}
              y={stagePos.y}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <Layer>
                <Group rotation={90} x={360} y={0}>
                  <KonvaImage
                    image={image.img}
                    width={Math.min(
                      dimensions.width,
                      (dimensions.height / image.height) * image.width
                    )}
                    height={Math.min(
                      dimensions.height,
                      (dimensions.width / image.width) * image.height
                    )}
                    x={0}
                    y={0}
                  />

                  {newHalls
                    ?.filter((hall) => hall.shape.type === "Polygon")
                    .map((hall) => (
                      <Group>
                        <Line
                          points={hall.shape.shape}
                          fill={hexToRGBA(hall.color, TRANSPARENCY)}
                          stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                          strokeWidth={hall.strokeWidth}
                          closed={true}
                          onClick={(e) => {
                            onViewExhibitor(Halls.hall, hall);
                          }}
                          onTap={(e) => {
                            onViewExhibitor(Halls.hall, hall);
                          }}
                        />
                      </Group>
                    ))}
                  {newHalls
                    ?.filter((hall) => hall.shape.type === "Rectangle")
                    .map((hall) => (
                      <Group>
                        <Rect
                          x={hall.shape.shape.x}
                          y={hall.shape.shape.y}
                          width={hall.shape.shape.width}
                          height={hall.shape.shape.height}
                          fill={hexToRGBA(hall.color, TRANSPARENCY)}
                          stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                          strokeWidth={hall.strokeWidth}
                          onClick={(e) => {
                            onViewExhibitor(Halls.hall, hall);
                          }}
                          onTap={(e) => {
                            onViewExhibitor(Halls.hall, hall);
                          }}
                        />

                        {locationImg && isHallHighlighted(hall) && (
                          <KonvaImage
                            image={locationImg}
                            x={hall.shape.shape.x + 4}
                            y={hall.shape.shape.y - 10}
                            width={24}
                            height={24}
                          />
                        )}
                        <Text
                          text={truncateText(
                            hall.name,
                            hall.shape.shape.width,
                            8
                          )}
                          x={hall.shape.shape.x + 4}
                          y={
                            hall.shape.shape.y + hall.shape.shape.height / 2 - 4
                          } // Center text vertically
                          fill={"black"}
                          fontSize={8} // Adjust the font size
                          fontWeight="bold"
                        />
                      </Group>
                    ))}
                </Group>
              </Layer>
            </Stage>
          ) : (
            <Box sx={{ p: 3, width: "100%", height: "100%" }}>Loading ...</Box>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default MobileHallView;
