import React, { useState } from "react";
import { API_ENDPOINT, USER } from "../../../../constants/constants";
import ProductItem from "../../../../widgets/ProductItem";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import SearchBar from "../../../../widgets/searchBar";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import { LoadingButton } from "@mui/lab";
import FilterListIcon from "@mui/icons-material/FilterList";
import useApiEndpoint from "../../../events/hooks/useApiEndpoint";
import FilterDialogMui from "../../../../widgets/FilterDialogMui";
import AlphabetSortWidget from "./AlphabetSortWidget";

const Products = () => {
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedHall, setSelectedHall] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedLetter, setSelectedLetter] = useState("");
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-explore-products?page=${page}&sq=${sq}&hall=${selectedHall}&country=${selectedCountry}&letter=${selectedLetter}`
  );
  const onEditProduct = () => {};
  const onDeleteProduct = () => {};
  const onSelectProduct = (product) => {};

  // if (isLoading) {
  //   return <MuPb />;
  // }
  const onPageChange = (e, page) => {
    setPage(page);
  };
  const onItemSearch = (e) => {
    setSq(e.target.value);
  };
  return (
    <Box>
      <CustomCircularP show={isLoading} />
      <FilterDialogMui
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        selectedHall={selectedHall}
        setSelectedHall={setSelectedHall}
        selectedLetter={selectedLetter}
        setSelectedLetter={setSelectedLetter}
        open={openFilter}
        setOpen={setOpenFilter}
      />
      <Grid
        mt={2}
        mb={2}
        gap={2}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" fontWeight={"bold"}>
            Products
          </Typography>
        </Grid>
        <Grid item xs style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <SearchBar
            onSearch={onItemSearch}
            label="Search Products"
            size="7px"
            boxAlign="inline-flex"
            mystyle={{
              display: "inline-flex",
              backgroundColor: "white",
              width: "276px",
            }}
            cstyle={{ flex: 1 }}
          />
        </Grid>
        {/* <Grid item>
          <LoadingButton
            onClick={() => setOpenFilter(true)}
            startIcon={<FilterListIcon />}
            variant="contained"
          >
            Filter
          </LoadingButton>
        </Grid> */}
      </Grid>
      <AlphabetSortWidget
        onSelectLetter={(newV) => {
          if (newV) {
            setSelectedLetter(newV);
          } else {
            setSelectedLetter("");
          }
        }}
      />

      <Grid container spacing={3}>
        {data?.data?.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <ProductItem
              viewMode={true}
              onSelectProduct={onSelectProduct}
              onEditProduct={onEditProduct}
              onDeleteProduct={onDeleteProduct}
              key={index}
              product={item}
            />
          </Grid>
        ))}
      </Grid>
      <Box mt={2} mb={2} justifyContent={"center"} display={"flex"}>
        <Pagination
          count={Math.ceil(data?.totalItems / 30)}
          page={page}
          onChange={onPageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default Products;
