import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const MadeFloor = ({ stalls, imageUrl }) => {
  const [activeStall, setActiveStall] = useState(null);
  const navigate = useNavigate();
  const goToExhibitor = () => {
    navigate("/exhibitor/profile");
  };
  return (
    <div
      style={{
        position: "relative",
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover", // Added this line
        backgroundPosition: "center", // Center the background imag
        width: "600px",
        height: "400px",
      }}
    >
      {stalls.map((stall) => (
        <div
          key={stall.id}
          onMouseEnter={() => setActiveStall(stall.id)}
          onMouseLeave={() => setActiveStall(null)}
          style={{
            position: "absolute",
            top: stall.position.y,
            left: stall.position.x,
            width: stall.size.width,
            height: stall.size.height,
            backgroundColor: "rgba(1,104,201, 0.6)", // semi-transparent blue
            border: "2px solid black",
            boxSizing: "border-box",
            overflow: "visible",
          }}
          onClick={goToExhibitor}
        >
          {stall.name}
          {activeStall === stall.id && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: "50%",
                transform: "translateX(-50%)",
                width: "200px",
                padding: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                zIndex: 10,
                border: "1px solid #ddd",
                borderRadius: "8px",
                transition: "opacity 0.2s, transform 0.2s",
                opacity: 0.95,
              }}
            >
              <strong>{stall.company.name}</strong>
              <img
                src={stall.company.logo}
                alt={stall.company.name + " logo"}
                style={{
                  width: "50px",
                  height: "auto",
                  display: "block",
                  margin: "10px 0",
                }}
              />
              <p>{stall.description}</p>
              <a
                href={stall.company.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Website
              </a>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MadeFloor;
