import React, { useState } from "react";
import ProductItem from "../../../../widgets/ProductItem";
import AddProduct from "../AddProduct";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import axios from "axios";
import { API_ENDPOINT, USER } from "../../../../constants/constants";
import { getHeader } from "../../../../utility/utility";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import ProductDetail from "../ProductDetail";
import { Box, Button, Grid, Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import useProducts from "../hooks/useProducts";
import MuPb from "../../../../widgets/MuPb";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { StatusCodes } from "http-status-codes";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import { toastError } from "../../../../utils2024/utils";
const Products = ({ gc = 4, exhibitor, isView = false }) => {
  const PARENT_VIEW = "parent_view";
  const DETAIL_VIEW = "detail_view";
  const [reload, setReload] = useState(false);
  const [currentView, setCurrentView] = useState(PARENT_VIEW);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data, isLoading } = useCommonGetInit(
    `user/get-products?reload=${reload}`
  );
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentMode, setCurrentMode] = useState("add");
  const [deleting, setDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const onAddProduct = () => {
    setCurrentMode("add");
    setCurrentProduct(null);
    setShowAddProduct(true);
  };
  const onEditProduct = (product) => {
    setCurrentMode("edit");
    setCurrentProduct(product);
    setShowAddProduct(true);
  };

  const onDeleteProduct = (product) => {
    setCurrentProduct(product);
    setShowDelete(true);
  };
  const onProductAdded = () => {
    setReload((prev) => !prev);
    setShowAddProduct(false);
  };
  const onSelectProduct = (product) => {
    setCurrentProduct(product);
    setCurrentView(DETAIL_VIEW);
  };
  const onYesDelete = async () => {
    setDeleting(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}${USER}/delete-product?id=${currentProduct._id}`,
        getHeader()
      );
      setDeleting(false);
      if (response.status === StatusCodes.OK) {
        setShowDelete(false);
        toast.success(response.data.message);
        onProductAdded();
      }
    } catch (error) {
      setDeleting(false);
      toastError(error);
    }
  };
  if (isLoading) {
    return <MuPb />;
  }
  const onBack = () => {
    setCurrentView(PARENT_VIEW);
  };
  const mdSize = gc === 4 ? 3 : 4;
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "16px",
        borderRadius: "8px",
      }}
    >
      <CustomCircularP show={deleting} />
      <RdMyModal
        showModal={showAddProduct}
        setShowModal={setShowAddProduct}
        modalC={
          <AddProduct
            mode={currentMode}
            onAdded={onProductAdded}
            showAddProduct={showAddProduct}
            product={currentProduct}
          />
        }
      />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message="Are you sure you want to delete this?"
        onYes={onYesDelete}
        show={showDelete}
        setShow={setShowDelete}
      />
      {currentView === PARENT_VIEW ? (
        <Box>
          <Stack direction={"row"}>
            <HeaderLabel onEdit={null} showEdit={false} label={"Products"} />
            <div style={{ flex: 1 }}></div>
            {!isView && (
              <Button
                sx={{ alignSelf: "center" }}
                startIcon={<AddIcon fontSize="small" />}
                variant="outlined"
                size="small"
                color="primary"
                onClick={onAddProduct}
              >
                Add Product
              </Button>
            )}
          </Stack>
          <Box>
            <Grid container spacing={3}>
              {data?.data?.map((item, index) => (
                <Grid item xs={12} sm={6} md={mdSize} key={index}>
                  <ProductItem
                    onSelectProduct={onSelectProduct}
                    viewMode={isView}
                    onEditProduct={onEditProduct}
                    onDeleteProduct={onDeleteProduct}
                    key={index}
                    product={item}
                  />
                </Grid>
              ))}
            </Grid>

            {data?.data?.length === 0 && (
              <div
                style={{
                  margin: "16px",
                  fontSize: "20px",
                  color: "#888",
                  fontWeight: 600,
                }}
              >
                No Products Found
              </div>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          <Button
            onClick={onBack}
            variant="text"
            sx={{
              margin: "12px 0",
              textTransform: "none",
              "& .MuiButton-startIcon": {
                marginRight: 0, // Adjust the value as per your needs
              },
            }}
            size="small"
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>
          <ProductDetail exhibitor={exhibitor} product={currentProduct} />
        </Box>
      )}
    </div>
  );
};

export default Products;
