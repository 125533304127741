import React, { useState } from 'react';
import RdIconContainer from '../RdIconContainer';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ICON_TYPE_NON_IMAGE } from '../../constants/constants';
const DeleteBtn = ({ onDelete }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    const handleClick = (e)=>{
        e.stopPropagation();
        onDelete()

    }
    return (
      <div
        style={{
          display: 'flex',
          cursor: 'pointer',
          padding: '4px',
          borderRadius: '12px',
          backgroundColor: isHovered ? '#d4d4d4' : 'transparent', 
          transition: 'background-color 0.3s'
        }}
        onMouseEnter={() => setIsHovered(true)} 
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
      >
        <DeleteOutlineIcon sx={{ alignSelf: 'center', color: '#fb866b', fontSize: '13px' }} />
        <div style={{ alignSelf: 'center',color: '#777', fontSize: '12px' }}>Delete</div>
      </div>
    );
  };
  
  export default DeleteBtn;