import React, { useContext, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  Stack,
  Button,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RdMyModal from "../../widgets/myModal/RdMyModal";
import UploadImageForm from "../../widgets/UploadImageForm";
import { API_ENDPOINT } from "../../constants/constants";
import { UserContext } from "../context/UserContext";
import { RegFormContext } from "./visitor/registration/context/RegFormContext";

const ProfileBasicDisplay = () => {
  const { user, loading: profileLoading, reloadUser } = useContext(UserContext);
  const { formData, updateFormData, initUser } = useContext(RegFormContext);
  const [showProfileEdit, setshowProfileEdit] = useState(false);
  const [isProfileEdit, setisProfileEdit] = useState(false);
  const [cmode, setCmode] = useState("view");
  const handleSwitchMode = (event, newAlignment) => {
    setCmode(newAlignment);
    updateFormData("preview", newAlignment === "view");
  };
  const onEditProfilePic = () => {
    setisProfileEdit(true);
    setshowProfileEdit(true);
  };
  const onEditBannerPic = () => {
    setisProfileEdit(false);
    setshowProfileEdit(true);
  };
  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        boxShadow: 3,
        borderRadius: 2,
        overflow: "hidden",
        textAlign: "center",
        position: "relative",
      }}
    >
      <RdMyModal
        showModal={showProfileEdit}
        setShowModal={setshowProfileEdit}
        modalC={
          <UploadImageForm
            aspect_ratio={isProfileEdit ? 1 : 3}
            sizeLimit={2048}
            url={
              isProfileEdit
                ? `${API_ENDPOINT}user/upload-profile-image?upload_type=profile_image`
                : `${API_ENDPOINT}user/upload-profile-image?upload_type=profile_banner`
            }
          />
        }
      />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: 266, // Updated height to 266px
        }}
      >
        <Box
          component="img"
          src={
            user.profile_banner
              ? user.profile_banner
              : "https://via.placeholder.com/600x266"
          }
          alt="Banner"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />

        <IconButton
          onClick={onEditBannerPic}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          aria-label="edit banner"
        >
          <EditIcon />
        </IconButton>
      </Box>
      {/* Adjusted position for profile picture */}
      <Box
        sx={{
          position: "absolute",
          top: 200, // Adjusted to align with new banner height
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: 120,
            height: 120,
            border: "4px solid white",
            borderRadius: "50%",
          }}
        >
          <Avatar
            src={
              user.profile_image
                ? user.profile_image
                : "https://via.placeholder.com/150"
            }
            alt="Profile Picture"
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
          />
          <IconButton
            onClick={onEditProfilePic}
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
            aria-label="edit profile picture"
          >
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
      {/* Adjusted position for the toggle button group */}
      <Stack
        position={"absolute"}
        top={266} // Adjusted to align with new banner height
        right={"2px"}
        mt={2}
        zIndex={1}
        direction={"row"}
        gap={2}
      >
        <Box flex={1}></Box>
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={cmode}
          exclusive
          onChange={handleSwitchMode}
          aria-label="Switch edit mode"
        >
          <ToggleButton size="small" value="view">
            View
          </ToggleButton>
          <ToggleButton size="small" value="edit">
            Edit
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <Box pt={4} pb={2} sx={{ mt: 4 }}>
        <Typography variant="h6" fontWeight="bold">
          {user?.title} {user?.firstName} {user?.lastName}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {user?.designation}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileBasicDisplay;
