import React, { useContext, useState } from "react";
import { Badge, useTheme, useMediaQuery, LinearProgress } from "@mui/material";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Explore as ExploreIcon,
  Map as FloorPlanIcon,
  BusinessCenter as ExhibitorServicesIcon,
  Book as CatalogueIcon,
  Print as PrintIcon,
  FavoriteBorder as FavoriteIcon,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";

import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { APP_HEADER_LOGO } from "../../../constants/constants";
import "./DashHeader.css";
import { UserContext } from "../../context/UserContext";
import AvatarMenu from "./AvatarMenu";
import { Link } from "react-router-dom";
const VisitorHeader = () => {
  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const { user, loading } = useContext(UserContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [exploreOpen, setExploreOpen] = useState(false);
  const notifications = [];
  const unseenCount = notifications.reduce(
    (count, notification) => count + (!notification.seen ? 1 : 0),
    0
  );

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleExploreClick = () => {
    setExploreOpen(!exploreOpen);
  };

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <div className="expp-dash-header-c">
      {isLargeScreen ? (
        <Link to={`/${user.role}`}>
          <img
            src={APP_HEADER_LOGO}
            alt="Imtex Logo"
            style={{
              marginLeft: "16px",
              height: 66,
              display: "block",
              cursor: "pointer",
            }}
          />
        </Link>
      ) : (
        <IconButton
          onClick={toggleDrawer(true)}
          color="primary"
          aria-label="menu"
          size="large"
        >
          <MenuIcon fontSize="inherit" />
        </IconButton>
      )}
      <div style={{ flex: 1 }}></div>
      <div className="hd-2-item">
        <Link to={`/${user.role}/my-chats`}>
          <IconButton color="inherit">
            <ChatBubbleOutlineIcon sx={{ height: 20, width: 20 }} />
          </IconButton>
        </Link>
        <Link to={`/${user.role}/my-notifications`}>
          <IconButton color="primary" aria-label="notifications">
            <Badge badgeContent={unseenCount} color="secondary">
              <NotificationsNoneIcon />
            </Badge>
          </IconButton>
        </Link>
        <AvatarMenu />
      </div>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem
            button
            component={Link}
            to="/visitor"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={handleExploreClick}>
            <ListItemIcon>
              <ExploreIcon />
            </ListItemIcon>
            <ListItemText primary="Explore" />
            {exploreOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={exploreOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                component={Link}
                to="/visitor/explore/exhibitors"
                onClick={toggleDrawer(false)}
              >
                <ListItemText inset primary="Exhibitors" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/visitor/explore/products"
                onClick={toggleDrawer(false)}
              >
                <ListItemText inset primary="Products" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/visitor/explore/exhibitor-matching"
                onClick={toggleDrawer(false)}
              >
                <ListItemText inset primary="B2B AI Matchmaking" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            component={Link}
            to="/visitor/floor-plan"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <FloorPlanIcon />
            </ListItemIcon>
            <ListItemText primary="Floor Plan" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/visitor/favorites"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <FavoriteIcon />
            </ListItemIcon>
            <ListItemText primary="Favorites" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/visitor/print-badge"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <PrintIcon />
            </ListItemIcon>
            <ListItemText primary="Print Badge" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default VisitorHeader;
