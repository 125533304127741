import { useMutation } from "react-query";
import axios from "axios";
import { getHomeUrl } from "../../../../utils2024/utils";
async function login(loginData) {
  const url = getHomeUrl("login");
  try {
    const res = await axios.post(url, loginData);
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
}

export function useLogin() {
  const mutation = useMutation(login);
  return {
    ...mutation,
    login: mutation.mutate,
  };
}
