import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  Alert,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Paper,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect } from "react";
import dayjs from "dayjs";
import {
  getDateFormat,
  getExhibitorHeader,
  getHeader,
} from "../../../../utility/utility";
import { MAX_DATE, MIN_DATE } from "./Security";
import axios from "axios";
import {
  API_ENDPOINT,
  EMAIL_FOOTER,
  EMAIL_HEADER,
  EXHIBITOR_API,
  USER,
} from "../../../../constants/constants";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import Supplier2 from "./reuse/Supplier2";
import { reformatDate } from "./ExitPass";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import MuPb from "../../../../widgets/MuPb";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import { useParams } from "react-router-dom";
const AudioEquipment = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-user?`
  );

  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [items, setItems] = useState(AUDIO_ITEMS);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const newTotal = items.reduce((acc, item) => acc + item.subtotal, 0);
    setTotal(newTotal);
  }, [items]);

  const handleQtyChange = (index, newQty) => {
    updateSubtotal(index, newQty);
  };

  const updateSubtotal = (index, newQty = null) => {
    const updatedItems = [...items];
    if (newQty !== null) updatedItems[index].qty = newQty;

    // Check if both dates are valid and present
    const startDate = updatedItems[index].dateFrom
      ? dayjs(updatedItems[index].dateFrom)
      : null;
    const endDate = updatedItems[index].dateTo
      ? dayjs(updatedItems[index].dateTo)
      : null;
    const areDatesValid = startDate?.isValid() && endDate?.isValid();

    // Calculate the number of days only if both dates are valid
    const days = areDatesValid
      ? Math.max(endDate.diff(startDate, "days"), 0) + 1
      : 0;

    // Calculate subtotal, and default to 0 if days is 0
    updatedItems[index].subtotal =
      days > 0 ? updatedItems[index].qty * updatedItems[index].rate * days : 0;

    setItems(updatedItems);
  };

  const handleDateChange = (index, type, newValue) => {
    const updatedItems = [...items];

    // Only update the date if newValue is a valid dayjs object
    if (newValue && dayjs(newValue).isValid()) {
      updatedItems[index][type] = newValue.format("YYYY-MM-DD");
      setItems(updatedItems);
      updateSubtotal(index);
    }
  };

  const generateItemsTemplate = (items, total) => {
    // Filter items with a subtotal greater than 0
    const filteredItems = items.filter((item) => item.subtotal > 0);

    // Generate HTML for each filtered item
    const itemsHtml = filteredItems
      .map(
        (item) => `
          <tr>
            <td>${item.name}</td>
            <td>${item.qty}</td>
            <td>${item.rate.toLocaleString()}</td>
            <td>${reformatDate(item.dateFrom) || ""}</td>
            <td>${reformatDate(item.dateTo) || ""}</td>
            <td>${item.subtotal.toLocaleString()}</td>
          </tr>
        `
      )
      .join("");

    // Calculate GST and grand total based on the total
    const gst = total * 0.18;
    const grandTotal = total + gst;

    // Footer HTML with total, GST, and grand total
    const footerHtml = `
      <tfoot>
        <tr>
          <td colspan="5">Total</td>
          <td>${total.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="5">GST (18%)</td>
          <td>${gst.toLocaleString()}</td>
        </tr>
        <tr>
          <td colspan="5">Grand Total</td>
          <td>${grandTotal.toLocaleString()}</td>
        </tr>
      </tfoot>
    `;

    // Combine items HTML and footer to form the complete table
    return `
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Qty</th>
            <th>Rate</th>
            <th>Date From</th>
            <th>Date To</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          ${itemsHtml}
        </tbody>
        ${footerHtml}
      </table>
    `;
  };

  // When calling the function, make sure to pass the 'total' along with the items.
  const itemsHtmlTable = generateItemsTemplate(items, total);
  if (isLoading) {
    return <MuPb />;
  }
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="${EMAIL_HEADER}" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div> ${spaceTemplate(
      data
    )} <br />${itemsHtmlTable} <br /><img src="${EMAIL_FOOTER}" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "5.6 Audio/Visual Equipment",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      data: { supplier, items, total },
      title: "5.6 Audio/Visual Equipment",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}${USER}/submit-oes`,
        toSubmitData,
        getHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" mb={1}>
          5.6 Audio/Visual Equipment
        </Typography>
        <Box mb={4}>
          {/* <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert> */}
        </Box>
        <Typography variant="body1" gutterBottom>
          Mail to reach before 10 May 2024.
        </Typography>
        <SpaceInfo data={data} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Services/Items</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Rate/Day</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Sub Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={item.qty}
                      variant="outlined"
                      type="number"
                      onChange={(e) => handleQtyChange(index, e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={item.rate}
                      variant="outlined"
                      type="number"
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <DatePicker
                      className="exp_date_pciker"
                      slotProps={{
                        textField: {
                          helperText: "mm/dd/yyyy",
                        },
                      }}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      label="Date From"
                      size="small"
                      variant="outlined"
                      value={item.dateFrom ? dayjs(item.dateFrom) : null}
                      onChange={(newValue) =>
                        handleDateChange(index, "dateFrom", newValue)
                      }
                    />

                    <DatePicker
                      className="exp_date_pciker"
                      slotProps={{
                        textField: {
                          helperText: "mm/dd/yyyy",
                        },
                      }}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      label="Date To"
                      size="small"
                      variant="outlined"
                      value={item.dateTo ? dayjs(item.dateTo) : null}
                      onChange={(newValue) =>
                        handleDateChange(index, "dateTo", newValue)
                      }
                    />
                  </TableCell>
                  <TableCell>{item.subtotal.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </LocalizationProvider>
        <Paper sx={{ padding: "1rem", mt: 2, elevation: 0 }}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Divider />
            </Grid>

            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">Total Amount</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">18% GST</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(0.18 * total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">Total</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(1.18 * total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>
          </Grid>
        </Paper>
        <Supplier2
          user={data}
          id={parseInt(id) + 1}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};
const AUDIO_ITEMS = [
  {
    name: "LED TV 40 / 42 WITH STAND OR WALL MOUNT",
    qty: 0,
    rate: 2000,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED TV 50 / 52 WITH STAND OR WALL MOUNT",
    qty: 0,
    rate: 2500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED TV 55 WITH STAND OR WALL MOUNT",
    qty: 0,
    rate: 3500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LAPTOP WITH ACCS., CORE I5 WITH O.S.",
    qty: 0,
    rate: 750,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 3.9  INDOOR 07ft  x 09ft (8X10 SIZE)",
    qty: 0,
    rate: 9600,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 08FT X 12FT",
    qty: 0,
    rate: 11520,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 09FT X 16FT",
    qty: 0,
    rate: 19200,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 10FT X 20FT",
    qty: 0,
    rate: 24000,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 12FT X 18FT",
    qty: 0,
    rate: 25920,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 15FT X 15FT",
    qty: 0,
    rate: 30720,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 12FT X 24FT",
    qty: 0,
    rate: 34560,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "DIGITAL STILL CAMERA /DIGITAL CAMERA TO CD CONVERTION 1 No.",
    qty: 0,
    rate: 7500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "H D VIDEO CAMERA [AS PER YOUR REQUIREMENT HARD DISK WILL CHARGE PER MARKET PRICE]",
    qty: 0,
    rate: 10000,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "MULTIMEDIA SPEAKER (Small)",
    qty: 0,
    rate: 1600,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LCD PROJECTOR 3,000 ANSI LUMENS WITH SCREEN.",
    qty: 0,
    rate: 3500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "4 x 6 / 8 x 6 SCREEN",
    qty: 0,
    rate: 500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "COMPUTER WITH ACCS., CORE 2 DUO",
    qty: 0,
    rate: 1500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "HP LAZER JET PRINTER BLACK",
    qty: 0,
    rate: 2500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "HP LAZER JET PRINTER COLOUR",
    qty: 0,
    rate: 5000,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "PAR LIGHT WITH DIMMER",
    qty: 0,
    rate: 750,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED PAR LIGHT",
    qty: 0,
    rate: 500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "CORDLESS COLLER / HAND MIKE",
    qty: 0,
    rate: 1000,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "PORTABLE P A SYSTEM WITH 1 MIC & 1 AUX INPUT",
    qty: 0,
    rate: 3500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "BUILT IN POWER SPEAKER WITH STAND & 1 CORDLESS HAND MIC",
    qty: 0,
    rate: 2500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "P.A. SYSTEM [AHUJA]- i. Amplifier 1 No./ii. Speakers 2 Nos",
    qty: 0,
    rate: 3500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "Flex printing with mounding (as per Size) @ 55/-per sq.fit",
    qty: 0,
    rate: 55,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
];

export default AudioEquipment;
