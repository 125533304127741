import React, { useContext, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import { getUser } from "../../../utility/utility";
import defaultPic from "./default_avatar.jpg";
import { Box, Typography } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { VISITOR } from "../../../constants/constants";

const AvatarMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, loading } = useContext(UserContext);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Tooltip title="Account settings">
        <IconButton onClick={handleClick} size="small">
          <Avatar
            sx={{ height: 32, width: 32 }}
            alt={
              getUser()?.role === VISITOR
                ? `${user.firstName} ${user.lastName}`
                : `${user.companyName.value}`
            }
            src={user.profile_image}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              maxWidth: 240,
              width: "100%",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <img
          src={user.profile_image ? user.profile_image : defaultPic}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            height: 120,
            borderRadius: "50%",
          }}
        />
        <Typography
          variant="body1"
          fontSize={14}
          textAlign={"center"}
          sx={{ pl: 2, pr: 2 }}
          mb={2}
        >
          {getUser()?.role === VISITOR
            ? `${user.firstName} ${user.lastName}`
            : `${user.companyName.value}`}
        </Typography>
        <Link
          to={`/${getUser()?.role}/profile`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
        </Link>
        <MenuItem
          onClick={() => navigate(`/${getUser()?.role}/support-request`)}
        >
          <ListItemIcon>
            <HelpIcon fontSize="small" />
          </ListItemIcon>
          Support Requests
        </MenuItem>
        <MenuItem onClick={() => navigate("/logout")}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AvatarMenu;
