import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const MyDatePicker = ({
  value,
  fullWidth = true,
  disabled = false,
  size = "small",
  format = "DD/MM/YYYY",
  onChange,
  required = false,
  label = "Select Date",
  min,
  max,
  textSize = 16,
  ...otherProps
}) => {
  // If value is not provided, manage the state internally.
  const [internalValue, setInternalValue] = useState(null);

  const handleChange = (newValue) => {
    // If an onChange function is provided, use it; otherwise, update the internal state.
    if (onChange) {
      onChange(newValue);
    } else {
      setInternalValue(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disabled}
        slotProps={{
          textField: {
            fullWidth: fullWidth,
            size: size,

            required: required,
            sx: {
              minWidth: "132px",
              "& .MuiInputBase-input": {
                fontSize: textSize, // Input text size
              },
              "& .MuiInputLabel-root": {
                fontSize: textSize, // Label text size
              },
              "& .MuiFormHelperText-root": {
                fontSize: textSize * 0.85, // Helper text size (proportional)
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: textSize, // Placeholder text size
              },
            },
          },
        }}
        label={label}
        format={format}
        value={value !== undefined ? dayjs(value) : internalValue}
        onChange={handleChange}
        {...otherProps}
        minDate={min ? dayjs(min) : undefined}
        maxDate={min ? dayjs(max) : undefined}
      />
    </LocalizationProvider>
  );
};

export default MyDatePicker;
