import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import BackBtn from "../../admin/widgets/BackBtn";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { INTERACTION_API } from "../../../../constants/constants";
import { toast } from "react-toastify";
import { getCommonHeaderVE } from "../../../../utility/utility";

const RescheduleMeeting = ({ meeting }) => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date(meeting.data.selectedDate)
  );
  // Define the minimum and maximum date and times
  const minDateTime = new Date("2024-01-19T10:00:00");
  const maxDateTime = new Date("2024-01-23T17:00:00");
  const submit = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${INTERACTION_API}/reschedule-meeting?id=${meeting._id}`,
        { selectedDate },
        getCommonHeaderVE()
      );
      toast.success(data.message);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (error) {
      toast.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box mt={2}>
      <Typography variant="h6" fontWeight={"bold"}>
        Want to Reschedule Meeting?
      </Typography>
      <Box maxWidth={600}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            minDateTime={minDateTime}
            maxDateTime={maxDateTime}
            sx={{ width: "100%", mt: 2 }}
            label="Select Date and Time*"
            value={selectedDate}
            onChange={(newValue) => {
              setSelectedDate(newValue);
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Box>
      <LoadingButton
        onClick={submit}
        sx={{ mt: 1 }}
        variant="contained"
        size="small"
      >
        Reschedule
      </LoadingButton>
    </Box>
  );
};

export default RescheduleMeeting;
