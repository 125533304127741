import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
const EditBtn = ({ onEdit }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    onEdit();
  };
  return (
    <div
      style={{
        display: "flex",
        cursor: "pointer",
        padding: "4px",
        borderRadius: "12px",
        backgroundColor: isHovered ? "#d4d4d4" : "transparent", // Background changes based on hover state
        transition: "background-color 0.3s",
      }}
      onMouseEnter={() => setIsHovered(true)} // Set hover state to true when mouse enters
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false when mouse leaves
      onClick={handleClick}
    >
      <EditIcon sx={{ alignSelf: "center", color: "#777", fontSize: "13px" }} />
      <div style={{ alignSelf: "center", color: "#777", fontSize: "12px" }}>
        Edit
      </div>
    </div>
  );
};

export default EditBtn;
