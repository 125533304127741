import {
  Box,
  Card,
  CardContent,
  CardMedia,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";

const BrochuresList = ({ user }) => {
  const { data, isLoading } = useCommonGetInit(
    `user/get-user-brochures?id=${user.username}`
  );
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <Box display="flex" gap={2} flexWrap="wrap">
      {data?.data?.map((item, index) => (
        <Card
          key={index}
          sx={{
            width: 244,
            cursor: "pointer",
            boxShadow: 3,
            textAlign: "center",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: 6,
            },
          }}
          onClick={() => window.open(item.pdfUrl, "_blank")}
        >
          <CardMedia
            component="img"
            image={item.preview}
            alt={item.title}
            sx={{ height: 140 }}
          />
          <CardContent>
            <Typography variant="h6" component="div">
              {item.title}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default BrochuresList;
