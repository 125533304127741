import React, { useRef, useState } from "react";
import RegHeader from "../regHeader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import { useRegistrationSettings } from "../context/RegistrationSettingsContext";
import axios from "axios";
import { ADMIN_API } from "../../../../../../constants/constants";
import { getAdminMultipartHeader } from "../../../../../../utility/utility";
const HeaderEditor = () => {
  const { settings, updateProperty, isLoading, error } =
    useRegistrationSettings();
  const [anchorEl, setAnchorEl] = useState(null);

  const fileInput = useRef(null);
  const [propName, setPropName] = useState("");

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const handleButtonClick = () => {
    fileInput.current.click(); // Trigger the hidden file input click event
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${ADMIN_API}/upload-file-only`,
        formData,
        getAdminMultipartHeader()
      );
      updateProperty(propName, response.data.link);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "Update Left Icon":
        setPropName("header.leftPic");
        handleButtonClick();
        break;
      case "Update Middle Icon":
        setPropName("header.midPic");
        handleButtonClick();
        break;
      case "Update Right Icon":
        setPropName("header.rightPic");
        handleButtonClick();
        break;
      case "Update Background":
        setPropName("header.bgPic");
        handleButtonClick();
        break;
      default:
        console.warn(`Unhandled action: ${action}`);
    }
    setAnchorEl(null); // Close the menu after an item is clicked
  };

  return (
    <div style={{ position: "relative", marginBottom: "16px" }}>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Button
        variant="outlined"
        size="small"
        fullWidth
        startIcon={<EditIcon />}
        onClick={handleMenuClick}
      >
        Header
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("Update Left Icon")}>
          Update Left Icon
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Update Middle Icon")}>
          Update Middle Icon
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Update Right Icon")}>
          Update Right Icon
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Update Background")}>
          Update Background
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HeaderEditor;
