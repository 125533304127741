import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Card,
  CardContent,
  Divider,
  Paper,
  Avatar,
  Alert,
  Grid,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Switch,
  Slider,
  Rating,
  Radio,
  Checkbox,
  InputLabel,
  Container,
  LinearProgress,
  ListItemButton,
  useMediaQuery,
  IconButton,
  Drawer,
  useTheme,
} from "@mui/material";
import parse from "html-react-parser";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { extractDate, getHeader, useQuery } from "../../../utility/utility";
import DynamicRowTable from "./widgets/DynamicRowTable";
import { UserContext } from "../../context/UserContext";
import MuPb from "../../../widgets/MuPb";
import { useNavigate } from "react-router-dom";
import SelectSupplierDetail from "./widgets/SelectSupplierDetail";
import { LoadingButton } from "@mui/lab";
import BackAndForthNav from "../../../widgets/BackAndForthNav";
import LabelInput from "./widgets/LabelInput";
import MyTimePicker from "./widgets/MyTimePicker";
import MyDatePicker from "./widgets/DatePicker";
import MenuIcon from "@mui/icons-material/Menu";
import PurchaseItems from "./widgets/PurchaseItems";
import RatedItemsRender from "./widgets/RatedItemsRender";
import axios from "axios";
import { API_ENDPOINT } from "../../../constants/constants";
import { getErrorResponse, toastError } from "../../../utils2024/utils";
import { toast } from "react-toastify";
import CustomCircularP from "../../../widgets/CustomCircularP";
import TeamMembers from "./TeamMembers";
import ExhibitorBadgeInfo from "./ExhibitorBadgeInfo";

const OesForms = () => {
  const query = useQuery();
  const id = query.get("id");
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const { user, loading } = useContext(UserContext);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [formFound, setFormFound] = useState();
  const { data, isLoading, isError, error } =
    useCommonGetInit(`user/get-form-items`);
  const [selectedForm, setSelectedForm] = useState();

  useEffect(() => {
    if (id && data) {
      const form = data.find((item) => item._id === id);
      loadData(id);
    } else if (data && !id) {
      const form = data[0];
      navigate(`/exhibitor/oes-forms?id=${form._id}`);
      loadData(form._id);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [id, data]);

  if (loading) {
    return <MuPb />;
  }
  if (!user) {
    return <Alert severity="error">User not found!</Alert>;
  }
  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  function replacePlaceholders(label) {
    return label.replace(/\{(.*?)\}/g, (_, key) => {
      const plainTextKey = key.replace(/<\/?[^>]+(>|$)/g, "").trim();

      const value = user[plainTextKey]?.value;

      if (
        plainTextKey === "exhibitArrival" ||
        plainTextKey === "materialArrival" ||
        plainTextKey === "dismantling"
      ) {
        if (value) {
          return new Date(value).toLocaleDateString("en-IN");
        } else {
          return "";
        }
      }
      return value || "";
    });
  }

  const renderFinalForm = () => {
    return (
      <form>
        <Grid container width={"100%"}>
          {selectedForm?.form?.map((field) => (
            <Grid
              item
              xs={
                field?.properties?.grid ? parseInt(field.properties.grid) : 12
              }
            >
              <Box key={field.id} m={1}>
                {renderFormElementInFinalForm(field)}
              </Box>
            </Grid>
          ))}
        </Grid>
      </form>
    );
  };
  const updateFieldProperties = (fieldId, newProperties) => {
    setSelectedForm((prevForm) => ({
      ...prevForm, // Retain other properties of selectedForm
      form: prevForm.form.map((field) =>
        field.id === fieldId
          ? { ...field, properties: { ...field.properties, ...newProperties } }
          : field
      ),
    }));
  };

  const renderFormElementInFinalForm = (field) => {
    const { type, properties } = field;
    switch (type) {
      case "TextField":
        return (
          <TextField
            value={properties.value}
            onChange={(event) =>
              updateFieldProperties(field.id, { value: event.target.value })
            }
            type={properties.type}
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
            fullWidth
            variant="outlined"
            size="small"
          />
        );
      case "PlainText":
        return (
          <Typography variant={properties.variant}>
            {properties.label}
          </Typography>
        );
      case "TeamMembers":
        return (
          <Box>
            <ExhibitorBadgeInfo properties={properties} />
            <TeamMembers internal={true} />
          </Box>
        );

      case "RatedItems":
        return (
          <RatedItemsRender
            onChange={(newV) => {
              updateFieldProperties(field.id, { items: newV });
            }}
            min={properties.minDate}
            max={properties.maxDate}
            isColumnSelection={properties.columnSelection}
            headers={properties.headers}
            itemsList={properties.items}
            totalRequired={properties.showTotal}
            selectedColumn={properties.selectedColumn}
            onColumnSelect={(col) => {
              updateFieldProperties(field.id, { selectedColumn: col });
            }}
            totalVar={properties.totalVar}
          />
        );
      case "LabelInput":
        return (
          <LabelInput
            value={properties.value}
            onChange={(event) => {
              updateFieldProperties(field.id, { value: event.target.value });
            }}
            type={properties.type}
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
          />
        );
      case "PurchaseItems":
        return (
          <PurchaseItems
            cartItems={properties.value || []}
            onCartUpdate={(newV) => {
              console.log(newV);
              updateFieldProperties(field.id, { value: newV });
            }}
            itemsList={properties.items}
            isEdit={false}
          />
        );
      case "DynamicTable":
        return (
          <DynamicRowTable
            rows={properties.value || []}
            onRowsChange={(newV) => {
              updateFieldProperties(field.id, { value: newV });
            }}
            headers={properties.headers}
          />
        );
      case "DocPDF":
        return (
          <>
            {properties.link !== "" ? (
              <a href={properties.link} target="_blank">
                <Button
                  variant="outlined"
                  startIcon={<PictureAsPdfIcon />}
                  size="small"
                >
                  {properties.label}
                </Button>
              </a>
            ) : (
              <></>
            )}
          </>
        );
      case "ParagraphDescription":
        return <>{parse(replacePlaceholders(properties.label))}</>;
      case "TextArea":
        return (
          <TextField
            value={properties.value}
            onChange={(event) => {
              updateFieldProperties(field.id, { value: event.target.value });
            }}
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={properties.rows}
          />
        );

      case "Checkbox":
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={properties.checked}
                onChange={(event) => {
                  updateFieldProperties(field.id, {
                    checked: event.target.checked,
                  });
                }}
              />
            }
            label={properties.label}
            required={properties.required}
          />
        );
      case "RadioGroup":
        return (
          <FormControl component="fieldset" required={properties.required}>
            <Typography variant="subtitle1">{properties.label}</Typography>
            <RadioGroup
              value={properties.value}
              onChange={(event) => {
                updateFieldProperties(field.id, {
                  checked: event.target.value,
                });
              }}
            >
              {properties.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "Select":
        return (
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            required={properties.required}
          >
            <InputLabel>{properties.label}</InputLabel>
            <Select
              value={properties.value}
              onChange={(event) => {
                updateFieldProperties(field.id, { value: event.target.value });
              }}
              label={properties.label}
            >
              {properties.options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "Switch":
        return (
          <FormControlLabel
            control={<Switch />}
            label={properties.label}
            required={properties.required}
          />
        );
      case "DatePicker":
        return (
          <MyDatePicker
            value={properties.value}
            onChange={(newV) => {
              updateFieldProperties(field.id, { value: newV });
            }}
            min={properties.minDate}
            max={properties.maxDate}
            label={properties.label}
          />
        );
      case "TimePicker":
        return (
          <MyTimePicker
            value={properties.value}
            onChange={(newV) => {
              updateFieldProperties(field.id, { value: newV });
            }}
            label={properties.label}
          />
        );
      case "Slider":
        return (
          <div style={{ width: "100%", padding: "0 16px" }}>
            <Typography gutterBottom>{properties.label}</Typography>
            <Slider
              defaultValue={(properties.min + properties.max) / 2}
              min={properties.min}
              max={properties.max}
              step={properties.step}
              marks
              valueLabelDisplay="auto"
            />
          </div>
        );
      case "FileUpload":
        return (
          <TextField
            label={properties.label}
            type="file"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            size="small"
            required={properties.required}
          />
        );
      case "Rating":
        return (
          <div>
            <Typography component="legend">{properties.label}</Typography>
            <Rating name={field.id} />
            <Typography variant="body2">Rating component goes here</Typography>
          </div>
        );
      case "Divider":
        return <Divider />;

      default:
        return null;
    }
  };
  const moveToTop = () => {
    const mainContentBox = document.getElementById("main-content-box");
    if (mainContentBox) {
      mainContentBox.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const handleListItemClick = (form) => {
    navigate(`/exhibitor/oes-forms?id=${form._id}`);
    moveToTop();
    if (isMobile) {
      setOpenDrawer(false); // Close the drawer on mobile after selecting an item
    }
  };

  const onFormSubmit = async () => {
    try {
      setSubmittingForm(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}user/submit-oes-form?id=${id}`,
        selectedForm,
        getHeader()
      );
      toast.success(data.message);
    } catch (error) {
      toastError(error);
    } finally {
      setSubmittingForm(false);
    }
  };
  async function loadData(id) {
    try {
      setLoadingSubmit(true);
      const { data: myData } = await axios.get(
        `${API_ENDPOINT}user/get-oes-submit?id=${id}`,
        getHeader()
      );
      const formId = myData.formId;
      delete myData.formId;
      delete myData._id;
      myData._id = formId;
      const form = data.find((item) => item._id === formId);
      setSelectedForm({ ...myData, disabled: form.disabled });
      setFormFound(true);
    } catch (error) {
      const message = getErrorResponse(error);
      if (message === "Not Found") {
        setFormFound(false);
        const form = data.find((item) => item._id === id);
        setSelectedForm(form);
      }
    } finally {
      setLoadingSubmit(false);
    }
  }
  const menuContent = (
    <Paper
      elevation={3}
      sx={{
        width: { xs: 250, md: "25%" },
        p: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Online Exhibitor Services
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <List>
        {data?.map((form) => (
          <ListItemButton
            disabled={form.disabled}
            key={form.serial}
            selected={selectedForm?.name === form.name}
            onClick={() => handleListItemClick(form)}
            sx={{
              mb: 1,
              borderRadius: 2,
              bgcolor:
                selectedForm?.name === form.name
                  ? "primary.light"
                  : "background.paper",
            }}
          >
            <Avatar
              sx={{
                width: 28,
                height: 28,
                fontSize: 14,
                mr: 1,
                bgcolor:
                  selectedForm?.name === form.name
                    ? "primary.main"
                    : "grey.400",
                color: "white",
              }}
            >
              {form.serial}
            </Avatar>
            <ListItemText
              primary={form.name}
              secondary={`Deadline: ${extractDate(form.expiry_date, "en-IN")}`}
              primaryTypographyProps={{ variant: "body2" }}
              secondaryTypographyProps={{ variant: "caption" }}
            />
          </ListItemButton>
        ))}
      </List>
    </Paper>
  );

  return (
    <Box
      maxWidth={1380}
      display="flex"
      ml={"auto"}
      mr={"auto"}
      flexDirection={{ xs: "column", md: "row" }}
      width="100%"
      pl={2}
      pr={2}
      pt={1}
      pb={1}
      gap={2}
    >
      <CustomCircularP show={loadingSubmit} />
      {isMobile ? (
        <>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mb: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={openDrawer}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {menuContent}
          </Drawer>
        </>
      ) : (
        menuContent
      )}

      <Box width={{ xs: "100%", md: "75%" }}>
        {loadingSubmit && <LinearProgress />}
        {formFound !== null &&
          formFound === false &&
          selectedForm?.suppliers?.length > 0 && (
            <Alert severity="info">
              You have not submitted this form. Last date of form submission is{" "}
              {selectedForm?.expiry_date
                ? new Date(selectedForm.expiry_date).toLocaleDateString("en-IN")
                : ""}
            </Alert>
          )}

        {formFound !== null && formFound === true && (
          <Alert severity="success">
            You submitted this form on{" "}
            {selectedForm?.submittedOn
              ? new Date(selectedForm.submittedOn).toLocaleDateString("en-IN")
              : ""}
          </Alert>
        )}
        <Card
          sx={{
            width: "100%",
            maxWidth: {
              xs: "320px", // Max width of 300px on extra-small screens
              sm: "400px", // Max width of 400px on small screens
              md: "100%", // Full width on medium and larger screens
            },
          }}
        >
          <CardContent>
            {selectedForm ? (
              <Box>
                {selectedForm.disabled !== true ? (
                  <>
                    {renderFinalForm()}

                    <Box>
                      {selectedForm?.suppliers?.length > 0 && (
                        <>
                          <Divider /> <br />
                          <SelectSupplierDetail
                            selectedSupplier={selectedForm?.selectedSupplier}
                            supplierList={selectedForm.suppliers || []}
                            onChange={(newSup) => {
                              setSelectedForm({
                                ...selectedForm,
                                selectedSupplier: newSup,
                              });
                            }}
                          />
                        </>
                      )}

                      {selectedForm?.suppliers?.length > 0 && (
                        <Box mt={2} display={"flex"} justifyContent={"center"}>
                          {" "}
                          <LoadingButton
                            disabled={!selectedForm.selectedSupplier}
                            onClick={onFormSubmit}
                            loading={submittingForm}
                            variant="contained"
                          >
                            Submit
                          </LoadingButton>
                        </Box>
                      )}
                    </Box>
                  </>
                ) : (
                  <Paper
                    elevation={3}
                    sx={{
                      padding: 4,
                      ml: "auto",
                      mr: "auto",
                      maxWidth: 400,
                      width: "100%",
                    }}
                  >
                    <Alert severity="info" sx={{ marginBottom: 2 }}>
                      This form is currently disabled.
                    </Alert>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="center"
                    >
                      Please check back later or contact support for more
                      information.
                    </Typography>
                  </Paper>
                )}
                {selectedForm && (
                  <Box mt={2}>
                    <BackAndForthNav
                      currentIndex={selectedForm.serial - 1}
                      arrayLength={data.length}
                      onBackPress={(i) => {
                        const item = data?.find((it) => it.serial === i + 1);
                        if (item) {
                          navigate(`/exhibitor/oes-forms?id=${item._id}`);
                          moveToTop();
                        }
                      }}
                      onForwardPress={(i) => {
                        const item = data?.find((it) => it.serial === i + 1);
                        if (item) {
                          navigate(`/exhibitor/oes-forms?id=${item._id}`);
                          moveToTop();
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
            ) : (
              <Alert severity="info">
                <Typography gutterBottom variant="h6">
                  Select form from the left menu
                </Typography>
              </Alert>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default OesForms;
