import React from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  useTheme,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import EcoIcon from "@mui/icons-material/EnergySavingsLeaf";
import GroupIcon from "@mui/icons-material/Group";
import { Link, Link as RouterLink } from "react-router-dom";

const HomePage = () => {
  const theme = useTheme(); // Use theme for consistent styling

  return (
    <Container maxWidth="lg">
      <Box
        textAlign="center"
        py={5}
        bgcolor={theme.palette.background.default}
        borderRadius={2}
      >
        <Typography
          variant="h2"
          gutterBottom
          color={theme.palette.primary.main}
        >
          ExpoPlanner
        </Typography>
        <Typography variant="h5" color="textSecondary">
          All-in-one event platform for your audience
        </Typography>
        <Link to={`/login`}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mt: 3 }}
          >
            Get Started
          </Button>
        </Link>
      </Box>

      <Grid container spacing={4} justifyContent="center">
        {features.map((feature) => (
          <Grid item xs={12} sm={6} md={4} key={feature.title}>
            <Card sx={{ "&:hover": { boxShadow: 6 } }}>
              <CardActionArea>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box
                    color={theme.palette.primary.main}
                    sx={{ "& svg": { fontSize: 60 } }}
                  >
                    {feature.icon}
                  </Box>
                  <Typography gutterBottom variant="h5" component="div">
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box
        component="footer"
        sx={{
          py: 3,
          mt: 4,
          bgcolor: theme.palette.background.paper,
          textAlign: "center",
        }}
      >
        <Typography variant="subtitle1">Connect with us</Typography>
        <RouterLink
          to="/privacy-policy"
          style={{
            margin: "0 8px",
            textDecoration: "none",
            color: theme.palette.primary.main,
          }}
        >
          Privacy Policy
        </RouterLink>
        <RouterLink
          to="/terms-of-use"
          style={{
            margin: "0 8px",
            textDecoration: "none",
            color: theme.palette.primary.main,
          }}
        >
          Terms of Use
        </RouterLink>
        <RouterLink
          to="/contact-us"
          style={{
            margin: "0 8px",
            textDecoration: "none",
            color: theme.palette.primary.main,
          }}
        >
          Contact Us
        </RouterLink>
      </Box>
    </Container>
  );
};

const features = [
  {
    title: "Smart Event App",
    description: "Seamless lead intelligence, website builder, and more.",
    icon: <EventIcon />,
  },
  {
    title: "AI Matchmaking",
    description: "Spark high-value connections with AI-powered matchmaking.",
    icon: <GroupIcon />,
  },
  {
    title: "Sustainability",
    description: "Reduce your event's carbon footprint with our programme.",
    icon: <EcoIcon />,
  },
];

export default HomePage;
