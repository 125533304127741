import React from "react";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";

const ProfileViewer = ({ id }) => {
  const { isLoading, isError, error, data } = useCommonGetInit(
    `user/profile-view?id=${id}`
  );
  return <></>;
};

export default ProfileViewer;
