import React, { useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import PropTypes from "prop-types";

const SelectSupplierDetail = ({ supplierList, selectedSupplier, onChange }) => {
  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const supplier = supplierList.find((s) => s._id === selectedId);
    if (onChange) {
      onChange(supplier);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }} // Stack on small screens, row on larger screens
      gap={2}
    >
      <Box width={{ xs: "100%", sm: "30%" }}>
        <Select
          fullWidth
          value={selectedSupplier ? selectedSupplier._id : ""}
          onChange={handleSelectChange}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select a Supplier
          </MenuItem>
          {supplierList.map((supplier) => (
            <MenuItem key={supplier._id} value={supplier._id}>
              {supplier.supplierName}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box width={{ xs: "100%", sm: "70%" }}>
        <Card>
          <CardContent>
            {selectedSupplier ? (
              <>
                <Typography variant="h6" gutterBottom>
                  {selectedSupplier.supplierName}
                </Typography>
                <Typography variant="body2">
                  <strong>Address:</strong> {selectedSupplier.address}
                </Typography>
                <Typography variant="body2">
                  <strong>Contact Person:</strong>{" "}
                  {selectedSupplier.contactPerson}
                </Typography>
                <Typography variant="body2">
                  <strong>Email:</strong> {selectedSupplier.emails.join(", ")}
                </Typography>
                <Typography variant="body2">
                  <strong>Phone Number:</strong> {selectedSupplier.phoneNumber}
                </Typography>
              </>
            ) : (
              // Placeholder content when no supplier is selected
              <Typography variant="body2" color="textSecondary">
                Please select a supplier from the dropdown to view details.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

SelectSupplierDetail.propTypes = {
  supplierList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      supplierName: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      contactPerson: PropTypes.string.isRequired,
      emails: PropTypes.arrayOf(PropTypes.string).isRequired,
      phoneNumber: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedSupplier: PropTypes.object,
  onChange: PropTypes.func,
};

export default SelectSupplierDetail;
