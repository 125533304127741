import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Paper,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid, // Import Grid
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import CustomCircularP from "./widgets/CustomCircularP";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const dates = [
  "2024-01-19",
  "2024-01-20",
  "2024-01-21",
  "2024-01-22",
  "2024-01-23",
];

const FootFallHallOne = () => {
  const [footfallCounts, setFootfallCounts] = useState({});
  const [hourlyCounts, setHourlyCounts] = useState({});
  const [overallHourlyCounts, setOverallHourlyCounts] = useState({});
  const [alltotal, setAlltotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(dates[4]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://apis.expoplanner.in/visitor/get-scanned-users-temp-pbi-hall-one"
        );
        const dataTemp = response.data;
        const uniqueItemsMap = new Map();
        dataTemp.forEach((item) => {
          if (!uniqueItemsMap.has(item.barcodeId)) {
            uniqueItemsMap.set(item.barcodeId, item);
          }
        });
        const data = Array.from(uniqueItemsMap.values());
        setAlltotal(data.length);

        const selectedDateObj = new Date(selectedDate);
        const nextDay = new Date(selectedDateObj);
        nextDay.setDate(selectedDateObj.getDate() + 1);

        const todaysData = data.filter((user) => {
          const createdDate = new Date(user.createdOn);
          return createdDate >= selectedDateObj && createdDate < nextDay;
        });

        // Count for selected date
        const counts = {};
        todaysData.forEach((user) => {
          const userType = user.user_type || "Regular Visitor";
          if (counts[userType]) {
            counts[userType] += 1;
          } else {
            counts[userType] = 1;
          }
        });
        setFootfallCounts(counts);

        // Hourly count for selected date
        const hourly = {
          "9am-10am": 0,
          "10am-11am": 0,
          "11am-12pm": 0,
          "12pm-1pm": 0,
          "1pm-2pm": 0,
          "2pm-3pm": 0,
          "3pm-4pm": 0,
          "4pm-5pm": 0,
        };
        todaysData.forEach((user) => {
          const hour = new Date(user.createdOn).getHours();
          // Increment the appropriate hourly count
          const hourLabel = getHourLabel(hour);
          if (hourLabel) {
            hourly[hourLabel]++;
          }
        });
        setHourlyCounts(hourly);

        // Overall hourly count
        const overallHourly = {
          "9am-10am": 0,
          "10am-11am": 0,
          "11am-12pm": 0,
          "12pm-1pm": 0,
          "1pm-2pm": 0,
          "2pm-3pm": 0,
          "3pm-4pm": 0,
          "4pm-5pm": 0,
        };
        data.forEach((user) => {
          const hour = new Date(user.createdOn).getHours();
          // Increment the appropriate hourly count
          const hourLabel = getHourLabel(hour);
          if (hourLabel) {
            overallHourly[hourLabel]++;
          }
        });
        setOverallHourlyCounts(overallHourly);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const totalOverall = Object.values(footfallCounts).reduce(
    (acc, count) => acc + count,
    0
  );

  const getHourLabel = (hour) => {
    if (hour >= 9 && hour < 10) return "9am-10am";
    else if (hour >= 10 && hour < 11) return "10am-11am";
    else if (hour >= 11 && hour < 12) return "11am-12pm";
    else if (hour >= 12 && hour < 13) return "12pm-1pm";
    else if (hour >= 13 && hour < 14) return "1pm-2pm";
    else if (hour >= 14 && hour < 15) return "2pm-3pm";
    else if (hour >= 15 && hour < 16) return "3pm-4pm";
    else if (hour >= 16 && hour < 17) return "4pm-5pm";
    return null; // for hours outside the defined range
  };

  // Data and options for the Hourly Counts Bar Chart
  const hourlyCountsData = {
    labels: Object.keys(hourlyCounts),
    datasets: [
      {
        label: "Hourly Footfall (Selected Date)",
        data: Object.values(hourlyCounts),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const overallHourlyCountsData = {
    labels: Object.keys(overallHourlyCounts),
    datasets: [
      {
        label: "Overall Hourly Footfall",
        data: Object.values(overallHourlyCounts),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const hourlyCountsOptions = {
    scales: {
      yAxis: {
        beginAtZero: true,
      },
    },
  };

  const footfallCountsData = {
    labels: Object.keys(footfallCounts),
    datasets: [
      {
        label: "Footfall by User Type",
        data: Object.values(footfallCounts),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#cc65fe",
          "#ff9f40",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#cc65fe",
          "#ff9f40",
        ],
      },
    ],
  };

  const displayDate = new Date(selectedDate).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return (
    <Box p={2} maxWidth={1280} ml={"auto"} mr={"auto"}>
      <CustomCircularP show={loading} />
      <Typography variant="h4" component="h2" gutterBottom>
        Footfall Analytics
      </Typography>

      <Typography variant="h5" component="h3" gutterBottom>
        Total Till Date: {alltotal}
      </Typography>
      <Grid item xs={12} md={12}>
        <Paper style={{ padding: "20px", height: "100%" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Overall Hourly Footfall Counts
          </Typography>
          <Bar data={overallHourlyCountsData} options={hourlyCountsOptions} />
        </Paper>
      </Grid>
      <Typography mt={4} variant="h5" component="h3" gutterBottom>
        Total as on {displayDate}: {totalOverall}
      </Typography>

      <FormControl size="small" fullWidth style={{ marginBottom: "20px" }}>
        <InputLabel id="date-select-label">Select Date</InputLabel>
        <Select
          labelId="date-select-label"
          id="date-select"
          value={selectedDate}
          label="Select Date"
          onChange={handleDateChange}
        >
          {dates.map((date) => (
            <MenuItem key={date} value={date}>
              {date}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container spacing={2}>
        {/* Grid item for the Bar chart of the selected date */}
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: "20px", height: "100%" }}>
            <Typography variant="h5" component="h3" gutterBottom>
              Hourly Footfall Counts (Selected Date)
            </Typography>
            <Bar data={hourlyCountsData} options={hourlyCountsOptions} />
          </Paper>
        </Grid>

        {/* Grid item for the Doughnut chart */}
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: "20px", height: "100%" }}>
            <Typography variant="h5" component="h3" gutterBottom>
              Footfall Count by User Type
            </Typography>
            <Doughnut data={footfallCountsData} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FootFallHallOne;
