import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import RegHeader from "../../headers/regHeader";
import { EXHIBITOR_API } from "../../../constants/constants";
import { EbadgeService } from "../../dashboard/visitor/EbadgeService";
export const getCategoryName = (userObj) => {
  const category = userObj.user_type
    ? userObj.user_type
    : "Pre-Registered Visitor";
  return category;
};

function ScanService() {
  const [currentUser, setCurrentUser] = useState();
  const [barcodeId, setBarcodeId] = useState("");
  const [loading, setLoading] = useState();
  const inputRef = useRef();

  const handleScanInput = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const scannedValue = event.target.value;
      inputRef.current.value = "";
      loadUser(scannedValue);
      setBarcodeId("");
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  async function loadUser(id) {
    try {
      const response = await axios.get(
        EXHIBITOR_API + `get-service-badge-barcode?id=${id}`
      );
      if (response.status === 200) {
        toast.success("Successfully Scanned!", { autoClose: 1 });
        setCurrentUser(response.data);
      } else if (response.status === 204) {
        // Handle successful response with no content
        toast.info("Invalid QR!", { autoClose: 1 });
      }
    } catch (error) {
      // Check if the error has a response object
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // If the response has a data object with a message, display it
        toast.error(error.response.data.message);
      } else {
        // Otherwise, fall back to a generic error message
        toast.error("An error occurred while registering. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      style={{
        width: "100vw",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <RegHeader />
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={1280}
        width={"100%"}
        margin="auto"
        gap={1}
        paddingBottom={1}
        flexGrow={1}
      >
        <Box
          sx={{
            flexGrow: 1, // This will make the box grow to fill available space
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Box style={{ display: "block" }} ml={"auto"} mr={"auto"} mb={4}>
            <input
              ref={inputRef}
              id="barcodeInput"
              type="text"
              // value={barcodeId}
              // onChange={handleScanInput}
              onKeyDown={handleScanInput}
              placeholder="Scan Badge"
              style={{
                padding: "10px",
                fontSize: "16px",
                width: "300px",
                textAlign: "center",
                borderRadius: "4px",
                border: "2px solid #000",
              }}
            />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"}>
          {currentUser && (
            <Box display={"flex"} gap={4}>
              <Box>
                <img
                  src={currentUser.photoUrl}
                  style={{
                    display: "block",
                    height: "14cm",
                    width: "auto",
                    marginTop: "16px",
                  }}
                />
              </Box>
              <EbadgeService isPreview={true} user={currentUser} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ScanService;
