import React from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
const eventsArray = [
  {
    eventId: "E1",
    logo: "https://apptest.ra2sa.com/images/imtex1.png",
    name: "Imtex Forming 2024",
    eventTags: ["Technology", "Innovation", "Startups"],
    country: "India",
    city: "Bangalore",
    startDate: "2024-19-01",
    endDate: "2024-23-01",
    type: "Technology",
    attachedTo: "Tech Conference 2023",
    action: "Clone",
  },
  {
    eventId: "E2",
    logo: "https://apis.expoplanner.in/images/pic1694666542898f480c029-5e41-4bf9-ad34-c4181932d400.png",
    name: "Pune Machine Tool Expo 2024",
    eventTags: ["Renewable Energy", "Sustainability", "Environment"],
    country: "India",
    city: "Pune",
    startDate: "2024-23-05",
    endDate: "2024-26-05",
    type: "Machine Tools",
    attachedTo: "World Energy Summit",
    action: "Clone",
  },
];
const AllEvents = () => {
  const navigate = useNavigate();
  return (
    <div>
      <table style={{ width: "100%" }} className="expo-table">
        <thead>
          <tr>
            <th>Logo</th>
            <th>Name</th>
            <th>Location</th>
            <th>Date To</th>
            <th>Date From</th>
            <th>Type</th>
            <th>Manage</th>
            <th>Clone</th>
          </tr>
        </thead>
        <tbody>
          {eventsArray.map((expo, i) => (
            <tr key={i}>
              <td data-label="Logo">
                <img src={expo.logo} alt={`${expo.name} Logo`} />
              </td>
              <td data-label="Name">
                <Link to={`manage/${expo.eventId}`}> {expo.name}</Link>
              </td>
              <td data-label="Location">{`${expo.city}, ${expo.country}`}</td>
              <td data-label="DateFrom">{`${expo.startDate}`}</td>
              <td data-label="DateTo">{`${expo.endDate}`}</td>
              <td data-label="Type">{expo.type}</td>
              <td data-label="Manage">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: "none" }}
                  to={`/event-settings?eventId=${expo.eventId}`}
                  component={Link}
                >
                  Manage
                </Button>
              </td>
              <td data-label="Clone">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: "none" }}
                  to={`/event-settings?eventId=${expo.eventId}`}
                  component={Link}
                >
                  Clone
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllEvents;
