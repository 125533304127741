import React, { useState } from 'react';
import './RdLoadingButton.css'
import RdLoader from './RdLoader';

const RdLoadingButton = (
  {
    btnWidth = "auto",
    label = "",
    loaderSize=20,
    loading = false,
    icon = null,
    iconPosition = 'start',
    onClick,
    isActive = true,
    disabled = false,
    margin = '12px',
    bgColor = 'var(--primary-color)',
    bgHoverColor = 'var(--primary-hover-color)',
    textColor = 'white',
    myStyle = {}
  }
) => {
  const [hover, setHover] = useState(false);
  const inActiveColor = '#a9a9a9'
  const getBackgroundColor = () => {
    if (loading || disabled) {
      return inActiveColor; // replace with the color when loading or disabled
    } else {
      return hover ? bgHoverColor :  !isActive? inActiveColor:bgColor;
    }
  }  
   const rdBtnStyle = {
        display: 'flex',
        padding: '8px',
        alignItems: 'center',
        margin: '12px',
        fontWeight: '600',
        borderRadius: '20px',
        cursor: 'pointer',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        backgroundColor: getBackgroundColor(),
        width: btnWidth,
        ...myStyle
    }
    const onBtnClicked = ()=>{
      if(!loading && !disabled && onClick !== undefined){
        onClick()
      }
    }
    return (
        <div 
        style={rdBtnStyle} 
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}

        onClick={onBtnClicked}
        >
          {loading &&
             <RdLoader
                width={loaderSize} 
                height={loaderSize} 
                borderSize={2}
                borderColor='#f3f3f3' 
                borderTopColor='rgb(85 88 89)' 
              />}
      {
        !loading && icon !== null && iconPosition ==='start' && icon
      }
      <div style={{display: 'flex', fontWeight: 'inherit', justifyContent: 'center', flex: 1, marginLeft: `-${loading? loaderSize/2:0}px`, color: textColor}}>
        {label}
      </div>
      {
        !loading && icon !== null && iconPosition !=='start' && icon
      }
        </div>
    );
};

export default RdLoadingButton;