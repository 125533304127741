import React, { useState } from "react";
import BackBtn from "../../admin/widgets/BackBtn";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import MuPb from "../../../../widgets/MuPb";
import { Alert, Box } from "@mui/material";
import ProfileDetails from "./ProfileDetails";
import VProfileDetails from "../../visitor/ProfileDetails";
import { useParams, useSearchParams } from "react-router-dom";
import { USER, VISITOR } from "../../../../constants/constants";

const ProfileViewByID = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { isLoading, isError, error, data } = useCommonGetInit(
    `user/get-user-by-id?id=${id}`
  );
  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert security="error">{error.message}</Alert>;
  }
  return (
    <Box>
      <Box ml={2} mt={1}>
        <BackBtn />
      </Box>
      {data.role === VISITOR ? (
        <></>
      ) : (
        <ProfileDetails user={data} isView={true} id={id} />
      )}
    </Box>
  );
};
const ProfileContainer = ({ user: userData, id }) => {
  const [user, setUser] = useState(userData);
  return (
    <VProfileDetails user={user} setUser={setUser} isView={true} id={id} />
  );
};

export default ProfileViewByID;
