import Switch from "@mui/material/Switch";
const ConfigItem = ({ label, checked, setChecked }) => {
  const onChange = () => {
    setChecked(!checked);
  };
  return (
    <div
      style={{
        marginTop: "12px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "1px 14px",
        userSelect: "none",
        cursor: "pointer",
        display: "flex",
      }}
    >
      <div
        style={{
          alignSelf: "center",
          flex: "1",
        }}
      >
        {label}
      </div>
      <Switch checked={checked} onChange={onChange} />
    </div>
  );
};
export default ConfigItem;
