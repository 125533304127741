import React from "react";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { Grid, LinearProgress } from "@mui/material";
import ExhibitorCard from "../../../widgets/ExhibitorCard";

const ExhibitorFavorites = () => {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-exhibitor-favorites`
  );

  return (
    <div>
      {isLoading && <LinearProgress />}
      <Grid container spacing={3}>
        {data?.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <ExhibitorCard key={index} item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ExhibitorFavorites;
