import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Checkbox,
  Typography,
  TableFooter,
  FormControlLabel,
} from "@mui/material";
import MyDateRange from "./MyDateRange";
import dayjs from "dayjs";

const RatedItemsRender = ({
  headers = [],
  itemsList = [],
  isColumnSelection = false,
  totalRequired,
  min,
  max,
  totalVar,
  onChange,
  selectedColumn,
  onColumnSelect,
}) => {
  const subTotal = (rowIndex, headerIndex, myItems) => {
    const formulaString = headers[headerIndex]["formula"];
    const suffixes = formulaString
      .match(/key(\d+)/g)
      .map((match) => match.replace("key", ""));

    // "input number", "input checkbox", "label checkbox", "input date range"
    const values = [];
    for (const suffix of suffixes) {
      const ci = parseInt(suffix) - 1;
      const type = headers[ci].type;
      if (type === "input number") {
        values.push(parseInt(myItems[rowIndex][`value${ci}`]) || 0);
      }
      if (type === "label") {
        values.push(parseInt(myItems[rowIndex][`key${suffix}`]) || 0);
      }
      if (type === "input checkbox") {
        if (myItems[rowIndex][`value${ci}`]) {
          values.push(1);
        } else {
          values.push(0);
        }
      }
      if (type === "label checkbox") {
        if (myItems[rowIndex][`value${ci}`]) {
          values.push(parseInt(myItems[rowIndex][`key${suffix}`]) || 0);
        } else {
          values.push(0);
        }
      }
      if (type === "input date range") {
        if (
          myItems[rowIndex][`dateTo${ci}`] &&
          myItems[rowIndex][`dateFrom${ci}`]
        ) {
          const diffInDays =
            dayjs(myItems[rowIndex][`dateFrom${ci}`]).diff(
              dayjs(myItems[rowIndex][`dateTo${ci}`]),
              "day"
            ) + 1;
          values.push(diffInDays);
        } else {
          values.push(0);
        }
      }
    }
    let updatedFormula = formulaString;
    suffixes.forEach((suffix, index) => {
      const keyPattern = new RegExp(`key${suffix}`, "g");
      updatedFormula = updatedFormula.replace(keyPattern, values[index]);
    });
    let total = 0;
    try {
      total = eval(updatedFormula);
    } catch (error) {
      console.error("Error evaluating formula:", error);
    }

    return total;
  };

  useEffect(() => {
    if (selectedColumn) {
    }
  }, [selectedColumn]);

  const getColumnTotal = () => {
    if (selectedColumn) {
      const suffix = selectedColumn + 1;
      const sum = itemsList.reduce((accumulator, item) => {
        const checked = item[`value${selectedColumn}`] === true; // Check if the specific value is true
        const value = parseFloat(
          (item[`key${suffix}`] || "0").toString().replace(/,/g, "")
        );
        return accumulator + (checked && !isNaN(value) ? value : 0);
      }, 0);
      return sum;
    } else {
      return 0;
    }
  };

  const valueChange = (rowIndex, columnIndex, propName, value) => {
    const calcIndex = headers.findIndex((item) => item.type === "calc");
    const myArray = [...itemsList];
    const targetObject = myArray[rowIndex];
    console.log(propName, value);
    targetObject[propName] = value;

    if (calcIndex !== -1) {
      targetObject[`calcVal${calcIndex}`] = subTotal(
        rowIndex,
        calcIndex,
        myArray
      );
    }
    if (isColumnSelection) {
      onColumnSelect(columnIndex);

      const updatedArray = myArray.map((item) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
          if (key === `value${columnIndex}` || !key.startsWith("value")) {
            newItem[key] = item[key];
          }
        });
        return newItem;
      });
      onChange(updatedArray);
    } else {
      onChange(myArray);
    }
  };
  const getCalcTotal = () => {
    const calcIndex = headers.findIndex((item) => item.type === "calc");
    const total = itemsList.reduce(
      (acc, obj) => acc + (obj[`calcVal${calcIndex}`] || 0),
      0
    );
    return total;
  };
  const totalValue = totalRequired
    ? isColumnSelection
      ? getColumnTotal()
      : getCalcTotal()
    : 0;
  return (
    <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.key}>{header.header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {itemsList.map((row, index) => (
            <TableRow key={index}>
              {headers.map((header, i) => {
                if (header.type === "label") {
                  return (
                    <TableCell key={header.key}>
                      <Typography minWidth={"86px"} variant="body2">
                        {!isNaN(parseFloat(row[`key${i + 1}`])) &&
                        isFinite(row[`key${i + 1}`])
                          ? parseFloat(row[`key${i + 1}`]).toLocaleString()
                          : row[`key${i + 1}`]}
                      </Typography>
                    </TableCell>
                  );
                } else if (header.type === "label checkbox") {
                  return (
                    <TableCell key={header.key}>
                      <FormControlLabel
                        label={
                          <Typography variant="body2">
                            {row[`key${i + 1}`]}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            size="small"
                            checked={row[`value${i}`] || ""}
                            onChange={(event) => {
                              valueChange(
                                index,
                                i,
                                `value${i}`,
                                event.target.checked
                              );
                            }}
                            disabled={
                              row?.disabledItems &&
                              row.disabledItems.includes(i)
                            }
                          />
                        }
                      />
                    </TableCell>
                  );
                } else if (
                  header.type === "input text" ||
                  header.type === "input number"
                )
                  return (
                    <TableCell key={header.key}>
                      <TextField
                        sx={{ minWidth: "72px" }}
                        type={
                          header.type === "input number" ? "number" : "text"
                        }
                        value={row[`value${i}`] || ""}
                        onChange={(event) => {
                          valueChange(
                            index,
                            i,
                            `value${i}`,
                            event.target.value
                          );
                        }}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    </TableCell>
                  );
                else if (header.type === "input date range")
                  return (
                    <TableCell key={header.key}>
                      <MyDateRange
                        textSize={13}
                        dateToVal={row[`dateTo${i}`]}
                        onChangeDateToVal={(newV) => {
                          valueChange(index, i, `dateTo${i}`, newV);
                        }}
                        dateFromVal={row[`dateFrom${i}`]}
                        onChangeDateFromVal={(newV) => {
                          valueChange(index, i, `dateFrom${i}`, newV);
                        }}
                        min={min}
                        max={max}
                      />
                    </TableCell>
                  );
                else if (header.type === "input checkbox")
                  return (
                    <TableCell key={header.key}>
                      <Checkbox
                        size="small"
                        checked={row[`value${i}`] || null}
                        onChange={(event) => {
                          valueChange(
                            index,
                            i,
                            `value${i}`,
                            event.target.checked
                          );
                        }}
                        disabled={
                          row?.disabledItems && row.disabledItems.includes(i)
                        }
                      />
                    </TableCell>
                  );
                else if (header.type === "calc")
                  return (
                    <TableCell key={header.key}>
                      <Typography minWidth={"76px"} variant="body2">
                        {row[`calcVal${i}`]
                          ? row[`calcVal${i}`].toLocaleString()
                          : ""}
                      </Typography>
                    </TableCell>
                  );
                else return <></>;
              })}
            </TableRow>
          ))}
        </TableBody>
        {totalRequired && (
          <TableFooter>
            <TableRow>
              <TableCell align="right" colSpan={headers.length - 1}>
                Total
              </TableCell>
              <TableCell>{totalValue.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" colSpan={headers.length - 1}>
                18% GST
              </TableCell>
              <TableCell>
                {Math.round(totalValue * 0.18).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" colSpan={headers.length - 1}>
                Grand Total
              </TableCell>
              <TableCell>
                {Math.round(totalValue + totalValue * 0.18).toLocaleString()}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default RatedItemsRender;
