import React from "react";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const ProfileActivities = () => {
  const { isLoading, isError, error, data } = useCommonGetInit(
    `user/profile-activities`
  );

  return (
    <Box maxHeight={"276px"} overflow={"auto"}>
      <List>
        {data?.map((activity) => (
          <ListItem key={activity._id} disablePadding>
            <ListItemButton
              component={Link}
              to={`profile-view/${activity.agent_id}`} // Link to the target_id
            >
              <ListItemText
                primary={activity.message}
                secondary={
                  <Typography color={"#777"} variant="caption">
                    {dayjs(activity.updatedOn).format("DD/MM/YYYY h:mm A")}
                  </Typography>
                } // Format the updatedOn date
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ProfileActivities;
