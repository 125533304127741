import { useQuery } from "react-query";
import axios from "axios";
import { getOnsiteHeader } from "../../../utils2024/utils";

const doAction = async (path, type) => {
  try {
    const header = getOnsiteHeader(type);
    const res = await axios.get(path, header);
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useOnsiteInit = (path, type) => {
  return useQuery(["com-init-get", path], () => doAction(path, type), {
    staleTime: 1 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 2, // If the query fails, it will retry up to 3 times
  });
};

export default useOnsiteInit;
