import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";

const MyTimePicker = ({
  value,
  fullWidth = true,
  disabled = false,
  size = "small",
  onChange,
  required = false,
  label = "Select Time",
  ...otherProps
}) => {
  // If value is not provided, manage the state internally.
  const [internalValue, setInternalValue] = useState(null);

  const handleChange = (newValue) => {
    // If an onChange function is provided, use it; otherwise, update the internal state.
    if (onChange) {
      onChange(newValue);
    } else {
      setInternalValue(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        disabled={disabled}
        slotProps={{
          textField: {
            fullWidth: fullWidth,
            size: size,
            required: required,
          },
        }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
        label={label}
        value={value !== undefined ? value : internalValue}
        onChange={handleChange}
        {...otherProps}
      />
    </LocalizationProvider>
  );
};

export default MyTimePicker;
